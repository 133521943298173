import { NavLink, useParams } from "react-router-dom"
import { SvgIconsMenu } from "../../components/SvgIconsMenu/SvgIconsMenu"
import { Account } from "./Account/Account"
import { Company } from "./Company/Company"
import { Payments } from "./Payments/Payments"
import { AppMenu } from './../../AppMenu'
import Cookies from "js-cookie"
import { useDispatch } from "react-redux"
import { logout } from "../../service/slices/authSlice"
import { NotFound } from "../NotFound/NotFound"

export const Settings = ({ onMenuItemClick, onSidebarClick, layoutColorMode }) => {
    const { params } = useParams()

    const dispatch = useDispatch()

    const menu = [
        {
            label: '',
            items: [
                { label: 'Аккаунт', icon: 'account', to: '/settings/account' },
                { label: 'Компания', icon: 'company', to: '/settings/company' },
                { label: 'Платежи и карты', icon: 'payments', to: '/settings/payments' },
            ]
        },
        {
            label: '',
            items: [
                { label: 'Справка', icon: 'reference', to: '/settings/reference' },
                {
                    label: 'Выйти из аккаунта', icon: 'sign_out', command: () => {
                        dispatch(logout())
                    }
                },
            ]
        }
    ]

    return (
        <>
            {params === 'account' || params === 'company' || params === 'payments'
                ? <div className="grid justify-content-start">
                    <div className="col-2 mr-5">
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        </div>
                    </div>

                    <div className="settings-content col-12">
                        {params === 'account' && <Account />}
                        {params === 'company' && <Company />}
                        {params === 'payments' && <Payments />}
                    </div>
                </div>
                : <NotFound />
            }

        </>

    )
    {/* <div className="grid justify-content-start">
            <div className="col-2 h-full">
                <ul className='sidemenu fixed col-2 flex flex-column justify-content-between'>
                    <div>
                        <li className='flex align-items-center'>
                            <NavLink className='flex align-items-center p-link p-3 w-10' to="account">
                                <SvgIconsMenu name='account' />
                                <span className="ml-3">Аккаунт</span>
                            </NavLink>
                        </li>
                        <li className='flex align-items-center'>
                            <NavLink to="company" className="p-link flex align-items-center p-3 w-10" >
                                <SvgIconsMenu name='company' width={20} height={19} />
                                <span className="ml-3">Компания</span>
                            </NavLink>
                        </li>
                        <li className='flex align-items-center'>
                            <NavLink to="payments" className="p-link flex align-items-center p-3 w-10" >
                                <SvgIconsMenu name='payments' />
                                <span className="ml-3">Платежи и карты</span>
                            </NavLink>
                        </li>
                    </div>

                    <div>
                        <li className='flex align-items-center'>
                            <NavLink className='flex align-items-center p-link p-3 w-10' to="reference">
                                <SvgIconsMenu name='reference' />
                                <span className="ml-3">Справка</span>
                            </NavLink>
                        </li>
                        <li className='flex align-items-center'>
                            <a href="#" className="sign_out flex align-items-center p-3 w-10" >
                                <SvgIconsMenu name='sign_out' width={20} height={19} />
                                <span className="ml-3">Выйти из аккаунта</span>
                            </a>
                        </li>
                    </div>
                </ul>

            </div>
            <div className="col-7">
                {params === 'account' && <Account />}
                {params === 'company' && <Company professionalSettings={professionalSettings} setProfessionalSettings={setProfessionalSettings} />}
                {params === 'payments' && <Payments />}
            </div>



        </div> */}

}