import React, { useEffect, useState } from 'react';
import { AutoComplete } from "primereact/autocomplete";
import './AddTags.css'
import { useDispatch, useSelector } from 'react-redux';
import { addTagAnswer, deleteTagAnswer } from '../../service/slices/smartAnswersSlice';
import { InputText } from 'primereact/inputtext';
import { InputAddTags } from './InputAddTags';

export const AddTags = ({ edit }) => {
    const answer = useSelector((state) => state.smartAnswers.answer)
    const [showNewTag, setShowNewTag] = useState(false)
    const role = localStorage.getItem('roleUser')
    const user = useSelector((state) => state.user.user)
    return (
        <div>
            {answer.tags ?
                <>
                    {edit
                        ? <>
                            <InputAddTags />
                        </>
                        : <>
                            <p className='text-answer'>Теги</p>
                            <div className="flex flex-wrap align-items-center">
                                {answer.tags?.map((item) => {
                                    return <div className="tag-container geotags-location w-max relative">
                                        <div>{item}</div>

                                    </div>
                                })}

                            </div>
                        </>
                    }

                </>

                :
                <>
                    {(user?.role === 'admin' || user?.role === 'moderator') &&
                        <>
                            {showNewTag
                                ? <>
                                    <h6 className='tags-title'>Теги</h6>
                                    <InputAddTags />
                                </>
                                : <button href="#" className='add-tag_button' onClick={() => setShowNewTag(!showNewTag)}>Добавьте тег</button>}
                        </>
                    }
                </>
            }

        </div>

    )
}
