import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { Editor } from "primereact/editor"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"
import { setInitNodes } from "../../../../service/slices/initNodesSlice"
import QRCodeStyling from "qr-code-styling"

import { createNode, deleteMedia, getFunnel, updateNode } from "../../../../service/funnels"
import SidebarHeader from "../components/SidebarHeader"
import UploadFiles from "../components/UploadFiles"
import "../Sidebar.scss"
import BotButton from "./BotButton"
import FormFields from "./FormFields"

const qrCode = new QRCodeStyling({
    width: 310,
    height: 310,
    image: `${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/magicBots.svg`,
    cornersSquareOptions: {
        color: "#007aff",
        type: "extra-rounded",
    },
    dotsOptions: {
        color: "#2a323d",
        type: "extra-rounded",
    },
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 15,
    },
})

const outputActiveIndex = [4]

const CreateForm = ({ data, onHide, funnelId, centerCoordinates }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)
    const [webformId, setWebformId] = useState(data?.formUniqueName || "")
    const [qrUrl, setQrUrl] = useState(`https://static.bmagic.ru/?formId=${webformId}`)
    const qrCodeBlock = useRef(null)

    const [activeIndex, setActiveIndex] = useState(data ? outputActiveIndex : [0, 1])
    const [isSaved, setIsSaved] = useState(false)

    const [nodeID, setNodeID] = useState("")
    const [blockTitle, setBlockTitle] = useState(data?.title || "")

    const [files, setFiles] = useState(data?.files)

    const [mainTitle, setMainTitle] = useState(data?.mainTitle || "")
    const [subtitle, setSubtitle] = useState(data?.subtitle || "")

    const [formFields, setFormFields] = useState(data?.fields || [])

    const [textForm, setTextForm] = useState(data?.text || "")
    const [colorScheme, setColorScheme] = useState(data?.colorScheme || "dark")
    const [isAgreement, setIsAgreement] = useState(false)

    const [publicOffer, setPublicOffer] = useState(data?.publicOffer || "")
    const [privacyStatement, setPrivacyStatement] = useState(data?.privacyStatement || "")
    const [consentToReceiveAdvertising, setConsentToReceiveAdvertising] = useState(data?.consentToReceiveAdvertising || "")
    const [additionalTerms, setAdditionalTerms] = useState(data?.additionalTerms || "")

    const [bot, setBot] = useState(data?.botID || null)
    const [output, setOutput] = useState("website-form")
    const [position, setPosition] = useState("top")

    const [deletedFile, setDeletedFile] = useState([])

    const colorSchemeOptions = [
        { label: "Светлая", value: "light" },
        { label: "Темная", value: "dark" },
        { label: "Как в системе", value: "system" },
    ]
    const outputTypes = [
        { label: "QR-код", value: "qr" },
        { label: "Веб-форма", value: "website-form" },
        { label: "Ссылка", value: "link" },
    ]
    const positionOptions = [
        { label: "Сверху", value: "top" },
        { label: "Снизу", value: "bottom" },
    ]

    useEffect(() => {
        const node_id = uuid()
        setNodeID(node_id)

        qrCode.append(qrCodeBlock.current)
    }, [])

    // QR CODE
    useEffect(() => {
        qrCode.append(qrCodeBlock.current)
    }, [output])
    useEffect(() => {
        qrCode.update({
            data: qrUrl,
        })
    }, [qrUrl])
    const onDownloadClick = () => {
        qrCode.download({
            extension: "png",
        })
    }

    // Текстовый "редактор"
    const renderHeader = () => {
        return (
            <span className='ql-formats'>
                <button className='ql-bold' aria-label='Bold'></button>
                <button className='ql-italic' aria-label='Italic'></button>
                <button className='ql-underline' aria-label='Underline'></button>
                <button className='ql-strike' aria-label='Strike'></button>
            </span>
        )
    }
    const header = renderHeader()

    // Добавление нового блока
    const deleteMediaHandler = (file) => {
        /*  deleteMedia({ fileObject: "node", nodeID: data?.id, filename: file?.path }) */
        const deletedMedia = files?.filter((fileItem) => fileItem.path !== file?.path)
        setFiles(deletedMedia)
    }

    const saveHandler = () => {
        const formBlock = {
            blueprintID: funnelId,
            title: blockTitle,
            files: files,
            mainTitle,
            subtitle,
            text: textForm,
            colorScheme,
            publicOffer,
            privacyStatement,
            consentToReceiveAdvertising,
            additionalTerms,
            fields: formFields,
            messenger: "Telegram",
            botID: bot ? bot : null,
            data: { title: blockTitle },
        }
        if (data) {
            const updateFormBlock = { ...formBlock, uuid: data?.id, files: files }
            updateNode(updateFormBlock, "form").then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes))))
            onHide()
        } else {
            const createFormBlock = { ...formBlock, uuid: nodeID, position: centerCoordinates }
            createNode(createFormBlock, "form").then((res) => {
                setWebformId(res?.data?.formUniqueName)
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            })
            updateActiveIndex()
            setIsSaved(true)
        }

        // onHide()
    }

    const addField = () => {
        const newField = {
            label: "",
            fieldType: "",
            required: false,
        }
        setFormFields([...formFields, newField])
    }

    const deleteFieldHandler = (fieldType) => {
        const newFields = formFields.filter((field) => field.fieldType !== fieldType)
        setFormFields(newFields)
    }

    const onChangeFields = (id, fieldTitle, fieldType, isRequired) => {
        formFields[id].label = fieldTitle
        formFields[id].fieldType = fieldType
        formFields[id].required = isRequired
    }

    const copyHandler = (textId) => {
        const copyText = document.getElementById(textId)
        let range = new Range()
        range.setStart(copyText, 0)
        range.setEnd(copyText, 1)
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(range)
        document.execCommand("copy")
        document.getSelection().removeAllRanges()
    }

    const hideHandler = () => {
        onHide()
        const dataId = data?.files.map((item) => item.path)
        // console.log(dataId)
        const uploadedFiles = files?.filter((item) => !dataId?.includes(item.path))
        // console.log(uploadedFiles)
        uploadedFiles?.map((item) => deleteMedia({ fileObject: "node", nodeID: data ? data.id : nodeID, filename: item?.path }))

        if (deletedFile?.length !== 0) {
            deletedFile?.map((item) => {
                if (!dataId?.includes(item.path)) {
                    deleteMedia({ fileObject: "node", nodeID: data ? data.id : nodeID, filename: item?.path })
                }
            })
        }
    }

    const updateActiveIndex = () => {
        setActiveIndex([null, null, null, null, outputActiveIndex])
    }

    return (
        <div className='new-node-container form-block flex flex-column justify-content-start'>
            <SidebarHeader title='Форма' icon={"webform"} onHide={hideHandler} />
            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <AccordionTab header='Информация' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === 'employer'}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Контент' headerClassName='accordion-header'>
                        <div className=''>
                            <div className='mb-5'>
                                <UploadFiles
                                    nodeID={data?.id ? data.id : nodeID}
                                    setFiles={setFiles}
                                    files={files}
                                    multiple={false}
                                    uploadText={"Загрузить изображение до 15 мб"}
                                    deleteMediaHandler={deleteMediaHandler}
                                    deletedFile={deletedFile}
                                    setDeletedFile={setDeletedFile}
                                />
                            </div>
                            <div className='mb-3'>
                                <div className='field w-full'>
                                    <span className='p-float-label'>
                                        <InputText
                                            id='mainTitle'
                                            className='block text w-full'
                                            value={mainTitle}
                                            onChange={(e) => setMainTitle(e.target.value)}
                                            readOnly={user?.role === 'employer'}
                                        />
                                        <label htmlFor='mainTitle'>Заголовок формы</label>
                                    </span>
                                </div>
                                <div className='field w-full'>
                                    <span className='p-float-label'>
                                        <InputText
                                            id='subtitle'
                                            className='block text w-full'
                                            value={subtitle}
                                            onChange={(e) => setSubtitle(e.target.value)}
                                            readOnly={user?.role === 'employer'}
                                        />
                                        <label htmlFor='subtitle'>Подзаголовок формы</label>
                                    </span>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <Editor
                                    headerTemplate={header}
                                    value={textForm}
                                    onTextChange={(e) => setTextForm(e.htmlValue)}
                                    placeholder='Текст формы'
                                    readOnly={user?.role === 'employer'}
                                />
                            </div>
                            <div className='mb-3'>
                                <h5 className='mb-3'>Поля ввода данных</h5>
                                {formFields?.map((formField, i) => (
                                    <FormFields
                                        key={i}
                                        id={i}
                                        label={formField.label}
                                        type={formField.fieldType}
                                        required={formField.required}
                                        onChangeFields={onChangeFields}
                                        deleteFieldHandler={deleteFieldHandler}
                                    />
                                ))}
                                {(user?.role === 'admin' || user?.role === 'moderator') &&
                                    <Button className='btn-40 w-full' label='Добавить поле' onClick={() => addField()} />}
                            </div>
                            <div className='mb-3'>
                                <h5>Цветовая схема</h5>
                                <Dropdown
                                    className='select w-full'
                                    value={colorScheme}
                                    options={colorSchemeOptions}
                                    onChange={(e) => setColorScheme(e.value)}
                                    placeholder='Цветовая схема'
                                    disabled={user?.role === 'employer'}
                                />
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Соглашения' headerClassName='accordion-header'>
                        <div className='mt-3 mb-3'>
                            {/* <p>Добавьте согласие, если Ваше предложение касается публичной оферты или политики конфиденциальности.</p>
                            <div className='field-checkbox mb-5'>
                                <InputSwitch
                                    className='settings-switch'
                                    inputId='binary'
                                    checked={isAgreement}
                                    onChange={(e) => setIsAgreement(e.value)}
                                />
                                <label htmlFor='binary'>Добавить соглашение</label>
                            </div> */}
                            <div>
                                <div className='mb-2'>
                                    <h5>Публичная оферта</h5>
                                    <InputText
                                        className='text block w-full'
                                        value={publicOffer}
                                        onChange={(e) => setPublicOffer(e.target.value)}
                                        placeholder=''
                                        readOnly={user?.role === 'employer'}
                                    />
                                </div>
                                <div className='mb-2'>
                                    <h5>Положение о конфиденциальности</h5>
                                    <InputText
                                        className='text block w-full'
                                        value={privacyStatement}
                                        onChange={(e) => setPrivacyStatement(e.target.value)}
                                        placeholder=''
                                        readOnly={user?.role === 'employer'}
                                    />
                                </div>
                                <div className='mb-2'>
                                    <h5>Согласие на получение рекламы</h5>
                                    <InputText
                                        className='text block w-full'
                                        value={consentToReceiveAdvertising}
                                        onChange={(e) => setConsentToReceiveAdvertising(e.target.value)}
                                        placeholder=''
                                        readOnly={user?.role === 'employer'}
                                    />
                                </div>
                                <div className='mb-2'>
                                    <h5>Дополнительные условия</h5>
                                    <InputText
                                        className='text block w-full'
                                        value={additionalTerms}
                                        onChange={(e) => setAdditionalTerms(e.target.value)}
                                        placeholder=''
                                        readOnly={user?.role === 'employer'}
                                    />
                                </div>
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Дополнительно' headerClassName='accordion-header'>
                        <div className='mt-3 mb-3'>
                            <BotButton bot={bot} setBot={setBot} />
                        </div>
                    </AccordionTab>
                    {webformId && (
                        <AccordionTab header='Вывод' headerClassName='accordion-header'>
                            <div className='mt-3 mb-3'>
                                <Dropdown
                                    className='select w-full mb-3'
                                    value={output}
                                    options={outputTypes}
                                    onChange={(e) => setOutput(e.value)}
                                    placeholder='Способ вывода'

                                />
                                {output === "qr" && (
                                    <div>
                                        <div className='flex justify-content-center mb-3 qr-container' ref={qrCodeBlock}></div>
                                        <Button
                                            icon='pi pi-download'
                                            iconPos='left'
                                            className='btn w-full'
                                            label='Скачать Qr-code'
                                            onClick={onDownloadClick}
                                        />
                                    </div>
                                )}
                                {output === "website-form" && (
                                    <div>
                                        <InputTextarea
                                            readOnly
                                            id='webformCode'
                                            className='text w-full website-form-display'
                                            autoResize={true}
                                            value={`<!-- BotMagic 🤖✦-->
<script id="${webformId}" type="text/javascript">
(function(b,o,t,s){
b["MAGIC_BOT_ID"]="${webformId}"
for (var j = 0; j < o.scripts.length; j++) {if (o.scripts[j].src === s) { return; }}
let a, k
k=o.createElement(t), a=o.getElementsByTagName(t)[0], k.async=1, k.src=s, a.parentNode.insertBefore(k,a)
})(window, document, "script", "http://localhost:3000/form.js");
</script>
<!-- BotMagic 🤖✦-->`}
                                        />
                                        <Button
                                            icon='pi pi-clone'
                                            iconPos='left'
                                            className='btn w-full'
                                            label='Скопировать код'
                                            onClick={() => copyHandler("webformCode")}
                                        />
                                    </div>
                                )}
                                {output === "link" && (
                                    <div>
                                        <InputTextarea
                                            readOnly
                                            id='webformLink'
                                            autoResize={true}
                                            className='text w-full website-form-display'
                                            value={`https://static.bmagic.ru/?formId=${webformId}`}
                                        />
                                        <Button
                                            icon='pi pi-clone'
                                            iconPos='left'
                                            className='btn w-full'
                                            label='Скопировать ссылку'
                                            onClick={() => copyHandler("webformLink")}
                                        />
                                    </div>
                                )}
                                {output === "website-popup" && (
                                    <div>
                                        <Dropdown
                                            className='select w-full mb-3'
                                            value={position}
                                            options={positionOptions}
                                            onChange={(e) => setPosition(e.value)}
                                            placeholder='Способ вывода'
                                        />
                                        <div className='mb-5'>
                                            <InputTextarea className='w-full text-area' />
                                        </div>
                                        <Button className='btn w-full' label='Копировать код' />
                                    </div>
                                )}
                                {output === "website-widget" && (
                                    <div>
                                        <Dropdown
                                            className='select w-full mb-3'
                                            value={position}
                                            options={positionOptions}
                                            onChange={(e) => setPosition(e.value)}
                                            placeholder='Способ вывода'
                                        />
                                        <div className='mb-5'>
                                            <InputTextarea className='w-full text-area' />
                                        </div>
                                        <Button className='btn w-full' label='Копировать код' />
                                    </div>
                                )}
                            </div>
                        </AccordionTab>
                    )}
                </Accordion>
            </div>

            <div className={`flex flex-column justify-content-between ${(user?.role === 'admin' || user?.role === 'moderator') ? 'sidebar-buttons' : 'sidebar-buttons close'} `}>
                {(user?.role === 'admin' || user?.role === 'moderator') ?
                    <>
                        {!isSaved && <Button label='Сохранить и закрыть' className='btn py-3 px-4' onClick={() => saveHandler()} />}
                        <Button label='Выйти из редактирования' className='btn-cancel p-button-text py-3 px-4' onClick={hideHandler} />
                    </>
                    : <Button label='Закрыть' className='btn py-3 px-4' onClick={() => onHide()} />
                }
            </div>
        </div>
    )
}

export default CreateForm
