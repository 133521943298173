import { useSelector } from "react-redux"
import { CardNewUsers } from "../../components/CardNewUsers/CardNewUsers"
import { SelectDate } from "../../components/SelectDate/SelectDate"
import { SkeletonDate } from "../../components/SkeletonDate/SkeletonDate"
import { SkeletonDiagram } from "../../components/SkeletonDiagram/SkeletonDiagram"
import { CardDiagramMain } from "../Statistics/CardDiagramMain/CardDiagramMain"
import { SkeletonDiagramMain } from "../Statistics/CardDiagramMain/SkeletonDiagramMain"
import { CardDiagramAudience } from "./CardDiagramAudience"

export const StatisticsAudience = ({ maximize, users, selectDate, formatStart, formatEnd, messangers }) => {

    console.log(messangers)
    return (
        <div className={maximize ? 'hidden' : 'grid px-2 justify-content-between'}>
            <div className="block-diagram mb-3 py-2">
                {messangers ?
                    <CardDiagramMain dataMessangers={messangers} title='Охват аудитории' />
                    /*  <CardDiagramAudience selectDate={selectDate} formatStart={formatStart} formatEnd={formatEnd} /> */
                    :
                    <SkeletonDiagramMain />}
            </div>

            <div className="block-select_date py-2 mb-3">
                {users ?
                    <SelectDate />
                    :
                    <SkeletonDate />}
            </div>
            <div className="mb-3 py-2 mb-2 block-called_answers">
                <CardNewUsers page='audience' />
            </div>

        </div>
    )
}