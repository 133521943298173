import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { createVariable, getVariables, updateVariable } from "../../../../service/funnels"

const AddVariable = ({ funnelId, selectedVariable, setAddVariable, setVariables }) => {
    const [varId, setVarId] = useState(null)
    const [name, setName] = useState("")
    const [type, setType] = useState("")
    const [initialValue, setInitialValue] = useState("")

    useEffect(() => {
        setVarId(selectedVariable?.variableID || "")
        setName(selectedVariable?.name || "")
        setType(selectedVariable?.type || "")
        setInitialValue(selectedVariable?.initialValue || "")
    }, [selectedVariable])

    const variablesType = [
        { label: "Строка", value: "string" },
        { label: "Число", value: "number" },
        { label: "Логический", value: "boolean" },
    ]
    const booleanOptions = [
        { label: "Да", value: "true" },
        { label: "Нет", value: "false" },
    ]

    const saveHandler = () => {
        if (Object.keys(selectedVariable).length !== 0) {
            const updatedVariable = {
                blueprintID: funnelId,
                variableID: varId,
                type: type,
                name: name,
                initialValue: initialValue,
            }
            updateVariable(updatedVariable).then((res) => getVariables(funnelId).then((res) => setVariables(res)))
        } else {
            const variable = {
                blueprintID: funnelId,
                type: type,
                name: name,
                initialValue: initialValue,
            }
            createVariable(variable).then((res) => getVariables(funnelId).then((res) => setVariables(res)))
        }
        // setVarId(null)
        // setName("")
        // setType("")
        // setInitialValue("")

        setAddVariable(false)
    }

    return (
        <div className='block'>
            <div className='field'>
                <span className='p-float-label'>
                    <InputText id='name' className='block text w-full' value={name} onChange={(e) => setName(e.target.value)} />
                    <label htmlFor='name'>Имя переменной</label>
                </span>
            </div>
            <div className='field'>
                <span className='p-float-label'>
                    <Dropdown className='select w-full' value={type} options={variablesType} onChange={(e) => setType(e.value)} />
                    <label htmlFor='initValue'>Тип переменной</label>
                </span>
            </div>
            {type === "string" && (
                <div className='field'>
                    <span className='p-float-label'>
                        <InputText
                            id='initValue'
                            className='block text w-full'
                            value={initialValue}
                            onChange={(e) => setInitialValue(e.target.value)}
                        />
                        <label htmlFor='initValue'>Начальное значение</label>
                    </span>
                </div>
            )}
            {type === "number" && (
                <div className='field'>
                    <span className='p-float-label'>
                        <InputText
                            keyfilter='num'
                            id='initValue'
                            className='block text w-full'
                            value={initialValue}
                            onChange={(e) => setInitialValue(e.target.value)}
                        />
                        <label htmlFor='initValue'>Начальное значение</label>
                    </span>
                </div>
            )}
            {type === "boolean" && (
                <div className='field'>
                    <span className='p-float-label'>
                        <Dropdown
                            className='select w-full'
                            value={initialValue}
                            options={booleanOptions}
                            onChange={(e) => setInitialValue(e.value)}
                        />
                        <label htmlFor='initValue'>Начальное значение</label>
                    </span>
                </div>
            )}
            <div className='flex justify-content-between '>
                <Button className='btn-40 w-full mr-2' label='Сохранить' onClick={() => saveHandler()} />
                <Button className='btn-40 w-full btn-cancel' label='Отменить' onClick={() => setAddVariable(false)} />
            </div>
        </div>
    )
}

export default AddVariable
