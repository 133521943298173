import axios from 'axios';
import Cookies from 'js-cookie';
import { log } from './log';

export const BASE_URL = 'https://bots.sdew.ru'

const apiClient = axios.create({
    baseURL: BASE_URL,
    /*     withCredentials: true */
})

apiClient.interceptors.request.use((config) => {
    return config
})

apiClient.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            await axios.get(`${BASE_URL}/gt/auth/refresh`)
            return apiClient(originalRequest)
        } catch (error) {
            log(error)
        }

    }
    throw error
})
export default apiClient;