import { Button } from "primereact/button"
import { InputSwitch } from "primereact/inputswitch"
import { useState } from "react"
import { useSelector } from "react-redux"
import { MessengersFilter } from "../MessengersFilter/MessengersFilter"
import { SvgIconsFilters } from "../SvgIconsFilters/SvgIconsFilters"


export const Filters = () => {

    const [gender, setGender] = useState(null)
    const [maximize, setMaximize] = useState(false)
    const [mail, setMail] = useState(false)
    const [phone, setPhone] = useState(false)

    const colorTheme = useSelector((state) => state.colorTheme.theme)

    return (
        <div className="grid">
            <div className="flex flex-wrap justify-content-start">
                <div className="flex flex-column align-items-start mr-5 mt-3">
                    <span className="ml-2">Гендер</span>
                    <div>
                        <Button className={gender === 'men' ? `p-button-text active p-2` : `p-button-text ${colorTheme} p-2`} onClick={() => setGender('men')}>
                            <SvgIconsFilters name='men' />
                        </Button>
                        <Button className={gender === 'women' ? `p-button-text active p-2` : `p-button-text ${colorTheme} p-2`} onClick={() => setGender('women')}>
                            <SvgIconsFilters name='women' />
                        </Button>
                    </div>

                </div>

                <div className="flex flex-column align-items-start justify-content-between mb-1 mr-5 mt-3">
                    <span>Почта</span>
                    <InputSwitch className='switch mr-3' checked={mail} onChange={() => setMail(!mail)} />
                </div>

                <div className="flex flex-column align-items-start justify-content-between mb-1 ml-2 mr-5 mt-3">
                    <span>Телефон</span>
                    <InputSwitch className='switch mr-3' checked={phone} onChange={() => setPhone(!phone)} />
                </div>
                <div className="mt-3">
                    <MessengersFilter />
                </div>


            </div>


        </div>
    )
}