import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { SvgIconsMenu } from "../../components/SvgIconsMenu/SvgIconsMenu"
import { resendEmail, setError } from "../../service/slices/authSlice"
import { updateCss } from "../../service/theme"
import { CardAuth } from "./CardAuth"
import { SuccessRegistration } from "./Registration/SuccessRegistration"

export const UnsuccessfulConfirm = () => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const error = useSelector((state) => state.auth.error)

    useEffect(() => {
        updateCss(colorTheme)
    }, [colorTheme])

    const [enterEmail, setEnterEmail] = useState(false)
    const [email, setEmail] = useState('')
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()
    const repeatHandler = async () => {
        let data = {
            email: email,
        }

        dispatch(resendEmail(data)).then(res => {
            if (res.payload.data.status === 200) {
                setEnterEmail(false)
                setSuccess(true)
            } else {
                setEnterEmail(true)
            }
        })
    }

    const backHandler = () => {
        setEnterEmail(false)
        dispatch(setError(null))
    }
    return <div className={`Authorization ${colorTheme} h-full`}>

        <CardAuth>
            {(!enterEmail && !success) &&
                <>
                    <div>
                        <p className='registration-text mt-5 mb-3'>
                            Не удалось подтвердить почту (ссылка неверная или устарела)
                        </p>
                    </div>
                    <Button
                        label='Отправить повторно'
                        onClick={() => setEnterEmail(true)}
                        className="w-full sign_in py-3 my-5" />
                </>
            }
            {enterEmail &&
                <>
                    <div className="flex align-items-center">
                        <Button
                            onClick={backHandler}
                            className="btn-back mr-3" >
                            <SvgIconsMenu
                                name="arrow-back"
                                className={colorTheme} />
                        </Button>
                        <p className="text-left my-5 title">
                            Введите Email
                        </p>
                    </div>

                    <div >
                        <InputText
                            id="email"
                            type="text"
                            value={email}
                            className={`w-full ${error ? 'error-margin' : 'default-margin'}`}
                            placeholder='Email'
                            onChange={(e) => setEmail(e.target.value)} />
                        {error
                            && <small
                                className="p-error block error-margin">
                                {error}
                            </small>}
                        <Button
                            label='Отправить'
                            className="w-full sign_in py-3"
                            onClick={repeatHandler} />
                    </div>
                </>
            }
            {success &&
                <SuccessRegistration />
            }

        </CardAuth>
    </div>
}