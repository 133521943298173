import { useEffect, useRef, useState } from "react";
import './SmartAnswers.css'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from 'primereact/divider';
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { AddNewAnswer } from "./AddNewAnswer";
import { useDispatch, useSelector } from "react-redux";
import { SvgIconsMenu } from "../../components/SvgIconsMenu/SvgIconsMenu";
import { Sidebar } from 'primereact/sidebar';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { EditSmartAnswer } from "./EditSmartAnswer";
import { Skeleton } from "primereact/skeleton";
import { ViewSmartAnswer } from "./ViewSmartAnswer";
import { coverageMessangersAnsvers, disableAnswer, enableAnswer, getAllAnswers, setOnlyEnabled } from "../../service/slices/smartAnswersSlice";
import { StatisticsAnswers } from "./StatisticsAnswers";
import { Paginator } from "primereact/paginator";
import { PaginatorTable } from "../../components/PaginatorTable/PaginatorTable";


export const SmartAnswers = () => {
    const dispatch = useDispatch()
    const role = localStorage.getItem('roleUser')
    const answers = useSelector((state) => state.smartAnswers.answers)

    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(false)
    const [maximize, setMaximize] = useState(localStorage.getItem('maximize-smart_answers') ? JSON.parse(localStorage.getItem('maximize-smart_answers')) : false)
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const date = useSelector((state) => state.selectDate.date)
    const selectDate = useSelector((state) => state.selectDate.selectDate)
    const error = useSelector((state) => state.smartAnswers.error)
    const onlyEnabled = useSelector((state) => state.smartAnswers.onlyEnabled)
    const messangers = useSelector((state) => state.smartAnswers.messangers)
    const user = useSelector((state) => state.user.user)
    // const [messangers, setMessangers] = useState(null)
    const start = useSelector((state) => state.selectDate.start)
    const end = useSelector((state) => state.selectDate.end)

    const [formatStart, setFormatStart] = useState(new Date(start))
    const [formatEnd, setFormatEnd] = useState(new Date(end))

    const totalPages = useSelector((state) => state.smartAnswers.totalPages)
    const [currentPage, setCurrentPage] = useState(0)
    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(null);

    useEffect(() => {
        document.title = 'Bot Magic | Умные ответы'
    }, [])

    useEffect(() => {
        setFormatStart(new Date(start))
        setFormatEnd(new Date(end))
    }, [start, end])

    useEffect(() => {
        const date = {
            start: formatStart,
            end: formatEnd
        }
        dispatch(coverageMessangersAnsvers(date))
    }, [formatStart, formatEnd])

    useEffect(() => {
        const data = {
            currentPage, currentPage,
            basicRows: basicRows
        }
        dispatch(getAllAnswers(data))
    }, [onlyEnabled, currentPage, basicRows])

    /*   useEffect(() => {
          localStorage.setItem('maximize-smart_answers', maximize)
          if (maximize) {
              setBasicRows(13)
          } else {
              setBasicRows(8)
          }
      }, [maximize]) */


    const createDateTemplate = (rowData) => {

        const dateValue = new Date(rowData.createdAt)

        const date = [
            addZero(dateValue?.getDate()),
            addZero(dateValue?.getMonth() + 1),
            dateValue?.getFullYear()
        ].join('.')

        function addZero(val) {
            if (val < 10) {
                return '0' + val;
            }
            return val;
        };

        return (
            <div>{date}</div>
        )
    }

    const tagsTemplate = (rowData) => {
        return (
            <>
                {rowData.tags ?
                    <>
                        {rowData.tags.map((item, index) => {
                            return <span key={index} className="mb-0">{item}; </span>
                        })}
                    </>
                    :
                    <p className="mb-0">--</p>
                }

            </>
        )
    }
    const changeEnableHandler = (answer) => {
        if (answer.enabled) {
            dispatch(disableAnswer(answer.answerID))
        } else {
            dispatch(enableAnswer(answer.answerID))
        }
        toast.current.show({ severity: 'info', summary: 'Изменено', detail: `Умный ответ "${answer.title}" ${!answer.enabled ? 'включен' : 'выключен'}`, life: 3000 });
    }

    const actionTemplate = (rowData) => {
        return (
            <div className="flex align-items-center py-2">
                {(user?.role === 'admin' || user?.role === 'moderator') &&
                    <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={rowData.enabled} onChange={() => changeEnableHandler(rowData)} />}
                <label htmlFor="turn_on">{rowData.enabled ? "Включен" : "Выключен"}</label>

            </div>
        )
    }

    const selectHandler = (e) => {
        setSelectedAnswer(e.value)
        setCreate(false)
        setEdit(false)
    }

    const isDesktop = () => {
        return window.innerWidth >= 1200
    }

    const toast = useRef(null);

    const onlyEnabledHandler = (e) => {
        dispatch(setOnlyEnabled(e.value))
    }

    const skeleton = [1, 2, 3, 4, 5, 6]

    const skeletonTemplate = (number) => {
        return <Skeleton width={`${number}rem`} className='mb-3'></Skeleton>
    }

    const IconPlus = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.36038 11.3604H8.63962V18.6396C8.63962 19.3795 9.24821 20 10 20C10.7518 20 11.3604 19.3795 11.3604 18.6396V11.3604H18.6396C19.3795 11.3604 20 10.7518 20 10C20 9.24821 19.3795 8.63962 18.6396 8.63962H11.3604V1.36038C11.3604 0.620525 10.7518 0 10 0C9.24821 0 8.63962 0.620525 8.63962 1.36038V8.63962H1.36038C0.620525 8.63962 0 9.24821 0 10C0 10.7518 0.620525 11.3604 1.36038 11.3604Z" />
        </svg>
    }

    useEffect(() => {
        localStorage.setItem('maximize-smart_answers', maximize)
        if (maximize) {
            setBasicRows(13)
        } else {
            setBasicRows(8)
        }
    }, [maximize])

    return (
        <>

            <div className="grid">
                <ConfirmPopup />
                <div className="left-column col-12 xl:col-8 full-height">
                    <StatisticsAnswers
                        maximize={maximize}
                        selectDate={selectDate}
                        formatStart={formatStart}
                        formatEnd={formatEnd}
                        create={create}
                        setCreate={setCreate}
                        setSelectedAnswer={setSelectedAnswer}
                    />
                    {answers ?
                        <div className="card">

                            <div className="flex justify-content-between">
                                <div className="grid w-full justify-content-start">
                                    <div className="col:12 md:col-6 xl:col-5 flex align-items-center flex-order-1 md:flex-order-0">
                                        <InputSwitch className={`settings-switch ${colorTheme} mr-3 ml-3`} checked={onlyEnabled} onChange={onlyEnabledHandler} />
                                        <span>Показать только запущенные ответы</span>
                                    </div>
                                    {(user?.role === 'admin' || user?.role === 'moderator') &&
                                        <div className="p-0 col:12 md:col-6 xl:col-7 flex-order-0 md:flex-order-1">
                                            {maximize &&
                                                <Button className="p-0 add-answer-btn w-full xl:w-8 mb-3 md:mb-0" onClick={() => { setCreate(!create); setSelectedAnswer(null) }}>
                                                    <div>
                                                        <div className="add-answer mr-3">
                                                            <IconPlus />
                                                        </div>
                                                    </div>

                                                    <span className="text-left">Создать новый умный ответ</span>
                                                </Button>}
                                        </div>}
                                </div>

                                <Button icon={maximize ? "pi pi-window-minimize" : "pi pi-window-maximize"} className="maximize p-button-text hidden lg:block" onClick={() => setMaximize(!maximize)} />
                            </div>
                            <Divider className={`${colorTheme}`} />
                            <DataTable className="smart-answer_table" selectionMode="single" selection={selectedAnswer} onSelectionChange={selectHandler} value={answers} responsiveLayout="stack" breakpoint="750px">
                                <Column field="title" header="Название"></Column>
                                <Column field="tags" body={tagsTemplate} header="Теги"></Column>
                                <Column field="create_date" body={createDateTemplate} header="Дата создания"></Column>
                                <Column field="called" header="Использовано"></Column>
                                <Column className="w-2" body={actionTemplate} header="Активность"></Column>
                            </DataTable>
                            <PaginatorTable
                                totalPages={totalPages}
                                basicFirst={basicFirst}
                                basicRows={basicRows}
                                setBasicFirst={setBasicFirst}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                        :
                        <div className="card">
                            <div className="flex justify-content-between">
                                <div className="grid w-full justify-content-start">
                                    <div className="col:12 md:col-6 xl:col-5 flex align-items-center flex-order-1 md:flex-order-0">
                                        <Skeleton width="18rem" height="3rem" className="mr-3 ml-0 md:ml-3 mb-3 md:mb-0" />
                                    </div>
                                    <div className="col:12 md:col-6 xl:col-7 flex-order-0 md:flex-order-1">
                                        <Skeleton width="18rem" height="3rem" className="mr-3 mb-3 md:mb-0" />
                                    </div>
                                    {/* <Skeleton width="18rem" height="3rem" className="mr-0 sm:mr-6 my-2" />
                                    <Skeleton width="18rem" height="3rem" className="my-2" /> */}

                                </div>

                                <Skeleton size="1.5rem" className="maximize p-button-text hidden lg:block" />
                            </div>
                            <Divider className={`${colorTheme}`} />
                            <DataTable selectionMode="single" value={skeleton} responsiveLayout="scroll">
                                <Column field="name" body={() => skeletonTemplate(15)}></Column>
                                <Column field="tags" body={() => skeletonTemplate(5)}></Column>
                                <Column field="create_date" body={() => skeletonTemplate(9)}></Column>
                                <Column field="used" body={() => skeletonTemplate(3)}></Column>
                                <Column className="w-2" body={() => skeletonTemplate(10)} ></Column>
                            </DataTable>
                        </div>}
                </div>
                <div className="right-column  hidden">
                    <Toast ref={toast} />
                    {answers ?
                        <div className="right-column fixed  pr-5 pt-0">

                            {create ? <div className="card w-full preview  lg:col-5 xl:col-4 pr-3">
                                <AddNewAnswer
                                    setCreate={setCreate}
                                    answers={answers}
                                    setSelectedAnswer={setSelectedAnswer}
                                    setCurrentPage={setCurrentPage}
                                    setBasicFirst={setBasicFirst}
                                    basicRows={basicRows}
                                    className='smart-answer_scroll' />
                            </div>
                                :
                                !selectedAnswer ?

                                    <div className="card w-full preview">
                                        <div className="h-full px-8 flex flex-column justify-content-center align-items-center">
                                            <SvgIconsMenu name='smart_answer' width={135} height={128} />
                                            <p className="text-no_selected text-center mt-5">Выберите умный ответ
                                                для его настройки</p>
                                        </div>

                                    </div>


                                    :
                                    <>
                                        {!edit ? <div className="card w-full preview  lg:col-5 xl:col-4 pr-3">
                                            <ViewSmartAnswer
                                                selectedAnswer={selectedAnswer}
                                                setSelectedAnswer={setSelectedAnswer}
                                                setEdit={setEdit}
                                                answers={answers}
                                                changeEnableHandler={changeEnableHandler}
                                                currentPage={currentPage}
                                                basicRows={basicRows}
                                                toast={toast} />
                                        </div>
                                            :
                                            <div className="card w-full preview flex flex-column lg:col-5 xl:col-4 pr-3">
                                                <EditSmartAnswer
                                                    setEdit={setEdit}
                                                    value={selectedAnswer}
                                                    toast={toast}
                                                    className='smart-answer_scroll' />
                                            </div>
                                        }


                                    </>
                            }

                        </div>
                        :

                        <div className="card w-full preview flex flex-column justify-content-center align-items-center lg:col-5 xl:col-4 pr-3">
                            <div className="flex flex-column align-items-center">
                                <Skeleton size="8rem" className="mb-3"></Skeleton>
                                <Skeleton width="20rem" height="1.5rem" className="mb-2"></Skeleton>
                                <Skeleton width="15rem" height="1.5rem" className="mb-2"></Skeleton>
                            </div>

                        </div>}
                </div>
                {!isDesktop() &&


                    <Sidebar visible={selectedAnswer || create} position="right" className="popover" onHide={() => setSelectedAnswer(false) || setCreate(false)}>
                        {create ? <AddNewAnswer sidebar={true} setCreate={setCreate} setCurrentPage={setCurrentPage}
                            setBasicFirst={setBasicFirst}
                            basicRows={basicRows} /> :

                            !edit ?
                                <ViewSmartAnswer sidebar={true} selectedAnswer={selectedAnswer} setSelectedAnswer={setSelectedAnswer} setEdit={setEdit} answers={answers} /* setAnswers={setAnswers} */ changeEnableHandler={changeEnableHandler} toast={toast} currentPage={currentPage}
                                    basicRows={basicRows} /> :

                                <EditSmartAnswer sidebar={true} setEdit={setEdit} value={selectedAnswer} toast={toast} className='sidebar-new_answer' />
                        }
                    </Sidebar>}
            </div>

        </>


    )
}