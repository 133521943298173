import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getAllCompanyBots } from "../../../service/funnels"
import { useRef } from "react"
import { AddBot } from "./AddBot"
import { Toast } from "primereact/toast"

export const ConnectedBots = () => {
    const [botsList, setBotsList] = useState([
        { id: 1, name: '@name1', messanger: 'Яндекс.Месседжер', type: 'yandex', added_date: '20 мая 2022 г. в 16:56' },
        { id: 2, name: '@name2', messanger: 'Messenger', type: 'sms', added_date: '20 мая 2022 г. в 16:56' },
        { id: 3, name: '@name3', messanger: 'WhatsApp', type: 'whatsapp', added_date: '20 мая 2022 г. в 16:56' },
        { id: 4, name: '@name4', messanger: 'ВКонтакте', type: 'vk', added_date: '20 мая 2022 г. в 16:56' },
        { id: 5, name: '@name5', messanger: 'Telegram', type: 'telegram', added_date: '20 мая 2022 г. в 16:56' }


    ])

    const bots = useSelector((state) => state.bots.bots)
    const [addBot, setAddBot] = useState(false)

    /*     useEffect(() => {
            getAllCompanyBots()
                .then((res) => console.log(res))
        }) */

    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const actionsTemplate = (rowData) => {

        return (
            <div className="flex justify-content-end">
                <Button className={`btn-edit mr-4 ${colorTheme}`}>
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.${colorTheme}.svg`}></img>
                </Button>
                <Button className="btn-trash">
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/trash.svg`}></img>
                </Button>
            </div>
        )
    }

    const toast = useRef(null);
    const messangerTemplate = (rowData) => {
        return (
            <div className="bot-item">
                <img alt={rowData.name} className='mr-2' src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${rowData.type}-bot.png`} />
                <div>{rowData.messanger}</div>
            </div>
        )
    }
    return (
        <>
            <Toast ref={toast} />
            <div className="card setting">
                <div className="flex justify-content-between align-items-center">
                    <h2 className="settings-title">Подключенные боты</h2>
                    <Button className='btn-main px-4' label="Добавить" onClick={() => setAddBot(!addBot)}></Button>
                </div>

                <DataTable value={bots} responsiveLayout="scroll">
                    <Column field="name" header="Имя"></Column>
                    <Column body={messangerTemplate} header="Мессенджер"></Column>
                    <Column field="added_date" header="Дата, время добавления"></Column>
                    <Column body={actionsTemplate} ></Column>
                </DataTable>
            </div>
            {addBot && <AddBot visible={addBot} onHide={setAddBot} toast={toast} />}
        </>

    )
}