import axios from "axios"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { registration, setError } from "../../../service/slices/authSlice"

export const SecondStep = ({ setShowNextStep, setSuccess, firstName, lastName, email, password }) => {
    const [nameCompany, setNameCompany] = useState('')
    const [noValid, setNoValid] = useState(false)

    const dispatch = useDispatch()
    const error = useSelector(state => state.auth.error)
    console.log(error);
    const registrationHandler = () => {
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            companyTitle: nameCompany,
        }

        dispatch(registration(data))
            .then((res) => {
                console.log(res);
                if (!res.payload) {
                    setShowNextStep(false)
                    setSuccess(true)
                } else {
                    setShowNextStep(true)
                }

            })
        // axios.post(`https://bots.sdew.ru/auth/register`, data).then((res) => console.log(res))
        /*   setShowNextStep(false)
          setSuccess(true) */
    }

    const backHandler = () => {
        setShowNextStep(false)
        dispatch(setError(null))
    }


    return (
        <>
            <div className='my-5 flex justify-content-between'>
                <p className="title">Регистрация</p>
                <p className="title">2 из 2</p>
            </div>

            <div>
                <p className='registration-text mt-5 mb-3'>
                    Точно введите название своего места работы. Если вы регистрируетесь через ссылку в письме, то это поле уже должно быть заполнено.
                </p>
                <InputText
                    id="company"
                    type="text"
                    className={`w-full ${error
                        ? 'mb-1'
                        : 'mb-4'}`}
                    placeholder='Название компании'
                    onChange={(e) => setNameCompany(e.target.value)} />
                {error
                    && <small
                        className="p-error block error-margin">
                        {error}
                    </small>}
            </div>
            <div className='flex justify-content-between mt-5'>
                <Button
                    className="cancel-registration mr-3"
                    onClick={backHandler}>
                    Назад
                </Button>
                <Button
                    className="next-step"
                    onClick={registrationHandler}>
                    Регистрация
                </Button>
            </div>
        </>
    )
}