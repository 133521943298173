import { Button } from "primereact/button"
import { Sidebar } from "primereact/sidebar"
import React, { useState, useEffect } from "react"
import { useReactFlow } from "react-flow-renderer"
import DemoMode from "../DemoMode/DemoMode"

import CreateAction from "../Sidebars/CreateAction/CreateAction"
import CreateButtons from "../Sidebars/CreateButtons"
import CreateComment from "../Sidebars/CreateComment"
import CreateCondition from "../Sidebars/CreateCondition"
import CreateDelay from "../Sidebars/CreateDelay"
import CreateFiles from "../Sidebars/CreateFiles"
import CreateForm from "../Sidebars/CreateFormBlock/CreateForm"
import CreateInterval from "../Sidebars/CreateInterval"
import CreateRedirect from "../Sidebars/CreateRedirect"
import CreateBlock from "../Sidebars/CreateTextBlock/CreateBlock"
import CreateVariables from "../Sidebars/CreateVariables/CreateVariables"
import CreateWebhook from "../Sidebars/CreateWebhook/CreateWebhook"
import Publication from "../Sidebars/Publication"
import "./Toolbar.scss"

const Toolbar = ({ isLimitOver, setNodes, funnelId, usedBot, setEdges }) => {
    const [visibleToolbar, setVisibleToolbar] = useState(false)
    const [visiblePublication, setVisiblePublication] = useState(false)
    const [visibleDemo, setVisibleDemo] = useState(false)
    const [visibleVariables, setVisibleVariables] = useState(false)

    const [visibleTextSidebar, setVisibleTextSidebar] = useState(false)
    const [visibleFilesSidebar, setVisibleFilesSidebar] = useState(false)
    const [visibleButtonsSidebar, setVisibleButtonsSidebar] = useState(false)
    const [visibleFormSidebar, setVisibleFormSidebar] = useState(false)
    const [visibleConditionSidebar, setVisibleConditionSidebar] = useState(false)
    const [visibleActionSidebar, setVisibleActionSidebar] = useState(false)
    const [visibleWebhookSidebar, setVisibleWebhookSidebar] = useState(false)
    const [visibleDelaySidebar, setVisibleDelaySidebar] = useState(false)
    const [visibleIntervalSidebar, setVisibleIntervalSidebar] = useState(false)
    const [visibleRedirectSidebar, setVisibleRedirectSidebar] = useState(false)
    const [visibleCommentSidebar, setVisibleCommentSidebar] = useState(false)

    const items = [
        {
            label: "Add",
            icon: "./assets/layout/images/funnels/block.svg",
            command: () => {
                setVisibleTextSidebar(true)
            },
        },
        {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {},
        },
        {
            label: "Delete",
            icon: "pi pi-trash",
            command: () => {},
        },
        {
            label: "Add",
            icon: "pi pi-pencil",
            command: () => {},
        },
        {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {},
        },
        {
            label: "Delete",
            icon: "pi pi-trash",
            command: () => {},
        },
        {
            label: "Add",
            icon: "pi pi-pencil",
            command: () => {},
        },
        {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {},
        },
        {
            label: "Delete",
            icon: "pi pi-trash",
            command: () => {},
        },
    ]

    const reactFlowInstance = useReactFlow()
    let centerCoordinates = reactFlowInstance.project({ x: 600, y: 300 })
    // console.log("INSTANCE: ", centerCoordinates)

    useEffect(() => {
        if (isLimitOver) {
            setVisibleToolbar(!isLimitOver)
        }
    }, [isLimitOver])

    return (
        <>
            <div className={`toolbar ${visibleToolbar ? "open" : "close"}`}>
                <div className={`toolbar-options ${visibleToolbar ? "opened" : "closed"}`}>
                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleTextSidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/text.svg`} alt='text' />
                            </Button>
                            <div className='toolbar-btn-title'>Текст</div>
                        </div>
                    </div>
                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleFilesSidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/files.svg`} alt='files' />
                            </Button>
                            <div className='toolbar-btn-title'>Файлы</div>
                        </div>
                    </div>
                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleButtonsSidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/buttons.svg`} alt='buttons' />
                            </Button>
                            <div className='toolbar-btn-title'>Кнопки</div>
                        </div>
                    </div>
                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleFormSidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/webform.svg`} alt='form' />
                            </Button>
                            <div className='toolbar-btn-title'>Веб-Форма</div>
                        </div>
                    </div>
                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleConditionSidebar(true)
                                }}
                            >
                                <img
                                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/condition.svg`}
                                    alt='condition'
                                />
                            </Button>
                            <div className='toolbar-btn-title'>Условие</div>
                        </div>
                    </div>
                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleActionSidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/action.svg`} alt='action' />
                            </Button>
                            <div className='toolbar-btn-title'>Действие</div>
                        </div>
                    </div>
                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleWebhookSidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/webhook.svg`} alt='webhook' />
                            </Button>
                            <div className='toolbar-btn-title'>WebHook</div>
                        </div>
                    </div>
                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleDelaySidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/pause.svg`} alt='pause' />
                            </Button>
                            <div className='toolbar-btn-title'>Пауза</div>
                        </div>
                    </div>
                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleIntervalSidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/interval.svg`} alt='interval' />
                            </Button>
                            <div className='toolbar-btn-title'>Распределение</div>
                        </div>
                    </div>

                    <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn p-button-rounded'
                                onClick={() => {
                                    setVisibleRedirectSidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/funnel.svg`} alt='funnel' />
                            </Button>
                            <div className='toolbar-btn-title'>Воронка</div>
                        </div>
                    </div>
                    {/* <div className='toolbar-option'>
                        <div className='toolbar-item-text'>
                            <Button
                                disabled={!visibleToolbar}
                                className='toolbar-btn toolbar-btn-comment p-button-rounded'
                                onClick={() => {
                                    setVisibleCommentSidebar(true)
                                }}
                            >
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/comment.svg`} alt='comment' />
                            </Button>
                            <div className='toolbar-btn-title'>Заметка</div>
                        </div>
                    </div> */}
                </div>
                <div className={`closed-toolbar ${!visibleToolbar ? "open" : "close"}`}>
                    <div className='pulse toolbar-option'>
                        <Button
                            disabled={isLimitOver}
                            className={`add-message toolbar-button ${visibleToolbar ? "open" : "close"}`}
                            onClick={() => setVisibleToolbar(!visibleToolbar)}
                        >
                            <i className='pi pi-plus' style={{ fontSize: "20px", fontWeight: "bold" }}></i>
                            {/* <img src='assets/layout/images/plus_green.svg'></img> */}
                        </Button>
                        <div className={`toolbar-btn-title ${visibleToolbar ? "hidden" : "block"}`}>Новый блок</div>
                    </div>

                    <div className='toolbar-items'>
                        <div className='toolbar-option'>
                            <div className='toolbar-item-text'>
                                <Button
                                    // disabled={!visibleToolbar}
                                    className='toolbar-btn  p-button-rounded'
                                    onClick={() => {
                                        setVisibleCommentSidebar(true)
                                    }}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/comment.svg`}
                                        alt='comment'
                                    />
                                </Button>
                                <div className='toolbar-btn-title'>Заметка</div>
                            </div>
                        </div>
                        {/* <div className='toolbar-option'>
                            <div className='toolbar-item-text'>
                                <Button
                                    disabled={visibleToolbar}
                                    className='toolbar-btn toolbar-btn-faq p-button-rounded'
                                    onClick={() => {
                                        setVisibleDemo(true)
                                    }}
                                >
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/demo.svg`} alt='demo' />
                                </Button>
                                <div className='toolbar-btn-title'>Демо</div>
                            </div>
                        </div> */}
                        <div className='toolbar-option'>
                            <div className='toolbar-item-text'>
                                <Button
                                    disabled={visibleToolbar}
                                    className='toolbar-btn toolbar-btn-save p-button-rounded'
                                    onClick={() => {
                                        setVisiblePublication(true)
                                    }}
                                >
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/save.svg`} alt='save' />
                                </Button>
                                <div className='toolbar-btn-title'>Опубликовать</div>
                            </div>
                        </div>
                        <div className='toolbar-option'>
                            <div className='toolbar-item-text'>
                                <Button
                                    disabled={visibleToolbar}
                                    className='toolbar-btn toolbar-btn-comment p-button-rounded'
                                    onClick={() => {
                                        setVisibleVariables(true)
                                    }}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/variables.svg`}
                                        alt='variables'
                                    />
                                </Button>
                                <div className='toolbar-btn-title'>Переменные</div>
                            </div>
                        </div>

                        <div className='toolbar-option toolbar-option-faq'>
                            <Button className='toolbar-btn toolbar-btn-faq p-button-rounded'>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/faq.svg`} alt='faq' />
                            </Button>
                            <div className='toolbar-btn-title'>Справка</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Публикация воронки */}
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visiblePublication}
                onHide={() => setVisiblePublication(false)}
                position='right'
            >
                <Publication onHide={() => setVisiblePublication(false)} usedBot={usedBot} funnelId={funnelId} />
            </Sidebar>

            {/* Демо Воронки */}
            {visibleDemo && <DemoMode setVisibleDemo={setVisibleDemo} />}

            {/* Переменные */}
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleVariables}
                onHide={() => setVisibleVariables(false)}
                position='right'
            >
                <CreateVariables onHide={() => setVisibleVariables(false)} funnelId={funnelId} />
            </Sidebar>

            {/* Сайдабры создания блоков */}
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleTextSidebar}
                onHide={() => setVisibleTextSidebar(false)}
                position='right'
            >
                <CreateBlock
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    //setInitNodes={setInitNodes}
                    onHide={() => setVisibleTextSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleFilesSidebar}
                onHide={() => setVisibleFilesSidebar(false)}
                position='right'
            >
                <CreateFiles
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    onHide={() => setVisibleFilesSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleButtonsSidebar}
                onHide={() => setVisibleButtonsSidebar(false)}
                position='right'
            >
                <CreateButtons
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    setEdges={setEdges}
                    //  setInitNodes={setInitNodes}
                    onHide={() => setVisibleButtonsSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleFormSidebar}
                onHide={() => setVisibleFormSidebar(false)}
                position='right'
            >
                <CreateForm
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    onHide={() => setVisibleFormSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleConditionSidebar}
                onHide={() => setVisibleConditionSidebar(false)}
                position='right'
            >
                <CreateCondition
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    onHide={() => setVisibleConditionSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleActionSidebar}
                onHide={() => setVisibleActionSidebar(false)}
                position='right'
            >
                <CreateAction
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    onHide={() => setVisibleActionSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleWebhookSidebar}
                onHide={() => setVisibleWebhookSidebar(false)}
                position='right'
            >
                <CreateWebhook
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    onHide={() => setVisibleWebhookSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleDelaySidebar}
                onHide={() => setVisibleDelaySidebar(false)}
                position='right'
            >
                <CreateDelay
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    onHide={() => setVisibleDelaySidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleIntervalSidebar}
                onHide={() => setVisibleIntervalSidebar(false)}
                position='right'
            >
                <CreateInterval
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    onHide={() => setVisibleIntervalSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleRedirectSidebar}
                onHide={() => setVisibleRedirectSidebar(false)}
                position='right'
            >
                <CreateRedirect
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    onHide={() => setVisibleRedirectSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleCommentSidebar}
                onHide={() => setVisibleCommentSidebar(false)}
                position='right'
            >
                <CreateComment
                    centerCoordinates={centerCoordinates}
                    setNodes={setNodes}
                    onHide={() => setVisibleCommentSidebar(false)}
                    funnelId={funnelId}
                />
            </Sidebar>
        </>
    )
}

export default Toolbar
