import { Button } from "primereact/button"
import { confirmPopup } from "primereact/confirmpopup"
import { InputSwitch } from "primereact/inputswitch"
import { ScrollPanel } from "primereact/scrollpanel"
import { useState } from "react"
import { useEffect } from "react"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AddTags } from "../../components/AddTags/AddTags"
import { deleteSmartAnswer, disableAnswer, enableAnswer, getAllAnswers, getOneAnswer } from "../../service/slices/smartAnswersSlice"
import { AddTag } from "./AddTag"

export const ViewSmartAnswer = ({ selectedAnswer, setSelectedAnswer, setEdit, toast, changeEnableHandler, currentPage, basicRows, sidebar }) => {
    const dispatch = useDispatch()
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const answers = useSelector((state) => state.smartAnswers.answers)
    const answer = useSelector((state) => state.smartAnswers.answer)
    const role = localStorage.getItem('roleUser')
    const user = useSelector((state) => state.user.user)
    useEffect(() => {
        if (selectedAnswer) {
            dispatch(getOneAnswer(selectedAnswer?.answerID))
        }

    }, [selectedAnswer, answers])

    console.log(currentPage, basicRows)

    const acceptFunc = (value) => {
        const data = {
            currentPage, currentPage,
            basicRows: basicRows
        }
        dispatch(deleteSmartAnswer(value.answerID))
            .then(() => {
                dispatch(getAllAnswers(data))
                    .then(() => {
                        setSelectedAnswer(null)
                    })
            })
        setSelectedAnswer(null)
        toast.current.show({ severity: 'error', summary: 'Удалено', detail: `Умный ответ "${value.title}" удален`, life: 3000 });
    };

    const reject = () => {

    };

    const removeHandler = (answer, e) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Вы действительно хотите удалить умный ответ',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Да',
            rejectLabel: 'Нет',
            accept: () => acceptFunc(answer),
            reject
        });
    };

    console.log(sidebar)

    return (
        <>
            <div className="mb-3">
                <>
                    <div className="header-view_answer flex align-items-center justify-content-between py-2">
                        <p className="title-header">Умный ответ</p>
                        {(user?.role === 'admin' || user?.role === 'moderator') &&
                            <div className="flex align-items-center">
                                <InputSwitch className={`settings-switch ${colorTheme}`} checked={answer.enabled} onChange={() => changeEnableHandler(answer)} />
                                <label className="label-switch" htmlFor="turn_on">{answer.enabled ? "Включен" : "Выключен"}</label>
                            </div>
                        }
                    </div>

                    <div className='flex justify-content-between mt-5'>
                        <div className="flex align-items-center">
                            <h5 className="title-answer">
                                {answer?.title}
                            </h5>
                        </div>
                        {(user?.role === 'admin' || user?.role === 'moderator') &&
                            <div className="flex">
                                <Button className={`btn-edit_answer mr-2 ${colorTheme}`} onClick={() => setEdit(true)}>
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.${colorTheme}.svg`}></img>
                                </Button>
                                <Button className="btn-trash_answer" onClick={(e) => removeHandler(answer, e)}>
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/trash.svg`}></img>
                                </Button>
                            </div>
                        }
                    </div>
                </>



            </div>
            <div className={sidebar ? "view-smart_answer sidebar" : "view-smart_answer"}>
                <ScrollPanel style={{ width: '100%', height: '100%' }} className="custom">
                    <div className="w-full">
                        {/* {answer.tags && <>
                            <p className="text-answer">Теги</p>
                            <div className="flex flex-wrap align-items-center">
                                {answer.tags?.map((item) => {
                                    return <div className="tag-container geotags-location w-max relative">
                                        <div>{item}</div>
                                    </div>
                                })}

                            </div>
                        </>} */}
                        <AddTags edit={false} />

                        {(answer?.reactions || answer?.noAnswerDays || answer?.noAnswerHours || answer?.noAnswerMinutes || answer?.noAnswerSeconds) &&
                            <div className='flex flex-column'>
                                <p className="text-answer">Если </p>
                                <div>
                                    {answer?.reactions &&
                                        <div className='wrapper-messange w-11'>
                                            <h6 className="title-condition">Пользователь отправил сообщение:</h6>
                                            <p className="condition-text">
                                                {answer?.reactions?.map((item) => {
                                                    return item + '; '
                                                })}</p>
                                        </div>}
                                    {(answer?.reactions && (answer?.noAnswerDays || answer?.noAnswerHours || answer?.noAnswerMinutes || answer?.noAnswerSeconds)) && <p className="text-and">и </p>}
                                    {(answer?.noAnswerDays || answer?.noAnswerHours || answer?.noAnswerMinutes || answer?.noAnswerSeconds) &&
                                        <div className='wrapper-messange w-11'>
                                            <h6 className="title-condition">На сообщение пользователя нет ответа:</h6>
                                            <p className="condition-text">{answer?.noAnswerDays && `${answer?.noAnswerDays} дней,`} {answer?.noAnswerHours && `${answer?.noAnswerHours} часа,`}  {answer?.noAnswerMinutes && `${answer?.noAnswerMinutes} минут`} </p>
                                        </div>}
                                </div>

                            </div>}
                        {(answer?.text || answer?.blueprintID) &&
                            <div className='flex flex-column'>
                                <p className="text-answer">То </p>
                                <div>
                                    {answer.text &&
                                        <div className='wrapper-messange w-11'>
                                            <h6 className="title-condition">Будет отправленно сообщение</h6>
                                            <p className="condition-text">{answer?.text}</p>
                                        </div>}
                                    {answer.blueprintID &&
                                        <div className='wrapper-messange w-11'>
                                            <h6 className="title-condition">Запустится воронка</h6>
                                            <p className="funnel-text">{answer?.blueprintID}</p>
                                        </div>}
                                </div>
                            </div>}
                    </div>


                </ScrollPanel>
            </div>
        </>
    )
}