import { useState } from 'react'
import './Onboarding.css'
import { Video } from './Video'
import { Welcome } from './Welcome'

export const Onboarding = () => {
    const [nextStep, setNextStep] = useState(false)
    return (
        <div className='Onboarding px-0 sm:px-5 xl:px-0'>
            <Video
            />
        </div>
    )
}