import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Editor } from "primereact/editor"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { v4 as uuid } from "uuid"
import { createEdge, createNode, deleteMedia, getFunnel, updateNode } from "../../../../service/funnels"

import { useDispatch, useSelector } from "react-redux"
import { setInitNodes } from "../../../../service/slices/initNodesSlice"
import SidebarHeader from "../components/SidebarHeader"
import UploadFiles from "../components/UploadFiles"
import "../Sidebar.scss"
import SidebarFooter from "../components/SidebarFooter"
import { clearNodes } from "../../utils"

const CreateBlock = ({
    data,
    onHide,
    funnelId,
    centerCoordinates,
    positionNewNode,
    sourceNewEdge,
    setEdges,
    newNodeFile,
    setNewNodeFile,
}) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)
    const [nodeID, setNodeID] = useState("")
    const [blockTitle, setBlockTitle] = useState(data?.title || "")
    const [blockText, setBlockText] = useState(data?.text || "")

    const [files, setFiles] = useState(data?.files)
    const [deletedFile, setDeletedFile] = useState([])

    const maxTextLength = 4000

    // console.log("Файлы в создании блока: ", files)

    // Текстовый "редактор"
    const renderHeader = () => {
        return (
            <span className='ql-formats'>
                <button className='ql-bold' aria-label='Bold'></button>
                <button className='ql-italic' aria-label='Italic'></button>
                <button className='ql-underline' aria-label='Underline'></button>
                <button className='ql-strike' aria-label='Strike'></button>
                <button className='ql-code' aria-label='Code'></button>
            </span>
        )
    }

    useEffect(() => {
        const node_id = uuid()
        setNodeID(node_id)
    }, [])

    const header = renderHeader()

    // Файлы

    // Добавление нового блока

    // console.log("NodeID в сайдабре:", nodeID)

    // console.log("Файлы, в сайдбаре: ", files)

    const saveHandler = () => {
        let textBlock = {
            blueprintID: funnelId,
            type: "message",
            title: blockTitle,
            text: blockText,
            files: files,
            messenger: "Telegram",
            data: { title: blockTitle, text: blockText, files: files, blueprintID: funnelId },
        }
        if (data && positionNewNode) {
            const addTextBlock = {
                ...textBlock,
                files,
                uuid: nodeID,
                position: positionNewNode,
            }
            const edge = {
                blueprintID: funnelId,
                uuid: uuid(),
                source: sourceNewEdge,
                target: nodeID,
                sourceHandle: "r",
                targetHandle: "l",
            }
            createNode(addTextBlock, "message")
                .then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes))))
                .then(() => createEdge(edge))
            setEdges((eds) => eds.concat({ nodeID, source: sourceNewEdge, target: nodeID }))
        } else if (data) {
            const updatedNode = { ...textBlock, uuid: data?.id, files, parentNode: data?.parentNode }
            updateNode(updatedNode, "message").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(clearNodes(res?.data?.nodes))))
            )
        } else {
            const addTextBlock = {
                ...textBlock,
                files,
                uuid: nodeID,
                position: centerCoordinates,
                parentNode: "",
            }

            createNode(addTextBlock, "message").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(clearNodes(res?.data?.nodes))))
            )
        }

        deletedFile.map((item) => deleteMedia({ fileObject: "node", nodeID: data?.id, filename: item?.path }))

        onHide()
        setBlockTitle("")
        setBlockText("")
    }

    const deleteMediaHandler = (file) => {
        // console.log("FILE!: ", file)
        const deletedMedia = files?.filter((fileItem) => fileItem.path !== file?.path)
        setFiles(deletedMedia)
        // console.log("DELETE: ", deletedMedia)
        // saveHandler()
    }

    console.log(deletedFile)

    const hideHandler = () => {
        onHide()
        const dataId = data?.files.map((item) => item.path)
        console.log(dataId)
        const uploadedFiles = files?.filter((item) => !dataId.includes(item.path))
        console.log(uploadedFiles)
        uploadedFiles?.map((item) => deleteMedia({ fileObject: "node", nodeID: data?.id, filename: item?.path }))

        if (deletedFile?.length !== 0) {
            deletedFile?.map((item) => {
                if (!dataId.includes(item.path)) {
                    deleteMedia({ fileObject: "node", nodeID: data?.id, filename: item?.path })
                }
            })
        }

        if (positionNewNode) {
            getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
        }
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Текст' icon={"text"} onHide={hideHandler} />
            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={[0, 1]}>
                    <AccordionTab header='Контент' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === "employer"}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                        <div className='field'>
                            <Editor
                                headerTemplate={header}
                                value={blockText}
                                onTextChange={(e) => {
                                    setBlockText(e.htmlValue?.replaceAll("style", "_style"))
                                }}
                                readOnly={user?.role === "employer"}
                                placeholder='Текст блока {переменная}'
                            />
                            <div
                                className='mt-1 text-length'
                                style={blockText?.length < maxTextLength ? { color: "#28CD41" } : { color: "#FF3B30" }}
                            >
                                {blockText ? blockText?.length : "0"} из {maxTextLength} символов
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Изображения' headerClassName='accordion-header'>
                        <div className='attach'>
                            <div className='w-full mb-4'>
                                <UploadFiles
                                    nodeID={data?.id ? data.id : nodeID}
                                    setFiles={setFiles}
                                    files={files}
                                    data={data}
                                    blockTitle={blockTitle}
                                    blockText={blockText}
                                    deleteMediaHandler={deleteMediaHandler}
                                    multiple={false}
                                    uploadText={"Загрузить изображение"}
                                    filesType={".jpg, .png"}
                                    filesSize={"15"}
                                    deletedFile={deletedFile}
                                    setDeletedFile={setDeletedFile}
                                    newNodeFile={newNodeFile}
                                    setNewNodeFile={setNewNodeFile}
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
            <SidebarFooter saveHandler={() => saveHandler()} disabled={!blockText} onHide={hideHandler} />
        </div>
    )
}

export default CreateBlock
