import { Button } from "primereact/button"
import { ScrollPanel } from "primereact/scrollpanel"
import Gravatar from "react-gravatar"
import { useSelector } from "react-redux"

export const ListContacts = ({ chatsList, active, setActive, setShowHistory, setShowChats }) => {

    const chatsHandler = (item) => {
        setActive(item)
        setShowHistory(true)
        setShowChats(false)
    }

    const colorTheme = useSelector((state) => state.colorTheme.theme)

    return (

        <div className="card chats pt-0 px-2">
            <div className="pt-3 header-chat w-full px-3">
                <h5 className='title-card'>Чаты</h5>
            </div>

            <div className="list-contact">
                <ScrollPanel style={{ width: '100%', height: '100%' }} className="custom">
                    <ul className="chats-list pl-0 w-full">
                        {chatsList?.map((item, idx) => {
                            return <li key={idx} className={active === item.id ? "pl-3 py-2 active_chat flex" : "pl-3 py-2 flex"} onClick={() => chatsHandler(item)}>
                                <div className="chat w-full flex  align-items-center pr-3">
                                    {item.avatar ?
                                        <img className="mr-3" style={{ width: '30px', height: '30px' }} src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${item.avatar}`}></img>
                                        :
                                        <Gravatar
                                            email={item?.contacts.email || item?.contacts.phone}
                                            size={30}
                                            rating="pg"
                                            default="retro"
                                            className="CustomAvatar-image mr-3"
                                        />
                                    }


                                    <div>{item.name}</div>
                                    <div className="ml-auto">
                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${item.messanger}.${colorTheme}.svg`}></img>
                                    </div>


                                </div>


                            </li>
                        })}
                    </ul>
                </ScrollPanel>
            </div>
        </div>


    )
}