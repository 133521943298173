import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import apiClient from '../api'

export const getAllBots = createAsyncThunk(
    'bots/getAllBots',
    async (_, { rejectWithValue }) => {

        try {
            const response = await apiClient.get(`bp/company/list-bots`)
            if (response.status !== 200) {
                throw new Error('Ошибка получения списка ботов');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const smartAnswersSlice = createSlice({
    name: 'bots',
    initialState: {
        bots: [],
        error: null,
    },
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(getAllBots.pending, state => {
                state.error = null
            })
            .addCase(getAllBots.fulfilled, (state, action) => {
                state.error = null;
                state.bots = action.payload
            })
            .addCase(getAllBots.rejected, (state, action) => {
                state.error = action.payload
            })
    }
})

export const { } = smartAnswersSlice.actions
export default smartAnswersSlice.reducer
