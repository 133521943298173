import React from 'react'
import '../Auth.css'
import { useParams } from 'react-router-dom';
import { PasswordRecovery } from '../PasswordRecovery/PasswordRecovery';
import { LoginForm } from './LoginForm';
import { CardAuth } from '../CardAuth';


const LoginPage = () => {
    const { params } = useParams()

    return (
        <CardAuth>
            <>
                {params === 'password_recovery'
                    && <PasswordRecovery />}
                {!params
                    && <LoginForm />}
            </>
        </CardAuth>

    )
}

export default LoginPage
