import { Button } from "primereact/button"
import { Carousel } from "primereact/carousel"
import { useState } from "react";
import { useSelector } from "react-redux";
import { CarouselFunnel } from "../CarouselFunnel";
import { Chart, registerables } from "chart.js";
import './StatisticsFunnels.css'
Chart.register(...registerables);


export const CardFunnelsCharts = ({ type, data, chart, colorFunc, backgroundColorFunc }) => {

    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const [sortMax, setSortMax] = useState(true)
    const titleHeader = type === 'problem' ? 'Просмотр состояния воронок' : 'Избранные воронки'
    const title = 'Воронка 559 “Новое начало”'
    const amountViews = 2

    const [currentFunnel, setCurrentFunnel] = useState(data[0].id)

    const funnel = data?.find(funnel => funnel.id === currentFunnel)

    const idMini = type === 'problem' ? 'problemMini' : 'favoritesMini'
    const id = type === 'problem' ? 'problem' : 'favorites'

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '1210px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '940px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '850px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '650px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '490px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const funnelTemplate = (item) => {

        return (
            <CarouselFunnel item={item} chart={chart} id={idMini} currentFunnel={currentFunnel} setCurrentFunnel={setCurrentFunnel} updateHandler={updateHandler} />

        );

    }

    const ButtonSort = () => {
        return <Button className={`btn-sort ${colorTheme}`} onClick={() => setSortMax(!sortMax)}>
            <i className={`pi ${sortMax ? 'pi-sort-numeric-up-alt' : 'pi-sort-numeric-down-alt'}`}></i>
            <span>{sortMax ? 'max' : 'min'}</span>
        </Button>
    }

    const ButtonFavorites = () => {
        return <Button className='btn-favorites'>
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/star.svg`}></img>
        </Button>
    }

    const updateHandler = (item) => {
        chart.data.labels = item?.data
        chart.data.datasets.forEach((dataset) => {
            dataset.data = item?.data
            dataset.borderColor = colorFunc(item)
            dataset.backgroundColor = backgroundColorFunc(item)
        });
        chart.update();
    }

    return (
        <>
            <div className="card-charts">
                <div className="card-charts_header">
                    <h5 className="title-card_statistics">
                        {titleHeader}
                    </h5>
                    {type === 'problem'
                        ? <ButtonSort />
                        : <ButtonFavorites />
                    }
                </div>
                <h6 className='title-funnel'>{funnel?.title} "{funnel?.description}"</h6>
                <p className='amount-views'>{amountViews} просмотра</p>
                {data && <div style={{ width: '100%', height: '120px', paddingRight: '22px' }}>
                    <canvas id={id} width="560" height="120"></canvas>
                </div>}
                <div className='grid mt-3 align-items-end'>
                    <div className='col-3 starting-funnel'>
                        <p className='starting-funnel_title'>Запуск воронки:</p>
                        <p className='starting-funnel_date'>{funnel?.start}</p>
                    </div>
                    <div className='col-9'>
                        <Carousel value={data || []} numVisible={2} numScroll={1} responsiveOptions={responsiveOptions}
                            itemTemplate={funnelTemplate} />
                    </div>
                </div>
            </div>
        </>

    )
}