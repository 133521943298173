import { Badge } from 'primereact/badge';
import './Statistics.css'
import { Problem } from './Problem';

import { Favorites } from './Favorites';
import { SelectDate } from '../../components/SelectDate/SelectDate';
import { useSelector } from 'react-redux';
import { CardNewUsers } from '../../components/CardNewUsers/CardNewUsers';
import { SalesToday } from '../../components/SalesToday/SalesToday';
import { useEffect, useState } from 'react';
import { getData } from '../../service/getData';
import { SkeletonCard } from '../../components/SkeletonCard/SkeletonCard';
import { Skeleton } from 'primereact/skeleton';
import { SkeletonDate } from '../../components/SkeletonDate/SkeletonDate';
import { SkeletonChart } from './SkeletonChart';
import { Chart, registerables } from "chart.js";
import axios from 'axios';
import { Button } from 'primereact/button';
import apiClient from '../../service/api';
import Cookies from 'js-cookie';
import { Onboarding } from '../Onboarding/Onboarding';
import { CardDiagram } from '../SmartAnswers/CardDiagram';
import { CardDiagramMain } from './CardDiagramMain/CardDiagramMain';
import { Events } from './Events/Events';
import { SkeletonEvents } from './Events/SkeletonEvents';
import { CardFunnelsCharts } from './StatisticsFunnels/CardFunnelsCharts';
import { log } from '../../service/log';
import { SkeletonDiagramMain } from './CardDiagramMain/SkeletonDiagramMain';
Chart.register(...registerables);

export const Statistics = () => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const visited = useSelector((state) => state.auth.visited)
    const date = useSelector((state) => state.selectDate.date)
    const selectDate = useSelector((state) => state.selectDate.selectDate)
    const [events, setEvents] = useState(null)
    const [plan, setPlan] = useState(null)
    const [data, setData] = useState(null)
    const [problemFunnels, setProblemFunnels] = useState(null)
    const [favoritesFunnels, setFavoritesFunnels] = useState(null)
    const [chartProblem, setChartProblem] = useState(null)
    const [chartFavorites, setChartFavorites] = useState(null)

    const [confirmation, setConfirmation] = useState(false)

    const messangers = useSelector((state) => state.smartAnswers.messangers)

    /* const [showOnboarding, setShowOnboarding] = useState(false) */

    useEffect(() => {
        document.title = 'Bot Magic | Главная'
    }, [])

    useEffect(() => {
        /* axios.get('data.json')
            .then((response) => {
                console.log(response.data)
                setEvents(response.data.events)
                setPlan(response.data.implementation_sales_plan)
            })
            .catch((error) => console.error(error)) */

        getData().then((response) => {
            log(response.data)
            setData(response.data)
            setProblemFunnels(response.data.funnels.problem)
            setFavoritesFunnels(response.data.funnels.favorites)
            setEvents(response.data.events)
            setPlan(response.data.implementation_sales_plan)
        })
            .catch((error) => console.error(error))

    }, [])

    /*     useEffect(() => {
            if (!visited) {
                setShowOnboarding(true)
                Cookies.set('visited', true)
            }
    
        }, []) */


    const colorFunc = (value) => {
        if (value) {
            if (value?.conversion < 50 && value?.conversion > 20) {
                return '#FFD60A'
            } else if (value.сonversion < 1) {
                return '#FF3B30'
            } else {
                return '#28CD41'
            }
        }


    }

    const backgroundColorFunc = (value) => {
        if (value) {
            if (value?.conversion < 50 && value?.conversion > 20) {
                return '#FFD60A1A'
            } else if (value.сonversion < 1) {
                return '#FF3B301A'
            } else {
                return '#28CD411A'
            }
        }

    }


    useEffect(() => {
        if (chartProblem !== null) {
            chartProblem.destroy()
        }

        if (problemFunnels) {
            setChartProblem(new Chart(document.getElementById('problem'), {
                type: 'line', data: {
                    labels: problemFunnels[0]?.data,
                    datasets: [

                        {
                            label: '',
                            data: problemFunnels[0]?.data,
                            fill: true,
                            borderColor: colorFunc(problemFunnels[0]),
                            tension: .4,
                            backgroundColor: backgroundColorFunc(problemFunnels[0]),
                            pointRadius: 0,
                            borderWidth: 2
                        }
                    ]

                },
                options: {
                    maintainAspectRatio: false,
                    aspectRatio: 2.5,
                    plugins: {
                        legend: false
                    },
                    scales: {
                        x: {
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            }
                        },
                        y: {
                            ticks: {
                                display: true,
                                color: ticksColor()
                            },
                            grid: {
                                display: true,
                                color: '#566476'
                            }

                        }
                    }
                }
            }))
        }

    }, [problemFunnels])
    const ticksColor = () => {
        if (colorTheme === 'dark') {
            return '#F7FFF7'
        } else {
            return '#293241'
        }
    }

    useEffect(() => {
        if (chartFavorites !== null) {
            chartFavorites.destroy()
        }

        if (favoritesFunnels) {
            setChartFavorites(new Chart(document.getElementById('favorites'), {
                type: 'line', data: {
                    labels: favoritesFunnels[0]?.data,
                    datasets: [

                        {
                            label: '',
                            data: favoritesFunnels[0]?.data,
                            fill: true,
                            borderColor: colorFunc(favoritesFunnels[0]),
                            tension: .4,
                            backgroundColor: backgroundColorFunc(favoritesFunnels[0]),
                            pointRadius: 0,
                            borderWidth: 2
                        }
                    ]

                },
                options: {
                    maintainAspectRatio: false,
                    aspectRatio: 2.5,
                    plugins: {
                        legend: false
                    },
                    scales: {
                        x: {
                            ticks: {
                                display: false,
                                color: '#495057'
                            },
                            grid: {
                                display: false,
                                color: '#566476'
                            }
                        },
                        y: {
                            ticks: {
                                display: true,
                                color: ticksColor()
                            },
                            grid: {
                                display: true,
                                color: '#566476'
                            }
                        }
                    }
                }
            }))
        }

    }, [favoritesFunnels])

    const colorMarker = (item) => {
        if (item === 'Покупка') {
            return 'blue'
        } else if (item === 'Оплата счета') {
            return 'yellow'
        } else {
            return 'red'
        }
    }




    return (
        <>
            {/*  {showOnboarding && <Onboarding setShowOnboarding={setShowOnboarding} />} */}

            <div className="panel-statistics">
                <div className="block-users">
                    <CardNewUsers page='main' />
                </div>

                {data
                    ? <>
                        <CardDiagramMain title='Умные ответы' />
                        <CardDiagramMain title='Охват аудитории' />
                    </>
                    :
                    <>
                        <SkeletonDiagramMain />
                        <SkeletonDiagramMain />
                    </>

                }

                {/* <div className="col-12 md:col-6 lg:col-4 xl:col-3">
                    {plan ?
                        <div className="card statistics h-11rem">
                            <div className="flex justify-content-between">
                                <span>Выполнение плана продаж за {selectDate === 'today' ? 'сегодня' : selectDate === 'yesterday' ? 'вчера' : 'период'}
                                </span>
                                <i className="pi pi-arrow-up-right"></i>
                            </div>
                            <h1 className="mb-0">{plan} %</h1>
                        </div>

                        :
                        <SkeletonCard />
                    }
                </div> */}

                {data ?
                    <div className='block-date'>
                        <SelectDate />
                    </div> :
                    <div className='block-date'>
                        <SkeletonDate />
                    </div>}

            </div>
            <div className="statistics-funnel">
                <div className="events-block">
                    {events ?
                        <Events />
                        :
                        <SkeletonEvents />}
                </div>

                <div className='funnel-block'>
                    {problemFunnels ?
                        <CardFunnelsCharts
                            type={'problem'}
                            data={problemFunnels}
                            chart={chartProblem}
                            setChart={setChartProblem}
                            colorFunc={colorFunc}
                            backgroundColorFunc={backgroundColorFunc}
                            ticksColor={ticksColor} />
                        :
                        <SkeletonChart />
                    }
                    {/*  <CardFunnelsCharts data={favoritesFunnels} chart={chartFavorites} setChart={setChartFavorites} colorFunc={colorFunc} backgroundColorFunc={backgroundColorFunc} ticksColor={ticksColor} /> */}

                    {favoritesFunnels ?
                        <CardFunnelsCharts
                            type={'favorites'}
                            data={favoritesFunnels}
                            chart={chartFavorites}
                            setChart={setChartFavorites}
                            colorFunc={colorFunc}
                            backgroundColorFunc={backgroundColorFunc}
                            ticksColor={ticksColor} />
                        :
                        <SkeletonChart />
                    }


                </div>


            </div>
            {/* <div className='grid justify-content-end'>
                        <div className='col-5'>
                            <div className='card'>
                                <div className='flex justify-content-between align-items-start'>
                                    <div>
                                        <h5 className='title-card line-height-1'>Будьте как дома</h5>
                                        <p className='text-sm line-height-2'>Настройте виджеты и информацию на главном экране</p>
                                    </div>

                                    <Button className='p-button-rounded p-button-text flex justify-content-center'>
                                        <img src="assets/layout/images/gearshape.svg"></img>
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div> */}
        </>

    )
}