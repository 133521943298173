import { createSlice } from '@reduxjs/toolkit'
import { formatDate, initialDate, initialDateEnd, initialDateStart, initialSelect } from '../formatDate'


export const selectDateSlice = createSlice({
    name: 'selectDate',
    initialState: {
        selectDate: 'today',
        start: initialDateStart(),
        end: initialDateEnd()
    },
    reducers: {
        setSelectDate: (state, action) => {
            state.selectDate = action.payload
        },
        setStart: (state, action) => {
            state.start = action.payload
        },
        setEnd: (state, action) => {
            state.end = action.payload
        },
    },
})

export const { setSelectDate, setStart, setEnd } = selectDateSlice.actions
export default selectDateSlice.reducer
