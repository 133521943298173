import Cookies from "js-cookie"
import { Button } from "primereact/button"
import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { updateCss } from "../../../service/theme"
import { CardAuth } from "../../AuthPage/CardAuth"

export const InviteFailure = () => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    useEffect(() => {
        updateCss(colorTheme)
    }, [colorTheme])

    let auth = Cookies.get('auth');

    return <div className={`Authorization ${colorTheme}  ${auth ? 'NotFound' : 'h-full'}`}>
        <CardAuth>
            <>
                <div>
                    <p className='registration-text mt-5 mb-3'>
                        Не удалось присоединиться к компании
                    </p>
                </div>
                <Link to={auth ? '/' : '/login'}
                >
                    <Button
                        label='Назад'
                        className="w-full sign_in py-3 my-5" />
                </Link>

            </>
        </CardAuth>
    </div>
}