import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Editor } from "primereact/editor"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { v4 as uuid } from "uuid"
import { createNode, getFunnel, updateNode } from "../../../service/funnels"

import { useDispatch, useSelector } from "react-redux"
import { setInitNodes } from "../../../service/slices/initNodesSlice"
import SidebarHeader from "./components/SidebarHeader"
import TextButton from "./CreateTextBlock/TextButton"
import "./Sidebar.scss"
import SidebarFooter from "./components/SidebarFooter"

const CreateButtons = ({ data, onHide, funnelId, centerCoordinates }) => {
    const dispatch = useDispatch()

    const [nodeID, setNodeID] = useState("")
    const [blockTitle, setBlockTitle] = useState(data?.title || "")
    const [blockText, setBlockText] = useState(data?.text || "")

    const [isKeyboardButtons, setIsKeyboardButtons] = useState(data?.buttonType === "keyboard")
    const [buttons, setButtons] = useState([])
    const user = useSelector((state) => state.user.user)

    useEffect(() => {
        const node_id = uuid()
        setNodeID(node_id)
    }, [])

    useEffect(() => {
        if (data?.buttons) {
            data?.buttons?.map((btn) => {
                if (btn.url) {
                    btn.callbackData = null
                    btn.id = btn.url
                    btn.type = "url"
                } else {
                    btn.id = btn.callbackData
                    btn.type = "callback"
                }
            })
            setButtons(data?.buttons)
        }
    }, [data])

    const addButton = () => {
        setButtons([...buttons, { id: uuid(), text: "" }])
    }
    const onChangeButton = (id, text, url, buttonType, buttonIndex) => {
        const btnId = buttons.find((btn) => btn.id === id)
        btnId.text = text || `Название кнопки ${buttonIndex + 1}`
        btnId.type = buttonType
        if (buttonType === "callback") {
            btnId.id = btnId.callbackData ? btnId.callbackData : uuid()
            btnId.url = null
            btnId.callbackData = btnId.callbackData ? btnId.callbackData : uuid()
        } else if (buttonType === "url") {
            btnId.id = url
            btnId.url = url
            btnId.callbackData = null
        }
    }

    const deleteButton = (id) => {
        const newButtons = buttons.filter((btn) => btn.id !== id)
        setButtons(newButtons)
    }

    // Текстовый "редактор"
    const renderHeader = () => {
        return (
            <span className='ql-formats'>
                <button className='ql-bold' aria-label='Bold'></button>
                <button className='ql-italic' aria-label='Italic'></button>
                <button className='ql-underline' aria-label='Underline'></button>
                <button className='ql-strike' aria-label='Strike'></button>
                <button className='ql-code' aria-label='Code'></button>
            </span>
        )
    }

    const header = renderHeader()

    // Добавление нового блока

    const saveHandler = () => {
        let textBlock = {
            blueprintID: funnelId,
            title: blockTitle,
            text: blockText,
            messenger: "Telegram",
            buttons: buttons,
            data: { title: blockTitle, text: blockText, blueprintID: funnelId, buttons: buttons },
        }
        if (data) {
            const updatedNode = {
                ...textBlock,
                uuid: data?.id,
                type: isKeyboardButtons ? "keyboard" : "button",
                data: { type: isKeyboardButtons ? "keyboard" : "button" },
                buttons,
                messageType: data?.messageType,
            }
            updateNode(updatedNode, "button").then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes))))
        } else {
            const addButtonsBlock = {
                ...textBlock,
                uuid: nodeID,
                position: centerCoordinates,
            }
            if (buttons?.length !== 0) {
                if (isKeyboardButtons) {
                    createNode({ ...addButtonsBlock, type: "keyboard" }, "button").then((res) =>
                        getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
                    )
                } else {
                    createNode({ ...addButtonsBlock, type: "button" }, "button").then((res) =>
                        getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
                    )
                }
            }
        }
        onHide()
        setBlockTitle("")
        setBlockText("")
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Кнопки' icon={"buttons"} onHide={() => onHide()} />
            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={[0, 1, 2]}>
                    <AccordionTab header='Контент' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === "employer"}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Кнопки для ответа' headerClassName='accordion-header'>
                        <div className='mb-3'>
                            <p>В разных мессенджерах кнопка может выглядеть по-разному или не показываться вовсе.</p>
                            <div className='field-checkbox'>
                                <InputSwitch
                                    className='settings-switch'
                                    inputId='binary'
                                    checked={isKeyboardButtons}
                                    onChange={(e) => setIsKeyboardButtons(e.value)}
                                    disabled={user?.role === "employer"}
                                />
                                <label htmlFor='binary'>Клавиатурные кнопки</label>
                            </div>
                            {buttons?.map((button, index) => (
                                <TextButton
                                    isKeyboardButtons={isKeyboardButtons}
                                    key={button.id}
                                    buttonIndex={index}
                                    id={button.id}
                                    deleteButton={deleteButton}
                                    onChangeButton={onChangeButton}
                                    button={button}
                                />
                            ))}
                            {(user?.role === "admin" || user?.role === "moderator") && (
                                <div className='mb-5'>
                                    <Button className='btn btn-40 w-full' label='Добавить кнопку' onClick={() => addButton()} />
                                </div>
                            )}
                        </div>
                    </AccordionTab>

                    <AccordionTab header='Надпись к кнопкам' headerClassName='accordion-header'>
                        <div className='field'>
                            <Editor
                                headerTemplate={header}
                                value={blockText}
                                onTextChange={(e) => setBlockText(e?.htmlValue?.replaceAll("style", "_style"))}
                                placeholder='Текст блока'
                                readOnly={user?.role === "employer"}
                            />
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>

            <SidebarFooter saveHandler={() => saveHandler()} disabled={!buttons.length > 0 || !blockText} onHide={() => onHide()} />
        </div>
    )
}

export default CreateButtons
