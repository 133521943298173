import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import apiClient from '../api'

const server = "https://bots.sdew.ru/cl"


export const getAllMailings = createAsyncThunk(
    'mailings/getAllMailings',
    async (data, { rejectWithValue, getState }) => {
        const onlyUSed = getState().mailings.onlyUSed
        try {
            const response = await apiClient.get(`co/distributions/list?page=${data.currentPage}&size=${data.basicRows}`)
            if (response.status !== 200) {
                throw new Error('Ошибка получения списка рассылок');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getOneMailing = createAsyncThunk(
    'mailings/getOneMailing',
    async (mailingID, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(`co/distributions?ID=${mailingID}`).then((res) => res)

            if (response.status !== 200) {
                throw new Error('Ошибка получения рассылки');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const createMailing = createAsyncThunk(
    'mailings/createMailing',
    async function (dataMailing, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.post(`co/distributions`, dataMailing);
            if (response.status !== 201) {
                throw new Error('Ошибка при добавлении рассылки');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateMailing = createAsyncThunk(
    'mailings/updateMailing',
    async function (updatedData, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.put(`co/distributions`, updatedData)
            if (response.status !== 200) {
                throw new Error('Ошибка при изменении рассылки');
            }
            dispatch(updateMailings(updatedData));
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteDistribution = createAsyncThunk(
    'mailings/deleteDistribution',
    async function (distributionID, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.delete(`co/distributions`, { data: { ID: distributionID } })
            if (response.status !== 200) {
                throw new Error('Ошибка при удалении рассылки');
            }
            /*   dispatch(deleteAnswer(answerID)); */
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const mailingsSlice = createSlice({
    name: 'mailings',
    initialState: {
        mailings: [],
        mailing: null,
        error: null,
        totalPages: 0,
        onlyUSed: false,
    },
    reducers: {
        addMailings: (state, action) => {
            state.mailings.push(action.payload)
        },
        updateMailings: (state, action) => {
            const updatedMailing = state.mailings.findIndex(mailing => mailing.distributionID === action.payload.distributionID);
            Object.keys(action.payload).forEach((item) => {
                state.mailings[updatedMailing][item] = action.payload[item]
            })
        },
        setOnlyUSed: (state) => {
            state.onlyUSed = !state.onlyUSed
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getAllMailings.pending, state => {
                state.error = null
            })
            .addCase(getAllMailings.fulfilled, (state, action) => {
                state.error = null;
                state.mailings = action.payload.content
                state.totalPages = action.payload.totalPages
            })
            .addCase(getAllMailings.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(createMailing.pending, state => {
                state.error = null
            })
            .addCase(createMailing.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(getOneMailing.pending, state => {
                state.error = null
            })
            .addCase(getOneMailing.fulfilled, (state, action) => {
                state.error = null;
                state.mailing = action.payload
            })
            .addCase(getOneMailing.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(updateMailing.pending, state => {
                state.error = null
            })
            .addCase(updateMailing.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(deleteDistribution.pending, state => {
                state.error = null
            })
            .addCase(deleteDistribution.rejected, (state, action) => {
                state.error = action.payload
            })

    }
})

export const { addMailings, updateMailings, setOnlyUSed } = mailingsSlice.actions
export default mailingsSlice.reducer
