import { Button } from "primereact/button"
import { InputSwitch } from "primereact/inputswitch"
import { ScrollPanel } from "primereact/scrollpanel"
import { useState, useEffect } from "react"
import Gravatar from "react-gravatar"
import { useSelector } from "react-redux"
import { SvgIconsFilters } from "../../components/SvgIconsFilters/SvgIconsFilters"
import { useHistory } from "react-router-dom"
import { deleteBlueprint } from "../../service/funnels"
import { Attention } from "./Attention"

export const ViewFunnel = ({ selectedFunnel, funnels, editActivityHandler, className, setFunnels }) => {
    // console.log("SelectedFunnel: ", selectedFunnel)
    const history = useHistory()
    /*     const role = localStorage.getItem('roleUser') */
    const user = useSelector((state) => state.user.user)
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const [showAttention, setShowAttention] = useState(false)
    const [favorite, setFavorite] = useState(false)
    const [newSelectedFunnel, setNewSelectedFunnel] = useState(selectedFunnel)

    useEffect(() => {
        const newSelected = funnels?.find((item) => item.id === selectedFunnel.id)
        setNewSelectedFunnel(newSelected)
    }, [funnels])

    useEffect(() => {
        setNewSelectedFunnel(selectedFunnel)
    }, [selectedFunnel])

    const iconMessanders = (item) => {
        switch (item) {
            case "Telegram":
                return "telegram"
            case "ВКонтакте":
                return "vk"
            case "Яндекс Мессенджер":
                return "yandex"
            case "WhatsApp":
                return "whatsapp"
            case "Сообщения":
                return "sms"

            default:
                return ""
        }
    }

    const IconTelegram = () => {
        return (
            <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.3584 7.3824C9.38613 7.78707 7.4422 8.6244 4.5266 9.8944C4.05326 10.0827 3.80546 10.2667 3.7832 10.4464C3.74447 10.7506 4.12544 10.8704 4.64426 11.0336C4.71492 11.0558 4.78813 11.0788 4.8632 11.1032C5.3738 11.2692 6.0606 11.4634 6.4178 11.4712C6.74153 11.4785 7.103 11.3452 7.5022 11.0712C10.2259 9.23254 11.6319 8.3032 11.7202 8.28321C11.7826 8.269 11.8686 8.2512 11.9272 8.3032C11.9858 8.3552 11.98 8.4536 11.9738 8.48C11.9362 8.64107 10.4418 10.0302 9.66726 10.7503C9.42521 10.9753 9.25344 11.135 9.2184 11.1714C9.13997 11.2529 9.06004 11.3299 8.98322 11.404C8.50894 11.8612 8.15324 12.2041 9.003 12.764C9.41139 13.0331 9.73817 13.2557 10.0642 13.4777C10.4202 13.7201 10.7752 13.9619 11.2346 14.263C11.3515 14.3397 11.4633 14.4193 11.5721 14.4968C11.9868 14.7924 12.3594 15.0579 12.8188 15.0156C13.086 14.9904 13.3622 14.7396 13.5022 13.9904C13.8336 12.22 14.4846 8.382 14.6352 6.8008C14.6444 6.66939 14.6387 6.53736 14.6184 6.4072C14.6052 6.3294 14.5772 6.2184 14.4762 6.1364C14.3564 6.04 14.1714 6.0186 14.0886 6.02C13.7126 6.0266 13.1354 6.2274 10.3584 7.3824Z'
                />
                <path
                    d='M4.5266 9.8944C7.4422 8.6244 9.38613 7.78707 10.3584 7.3824C13.1354 6.2274 13.7126 6.0266 14.0886 6.02C14.1714 6.0186 14.3564 6.04 14.4762 6.1364C14.5772 6.2184 14.6052 6.3294 14.6184 6.4072C14.6387 6.53736 14.6444 6.66939 14.6352 6.8008C14.4846 8.382 13.8336 12.22 13.5022 13.9904C13.3622 14.7396 13.086 14.9904 12.8188 15.0156C12.3594 15.0579 11.9868 14.7924 11.5721 14.4968C11.4633 14.4193 11.3515 14.3397 11.2346 14.263C10.7752 13.9619 10.4202 13.7201 10.0642 13.4777C9.73817 13.2557 9.41139 13.0331 9.003 12.764C8.15324 12.2041 8.50894 11.8612 8.98322 11.404C9.06004 11.3299 9.13997 11.2529 9.2184 11.1714C9.25344 11.135 9.42521 10.9753 9.66726 10.7503C10.4418 10.0302 11.9362 8.64107 11.9738 8.48C11.98 8.4536 11.9858 8.3552 11.9272 8.3032C11.8686 8.2512 11.7826 8.269 11.7202 8.28321C11.6319 8.3032 10.2259 9.23254 7.5022 11.0712C7.103 11.3452 6.74153 11.4785 6.4178 11.4712C6.0606 11.4634 5.3738 11.2692 4.8632 11.1032C4.78813 11.0788 4.71492 11.0558 4.64426 11.0336C4.12544 10.8704 3.74447 10.7506 3.7832 10.4464C3.80546 10.2667 4.05326 10.0827 4.5266 9.8944Z'
                    fill='#007AFF'
                />
            </svg>
        )
    }

    console.log("SELECTED: ", selectedFunnel)

    const deleteHandler = (id) => {
        setShowAttention(true)
    }

    // Открытие воронки
    const openBlueprint = () => {
        // console.log("Selected funnel: ,", selectedFunnel)
        history.push(`funnels/${selectedFunnel.id}`)
    }

    return (
        <>
            <div className='flex justify-content-between align-items-center pl-3'>
                {(user?.role === "admin" || user?.role === "moderator") && (
                    <div>
                        <Button className={`btn-edit mr-2 ${colorTheme}`} onClick={() => setFavorite(!favorite)}>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${favorite ? "star" : "star_white"}.svg`}
                            ></img>
                        </Button>
                    </div>
                )}

                <h5 className='view_funnel-title m-0 mr-5'>{newSelectedFunnel?.name}</h5>

                <div className='flex align-items-center'>
                    <label htmlFor='turn_on'>{newSelectedFunnel?.status === "started" ? "Включена" : "Выключена"}</label>
                    {(user?.role === "admin" || user?.role === "moderator") && (
                        <InputSwitch
                            className={`settings-switch ${colorTheme} ml-3`}
                            checked={newSelectedFunnel?.status === "started" ? true : false}
                            disabled={newSelectedFunnel?.startable === false}
                            onChange={(e) => editActivityHandler(e, newSelectedFunnel)}
                        />
                    )}
                </div>
            </div>

            <div className='grid pl-3 my-5 justify-content-start'>
                <div className='col-3'>
                    <Button className='w-full flex flex-column justify-content-between btn-edit_funnel' onClick={() => openBlueprint()}>
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/edit.svg`}></img>
                        {user?.role === "employer" ? (
                            <span className='text-btn'>Просмотр</span>
                        ) : (
                            <span className='text-btn'>Изменить</span>
                        )}
                    </Button>
                </div>
                {(user?.role === "admin" || user?.role === "moderator") && (
                    <>
                        <div className='col-3'>
                            <Button className='w-full flex flex-column justify-content-between btn_funnel'>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/demo.svg`}></img>
                                <span className='text-btn'>Демо-режим</span>
                            </Button>
                        </div>
                        <div className='col-3'>
                            <Button className='w-full flex flex-column justify-content-between btn_funnel'>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/copy.svg`}></img>
                                <span className='text-btn'>Создать копию</span>
                            </Button>
                        </div>
                        <div className='col-3'>
                            <Button
                                className='w-full flex flex-column justify-content-between btn_funnel'
                                onClick={() => deleteHandler(selectedFunnel.id)}
                            >
                                <img
                                    style={{ width: "16px", height: "18px" }}
                                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/trash.svg`}
                                ></img>
                                <span className='text-btn'>Удалить</span>
                            </Button>
                        </div>
                    </>
                )}
            </div>

            <div className={className}>
                <ScrollPanel style={{ width: "100%", height: "100%" }} className='custom'>
                    <div className='w-full pr-3'>
                        <h6 className='funnels-subtitle mb-4 pl-3'>Месенджеры</h6>
                        <div className='messangers-list_block grid justify-content-start align-items-center pl-3'>
                            <div className='messanger-item'>
                                <div className='messanger_card flex align-items-center'>
                                    <div className='flex align-items-center'>
                                        <IconTelegram />
                                    </div>

                                    <span className=''>Telegram</span>
                                </div>
                            </div>
                            <div className='messanger-item  no_active'>
                                <div className='messanger_card flex align-items-center'>
                                    <div>
                                        <SvgIconsFilters name={iconMessanders("ВКонтакте")} width={20} height={20} />
                                    </div>

                                    <span className=''>ВКонтакте</span>
                                </div>
                            </div>

                            <div className='messanger-item no_active'>
                                <div className='messanger_card flex align-items-center'>
                                    <div>
                                        <SvgIconsFilters name={iconMessanders("Яндекс Мессенджер")} width={13} height={20} />
                                    </div>

                                    <span className=''>Яндекс Мессенджер</span>
                                </div>
                            </div>

                            <div className='messanger-item text'>
                                <div className='messanger_card flex align-items-center border-none'>
                                    <span className='text'>Скоро будут доступны и другие мессенджеры!</span>
                                </div>
                            </div>

                            <div className='messanger-item no_active'>
                                <div className='messanger_card flex align-items-center'>
                                    <div>
                                        <SvgIconsFilters name={iconMessanders("Сообщения")} width={20} height={20} />
                                    </div>

                                    <span className=''>Сообщения</span>
                                </div>
                            </div>

                            <div className='messanger-item whatsapp no_active'>
                                <div className='messanger_card flex align-items-center'>
                                    <div>
                                        <SvgIconsFilters name={iconMessanders("WhatsApp")} width={20} height={20} />
                                    </div>

                                    <span className=''>WhatsApp</span>
                                </div>
                            </div>

                            {/*  <Button className="add-message ml-3">
                    <img src{`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/plus_green.svg"></img>
                </Button> */}
                        </div>
                        {/* <div className="flex align-items-center my-4 pl-3">
                            <div className={selectedFunnel.payment_data ? 'hidden' : "warning mr-3"}>!</div>
                            <h6 className="funnels-subtitle my-0">Платежные данные</h6>
                        </div> */}
                        {/*     {selectedFunnel.payment_data ?
                            <div className="tunnel-card px-3 py-4 grid justify-content-between align-items-center ml-2 pl-3">
                                <div className="col-4">
                                    <img className="w-full" src{`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/ЮКАССА.svg"></img>
                                </div>
                                <div className="col-6">
                                    <p className="payment_data-text mb-0 mt-1">Платежи в этом туннеле будут проводиться через сторонний сервис ЮКаssа</p>
                                </div>

                                <div className="col-2 flex justify-content-end">
                                    <div>
                                        <Button className={`btn-edit ${colorTheme}`} >
                                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.${colorTheme}.svg`}></img>
                                        </Button>
                                    </div>

                                </div>

                            </div>
                            :
                            <div className="card_warning px-3 py-4 flex justify-content-between align-items-center ml-2 pl-3">
                                <div>
                                    <h6 className="funnels-subtitle">Выберите карту или платежную систему</h6>
                                    <p className="w-8">Некоторые элементы туннеля не будут работать без этой настройки</p>

                                </div>
                                <div>
                                    <Button className={`btn-edit ${colorTheme}`} >
                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.${colorTheme}.svg`}></img>
                                    </Button>
                                </div>

                            </div>} */}
                        <div className='additional_information mt-6 pl-3'>
                            <h6 className='funnels-subtitle mb-4'>Дополнительная информация</h6>

                            <div className='grid justify-content-start'>
                                <div className='col-12 sm:col-8'>
                                    <div className='last_changes'>
                                        <h6>Последние изменения</h6>
                                        {selectedFunnel.last_changes?.map((item) => {
                                            return (
                                                <div className='grid align-items-center justify-content-start'>
                                                    <div className='col-2'>
                                                        {/*   <div className="avatar"></div> */}
                                                        <Gravatar
                                                            email={item.email}
                                                            size={40}
                                                            rating='pg'
                                                            default='retro'
                                                            className='CustomAvatar-image mr-3'
                                                        />
                                                    </div>
                                                    <div className='col-7'>
                                                        <p className='name mb-0'>{item.name}</p>
                                                        <p className='email mb-0'>{item.email}</p>
                                                    </div>
                                                    <div className='col-3 flex flex-column align-items-end'>
                                                        <p className='changes-date mb-0'>{item.date}</p>
                                                        <p className='changes-date mb-0'>в {item.time}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollPanel>
            </div>
            <Attention
                title='Удаление воронки'
                description='Вы уверены, что хотите удалить воронку?'
                additionally='Это действие нельзя будет отменить'
                buttonText='Удалить'
                buttonFunction='delete'
                visible={showAttention}
                onHide={setShowAttention}
                value={selectedFunnel}
                // editActivityHandler={editActivityHandler}
                setFunnels={setFunnels}
            />
        </>
    )
}
