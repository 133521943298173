import React, { useState, useEffect } from "react"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Checkbox } from "primereact/checkbox"
import { Button } from "primereact/button"
import { useSelector } from "react-redux"

const FormFields = ({ onChangeFields, label, type, id, required, deleteFieldHandler }) => {
    const [fieldLabel, setFieldLable] = useState(label)
    const [fieldType, setFieldType] = useState(type)
    const [isRequired, setIsRequired] = useState(required)

    const user = useSelector((state) => state.user.user)
    useEffect(() => {
        onChangeFields(id, fieldLabel, fieldType, isRequired)
    }, [fieldLabel, fieldType, isRequired])

    const fieldTypes = [
        { label: "Полное имя", value: "fullName" },
        { label: "Номер телефона", value: "phone" },
        { label: "Email", value: "email" },
        { label: "Гендер", value: "gender" },
        { label: "Телеграм", value: "telegram" },
    ]

    return (
        <div className='create-button-container mb-3'>
            {(user?.role === 'admin' || user?.role === 'moderator') &&
                <div className='create-button-header mb-2'>
                    <h5>Редактирование поля</h5>
                    <Button className='p-button-rounded button-delete' icon='pi pi-trash' onClick={() => deleteFieldHandler(fieldType)} />
                </div>}
            <div className='field w-full'>
                <span className='p-float-label'>
                    <InputText
                        id='fieldLabel'
                        className='block text w-full'
                        value={fieldLabel}
                        onChange={(e) => setFieldLable(e.target.value)}
                        readOnly={user?.role === 'employer'}
                    />
                    <label htmlFor='fieldTitle'>Введите подпись</label>
                </span>
            </div>
            <div className='field'>
                <span className='p-float-label'>
                    <Dropdown className='select w-full' value={fieldType} options={fieldTypes} disabled={user?.role === 'employer'} onChange={(e) => setFieldType(e.value)} />
                    <label htmlFor='initValue'>Тип поля</label>
                </span>
            </div>
            <div className='w-full'>
                <div className='field-checkbox'>
                    <Checkbox inputId='binary' disabled={user?.role === 'employer'} checked={isRequired} onChange={(e) => setIsRequired(e.checked)} />
                    <label htmlFor='binary'>Обязательное поле</label>
                </div>
            </div>
        </div>
    )
}

export default FormFields
