import { Button } from "primereact/button"
import { Sidebar } from "primereact/sidebar"
import React, { memo, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Handle, useReactFlow } from "react-flow-renderer"
import { useParams } from "react-router-dom"
import { changeNodePosition, getFunnel, updateNode } from "../../../service/funnels"
import { setInitNodes } from "../../../service/slices/initNodesSlice"

import CreateInterval from "../Sidebars/CreateInterval"
import "./Blocks.scss"
import { slicedText } from "../utils"

export default memo((props) => {
    const dispatch = useDispatch()

    const params = useParams()
    const funnelId = +params?.id

    const data = props.data
    data.id = props.id
    data.type = props.type

    const [visibleIntervalSidebar, setVisibleIntervalSidebar] = useState(false)
    const user = useSelector((state) => state.user.user)
    // console.log("interval: ", data)
    const date = new Date(data?.waitUntil).toLocaleDateString()
    // console.log("Date in INTERVAL: ", date)

    const reactFlowInstance = useReactFlow()

    const onConnect = (params) => {
        let source = reactFlowInstance.getNode(params?.source)
        let target = reactFlowInstance.getNode(params?.target)

        if (
            data.type === "intervalStart" &&
            (target?.type === "message" || target?.type === "button" || target?.type === "file") &&
            !target.parentNode
        ) {
            console.log("МОЖНО", source)
            const addedNodeToInterval = {
                messenger: target?.data?.messenger,
                files: target?.data?.files,
                buttons: target?.data?.buttons,
                blueprintID: funnelId,
                uuid: params.target,
                isInterval: true,
                parentNode: source.id,
            }
            console.log("TRGET: ", target)
            updateNode(addedNodeToInterval, target?.type).then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
            const nodePosition = {
                uuid: params.target,
                position: { x: target.position.x - source.position.x, y: target.position.y - source.position.y },
            }
            changeNodePosition({ blueprintID: funnelId, nodes: [nodePosition] })
        } else if (data.type === "intervalEnd") {
            console.log("End of interval")
        }
    }

    return (
        <div
            className='PauseBlock'
            onDoubleClick={() => {
                data.type === "intervalStart" && setVisibleIntervalSidebar(true)
            }}
        >
            <Handle
                id='l'
                type='target'
                className='custom-handle'
                position='left'
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={true}
            />
            <div className='node-block'>
                <div className='header'>
                    <h3>{slicedText(data?.title, 14) || "Распределение"}</h3>
                    <div className={`${data.type === "intervalStart" ? "button-container" : "hidden"}`}>
                        <Button className={`p-button-text `} onClick={() => setVisibleIntervalSidebar(true)}>
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.svg`}></img>
                        </Button>
                    </div>
                </div>
                <div className='body'>
                    {data?.waitUntil && <p>до {date}</p>}
                    {data?.type === "intervalStart" ? <p className='block-type start'>Старт</p> : <p className='block-type end'>Конец</p>}
                </div>
            </div>
            <Handle
                id='r'
                type='source'
                className='custom-handle'
                position='right'
                onConnect={(params) => onConnect(params)}
                isConnectable={true}
            />
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleIntervalSidebar}
                onHide={() => setVisibleIntervalSidebar(false)}
                position='right'
            >
                <CreateInterval onHide={() => setVisibleIntervalSidebar(false)} funnelId={funnelId} data={data} />
            </Sidebar>
        </div>
    )
})
