import React from "react"
import { Button } from "primereact/button"
import { useSelector } from "react-redux"

const SidebarFooter = ({ saveHandler, onHide, disabled }) => {
    const user = useSelector((state) => state.user.user)
    return (
        <div className={`flex flex-column justify-content-between ${(user?.role === 'admin' || user?.role === 'moderator') ? 'sidebar-buttons' : 'sidebar-buttons close'} `}>
            {(user?.role === 'admin' || user?.role === 'moderator') ?
                <>
                    <Button label='Сохранить и закрыть' disabled={disabled} className='btn py-3 px-4' onClick={() => saveHandler()} />
                    <Button label='Выйти из редактирования' className='btn-cancel p-button-text py-3 px-4' onClick={() => onHide()} />
                </>
                : <Button label='Закрыть' className='btn py-3 px-4' onClick={() => onHide()} />
            }
        </div>

    )
}

export default SidebarFooter
