import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useState } from 'react'

export const SelectTime = ({ selectDay, selectHour, selectMinutes, setSelectDay, setSelectHour, setSelectMinutes }) => {

    function addZero(val) {
        if (val < 10) {
            return '0' + val;
        }
        return val;
    };

    const selectTimeHandler = (selectValue, value, number, min) => {
        console.log(value)
        value = value.replace(/[^0-9]/g, '')
        if (+value > number) {
            value = number
        }

        selectValue(addZero(Number(value)))
    }

    const blurHandler = (e) => {
        if (+e.target.value < 5) {
            setSelectMinutes(addZero(5))
        }
    }

    const minutesMinusHandler = () => {
        setSelectMinutes(prev => {
            if (prev <= 5) {
                return addZero(5)
            }
            return addZero((+prev) - 1)
        })
    }
    const minutesPlusHandler = () => {
        setSelectMinutes(prev => {
            if (prev < 5) {
                return addZero(5)
            }
            return addZero((+prev) + 1)
        })
    }

    return (
        <div className="card-time w-full xl:w-10 flex justify-content-center">
            <div className="time-block flex flex-column align-items-center">
                <h6>Дни</h6>
                <div className="flex align-items-center">
                    <Button className="btn-arrow px-1 xl:px-2" disabled={!selectDay} onClick={() => setSelectDay(prev => addZero((+prev) - 1))}>
                        <i className="pi pi-angle-left"></i>
                    </Button>
                    <InputText className="select-time_input" inputId="integeronly" value={selectDay} placeholder='00' onChange={(e) => selectTimeHandler(setSelectDay, e.target.value)} />
                    <Button className="btn-arrow px-1 xl:px-2" onClick={() => setSelectDay(prev => addZero((+prev) + 1))}>
                        <i className="pi pi-angle-right"></i>
                    </Button>
                </div>

            </div>
            <div className="time-block flex flex-column align-items-center">
                <h6>Часы</h6>
                <div className="flex align-items-center">
                    <Button className="btn-arrow px-1 xl:px-2" disabled={!selectHour} onClick={() => setSelectHour(prev => addZero((+prev) - 1))}>
                        <i className="pi pi-angle-left"></i>
                    </Button>
                    <InputText className="select-time_input" inputId="integeronly" value={selectHour} placeholder='00' onChange={(e) => selectTimeHandler(setSelectHour, e.target.value, 23)} />
                    <Button className="btn-arrow px-1 xl:px-2" disabled={selectHour === 23} onClick={() => setSelectHour(prev => addZero((+prev) + 1))}>
                        <i className="pi pi-angle-right"></i>
                    </Button>
                </div>

            </div>
            <div className="time-block flex flex-column align-items-center">
                <h6>Минуты</h6>
                <div className="flex align-items-center">
                    <Button className="btn-arrow px-1 xl:px-2" disabled={!selectMinutes} onClick={minutesMinusHandler}>
                        <i className="pi pi-angle-left"></i>
                    </Button>
                    <InputText className="select-time_input" inputId="integeronly" value={selectMinutes} placeholder='00' onChange={(e) => selectTimeHandler(setSelectMinutes, e.target.value, 59, 5)} onBlur={(e) => blurHandler(e)} />
                    <Button className="btn-arrow px-1 xl:px-2" disabled={selectMinutes === 59} onClick={minutesPlusHandler}>
                        <i className="pi pi-angle-right"></i>
                    </Button>
                </div>

            </div>

        </div>
    )
}