import { InputText } from "primereact/inputtext"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addTagAnswer, deleteTagAnswer } from "../../service/slices/smartAnswersSlice"

export const InputAddTags = () => {
    const answer = useSelector((state) => state.smartAnswers.answer)
    const [tagValue, setTagValue] = useState('')
    const dispatch = useDispatch()

    const enterHandler = (e) => {
        if (e.keyCode === 13) {
            if (!e.target.value) {
                e.preventDefault()
            } else {
                const dataTag = {
                    answerID: answer.answerID,
                    tag: e.target.value
                }
                dispatch(addTagAnswer(dataTag))
                e.target.value = null
            }

        }
    }


    const deleteTagHandler = (item) => {
        console.log('click')
        console.log(item)

        const dataTag = {
            answerID: answer.answerID,
            tag: item
        };
        console.log(dataTag)
        dispatch(deleteTagAnswer(dataTag))
    }
    return (
        <div className='w-full sm:w-10'>
            <ul className='tags-container'>
                {answer.tags?.map((item) => <li>
                    <span>{item}</span>
                    <span onClick={() => deleteTagHandler(item)}>
                        <i className="pi pi-times" style={{ fontSize: '10px' }}></i>
                    </span>

                </li>)}
                <li className='input_tags_container'>
                    <InputText className='input-add_tags' placeholder='Введите тег...' value={tagValue} onChange={(e) => setTagValue(e.value)} onKeyUp={(e) => enterHandler(e)} />
                </li>
            </ul>
        </div>
    )
}