import { Button } from "primereact/button"
import React from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import { CardNewUsers } from "../../components/CardNewUsers/CardNewUsers"
import { SelectDate } from "../../components/SelectDate/SelectDate"
import { SkeletonDate } from "../../components/SkeletonDate/SkeletonDate"
import { CardDiagramMain } from "../Statistics/CardDiagramMain/CardDiagramMain"
import { SkeletonDiagramMain } from "../Statistics/CardDiagramMain/SkeletonDiagramMain"
import { CardDiagram } from "./CardDiagram"

export const StatisticsMailing = ({ maximize, selectDate, formatStart, formatEnd, create, setCreate, setSelectedMailing }) => {
    const answers = useSelector((state) => state.smartAnswers.answers)
    const messangers = useSelector((state) => state.smartAnswers.messangers)
    const role = localStorage.getItem('roleUser')
    const user = useSelector((state) => state.user.user)
    const [called, setCalled] = useState(0)
    const newUsers = useSelector((state) => state.newUsers.users)
    console.log(messangers)
    return (
        <div className={maximize ? 'hidden' : 'grid px-2 justify-content-between'}>
            <div className="block-diagram mb-3 py-2">

                {/*  <CardDiagram
                    selectDate={selectDate}
                    formatStart={formatStart}
                    formatEnd={formatEnd}
                /> */}
                {answers
                    ? <CardDiagramMain title='Умные ответы' />
                    : <SkeletonDiagramMain />
                }


            </div>

            <div className="block-select_date py-2 mb-3">
                {!answers ?
                    <SkeletonDate />
                    :
                    <SelectDate />}
            </div>
            <div className="mb-3 py-2 mb-2 block-called_answers">
                <div className="card h-full called-answers">
                    <div className="wrapper-text">
                        <span>
                            {newUsers === 0
                                ? <span>Новых пользователей за сегодня нет.</span>
                                : <div className='flex flex-column'>
                                    <span>Новых пользователей за {selectDate === 'today' ? 'сегодня' : selectDate === 'yesterday' ? 'вчера' : 'период'}
                                    </span>
                                    <h1 className="mb-0">{newUsers} чел.</h1>
                                </div>
                            }

                        </span>


                    </div>
                    {(user?.role === 'admin' || user?.role === 'moderator') &&
                        <Button className="add-answer-btn primary mb-3 md:mb-0" onClick={() => { setCreate(!create); setSelectedMailing(null) }}>
                            <span>Создать рассылку</span>
                        </Button>}
                </div>
            </div>
        </div>
    )
}

export const MemoStatisticAnswers = React.memo(StatisticsMailing)