import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CarouselFunnel } from "./CarouselFunnel";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export const Problem = ({ data, chart, colorFunc, backgroundColorFunc }) => {

    const [currentFunnel, setCurrentFunnel] = useState(data[0].id)
    const [sortMax, setSortMax] = useState(true)
    const funnel = data?.find(funnel => funnel.id === currentFunnel)
    const colorTheme = useSelector((state) => state.colorTheme.theme)


    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    const funnelTemplate = (item) => {

        return (
            <CarouselFunnel item={item} chart={chart} id='problemMini' currentFunnel={currentFunnel} setCurrentFunnel={setCurrentFunnel} updateHandler={updateHandler} />
        );

    }

    const updateHandler = (item) => {
        chart.data.labels = item?.data
        chart.data.datasets.forEach((dataset) => {
            dataset.data = item?.data
            dataset.borderColor = colorFunc(item)
            dataset.backgroundColor = backgroundColorFunc(item)
        });
        chart.update();
    }




    return (

        <div className="card pr-0 h-full flex flex-column justify-content-between">
            <div className='flex justify-content-between align-items-start pr-4'>
                <h5 className='title-card'>Низкоконвер&shy;сионные воронки</h5>
                <Button className={`p-button-outlined max ${colorTheme}`} onClick={() => setSortMax(!sortMax)}>
                    <i className={`pi ${sortMax ? 'pi-sort-numeric-up-alt' : 'pi-sort-numeric-down-alt'}`}></i>
                    <span className="text-btn hidden sm:inline md:hidden lg:inline xl:hidden  ml-2">{sortMax ? 'max' : 'min'}</span>
                </Button>

            </div>
            <div>
                <a className="font-bold" href='#'>{funnel?.title} "{funnel?.description}"</a>
                <h5 className='title-card red m-2'>$ {Intl.NumberFormat('ru-RU').format(funnel?.sum)}</h5>

                {data && <div style={{ width: '100%', height: '120px', paddingRight: '22px' }}>
                    <canvas id={'problem'} width="560" height="120"></canvas>
                </div>
                }

                <div className='grid mt-3 align-items-end'>
                    <div className='col-3'>
                        <p className='my-0 text-sm'>Запуск воронки:</p>
                        <p className='my-0 text-xs gray'>{funnel?.start}</p>
                        <p className='mt-2 mb-0 text-sm'>Конверсия:</p>
                        <div className="flex align-items-center">
                            <p className='my-0 сonversion mr-2'>{funnel?.сonversion}%</p>
                            <div>
                                <Button className="p-button-rounded p-button-text p-0">
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/exclamationmark.circle.svg`}></img>
                                </Button>
                            </div>

                        </div>

                    </div>
                    <div className='col-9'>
                        <Carousel className="pr-2" value={data || []} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions}
                            itemTemplate={funnelTemplate} />
                    </div>
                </div>
            </div>



        </div>
    )
}