import { Skeleton } from "primereact/skeleton"

export const SkeletonChart = () => {
    return (
        <div className="card-charts pr-0 h-full flex flex-column justify-content-between">
            <div className='flex justify-content-between align-items-start pr-4'>
                <Skeleton width='17rem' height='1.5rem' />
                <Skeleton size='2rem' />

            </div>
            <div>
                <Skeleton width='15rem' height='1.5rem' className='mb-3' />
                <Skeleton width='7rem' height='2rem' className='mb-3' />
                <div className='pr-4'>
                    <Skeleton width="100%" height="110px"></Skeleton>
                </div>


                <div className='grid mt-3 align-items-end'>
                    <div className='col-3'>
                        <Skeleton width='7rem' height='1rem' className='mb-1' />
                        <Skeleton width='5rem' height='1rem' className='mb-3' />
                        <Skeleton width='5rem' height='1rem' className='mb-1' />

                        <Skeleton width='4rem' height='2.5rem' />
                    </div>
                    <div className='col-9'>
                        <div className='flex align-items-center'>
                            <Skeleton size='1.5rem' className='mr-2'></Skeleton>

                            <Skeleton width="100px" height="100px" className='mr-3'></Skeleton>

                            <Skeleton width="100px" height="100px" className='mr-3 skeleton-block'></Skeleton>

                            <Skeleton width="100px" height="100px" className='mr-2'></Skeleton>
                            <Skeleton size='1.5rem'></Skeleton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}