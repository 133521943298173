import { Chart, registerables } from "chart.js";

import { useEffect } from "react";
import { useSelector } from "react-redux";
Chart.register(...registerables);

export const ChartMini = ({ item, id }) => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    useEffect(() => {
        const color = () => {
            if (item?.conversion < 50 && item?.conversion > 20) {
                return '#FFD60A'
            } else if (item.сonversion < 1) {
                return '#FF3B30'
            } else {
                return '#28CD41'
            }

        }

        const backgroundColor = () => {
            if (item?.conversion < 50 && item?.conversion > 20) {
                return '#FFD60A1A'
            } else if (item.сonversion < 1) {
                return '#FF3B301A'
            } else {
                return '#28CD411A'
            }
        }

        const ticksColor = () => {
            if (colorTheme === 'dark') {
                return '#F7FFF7'
            } else {
                return '#293241'
            }
        }

        const ctx = document.getElementById(`${id}`).getContext('2d');
        let chart = null;


        if (chart !== null) {
            chart.destroy();
        }
        chart = new Chart(ctx, {
            type: 'line', data: {
                labels: item?.data,
                datasets: [

                    {
                        label: '',
                        data: item?.data,
                        fill: true,
                        borderColor: color(),
                        tension: .4,
                        backgroundColor: backgroundColor(),
                        pointRadius: 0,
                        borderWidth: 1
                    }
                ]

            },
            options: {
                maintainAspectRatio: false,
                aspectRatio: 2.5,
                plugins: {
                    legend: false
                },
                scales: {
                    x: {
                        ticks: {
                            display: false,
                            color: '#495057'
                        },
                        grid: {
                            display: false,
                            color: '#566476'
                        }
                    },
                    y: {
                        ticks: {
                            display: false,
                            color: ticksColor()
                        },
                        grid: {
                            display: false,
                            color: '#566476'
                        }
                    }
                }
            }
        });

    }, [])

    return (
        <div style={{ width: '90%', height: '35px' }}>
            <canvas id={`${id}`} height="35"></canvas>
        </div>

    )
}