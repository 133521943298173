import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { useEffect, useRef, useState } from "react"
import { ScrollPanel } from 'primereact/scrollpanel';

import './Chats.css'
import { useSelector } from "react-redux"
import { Portrait } from "../../components/Portrait/Portrait"
import { Sidebar } from "primereact/sidebar";
import { ListContacts } from "./ListContacts";
import { History } from "./History";
import axios from 'axios'
import { Skeleton } from "primereact/skeleton";
import { log } from "../../service/log";
import { SkeletonPortrait } from "../../components/Portrait/SkeletonPortrait";

export const Chats = () => {

    const [message, setMessage] = useState('')
    const [showHistory, setShowHistory] = useState(false)
    const [showChats, setShowChats] = useState(true)
    const [showProfile, setShowProfile] = useState(false)
    const [chatsList, setChatsList] = useState(null)
    const [active, setActive] = useState(null)

    const colorTheme = useSelector((state) => state.colorTheme.theme)

    useEffect(() => {
        document.title = 'Bot Magic | Чаты'
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_URL}/data.json`)
            .then((response) => {
                log(response.data)
                setChatsList(response.data.users)
                setActive(response.data.users[0])
            })
            .catch((error) => console.error(error))

    }, [])

    /*     const chatsList = [
            { "id": 1, "avatar": "photo_1.png", "name": "Константин Констанинопольский", "messanger": "instagram", "history": [{ "date": "25.05.2022", "tunnel": { "number": 250, "name": "Продажа курса" }, "message": "Спасибо, что заинтересовались нашим курсом!", "from": false, "to": true }, { "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "smart_answer": "Спасибо за спасибо", "message": "Вам спасибо! Не забывайте про наши дополнительные курсы, которые можно приобрести сейчас со скидкой 20 %", "from": false, "to": true }, { "date": "25.05.2022", "message": "Вам спасибо!", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 2, "avatar": "photo_2.png", "name": "Sergey Vasiliev", "messanger": "telegram", "history": [{ "date": "25.05.2022", "message": "Спасибо большое!", "from": true, "to": false }, { "date": "25.05.2022", "message": "И Вам спасибо!", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": true },
            { "id": 3, "avatar": "photo_3.png", "name": "Ольга", "messanger": "facebook", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо!", "from": false, "to": true }, { "date": "26.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "26.05.2022", "message": "Вам спасибо!", "from": false, "to": true }, { "date": "27.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "27.05.2022", "message": "Вам спасибо!", "from": false, "to": true }, { "date": "28.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "28.05.2022", "message": "Вам спасибо!", "from": false, "to": true }, { "date": "29.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "29.05.2022", "message": "Вам спасибо!", "from": false, "to": true }, { "date": "30.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "30.05.2022", "message": "Вам спасибо!", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 4, "avatar": "photo_4.png", "name": "Виктор Викторович", "messanger": "whatsapp", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо!", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": true },
            { "id": 5, "avatar": "photo_5.png", "name": "Татьяна Анатольевна Ч.", "messanger": "facebook_messenger", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо! ", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 6, "avatar": "photo_6.png", "name": "+7 (987) 117 - 99 - 88", "messanger": "wechat", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо! ", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 7, "avatar": "photo_7.png", "name": "@assignov", "messanger": "telegram", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо! ", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 8, "avatar": "photo_8.png", "name": "Дима Билан", "messanger": "instagram", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо! ", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 9, "avatar": "photo_9.png", "name": "Степан.", "messanger": "facebook", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо!", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 10, "name": "top.petuh.in.world", "messanger": "whatsapp", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо! ", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 11, "avatar": "photo_11.png", "name": "Артемий Л.", "messanger": "facebook_messenger", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо!", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 12, "avatar": "photo_12.png", "name": "~СаМаЯ СчАсТлИвАя~", "messanger": "wechat", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо!", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 13, "avatar": "photo_13.png", "name": "и так далее", "messanger": "facebook_messenger", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо!", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
            { "id": 14, "avatar": "photo_14.png", "name": "и тому подобное", "messanger": "wechat", "history": [{ "date": "25.05.2022", "message": "Спасибо!", "from": true, "to": false }, { "date": "25.05.2022", "message": "Вам спасибо! ", "from": false, "to": true }], "actions": [{ "action": "Воронка 260", "date": "24.05.2022" }, { "action": "Воронка 10", "date": "30.05.2022" }, { "action": "Покупка курса №30", "date": "30.05.2022" }, { "action": "Воронка 61", "date": "14.06.2022" }], "blocked": false },
        ] */



    const activeChat = chatsList?.find(item => item.id === active)
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [active?.history]);


    const profileHandler = () => {
        setShowProfile(true)
    }


    const isDesktop = () => {
        return window.innerWidth >= 992
    }

    const skeleton = [15, 10, 7, 12, 15, 10, 7, 12, 15, 10, 7, 12, 15, 10]

    log(active)

    return (
        <>
            <div className="">
                <div className="grid justify-content-start">
                    <div className="col-12 md:col-6 lg:col-4">
                        <div className="block md:hidden fixed w-full pr-7">
                            {chatsList ?
                                <>
                                    {showChats &&
                                        <ListContacts chatsList={chatsList} active={active} setActive={setActive} setShowHistory={setShowHistory} setShowChats={setShowChats} />
                                    }
                                </>
                                :
                                <div className="card chats pt-0 px-2">
                                    <div className="pt-3 w-full px-3 mb-5">
                                        <Skeleton width="7rem" height="2rem" />
                                    </div>

                                    <div>
                                        <div className="pl-0 w-full">
                                            {skeleton.map((item, idx) => {
                                                return <div key={idx} className="pl-3 py-2 flex">
                                                    <div className="w-full flex justify-content-between align-items-center pr-3">
                                                        <div className="flex align-items-center">
                                                            <Skeleton shape="circle" size="2rem" className="mr-3"></Skeleton>
                                                            <Skeleton width={`${item}rem`} />
                                                        </div>
                                                        <Skeleton size="1.5rem" ></Skeleton>


                                                    </div>


                                                </div>
                                            })}
                                        </div>

                                    </div>
                                </div>
                            }

                            {showHistory &&
                                <History setShowProfile={setShowProfile} setShowChats={setShowChats} setShowHistory={setShowHistory} active={active} />
                            }
                        </div>
                        <div className="hidden md:block fixed col-12 md:col-6 lg:col-4 p-0 pr-5">
                            {chatsList ?
                                <ListContacts chatsList={chatsList} active={active} setActive={setActive} setShowHistory={setShowHistory} setShowChats={setShowChats} />
                                :
                                <div className="card chats pt-0 px-2">
                                    <div className="pt-3 w-full px-3 mb-5">
                                        <Skeleton width="7rem" height="2rem" />
                                    </div>

                                    <div>
                                        <div className="pl-0 w-full">
                                            {skeleton.map((item, idx) => {
                                                return <div key={idx} className="pl-3 py-2 flex">
                                                    <div className="w-full flex justify-content-between align-items-center pr-3">
                                                        <div className="flex align-items-center">
                                                            <Skeleton shape="circle" size="2rem" className="mr-3"></Skeleton>
                                                            <Skeleton width={`${item}rem`} />
                                                        </div>
                                                        <Skeleton size="1.5rem" ></Skeleton>


                                                    </div>


                                                </div>
                                            })}
                                        </div>

                                    </div>
                                </div>}
                        </div>

                    </div>
                    <div className="col-6 lg:col-8 xl:col-4">
                        <div className="hidden md:block fixed col-6 lg:col-8 xl:col-4 pt-0 pr-5">
                            {chatsList ?
                                <History setShowProfile={setShowProfile} setShowChats={setShowChats} setShowHistory={setShowHistory} active={active} />
                                :
                                <div className="card chats pt-0 px-4 flex flex-column justify-content-between">
                                    <div className="pt-3 w-full px-3 mb-5">
                                        <Skeleton width="15rem" height="2rem" />
                                    </div>
                                    <div className="message-list">
                                        <div className="flex justify-content-center mb-3">
                                            <Skeleton width="15rem" height="2rem" />
                                        </div>
                                        <div className="flex align-items-end flex-column mb-5">
                                            <Skeleton width="25rem" height="4rem" className="mb-2" />
                                            <Skeleton width="15rem" height="1rem" />
                                        </div>
                                        <div className="flex align-items-start flex-column mb-5">
                                            <Skeleton width="8rem" height="2rem" className="mb-2" />
                                        </div>
                                        <div className="flex align-items-end flex-column mb-5 skeleton-message">
                                            <Skeleton width="25rem" height="6rem" className="mb-2" />
                                            <Skeleton width="15rem" height="1rem" />
                                        </div>
                                        <div className="flex align-items-end flex-column mb-5">
                                            <Skeleton width="13rem" height="2rem" className="mb-2" />
                                            <Skeleton width="11rem" height="1rem" />
                                        </div>
                                    </div>
                                    <div className="flex mt-8 w-full">
                                        <Skeleton height="3rem" />
                                        <div className="ml-3">
                                            <Skeleton shape="circle" size="3rem" ></Skeleton>
                                        </div>

                                    </div>
                                </div>}
                        </div>
                        <Sidebar visible={showProfile} position="right" className="popover" onHide={() => setShowProfile(false)}>
                            {chatsList ?
                                <Portrait activeChat={active} dataUsers={chatsList[0]} className='sidebar' />
                                :
                                <SkeletonPortrait />
                            }
                        </Sidebar>
                    </div>
                    <div className="col-4 hidden xl:block">
                        <div className="fixed col-4 p-0 pr-5">
                            <div className="card chats pt-0 px-2">
                                {chatsList ?
                                    <Portrait activeChat={active} dataUsers={chatsList[0]} className='list-contact' />
                                    :
                                    <SkeletonPortrait />
                                }
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>

    )
}