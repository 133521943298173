import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"

import { createNode, getFunnel, updateNode } from "../../../service/funnels"
import { setInitNodes } from "../../../service/slices/initNodesSlice"
import { formatedSeconds } from "../utils"
import SidebarFooter from "./components/SidebarFooter"
import SidebarHeader from "./components/SidebarHeader"
import "./Sidebar.scss"

const CreateDelay = ({ data, onHide, funnelId, centerCoordinates }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)
    const [nodeID, setNodeID] = useState("")
    const [blockTitle, setBlockTitle] = useState(data?.title || "")
    const [type, setType] = useState("delay")

    const [days, setDays] = useState("")
    const [hours, setHours] = useState("")
    const [minutes, setMinutes] = useState("")

    const [dateUntil, setDateUntil] = useState(null)

    useEffect(() => {
        const node_id = uuid()
        setNodeID(node_id)
    }, [])

    useEffect(() => {
        if (data?.delay) {
            setType("delay")
            const delay = formatedSeconds(data?.delay)
            setDays(delay[0])
            setHours(delay[1])
            setMinutes(delay[2])
        } else if (data?.waitUntil) {
            setType("waitUntil")
            setDateUntil(new Date(data?.waitUntil))
        }
    }, [data])

    const options = [
        { label: "Приостановка на время", value: "delay" },
        { label: "Приостановка до даты", value: "waitUntil" },
    ]

    const delay = () => {
        const sum = +days * 24 * 60 * 60 + +hours * 60 * 60 + +minutes * 60
        return sum
    }

    // Добавление нового блока
    const saveHandler = () => {
        if (type === "delay") {
            const delayNode = {
                blueprintID: funnelId,
                title: blockTitle,
                position: centerCoordinates,
                delay: delay(),
                data: { title: blockTitle },
            }
            if (data) {
                const updateDelayNode = { ...delayNode, uuid: data?.id }
                updateNode(updateDelayNode, "delay").then((res) =>
                    getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
                )
            } else {
                const createDelayNode = { ...delayNode, uuid: uuid() }
                createNode(createDelayNode, "delay").then((res) =>
                    getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
                )
            }
        }
        if (type === "waitUntil") {
            const waitUntilNode = {
                blueprintID: funnelId,
                title: blockTitle,
                type: "waitUntil",
                waitUntil: dateUntil.toString(),
                position: centerCoordinates,
                data: { title: blockTitle, dateUntil },
            }
            if (data) {
                const updateWaitUntilNode = { ...waitUntilNode, uuid: data?.id }
                updateNode(updateWaitUntilNode, "wait-until").then((res) =>
                    getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
                )
            } else {
                const createWaitUntilNode = { ...waitUntilNode, uuid: nodeID }
                createNode(createWaitUntilNode, "wait-until").then((res) =>
                    getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
                )
            }
        }

        onHide()
        setBlockTitle("")
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Пауза' icon={"pause"} onHide={() => onHide()} />

            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={[0, 1]}>
                    <AccordionTab header='Контент' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === "employer"}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                        <div className='mb-3 mt-3 w-full'>
                            <Dropdown
                                disabled={data}
                                className='select mb-2 w-full'
                                value={type}
                                options={options}
                                onChange={(e) => setType(e.value)}
                            />
                            <p>Останавливает действие конкретной ветки воронки до указаного времени.</p>
                            {type === "delay" && (
                                <div className='flex'>
                                    <InputText
                                        type='number'
                                        className='text w-full mr-2'
                                        placeholder='Дней'
                                        value={days}
                                        onChange={(e) => setDays(e.target.value)}
                                        min={0}
                                        readOnly={user?.role === "employer"}
                                    />
                                    <InputText
                                        type='number'
                                        className='text w-full mr-2'
                                        placeholder='Час.'
                                        value={hours}
                                        onChange={(e) => setHours(e.target.value)}
                                        min={0}
                                        readOnly={user?.role === "employer"}
                                    />
                                    <InputText
                                        type='number'
                                        className='text w-full'
                                        placeholder='Мин.'
                                        value={minutes}
                                        onChange={(e) => setMinutes(e.target.value)}
                                        min={0}
                                        readOnly={user?.role === "employer"}
                                    />
                                </div>
                            )}
                            {type === "waitUntil" && (
                                <Calendar
                                    className={`select ${user?.role === "employer" ? "disabled-btn" : ""} w-full`}
                                    showTime
                                    hourFormat='24'
                                    showIcon
                                    icon='pi pi-clock'
                                    value={dateUntil}
                                    onChange={(e) => setDateUntil(e.value)}
                                    showOnFocus={user?.role !== "employer"}
                                ></Calendar>
                            )}
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>

            <SidebarFooter saveHandler={() => saveHandler()} disabled={type === "delay" ? !delay() : !dateUntil} onHide={() => onHide()} />
        </div>
    )
}

export default CreateDelay
