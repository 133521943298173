import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Toast } from "primereact/toast"
import { deleteBlueprint, getAllBlueprints, stopBlueprint } from "../../service/funnels"

export const Attention = ({ title, description, additionally, buttonText, buttonFunction, visible, onHide, value, setFunnels }) => {
    const toast = useRef(null)

    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const renderHeader = () => {
        return (
            <>
                <div className='flex justify-content-start align-items-center'>
                    <div className='attention-symbol mr-5'>
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/attention.svg`}></img>
                    </div>
                    <h5 className='attention-header'>{title || "Подтверждение действия"}</h5>
                </div>
            </>
        )
    }

    const renderFooter = () => {
        return (
            <>
                <div className='flex justify-content-between mt-3'>
                    <Button label='Отменить' onClick={() => onHide(false)} className='btn-main p-button-text py-3 px-4 mr-3' />
                    <Button label={buttonText} onClick={disableHandler} className='btn-disable py-3 px-4' />
                </div>
            </>
        )
    }

    const disableHandler = () => {
        onHide(false)
        if (buttonFunction === "disable") {
            stopBlueprint(value.id).then((res) =>
                getAllBlueprints().then((res) => {
                    const newArr = res?.data?.blueprints?.map(({ title, ...n }) => ((n.name = title), n))
                    setFunnels(newArr)
                })
            )
            toast.current.show({ severity: "info", summary: "Выключено", detail: `Воронка "${value.name}" выключена`, life: 3000 })
        }
        if (buttonFunction === "delete") {
            deleteBlueprint(value.id).then((res) =>
                getAllBlueprints().then((res) => {
                    const newArr = res?.data?.blueprints?.map(({ title, ...n }) => ((n.name = title), n))
                    setFunnels(newArr)
                })
            )
            toast.current.show({ severity: "info", summary: "Удалено", detail: `Воронка "${value.name}" удалена`, life: 3000 })
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                className={`attention ${colorTheme} attention-popup`}
                header={renderHeader()}
                visible={visible}
                footer={renderFooter()}
                onHide={() => onHide(false)}
            >
                <p>
                    {description || "Вы уверены?"}
                    {/* Удаление сообщений из очереди отправки займет <span>около 15 минут.</span>{" "} */}
                </p>

                {additionally && <p className='font-italic mt-6'>P.S. {additionally}</p>}
            </Dialog>
        </>
    )
}
