import { Chart } from "primereact/chart";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

export const Diagram = ({ backgroundColor, sumCalls }) => {
    const messangers = useSelector((state) => state.smartAnswers.messangers)



    const data = messangers?.map((item) => {
        return (item.called * 100) / sumCalls
    })

    const labels = messangers?.map((item) => {
        return item.messenger
    })

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: '%',
                data: data,
                backgroundColor: backgroundColor,
                hoverBackgroundColor: backgroundColor,
                cutout: '85%',
                borderRadius: 15,
                rotation: 150,
                borderWidth: 0,


            },
        ]
    }
        ;

    const lightOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (item) {
                        return item.label + ': ' + item.parsed.toFixed(1) + '%';
                    }
                }
            },
            legend: false
        }
    };


    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: 2,
            plugins: {
                legend: false
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#2D3645'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#566476'
                    }
                }
            }
        };

        return {
            basicOptions

        }
    }

    const { basicOptions } = getLightTheme();
    return (
        <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative', width: '208px' }} />
    )
}