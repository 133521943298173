import { Button } from "primereact/button"
import { Sidebar } from "primereact/sidebar"
import { ScrollPanel } from "primereact/scrollpanel"
import { Image } from "primereact/image"
import React, { memo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Handle, useReactFlow } from "react-flow-renderer"
import { useParams } from "react-router-dom"

import { changeNodePosition, getFunnel, updateNode } from "../../../service/funnels"
import { setInitNodes } from "../../../service/slices/initNodesSlice"
import CreateButtons from "../Sidebars/CreateButtons"
import CreateFiles from "../Sidebars/CreateFiles"
import CreateBlock from "../Sidebars/CreateTextBlock/CreateBlock"
import { getFileIcon, slicedText, slicedTextWithExtension } from "../utils"
import "./Blocks.scss"

export default memo((props) => {
    const dispatch = useDispatch()

    const params = useParams()
    const funnelId = +params?.id
    // console.log("FUNEL ID::: ", funnelId)

    // console.log("ПРОПСЫ", props)

    const data = props.data
    data.id = props.id
    data.type = props.type
    // data.buttonType = props?.data?.type
    data.position = { x: props.xPos, y: props.yPos }

    const [visibleTextSidebar, setVisibleTextSidebar] = useState(false)
    const reactFlowInstance = useReactFlow()
    const user = useSelector((state) => state.user.user)
    const onConnect = (params) => {
        // console.log("CONNECT", params)
        let source = reactFlowInstance.getNode(params?.source)
        let target = reactFlowInstance.getNode(params?.target)

        let startIntervalNode = reactFlowInstance.getNode(source.parentNode)

        // console.log("src: ", source)
        // console.log("Старт распределения: ", startIntervalNode)

        if (
            source?.isInterval === true &&
            (target?.type === "message" || target?.type === "button" || target?.type === "file") &&
            !target.parentNode
        ) {
            console.log("TRUE", target)
            const addedNodeToInterval = {
                messenger: target?.data?.messenger,
                files: target?.data?.files,
                buttons: target?.data?.buttons,
                blueprintID: funnelId,
                uuid: params.target,
                isInterval: true,
                parentNode: source.data?.parentNode,
            }
            updateNode(addedNodeToInterval, "message").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
            const nodePosition = {
                uuid: params.target,
                position: { x: target.position.x - startIntervalNode.position.x, y: target.position.y - startIntervalNode.position.y },
            }
            changeNodePosition({ blueprintID: funnelId, nodes: [nodePosition] })
        } else {
            console.log("FALSE", target)
        }
    }

    const getBlockType = () => {
        switch (data.type) {
            case "file":
                return "Файлы"
            case "button":
                return "Блок с кнопками"

            default:
                return "Текстовый блок"
        }
    }

    return (
        <>
            <div className='NodeBlock' onDoubleClick={() => setVisibleTextSidebar(true)}>
                <Handle
                    id='l'
                    type='target'
                    className='custom-handle'
                    position='left'
                    onConnect={(params) => console.log("handle onConnect", params)}
                    isConnectable={true}
                />
                <div className='node-block'>
                    <div className='header'>
                        <h3>{slicedText(data?.title, 25) || getBlockType()}</h3>
                        <div className='button-container'>
                            <Button className={`p-button-text`} onClick={() => setVisibleTextSidebar(true)}>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.svg`} alt='edit'></img>
                            </Button>
                        </div>
                    </div>
                    {data?.buttons && data?.buttons?.length !== 0 && (
                        <div className='buttons-container' style={{ height: `${data?.buttons?.length * 50 + "px"}` }}>
                            <h5>Кнопки для ответа</h5>
                        </div>
                    )}
                    {data?.type !== "file" && (
                        <>
                            {data?.text && (
                                <div className='body'>
                                    {data?.type === "button" ? <h5>Сообщение к кнопкам</h5> : <h5>Текстовый блок</h5>}
                                    <ScrollPanel style={{ width: "100%", height: "50px" }}>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: data?.text,
                                            }}
                                        ></p>
                                    </ScrollPanel>
                                </div>
                            )}
                        </>
                    )}
                    {data?.files && data?.files?.length > 0 && (
                        <div className='footer'>
                            {data?.type === "message" && (
                                <div className='footer-with-files'>
                                    {data?.files?.map((file) => (
                                        <Image
                                            src={file?.media}
                                            alt={file?.path}
                                            dowloadable={true}
                                            width='30'
                                            height='24'
                                            preview
                                            onShow={() => {
                                                const picContainerZoom = document.getElementsByClassName("p-image-mask")
                                                const picSrcZoom = document.getElementsByClassName("p-image-preview")
                                                picContainerZoom[0].style.zIndex = "9999"
                                                picSrcZoom[0].src = file?.media?.replace("small/", "")
                                            }}
                                        />
                                        // <img key={file?.path} src={file?.media} alt='pic' />
                                    ))}
                                </div>
                            )}
                            {data?.type === "file" && (
                                <div className='footer-separately-files mt-0'>
                                    {/* <h5>Отправленые файлы:</h5> */}
                                    {data?.files?.map((file) => (
                                        <div className='separated-file' key={file?.path}>
                                            {getFileIcon(file.type.toString().split("/").pop())}
                                            <span>{slicedTextWithExtension(file.path, 16)}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Handle
                    id='r'
                    type='source'
                    className='custom-handle'
                    position='right'
                    onConnect={(params) => onConnect(params)}
                    isConnectable={true}
                />
            </div>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleTextSidebar}
                onHide={() => setVisibleTextSidebar(false)}
                position='right'
            >
                {data?.type === "message" && <CreateBlock onHide={() => setVisibleTextSidebar(false)} funnelId={funnelId} data={data} />}
                {data?.type === "file" && <CreateFiles onHide={() => setVisibleTextSidebar(false)} funnelId={funnelId} data={data} />}
                {data?.type === "button" && <CreateButtons onHide={() => setVisibleTextSidebar(false)} funnelId={funnelId} data={data} />}
            </Sidebar>
        </>
    )
})
