
import { Button } from 'primereact/button';
import React, { useRef, useState } from 'react';
import './UploadFileMailing.css'
import { useEffect } from 'react';

export const UploadFileMailing = ({ fileUploaded, setFileUploaded, view }) => {
    /*     const [fileUploaded, setFileUploaded] = useState(files || null) */
    const [drag, setDrag] = useState(false)
    const [imgUploadedeFile, setImgUploadedFile] = useState(fileUploaded?.src || null)

    useEffect(() => {
        setImgUploadedFile(fileUploaded?.map((item) => {
            return {
                src: window.URL.createObjectURL(item),
                name: item.name
            }
        }))
    }, [fileUploaded])

    const inputFile = useRef()
    const selectFileHandler = () => {
        inputFile.current.click()
    }

    const handleChange = event => {
        setFileUploaded([event.target.files[0]])
    };

    const dragStartHandler = (e) => {
        e.preventDefault()
        setDrag(true)
    }
    const dragLeaveHandler = (e) => {
        e.preventDefault()
        setDrag(false)
    }
    const onDropHandler = (e) => {
        e.preventDefault()
        setFileUploaded([e.dataTransfer.files[0]])
        setDrag(false)
    }
    const deleteFileHandler = () => {
        setFileUploaded(null)
        setImgUploadedFile(null)
    }

    console.log(imgUploadedeFile)

    return (
        <>
            {!fileUploaded
                ?
                <>
                    <Button
                        className={`btn-upload_file ${drag ? 'drag' : ''}`}
                        onClick={selectFileHandler}
                        onDragStart={(e) => dragStartHandler(e)}
                        onDragLeave={(e) => dragLeaveHandler(e)}
                        onDragOver={(e) => dragStartHandler(e)}
                        onDrop={(e) => onDropHandler(e)}

                    >
                        <i className='pi pi-upload' />
                        <p>Загрузите изображение или файл <span>до 15 мб</span></p>
                    </Button>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={inputFile}
                        onChange={handleChange}
                    />
                </>
                :
                <div className='uploaded-file_container'>
                    {imgUploadedeFile?.map((item) =>
                        <div className='uploaded-file'>
                            <img className='img-uploaded' src={item.src} alt="" />
                            <p className='name-uploaded_file'>{item.name}</p>
                        </div>
                    )}
                    {!view &&
                        <Button
                            className='btn-delete_file'
                            onClick={deleteFileHandler}
                        >
                            <img src='assets/layout/images/trash.svg' />
                        </Button>}
                </div>

            }
        </>
    );
}
