export const SvgIconsFilters = ({ name, width, height }) => {
    switch (name) {
        case 'men':
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.372408 20C-0.14613 20 0.0038041 18.4989 0.0811183 18.0144C0.245078 16.9921 0.616986 16.0492 1.27593 15.2971C2.20059 14.241 3.75621 13.4881 6.3338 13.4881H6.82256V12.4339L6.72481 12.3135C6.49109 12.0256 6.13784 11.5463 5.84547 11.0562C5.76327 10.9181 5.68729 10.7817 5.62064 10.6505C5.5691 10.5489 5.52333 10.4505 5.48467 10.3569C5.39314 10.1346 5.35626 9.97311 5.35626 9.87391V9.68678L5.21318 9.55445C4.94925 9.31072 4.37872 8.54576 4.37872 7.61503C4.37872 7.33507 4.37872 7.10201 4.38228 6.90437C4.38805 6.61119 4.40138 6.39592 4.43293 6.22104C4.44537 6.15147 4.46048 6.09062 4.47914 6.03572C4.52846 5.89074 4.60311 5.78748 4.72397 5.6756L4.86749 5.5433V4.18506C4.83239 2.49105 5.46912 1.63672 6.2005 1.20681C6.96831 0.755512 7.9494 0.706565 8.69099 0.831107L8.94293 0.873444L9.12377 0.706067C9.18109 0.653134 9.35883 0.539163 9.67475 0.413125C9.9769 0.292518 10.3697 0.175164 10.8234 0.0965255C11.7325 -0.0609619 12.8566 -0.0585749 13.9737 0.354318C15.081 0.763748 15.5515 1.33848 15.6933 1.94955C15.843 2.59547 15.651 3.38766 15.1841 4.25056L15.1325 4.34596V5.54327L15.276 5.6756C15.4427 5.82981 15.5213 5.96768 15.5671 6.22101C15.6199 6.51379 15.6213 6.91979 15.6213 7.61503C15.6213 8.54573 15.0508 9.31072 14.7868 9.55445L14.6437 9.68676V9.87388C14.6437 9.97311 14.6069 10.1346 14.5153 10.3569C14.4651 10.4784 14.4029 10.608 14.3318 10.7421C14.2776 10.8447 14.2181 10.95 14.1545 11.0562C13.8622 11.5463 13.5089 12.0255 13.2752 12.3135L13.1774 12.4339V13.4881H13.6662C16.2438 13.4881 17.7994 14.241 18.7241 15.2971C19.383 16.0492 19.7549 16.9921 19.9189 18.0144C19.9962 18.4989 20.1461 20 19.6276 20H0.372408Z" />
                </svg>

            );
        case 'women':
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.3944 14.8244L13.3322 14.06L13.21 13.5678C15.3067 13.1933 16.3933 12.5611 16.4433 12.5311C16.6 12.4389 16.6978 12.2722 16.71 12.0911C16.7233 11.9089 16.6422 11.73 16.5011 11.6156C16.4867 11.6044 15.0733 10.4122 15.0733 6.30556C15.0733 2.66778 14.1956 0.822222 12.4644 0.822222H12.28C11.6922 0.264444 11.2056 0 10 0C8.41 0 4.92778 1.58778 4.92778 6.30444C4.92778 10.4122 3.51444 11.6033 3.50778 11.6089C3.36 11.72 3.27667 11.8967 3.28667 12.0811C3.29556 12.2667 3.39667 12.4333 3.55444 12.5289C3.60333 12.5578 4.68111 13.1944 6.79111 13.57L6.66889 14.0589L3.60667 14.8244C1.48222 15.3544 0 17.2544 0 19.4444C0 19.7511 0.248889 20 0.555556 20H19.4444C19.7511 20 20 19.7489 20 19.4422C20 17.2544 18.5178 15.3544 16.3944 14.8244Z" />
                </svg>
            );
        case 'telegram':
            return (
                <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.3584 7.3824C9.38613 7.78707 7.4422 8.6244 4.5266 9.8944C4.05326 10.0827 3.80546 10.2667 3.7832 10.4464C3.74447 10.7506 4.12544 10.8704 4.64426 11.0336C4.71492 11.0558 4.78813 11.0788 4.8632 11.1032C5.3738 11.2692 6.0606 11.4634 6.4178 11.4712C6.74153 11.4785 7.103 11.3452 7.5022 11.0712C10.2259 9.23254 11.6319 8.3032 11.7202 8.28321C11.7826 8.269 11.8686 8.2512 11.9272 8.3032C11.9858 8.3552 11.98 8.4536 11.9738 8.48C11.9362 8.64107 10.4418 10.0302 9.66726 10.7503C9.42521 10.9753 9.25344 11.135 9.2184 11.1714C9.13997 11.2529 9.06004 11.3299 8.98322 11.404C8.50894 11.8612 8.15324 12.2041 9.003 12.764C9.41139 13.0331 9.73817 13.2557 10.0642 13.4777C10.4202 13.7201 10.7752 13.9619 11.2346 14.263C11.3515 14.3397 11.4633 14.4193 11.5721 14.4968C11.9868 14.7924 12.3594 15.0579 12.8188 15.0156C13.086 14.9904 13.3622 14.7396 13.5022 13.9904C13.8336 12.22 14.4846 8.382 14.6352 6.8008C14.6444 6.66939 14.6387 6.53736 14.6184 6.4072C14.6052 6.3294 14.5772 6.2184 14.4762 6.1364C14.3564 6.04 14.1714 6.0186 14.0886 6.02C13.7126 6.0266 13.1354 6.2274 10.3584 7.3824Z" />
                </svg>

            );
        case 'whatsapp':
            return (
                <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C8.23637 20 6.57709 19.5427 5.13692 18.7397L4.83171 18.5624L1.79975 19.4542C1.06936 19.6691 0.388221 19.0329 0.519871 18.3093L0.54581 18.2002L1.43756 15.1683C0.52505 13.6594 0 11.8896 0 10C0 4.47715 4.47715 0 10 0ZM10 2C5.58172 2 2 5.58172 2 10C2 11.5769 2.4552 13.0444 3.24098 14.2818C3.43935 14.5943 3.52374 14.9781 3.45652 15.3589L3.41832 15.5217L2.97667 17.0233L4.47827 16.5817C4.91075 16.4545 5.36114 16.5323 5.71817 16.759C6.95564 17.5448 8.4231 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM7.10162 5.18408C7.31746 5.09158 7.57889 5.1085 7.78556 5.25926C8.28999 5.62722 8.69048 6.12076 9.03441 6.60344L9.36124 7.07746C9.41315 7.15417 9.46393 7.22933 9.5138 7.30228C9.69632 7.56921 9.67529 7.9239 9.46849 8.16596L9.3927 8.2422L8.4693 8.928C8.3778 8.996 8.3473 9.1195 8.4022 9.2195C8.6112 9.5998 8.9834 10.1657 9.4093 10.5916C9.8357 11.018 10.4284 11.4143 10.8348 11.6467C10.9226 11.6969 11.0294 11.6811 11.101 11.6157L11.1394 11.5706L11.7402 10.6555C11.9705 10.349 12.4007 10.282 12.7134 10.4984L13.2563 10.8768C13.7957 11.2618 14.315 11.6757 14.7255 12.2014C14.8872 12.4085 14.9112 12.6792 14.8148 12.9042C14.4188 13.8283 13.4165 14.6153 12.374 14.5769L12.2155 14.5678L12.0235 14.5488C11.9889 14.5446 11.953 14.5399 11.9158 14.5346L11.6781 14.4952C10.7544 14.3208 9.2726 13.797 7.73827 12.2627C6.20397 10.7284 5.68017 9.24657 5.50573 8.32286L5.46632 8.08516L5.44126 7.87742L5.42756 7.70191C5.42606 7.67547 5.42491 7.65047 5.42404 7.62695C5.38562 6.58294 6.17688 5.5804 7.10162 5.18408Z" />
                </svg>

            );
        case 'vk':
            return (
                <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.40589 1.40589C0 2.81177 0 5.0745 0 9.6V10.4C0 14.9255 0 17.1882 1.40589 18.5941C2.81177 20 5.0745 20 9.6 20H10.4C14.9255 20 17.1882 20 18.5941 18.5941C20 17.1882 20 14.9255 20 10.4V9.6C20 5.0745 20 2.81177 18.5941 1.40589C17.1882 0 14.9255 0 10.4 0H9.6C5.0745 0 2.81177 0 1.40589 1.40589ZM3.37505 6.08337C3.48338 11.2834 6.08337 14.4084 10.6417 14.4084H10.9001V11.4334C12.5751 11.6 13.8417 12.825 14.35 14.4084H16.7167C16.0667 12.0417 14.3583 10.7334 13.2916 10.2334C14.3583 9.61671 15.8583 8.11671 16.2166 6.08337H14.0666C13.5999 7.73337 12.2168 9.23337 10.9001 9.37504V6.08337H8.75V11.85C7.41667 11.5167 5.73338 9.90004 5.65837 6.08337H3.37505Z" />
                </svg>


            );
        case 'yandex':
            return (
                <svg width={width} height={height} viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5965 2.70467H8.07742C5.29027 2.70467 3.82658 4.14312 3.82658 6.27289C3.82658 8.69012 4.84009 9.81201 6.92249 11.2517L8.63956 12.4318L3.68987 20H0L4.44765 13.2371C1.8856 11.3669 0.450187 9.55367 0.450187 6.47423C0.450187 2.61856 3.06763 0 8.05031 0H13V20H9.59414L9.5965 2.70467Z" />
                </svg>


            );
        case 'sms':
            return (
                <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 11.1948L4.39862 11.0006C4.67925 11.4201 5.00345 11.6298 5.37125 11.6298C5.52857 11.6298 5.67632 11.6004 5.81451 11.5417C5.9527 11.4813 6.05793 11.4011 6.13022 11.301C6.2025 11.2009 6.23864 11.0947 6.23864 10.9825C6.23864 10.8548 6.18549 10.7297 6.07919 10.6071C5.9325 10.438 5.66463 10.2343 5.27558 9.99612C4.8844 9.7562 4.64098 9.58274 4.54531 9.47573C4.37948 9.29622 4.29657 9.10205 4.29657 8.8932C4.29657 8.72751 4.34547 8.57648 4.44326 8.44013C4.54106 8.30378 4.67818 8.19676 4.85464 8.11909C5.03322 8.0397 5.22668 8 5.43503 8C5.65613 8 5.86234 8.04488 6.05368 8.13463C6.24714 8.22265 6.45124 8.38576 6.66596 8.62395L6.28328 8.85955C6.10683 8.66969 5.95589 8.54455 5.83045 8.48414C5.70715 8.42373 5.57215 8.39353 5.42546 8.39353C5.23625 8.39353 5.08105 8.44013 4.95987 8.53333C4.84082 8.62654 4.78129 8.74132 4.78129 8.87767C4.78129 8.96052 4.80255 9.04078 4.84507 9.11845C4.88759 9.19612 4.96519 9.28069 5.07786 9.37217C5.13952 9.4205 5.34148 9.54736 5.68376 9.75275C6.08982 9.99612 6.36832 10.2127 6.51927 10.4026C6.67021 10.5924 6.74568 10.7832 6.74568 10.9748C6.74568 11.2509 6.616 11.4908 6.35663 11.6945C6.09939 11.8982 5.78581 12 5.41589 12C5.13101 12 4.87271 11.9387 4.64098 11.8162C4.40925 11.6919 4.19559 11.4848 4 11.1948Z" />
                    <path d="M7.97661 11.9042H7.52378L8.19027 8.09579H8.26681L10.1738 11.2207L12.0617 8.09579H12.1382L12.8111 11.9042H12.3487L11.8895 9.18058L10.2312 11.9042H10.1132L8.43263 9.15987L7.97661 11.9042Z" />
                    <path d="M13.6529 11.0006L13.2543 11.1948C13.4499 11.4848 13.6636 11.6919 13.8953 11.8162C14.127 11.9387 14.3853 12 14.6702 12C15.0401 12 15.3537 11.8982 15.6109 11.6945C15.8703 11.4908 16 11.2509 16 10.9748C16 10.7832 15.9245 10.5924 15.7736 10.4026C15.6226 10.2127 15.3441 9.99612 14.9381 9.75275C14.5958 9.54736 14.3938 9.4205 14.3322 9.37217C14.2195 9.28069 14.1419 9.19612 14.0994 9.11845C14.0569 9.04078 14.0356 8.96052 14.0356 8.87767C14.0356 8.74132 14.0951 8.62654 14.2142 8.53333C14.3354 8.44013 14.4906 8.39353 14.6798 8.39353C14.8265 8.39353 14.9615 8.42373 15.0848 8.48414C15.2102 8.54455 15.3611 8.66969 15.5376 8.85955L15.9203 8.62395C15.7056 8.38576 15.5015 8.22265 15.308 8.13463C15.1167 8.04488 14.9104 8 14.6893 8C14.481 8 14.2875 8.0397 14.109 8.11909C13.9325 8.19676 13.7954 8.30378 13.6976 8.44013C13.5998 8.57648 13.5509 8.72751 13.5509 8.8932C13.5509 9.10205 13.6338 9.29622 13.7996 9.47573C13.8953 9.58274 14.1387 9.7562 14.5299 9.99612C14.9189 10.2343 15.1868 10.438 15.3335 10.6071C15.4398 10.7297 15.493 10.8548 15.493 10.9825C15.493 11.0947 15.4568 11.2009 15.3845 11.301C15.3123 11.4011 15.207 11.4813 15.0688 11.5417C14.9306 11.6004 14.7829 11.6298 14.6256 11.6298C14.2578 11.6298 13.9336 11.4201 13.6529 11.0006Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 11.8896 0.52505 13.6594 1.43756 15.1683L0.54581 18.2002L0.519871 18.3093C0.388221 19.0329 1.06936 19.6691 1.79975 19.4542L4.83171 18.5624L5.13692 18.7397C6.57709 19.5427 8.23637 20 10 20C15.5228 20 20 15.5228 20 10ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C8.4231 18 6.95564 17.5448 5.71817 16.759C5.36114 16.5323 4.91075 16.4545 4.47827 16.5817L2.97667 17.0233L3.41832 15.5217L3.45652 15.3589C3.52374 14.9781 3.43935 14.5943 3.24098 14.2818C2.4552 13.0444 2 11.5769 2 10Z" />
                </svg>
            );

        default:
            return <svg></svg>;

    }
}