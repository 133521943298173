import { Skeleton } from "primereact/skeleton"

export const SkeletonPortrait = () => {
    return (
        <div className="card chats pt-0 px-4">
            <div className="pt-3 w-full mb-5">
                <Skeleton width="10rem" height="2rem" />
            </div>
            <div>
                <div className="flex align-items-center mb-5">
                    <Skeleton shape="circle" size="6rem" className="mr-3"></Skeleton>
                    <Skeleton width="17rem" height="2.5rem" />
                </div>
                <Skeleton width="13rem" height="2rem" className="mb-3" />
                <Skeleton width="8rem" height="2.5rem" className="mb-5" />
                <Skeleton width="10rem" height="2rem" className="mb-4" />
                <div className="grid mb-5">
                    <div className="col-6 flex flex-column align-items-end">
                        <Skeleton width="10rem" height="1rem" className="mb-5" />
                        <Skeleton width="9rem" height="1rem" className="mb-5" />
                        <Skeleton width="13rem" height="1rem" className="mb-5" />
                        <Skeleton width="9rem" height="1rem" />
                    </div>
                    <div className="col-6 flex flex-column align-items-start mb-3">
                        <Skeleton width="8rem" height="1rem" className="mb-5" />
                        <Skeleton width="8rem" height="1rem" className="mb-5" />
                        <Skeleton width="8rem" height="1rem" className="mb-5" />
                        <Skeleton width="8rem" height="1rem" />
                    </div>
                </div>
                <Skeleton width="11rem" height="2rem" className="mb-4" />
                <Skeleton width="100%" height="110px" />
            </div>

        </div>
    )
}