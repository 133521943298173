import { Button } from "primereact/button"
import { Link } from "react-router-dom"

export const SuccessRegistration = () => {
    return (
        <>
            <p className="my-5 title">Поздравляем!</p>

            <div>
                <p className='registration-text mt-5 mb-3'>
                    На ваш Email отправлено письмо для подтверждения. Откройте его и перейдите по предложеной ссылке.
                </p>
            </div>
            <Link to={'/login'}>
                <Button
                    label='Продолжить'
                    className="w-full sign_in py-3 my-5" />
            </Link>
        </>
    )
}