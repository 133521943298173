import { Accordion, AccordionTab } from "primereact/accordion"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"

import { createNode, getFunnel, getVariables, updateNode } from "../../../../service/funnels"
import { setInitNodes } from "../../../../service/slices/initNodesSlice"
import SidebarFooter from "../components/SidebarFooter"
import SidebarHeader from "../components/SidebarHeader"
import "../Sidebar.scss"
import Action from "./Action"

const CreateAction = ({ data, onHide, setNodes, centerCoordinates, funnelId }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)
    const [nodeID, setNodeID] = useState("")
    const [blockTitle, setBlockTitle] = useState(data?.title || "")
    const [actions, setActions] = useState([])

    const [variables, setVariables] = useState(null)

    useEffect(() => {
        getVariables(funnelId).then((res) => {
            const variablesFormated = []
            res?.map((variable) => {
                const variableFormated = {
                    variableID: variable.variableID,
                    type: variable.type,
                    name: variable.name,
                    value: variable.variableID,
                    // value: { type: variable.type, id: variable.id },
                    initialValue: variable.initialValue,
                }
                variablesFormated.push(variableFormated)
            })
            setVariables(variablesFormated)
        })

        const node_id = uuid()
        setNodeID(node_id)
    }, [])

    // Создание действия
    const [actionType, setActionType] = useState("")
    const [action, setAction] = useState("")
    const [variableId, setVariableId] = useState(null)
    const [variableArgument, setVariableArgument] = useState(null)

    const onChangeActions = (id, type, option2, action, argument) => {
        actions.actionType = type
        actions.action = action
        actions.argument = argument
        actions.variableId = option2

        setActionType(type)
        setAction(action)
        setVariableId(option2)
        setVariableArgument(argument)
    }

    const saveHandler = () => {
        const actionBlock = {
            actionType,
            action: action,
            title: blockTitle,
            argument: variableArgument,
            variableID: variableId,
            messenger: "Telegram",
            blueprintID: funnelId,
            data: { title: blockTitle, actions },
        }

        if (data) {
            const updatedActionBlock = { ...actionBlock, uuid: data?.id }
            updateNode(updatedActionBlock, "action").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
        } else {
            const newActionBlock = {
                ...actionBlock,
                blueprintID: funnelId,
                uuid: nodeID,
                position: centerCoordinates,
            }
            createNode(newActionBlock, "action").then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes))))
        }

        onHide()
        setBlockTitle("")
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Действие' icon={"action"} onHide={() => onHide()} />
            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={[0, 1]}>
                    <AccordionTab header='Информация' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === "employer"}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Контент' headerClassName='accordion-header'>
                        <div className='mt-3 mb-3'>
                            <Action data={data} variables={variables} onChangeActions={onChangeActions} />
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>

            <SidebarFooter saveHandler={() => saveHandler()} disabled={!variableArgument} onHide={() => onHide()} />
        </div>
    )
}

export default CreateAction
