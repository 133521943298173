import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useRef, useState } from "react"
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch, useSelector } from "react-redux";
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import { getAllBlueprints } from "../../service/funnels";
import { createSmartAnswer, getAllAnswers } from "../../service/slices/smartAnswersSlice";
import { AddTag } from "./AddTag";
import { MultiSelect } from 'primereact/multiselect';
import { log } from "../../service/log";
import { AddTags } from "../../components/AddTags/AddTags";
import { SelectTime } from "../../components/SelectTime/SelectTime";

export const AddNewAnswer = ({ setCreate, value, className, answers, setAnswers, setSelectedAnswer, setCurrentPage, setBasicFirst, basicRows, sidebar }) => {
    const [title, setTitle] = useState('')
    const [addСondition, setAddCondition] = useState(false)
    const [addAction, setAddAction] = useState(false)
    const [noResponse, setNoResponse] = useState(false)
    const [message, setMessage] = useState(false)
    const [messageText, setMessageText] = useState('')
    const [selectDay, setSelectDay] = useState(null)
    const [selectHour, setSelectHour] = useState(null)


    function addZero(val) {
        if (val < 10) {
            return '0' + val;
        }
        return val;
    };

    const [selectMinutes, setSelectMinutes] = useState(addZero(5))
    const [selectSeconds, setSelectSeconds] = useState(null)
    const [sendMessage, setSendMessage] = useState(false)
    const [sendMessageText, setSendMessageText] = useState('')
    const [messagesFromUser, setMessagesFromUser] = useState([])
    const [showThen, setShowThen] = useState(false)
    const [funnels, setFunnels] = useState(null)
    const [selectedFunnel, setSelectedFunnel] = useState(null)
    const [filteredFunnels, setFilteredFunnels] = useState(null)
    const [showEdit, setShowEdit] = useState(false)
    const [startFunnel, setStartFunnel] = useState(false)
    const [noTitle, setNoTitle] = useState(false)

    const [attachBot, setAttachBot] = useState(false)

    const noAnswer = { days: +selectDay, hours: +selectHour, minutes: +selectMinutes, seconds: +(selectSeconds) }

    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const totalPages = useSelector((state) => state.smartAnswers.totalPages)

    /*  const [bots, setBots] = useState(['Bot1', 'Bot2', 'Bot3']) */
    const [selectedBot, setSelectedBot] = useState(null)
    const [filteredBots, setFilteredBots] = useState(null)

    const [emptyMessage, setEmptyMessage] = useState(false)
    const [emptySendMessageText, setEmptySendMessageText] = useState(false)
    const [emptyStartFunnel, setEmptyStartFunnel] = useState(false)
    const [emptyAttachBot, setEmptyAttachBot] = useState(false)
    const dispatch = useDispatch()
    const toast = useRef(null);
    const bots = useSelector((state) => state.bots.bots)
    useEffect(() => {
        getAllBlueprints(1).then((res) => {
            const allFunnels = res.data.map((item) => ({ id: item.id, title: item.title }))

            setFunnels(allFunnels)
        })
    }, [])


    const searchFunnel = (event) => {
        setTimeout(() => {
            let _filteredFunnels;
            if (!event.query.trim().length) {
                _filteredFunnels = [...funnels];
            }
            else {
                _filteredFunnels = funnels.filter((funnel) => {
                    return funnel.title.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredFunnels(_filteredFunnels);
        }, 250);
    }

    /*  const searchBot = (event) => {
         setTimeout(() => {
             let _filteredBots;
             if (!event.query.trim().length) {
                 _filteredBots = [...bots];
             }
             else {
                 _filteredBots = bots.filter((bot) => {
                     return bot.toLowerCase().startsWith(event.query.toLowerCase());
                 });
             }
 
             setFilteredBots(_filteredBots);
 
         }, 250);
     } */


    const addMessageHandler = (e) => {
        if (messageText === '') {
            e.preventDefault()
        } else {
            setMessagesFromUser([...messagesFromUser, messageText])
            setMessageText('')
        }

    }


    const selectTimeHandler = (selectValue, value, number, min) => {
        value = value.replace(/[^0-9]/g, '')
        if (+value > number) {
            value = number
        } else if (+value < min) {
            value = min
        }

        selectValue(addZero(Number(value)))
    }

    const saveHandler = (e) => {
        if (!title || (sendMessage && !sendMessageText) || (startFunnel && !selectedFunnel) || (attachBot && !selectedBot)) {
            e.preventDefault()
            setCreate(true)
            if (!title) {
                setNoTitle(true)
            }
            if (sendMessage && !sendMessageText) {
                setEmptySendMessageText(true)
            }
            if (startFunnel && !selectedFunnel) {
                setEmptyStartFunnel(true)
            }
            if (attachBot && !selectedBot) {
                setEmptyAttachBot(true)
            }
        } else {
            const dataAnswer = {
                title: title,
                text: sendMessageText,
                noAnswerDays: +selectDay,
                noAnswerHours: +selectHour,
                noAnswerMinutes: noResponse ? +selectMinutes : 0,
                noAnswerSeconds: +selectSeconds,
                blueprintID: selectedFunnel?.id,
                reactions: messagesFromUser
            }
            log('dataAnswer', dataAnswer)
            dispatch(createSmartAnswer(dataAnswer))
                .then(() => {
                    setCreate(false)
                    setCurrentPage(totalPages)
                    setBasicFirst((totalPages - 1) * basicRows)
                })
                .then(() => {
                    const data = {
                        currentPage: totalPages === 0 ? 0 : totalPages - 1,
                        basicRows: basicRows
                    }
                    dispatch(getAllAnswers(data))
                        .then((res) => {
                            console.log(res.payload.content)
                            setSelectedAnswer(res.payload.content[res.payload.content.length - 1])
                        })
                })

            toast.current.show({ severity: 'success', summary: 'Сохранено', detail: `Умный ответ "${title}" сохранен`, life: 3000 });
        }

    }

    const changeTitleHandler = (e) => {
        setTitle(e.target.value)
        setNoTitle(false)
    }

    const deleteHandler = (item) => {
        let messages = [...messagesFromUser];
        messages.splice(messages.indexOf(item), 1);
        setMessagesFromUser(messages)
    }

    const IconSmile = () => {
        return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 10.0078C20 12.7477 19.0222 15.1007 17.0667 17.0667C15.1111 19.0222 12.7582 20 10.0078 20C7.24706 20 4.88889 19.0275 2.93333 17.0824C0.977778 15.1268 0 12.7686 0 10.0078C0 7.25752 0.977778 4.90458 2.93333 2.94902C4.89935 0.983007 7.25752 0 10.0078 0C12.7686 0 15.1216 0.977778 17.0667 2.93333C19.0222 4.87843 20 7.2366 20 10.0078ZM18.5569 10.0078C18.5569 7.6549 17.715 5.64183 16.0314 3.96863C14.3582 2.29542 12.3503 1.45882 10.0078 1.45882C7.6549 1.45882 5.64183 2.29542 3.96863 3.96863C2.29542 5.64183 1.45882 7.6549 1.45882 10.0078C1.45882 12.3503 2.29542 14.3582 3.96863 16.0314C5.64183 17.7046 7.6549 18.5412 10.0078 18.5412C12.3503 18.5412 14.3582 17.7046 16.0314 16.0314C17.715 14.3582 18.5569 12.3503 18.5569 10.0078ZM7.43529 7.93726C7.43529 8.28235 7.3098 8.58039 7.05882 8.83137C6.8183 9.08235 6.52026 9.20784 6.16471 9.20784C5.81961 9.20784 5.52157 9.08235 5.27059 8.83137C5.01961 8.58039 4.89412 8.28235 4.89412 7.93726C4.89412 7.5817 5.01961 7.28366 5.27059 7.04314C5.52157 6.79216 5.81961 6.66667 6.16471 6.66667C6.52026 6.66667 6.8183 6.79216 7.05882 7.04314C7.3098 7.28366 7.43529 7.5817 7.43529 7.93726ZM15.1059 7.93726C15.1059 8.28235 14.9804 8.58039 14.7294 8.83137C14.4784 9.08235 14.1804 9.20784 13.8353 9.20784C13.4902 9.20784 13.1922 9.08235 12.9412 8.83137C12.6902 8.58039 12.5647 8.28235 12.5647 7.93726C12.5647 7.5817 12.6902 7.28366 12.9412 7.04314C13.1922 6.79216 13.4902 6.66667 13.8353 6.66667C14.1804 6.66667 14.4784 6.79216 14.7294 7.04314C14.9804 7.28366 15.1059 7.5817 15.1059 7.93726ZM5.39608 13.2235L6.36863 12.6588C7.19477 14.1647 8.40784 14.9176 10.0078 14.9176C11.6078 14.9176 12.8157 14.1647 13.6314 12.6588L14.6196 13.2235C13.532 15.2627 11.9948 16.2824 10.0078 16.2824C8.01046 16.2824 6.4732 15.2627 5.39608 13.2235Z" />
        </svg>

    }

    /* const bots = [
        { name: 'Bot1', type: 'yandex' },
        { name: 'Bot2', type: 'sms' },
        { name: 'Bot3', type: 'whatsapp' },
        { name: 'Bot4', type: 'telegram' },
        { name: 'Bot5', type: 'vk' },
    ]; */

    const botTemplate = (option) => {
        return (
            <div className="bot-item">
                <img alt={option.name} className='mr-2' src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${option.type}-bot.png`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                <div>{option.name}</div>
            </div>
        );
    }

    const selectedBotsTemplate = (option) => {
        if (option) {
            return (
                <div className="bot-item bot-item-value">
                    <img alt={option.name} className='mr-2' src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${option.type}-bot.png`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return "Введите имя бота";
    }

    const panelFooterTemplate = () => {
        const selectedItems = selectedBot;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    }
    const minutesMinusHandler = () => {
        setSelectMinutes(prev => {
            if (prev <= 5) {
                return addZero(5)
            }
            return addZero((+prev) - 1)
        })
    }
    const minutesPlusHandler = () => {
        setSelectMinutes(prev => addZero((+prev) + 1))
    }

    const thenHandler = (e) => {
        if (noResponse) {
            if (message) {

                if (!messageText) {
                    if (!messagesFromUser.length) {
                        setEmptyMessage(true)
                    } else {
                        setEmptyMessage(false)
                        setShowThen(true)
                    }
                } else {
                    setMessagesFromUser([...messagesFromUser, messageText])
                    setMessageText('')
                    setEmptyMessage(false)
                    setShowThen(true)
                }

            } else {
                setShowThen(true)
            }

        } else {
            if (message) {

                if (!messageText) {
                    if (!messagesFromUser.length) {
                        setEmptyMessage(true)
                    } else {
                        setEmptyMessage(false)
                        setShowThen(true)
                    }
                } else {
                    setMessagesFromUser([...messagesFromUser, messageText])
                    setMessageText('')
                    setEmptyMessage(false)
                    setShowThen(true)
                }

            } else {
                setShowThen(false)
            }
        }
    }



    const noResponseHandler = (e) => {
        setNoResponse(e.value)

        /*   if (e.value) {
              setSelectMinutes(addZero(5))
          } else {
              setSelectMinutes(null)
          } */
    }

    const messageHandler = (e) => {
        setMessage(e.value)

        if (!e.value) {
            setEmptyMessage(false)
        }
    }

    const messageTextHandler = (e) => {
        setMessageText(e.target.value)
        setEmptyMessage(false)
    }

    const sendMessageTextHandler = (e) => {
        setSendMessageText(e.target.value)
        setEmptySendMessageText(false)
    }

    const selectedFunnelHandler = (e) => {
        setSelectedFunnel(e.value)
        setEmptyStartFunnel(false)
    }

    const selectedBotsHandler = (e) => {
        setSelectedBot(e.value)
        setEmptyAttachBot(false)
    }
    console.log(selectMinutes)
    return (
        <>
            <div className="">
                <Toast className="absolute" ref={toast} />
                <InputText id="title" type="text" className={noTitle ? "p-invalid title-answer_input w-full xl:w-10 " : "w-full xl:w-10 title-answer_input"} value={title} placeholder='Название ответа'
                    onChange={changeTitleHandler} />
                {noTitle && <small id="title-help" className="p-error block">Введите название умного ответа</small>}
            </div>

            {!showThen ?
                <div className="flex flex-column justify-content-between">
                    <div className="">
                        <span className="text-answer">Если: </span>

                        <div className={sidebar ? 'smart-answer_scroll sidebar' : 'smart-answer_scroll'}>
                            <ScrollPanel style={{ width: '100%', height: '100%' }} className="custom">
                                <div className="w-full">

                                    <div className="flex align-items-center switch-block">
                                        <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={noResponse} onChange={(e) => noResponseHandler(e)} />
                                        <label htmlFor="noResponse">На сообщение пользователя нет ответа</label>
                                    </div>
                                    {noResponse &&
                                        <SelectTime
                                            selectDay={selectDay}
                                            selectHour={selectHour}
                                            selectMinutes={selectMinutes}
                                            setSelectDay={setSelectDay}
                                            setSelectHour={setSelectHour}
                                            setSelectMinutes={setSelectMinutes} />
                                    }
                                    <div>
                                        <div className="flex align-items-center switch-block">
                                            <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={message} onChange={(e) => messageHandler(e)} />
                                            <label htmlFor="noResponse">Сообщение от пользователя содержит</label>
                                        </div>
                                        {message && <>
                                            <div className="messages_user-block w-full sm:w-10">
                                                <div className="flex align-items-center">
                                                    <InputText id="title" type="text" value={messageText} className={emptyMessage ? 'p-invalid new-answer_input w-full' : "new-answer_input w-full"} placeholder='Сообщение'
                                                        onChange={(e) => messageTextHandler(e)} />

                                                    <Button className="add-message" onClick={(e) => addMessageHandler(e)}>
                                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/plus_green.svg`}></img>
                                                    </Button>
                                                </div>
                                                {emptyMessage && <small id="title-help" className="p-error block">Введите сообщение</small>}
                                                <div className="list-messages flex align-items-center flex-wrap">
                                                    {messagesFromUser?.map((item) => {
                                                        return <div className="messages_from-user flex relative" >
                                                            <span className="text-messages_user">{item}</span>
                                                            <Button className="btn-delete_tag" onClick={() => deleteHandler(item)}>
                                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/trash.svg`}></img>
                                                            </Button>
                                                            {/*           <div className="absolute block-buttons_edit">
                                                            <Button className={`btn-edit mr-2 ${colorTheme}`} onClick={() => setMessageText(item)}>
                                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.${colorTheme}.svg`}></img>
                                                            </Button>
                                                            <Button className="btn-trash" onClick={() => deleteHandler(item)}>
                                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/trash.svg`}></img>
                                                            </Button>
                                                        </div> */}

                                                        </div>

                                                    })}
                                                </div>
                                            </div>

                                            <p className="warning-message">При чтении сообщения система игнорирует ошибки и неточности. Например если у вас добавлено слово “Вторник”, то система засчитает также слова: “Фторник”, “Вторнек” и тд.</p>
                                        </>}

                                    </div>


                                </div>
                            </ScrollPanel>
                        </div>

                    </div>


                    <div className="flex justify-content-between mt-auto">
                        <Button label="Отменить" className='btn-cancel p-button-text py-3 px-4 mr-3' onClick={() => setCreate(false)} />
                        <Button label="Продолжить" disabled={!noResponse && !message} className="btn-save py-3 px-4" onClick={(e) => thenHandler(e)} />
                    </div>
                </div>
                :
                <div className="flex flex-column justify-content-between">
                    <div className={noTitle ? "action-block" : ""}>
                        <span className="text-answer">То: </span>
                        <div className={sidebar ? 'smart-answer_scroll_2 sidebar' : 'smart-answer_scroll_2'}>
                            <ScrollPanel style={{ width: '100%', height: '100%' }} className="custom">
                                <div className="w-full">
                                    <div className="">
                                        <div className="flex align-items-center switch-block">
                                            <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={sendMessage} onChange={(e) => setSendMessage(e.value)} />
                                            <label htmlFor="noResponse">Отправить сообщение</label>
                                        </div>
                                        {sendMessage &&
                                            <div className={emptySendMessageText ? "p-invalid new-answer_textarea w-10" : "new-answer_textarea w-10"}>
                                                <InputTextarea value={sendMessageText} onChange={(e) => sendMessageTextHandler(e)} rows={2} cols={56} autoResize placeholder="Сообщение" />

                                                <div className="w-full flex justify-content-end">
                                                    <Button className="p-button-outlined textarea-smile p-0 mr-2">
                                                        <IconSmile />
                                                    </Button>
                                                </div>

                                            </div>}
                                        {emptySendMessageText && <small id="title-help" className="p-error block">Введите сообщение</small>}
                                    </div>
                                    <div>
                                        <div className="flex align-items-center switch-block">
                                            <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={startFunnel} onChange={(e) => setStartFunnel(e.value)} />
                                            <label htmlFor="noResponse">Запустить воронку</label>
                                        </div>
                                        {startFunnel && <>
                                            <div className="mt-3">
                                                <AutoComplete className={emptyStartFunnel ? "p-invalid w-full funnel_selection w-10" : "w-full funnel_selection w-10"} value={selectedFunnel} suggestions={filteredFunnels} completeMethod={searchFunnel} field="title" dropdown forceSelection onChange={(e) => selectedFunnelHandler(e)} aria-label="Funnels" placeholder="Выберите воронку" />
                                            </div>
                                            {emptyStartFunnel ? <small id="title-help" className="p-error block">Выберите воронку</small> :
                                                <span className="warning-message">Введите точное название воронки.</span>}
                                        </>}
                                    </div>
                                    <div className="attach-bot w-10">
                                        <div className="flex align-items-center switch-block">
                                            <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={attachBot} onChange={(e) => setAttachBot(e.value)} />
                                            <label htmlFor="noResponse">Выбрать ботов</label>
                                        </div>
                                        {attachBot
                                            ? <>
                                                <div className="flex align-items-center">
                                                    <MultiSelect value={selectedBot} options={bots} onChange={(e) => selectedBotsHandler(e)} optionLabel="name" placeholder="Введите имя бота" filter className={emptyAttachBot ? "p-invalid attach-bot_select" : "attach-bot_select"}
                                                        itemTemplate={botTemplate} selectedItemTemplate={selectedBotsTemplate} panelFooterTemplate={panelFooterTemplate} />
                                                </div>
                                                {emptyAttachBot && <small id="title-help" className="p-error block">Выберите бот</small>}
                                            </>
                                            : <span className="warning-message">Если умный ответ не прикрепляется к боту, то он используется во всех ботах привязанных к аккаунту.</span>
                                        }

                                    </div>
                                </div>
                            </ScrollPanel>
                        </div>



                    </div>
                    <div className="btns-block flex justify-content-between mt-5">
                        <Button label="Отменить" className='btn-cancel p-button-text py-3 px-4 mr-3' onClick={() => setCreate(false)} />
                        <div>
                            <Button label="Назад" className='btn-main p-button-text py-3 px-6 mr-3' onClick={() => setShowThen(false)} />
                            <Button label="Сохранить" disabled={!sendMessage && !startFunnel && !attachBot} className="btn-save green py-3 px-4" onClick={(e) => saveHandler(e)} />
                        </div>

                    </div>
                </div>}

        </>
    )
}