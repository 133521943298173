import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"

import "../Sidebar.scss"

import { createNode, getFunnel, updateNode } from "../../../../service/funnels"
import { setInitNodes } from "../../../../service/slices/initNodesSlice"
import SidebarHeader from "../components/SidebarHeader"
import WebhookField from "./WebhookField"
import SidebarFooter from "../components/SidebarFooter"

const CreateWebhook = ({ data, onHide, setNodes, centerCoordinates, funnelId }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)
    const [nodeID, setNodeID] = useState("")
    const [blockTitle, setBlockTitle] = useState(data?.title || "")
    const [webhookType, setWebhookType] = useState(data?.webhookType || "get")
    const [webhookUrl, setWebhookUrl] = useState(data?.webhookUrl || "")

    const [webhookHeaders, setWebhookHeaders] = useState([])
    const [webhookParameters, setWebhookParameters] = useState([])

    useEffect(() => {
        const node_id = uuid()
        setNodeID(node_id)
    }, [])

    useEffect(() => {
        if (data) {
            const headers = []
            const params = []
            data?.webhookData?.map((item) => {
                if (item.type === "header") {
                    const formatedItem = { ...item, id: uuid() }
                    headers.push(formatedItem)
                } else if (item.type === "parameter") {
                    const formatedItem = { ...item, id: uuid() }
                    params.push(formatedItem)
                }
            })
            setWebhookHeaders(headers)
            setWebhookParameters(params)
        }
    }, [data])

    const webhookTypes = [
        { label: "GET", value: "get" },
        { label: "POST", value: "post" },
    ]

    const addHeader = () => {
        setWebhookHeaders([...webhookHeaders, { id: uuid(), type: "header", title: "", value: "" }])
    }
    const addParameter = () => {
        setWebhookParameters([...webhookParameters, { id: uuid(), type: "parameter", title: "", value: "" }])
    }

    const onChangeHeader = (id, title, value) => {
        const changedHeader = webhookHeaders?.find((header) => header.id === id)
        changedHeader.title = title
        changedHeader.value = value
    }
    const onChangeParameter = (id, title, value) => {
        const changedParameter = webhookParameters?.find((param) => param.id === id)
        changedParameter.title = title
        changedParameter.value = value
    }

    const deleteHeader = (id) => {
        const newHeaders = webhookHeaders?.filter((header) => header.id !== id)
        setWebhookHeaders(newHeaders)
    }
    const deleteParameter = (id) => {
        const newParameters = webhookParameters?.filter((param) => param.id !== id)
        setWebhookParameters(newParameters)
    }

    const saveHandler = () => {
        const webhookBlock = {
            blueprintID: funnelId,
            title: blockTitle,
            messenger: "telegram",
            webhookUrl,
            webhookType,
            webhookData: webhookHeaders.concat(webhookParameters),
            data: { title: blockTitle },
        }

        if (data) {
            const updatedActionBlock = { ...webhookBlock, uuid: data?.id }
            updateNode(updatedActionBlock, "webhook").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
        } else {
            const newWebhookBlock = {
                ...webhookBlock,
                uuid: nodeID,
                position: centerCoordinates,
            }
            createNode(newWebhookBlock, "webhook").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
        }

        onHide()
        setBlockTitle("")
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='WebHook' icon={"webhook"} onHide={() => onHide()} />
            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={[0, 1]}>
                    <AccordionTab header='Информация' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === 'employer'}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Контент' headerClassName='accordion-header'>
                        <div className='mt-3 mb-3'>
                            <p>Блок добавляет возможность выполнить действия используя Web-hook.</p>
                            <Dropdown
                                className='select w-full mb-3'
                                value={webhookType}
                                options={webhookTypes}
                                onChange={(e) => setWebhookType(e.value)}
                                placeholder='Выберите тип'
                                disabled={user?.role === 'employer'}
                            />
                            <InputText
                                className='text w-full mb-5'
                                placeholder='URL'
                                value={webhookUrl}
                                onChange={(e) => setWebhookUrl(e.target.value)}
                                readOnly={user?.role === 'employer'}
                            />
                            <p className='mb-5'>
                                Значение value может быть переменной, для этого укажите имя переменной через фигурные скобки, пример:{" "}
                                <span style={{ color: "#9747ff" }}>{`{name}`}</span>
                            </p>
                            <div className='mb-3'>
                                <h5 className='mb-3'>Параметры</h5>
                                {webhookParameters?.map((param) => (
                                    <WebhookField
                                        id={param.id}
                                        key={param.id}
                                        data={param}
                                        deleteHandler={deleteParameter}
                                        onChangeHandler={onChangeParameter}
                                    />
                                ))}
                                {(user?.role === 'admin' || user?.role === 'moderator') &&
                                    <Button className='btn-40 w-full' label='Добавить параметр' onClick={() => addParameter()} />}
                            </div>
                            <div className='mb-3'>
                                <h5 className='mb-3'>Заголовки</h5>
                                {webhookHeaders?.map((header) => (
                                    <WebhookField
                                        id={header.id}
                                        key={header.id}
                                        data={header}
                                        deleteHandler={deleteHeader}
                                        onChangeHandler={onChangeHeader}
                                    />
                                ))}
                                {(user?.role === 'admin' || user?.role === 'moderator') &&
                                    <Button className='btn-40 w-full' label='Добавить заголовок' onClick={() => addHeader()} />}
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>

            <SidebarFooter saveHandler={() => saveHandler()} disabled={!webhookUrl} onHide={() => onHide()} />
        </div>
    )
}

export default CreateWebhook
