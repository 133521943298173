import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, HashRouter, Redirect, Switch, useHistory, useParams } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import { Route } from 'react-router-dom';
import { Authorization } from './pages/AuthPage/Authorization';
import store from './service/store';
import { Provider } from 'react-redux'
import { NotFound } from './pages/NotFound/NotFound';
import Cookies from 'js-cookie';
import { Confirm, ConfirmSuccess } from './pages/AuthPage/ConfirmSuccess';
import { NewPassword } from './pages/AuthPage/PasswordRecovery/NewPassword';
import { BadToken } from './pages/AuthPage/PasswordRecovery/BadToken';
import { UnsuccessfulConfirm } from './pages/AuthPage/UnsuccessfulConfirm';
import { InviteSuccess } from './pages/Settings/Company/InviteSuccess';
import { InviteFailure } from './pages/Settings/Company/InviteFailure';
import RegistrationPage from './pages/AuthPage/Registration/RegistrationPage';

let auth = Cookies.get('auth');
export function RequireAuth({ children }) {
    let auth = Cookies.get('auth');
    let location = useHistory();

    console.log(location.location)

    const exceptions = ['/registration', '/update-password', '/confirm-success', '/bad-token', '/confirm-failure', '/invite-success', '/invite-failure']
    if (!auth && !exceptions.includes(location.location.pathname)) {
        return <Redirect to="/login" state={{ from: location }} />;
    } else if (location.location.pathname === '/registration' && location.location.search) {
        return <Route path={`/registration${location.location.search}`} exact component={Authorization} />
    }
    return children;
}

function DontAuth({ children }) {
    let auth = Cookies.get('auth');
    let location = useHistory();

    console.log(location.location)
    const exceptions = ['/login', '/registration', '/update-password', '/confirm-success', '/bad-token', '/confirm-failure', /* '/invite-success', '/invite-failure' */]
    if (auth && exceptions.includes(location.location.pathname)) {
        return <Redirect to="/" state={{ from: location }} />;
    }
    return children;
}


ReactDOM.render(

    <BrowserRouter>
        <ScrollToTop>
            <Provider store={store}>
                <DontAuth>
                    <Route path="/login" exact component={Authorization} />
                    <Route path="/registration" exact component={Authorization} />
                    <Route path="/login/password_recovery" exact component={Authorization} />
                    <Route path="/confirm-success" exact component={ConfirmSuccess} />
                    <Route path="/update-password" exact component={NewPassword} />
                    <Route path="/bad-token" exact component={BadToken} />
                    <Route path="/confirm-failure" exact component={UnsuccessfulConfirm} />
                    {!auth &&
                        <>
                            <Route path="/invite-success" exact component={InviteSuccess} />
                            <Route path="/invite-failure" exact component={InviteFailure} />
                        </>
                    }
                </DontAuth>

                <RequireAuth>
                    <Route path="/" exact component={App} />
                    <Route path="/:params" exact component={App} />
                    <Route path="/:params/:params" exact component={App} />
                </RequireAuth>

            </Provider>
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById('root')
);