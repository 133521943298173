import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { log } from "../../../service/log";

export const InviteUser = ({ visible, onHide, toast }) => {

    const [email, setEmail] = useState(null)
    const [widthScreen, setWidthScreen] = useState(window.innerWidth)
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    useEffect(() => {
        window.addEventListener("resize", () => setWidthScreen(window.innerWidth));

        return () => {
            window.removeEventListener("resize", () => setWidthScreen(window.innerWidth));
        };
    }, [])

    const renderFooter = () => {
        return (
            <>
                <div className="flex justify-content-between">
                    <Button label="Отменить" onClick={() => onHide(false)} className='btn-main py-3 px-4 mr-3' />
                    <Button label="Отправить" onClick={sendHandler} autoFocus className="btn-save py-3 px-4" />
                </div>
            </>

        );
    }


    const sendHandler = () => {
        onHide(false)

        const data = {
            email
        }

        axios
            .put(`https://bots.sdew.ru/gt/company/invite-user`, data)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => console.error(error))
        toast.current.show({ severity: 'success', summary: 'Сохранено', detail: `Приглашение на ${email} отправлено`, life: 3000 });

    }

    return (
        <>
            <Dialog className={`personal-setting w-12 sm:w-11 md:w-8 lg:w-6 xl:w-4 ${colorTheme}`} header="Отправка приглашения" visible={visible} footer={renderFooter()} onHide={() => onHide(false)}>
                <div className="grid justify-content-start">
                    <div className="col-12">
                        <span className="p-float-label">
                            <InputText className="w-full input-mail" id="username" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="username">Введите Email</label>
                        </span>
                    </div>
                </div>
            </Dialog>
        </>

    )
}