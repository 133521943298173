import { Dropdown } from "primereact/dropdown"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SkeletonDiagram } from "../../../components/SkeletonDiagram/SkeletonDiagram";
import { formatDate } from "../../../service/formatDate"
import { Diagram } from "../../SmartAnswers/Diagram";
import axios from "axios";
import { Chart } from "primereact/chart";
import { SvgIconsFilters } from "../../../components/SvgIconsFilters/SvgIconsFilters"
import { Skeleton } from "primereact/skeleton";

export const SkeletonDiagramMain = () => {
    return (
        <>
            <div className="CardDiagramMain">
                <Skeleton width="100%" height="1.5rem" />
                <div className="diagram">
                    <div>
                        <Skeleton shape="circle" size="9rem" className="mr-2"></Skeleton>
                    </div>

                    <div>
                        <Skeleton width="10rem" height="1.5rem" className="mb-3" />
                        <Skeleton width="10rem" height="1.5rem" className="mb-3" />
                        <Skeleton width="9rem" height="1.5rem" />
                    </div>
                </div>
            </div>
        </>


    )
}