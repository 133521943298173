import { Sidebar } from "primereact/sidebar"
import React, { useCallback, useEffect, useState } from "react"
import ReactFlow, {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    ControlButton,
    Controls,
    MarkerType,
    updateEdge,
    useReactFlow,
} from "react-flow-renderer"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { v4 as uuid } from "uuid"
import ActionBlock from "./Blocks/ActionBlock"
import CommentBlock from "./Blocks/CommentBlock"
import ConditionBlock from "./Blocks/ConditionBlock"
import FormBlock from "./Blocks/FormBlock"
import IntervalBlock from "./Blocks/IntervalBlock"
import NodeBlock from "./Blocks/NodeBlock"
import PauseBlock from "./Blocks/PauseBlock"
import RedirectBlock from "./Blocks/RedirectBlock"
import StartBlock from "./Blocks/StartBlock"
import WebhookBlock from "./Blocks/WebhookBlock"

import { useRef } from "react"
import {
    changeNodePosition,
    createEdge,
    createNode,
    deleteEdge,
    deleteMedia,
    deleteNode,
    getFunnel,
    updateNode,
    updatingEdge,
} from "../../service/funnels"
import { setInitNodes } from "../../service/slices/initNodesSlice"
import ButtonBlock from "./Blocks/ButtonBlock"
import "./CreateFunnel.scss"
import CreateBlock from "./Sidebars/CreateTextBlock/CreateBlock"
import Toolbar from "./Toolbar/Toolbar"
import UsedBot from "./UsedBot/UsedBot"
import { clearNodes, slicedText } from "./utils"
import LimitPanel from "./LimitPanel/LimitPanel"

const nodeTypes = {
    start: StartBlock,
    message: NodeBlock,
    file: NodeBlock,
    button: NodeBlock,
    form: FormBlock,
    action: ActionBlock,
    condition: ConditionBlock,
    delay: PauseBlock,
    intervalStart: IntervalBlock,
    intervalEnd: IntervalBlock,
    wait: PauseBlock,
    redirect: RedirectBlock,
    nodesButton: ButtonBlock,
    comment: CommentBlock,
    webhook: WebhookBlock,
}

export const CreateFunnel = () => {
    const dispatch = useDispatch()
    const params = useParams()

    const funnelId = +params?.id
    const user = useSelector((state) => state.user.user)
    const initNodes = useSelector((state) => state.initNodes.nodes)
    // УБРАТЬ ТЕСТОВЫЕ ДАННЫЕ
    const [nodesCount, setNodesCount] = useState(0)
    const [nodesLimit, setNodesLimit] = useState(10)

    const [isLimitPanelHidden, setIsLimitPanelHidden] = useState(false)

    const [copiedNode, setCopiedNode] = useState(null)
    const [usedBot, setUsedBot] = useState(null)
    const [nodes, setNodes] = useState([])
    const [edges, setEdges] = useState([])
    const [edgeZIndex, setEdgeZIndex] = useState(false)
    const reactFlowInstance = useReactFlow()
    const [positionNewNode, setPositionNewNode] = useState(null)
    const [newEdge, setNewEdge] = useState([])
    const { project } = useReactFlow()
    // console.log(copiedNode)

    useEffect(() => {
        getFunnel(funnelId).then((res) => {
            setUsedBot(res?.data?.botID)
            setNodesCount(res?.data?.allowNodes)
            setNodesLimit(res?.data?.limitNodes)
            dispatch(setInitNodes(clearNodes(res?.data?.nodes)))
            setEdges(JSON?.parse(JSON?.stringify(res?.data?.edges).replaceAll('"uuid"', '"id"')))
        })
        const zIndexEdge = localStorage.getItem("edgeZIndex")
        if (zIndexEdge === "true") {
            setEdgeZIndex(true)
        } else {
            setEdgeZIndex(false)
        }
    }, [funnelId])

    useEffect(() => {
        const nodesToFormat = JSON?.parse(JSON?.stringify(initNodes).replaceAll('"uuid"', '"id"'))
        setNodesCount(nodesToFormat?.filter((node) => node.type !== "comment").length)
        const allNodes = nodesToFormat?.map((node) => {
            return { ...node, parentNode: node?.data?.parentNode, isInterval: node?.data?.isInterval }
        })
        const conditionNodes = initNodes?.filter((node) => node?.type === "condition")
        const textButtonNodes = initNodes?.filter((node) => node?.type === "button")

        textButtonNodes.forEach((node) => {
            if (node?.data?.buttons?.length !== 0) {
                node?.data?.buttons?.forEach((btn, i) => {
                    const callbackId = btn.callbackData || btn.url
                    const btnAdd = {
                        id: callbackId,
                        type: "nodesButton",
                        parentNode: node?.uuid,
                        data: {
                            label: slicedText(btn.text, 18),
                            url: btn.url,
                            callbackData: btn.callbackData,
                            id: callbackId || btn.url,
                        },
                        url: btn.url,
                        callbackData: btn.callbackData,
                        draggable: false,
                        position: { x: 20, y: 40 + (i + 1) * 40 },
                    }
                    allNodes.push(btnAdd)
                })
            }
        })

        conditionNodes.forEach((node) => {
            const trueOption = {
                blueprintID: funnelId,
                id: node?.data?.uuidTrue,
                parentNode: node?.uuid,
                type: "input",
                draggable: false,
                position: { x: 20, y: 50 },
                sourcePosition: "right",
                data: {
                    label: "Положительный",
                },
                className: "node-group-item true",
            }
            const falseOption = {
                blueprintID: funnelId,
                id: node?.data?.uuidFalse,
                parentNode: node?.uuid,
                type: "input",
                draggable: false,
                position: { x: 20, y: 90 },
                sourcePosition: "right",
                data: {
                    label: "Отрицательный",
                },
                className: "node-group-item false",
            }
            allNodes.push(trueOption)
            allNodes.push(falseOption)
        })
        setNodes(allNodes)
    }, [initNodes])

    // Взаимодействие с node / edge
    const onNodesChange = useCallback(
        (changes) => {
            if (user?.role === "admin" || user?.role === "moderator") {
                setNodes((nds) => applyNodeChanges(changes, nds))
            }
        },
        [setNodes, user]
    )
    const onNodeDragStop = useCallback(
        (event, node, nodes) => {
            // console.log(nodes)
            const changedNodes = nodes.map((node) => {
                const formatedNode = {
                    uuid: node.id,
                    position: node.position,
                }
                return formatedNode
            })
            changeNodePosition({ blueprintID: funnelId, nodes: changedNodes })
        },
        [setNodes]
    )

    const onSelectionDragStop = useCallback(
        (event, nodes) => {
            const changedNodes = nodes.map((node) => {
                const formatedNode = {
                    uuid: node.id,
                    position: node.position,
                }
                return formatedNode
            })
            changeNodePosition({ blueprintID: funnelId, nodes: changedNodes })
        },
        [setNodes]
    )

    // const onNodesDelete = useCallback(
    //     (changes) => {
    //         console.log("Нажал delete: ", changes)
    //         const nodesToDelete = changes
    //             .filter((node) => node.type !== "intervalStart")
    //             .map((node) => {
    //                 return node.id
    //             })

    //         let getNodes = reactFlowInstance.getNodes()

    //         const intervalStartNode = changes.filter((node) => node.type === "intervalStart").map((node) => node.id)

    //         const findNodesToDelete = getNodes.filter((node) => node.parentNode === intervalStartNode[0]).map((node) => node.id)

    //         console.log("Другой способ: ", getNodes, "С чем сравниваю(старт) ", intervalStartNode[0], "Получаю: ", findNodesToDelete)

    //         console.log("Удалить: ", nodesToDelete, "Стартовый: ", intervalStartNode)

    //         if (nodesToDelete && nodesToDelete.length !== 0) {
    //             deleteNode({ blueprintID: funnelId, nodes: nodesToDelete })
    //                 .then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(clearNodes(res?.data?.nodes)))))
    //                 .then((res) =>
    //                     deleteNode({ blueprintID: funnelId, nodes: intervalStartNode }).then((res) =>
    //                         dispatch(setInitNodes(clearNodes(res?.data?.nodes)))
    //                     )
    //                 )
    //         } else {
    //             deleteNode({ blueprintID: funnelId, nodes: findNodesToDelete })
    //             .then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(clearNodes(res?.data?.nodes)))))
    //             .then((res) =>
    //                 deleteNode({ blueprintID: funnelId, nodes: intervalStartNode }).then((res) =>
    //                     dispatch(setInitNodes(clearNodes(res?.data?.nodes)))
    //                 )
    //             )
    //         }
    //     },
    //     [setNodes]
    // )

    const onNodesDelete = useCallback(
        (changes) => {
            const nodesToDelete = changes.map((node) => {
                return node.id
            })
            deleteNode({ blueprintID: funnelId, nodes: nodesToDelete }).then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(clearNodes(res?.data?.nodes))))
            )
            // setNodes((nds) => applyNodeChanges(changes, nds))
        },
        [setNodes]
    )

    const onEdgesChange = useCallback(
        (changes) => {
            if (user?.role === "admin" || user?.role === "moderator") {
                setEdges((eds) => applyEdgeChanges(changes, eds))
            }
        },
        [setEdges, user]
    )

    const onEdgesDelete = useCallback(
        (edges) => {
            edges.map((edge) => {
                // console.log("УДАЛЕНИЕ ЕДЖИ: ", edge)
                const edgeToDelete = { blueprintID: funnelId, uuid: edge.id }
                deleteEdge(edgeToDelete).then((res) => {
                    let sourceNode = reactFlowInstance.getNode(edge?.source)
                    if (sourceNode?.type === "intervalStart") {
                        const intervalNodes = nodes?.filter((node) => node?.parentNode === sourceNode.id && node.type !== "intervalEnd")
                        const intervalEndNode = nodes?.filter((node) => node?.parentNode === sourceNode.id && node.type === "intervalEnd")
                        let intervalEndEdge = reactFlowInstance?.getEdges()?.filter((edge) => edge.target === intervalEndNode[0].id)
                        intervalNodes.forEach((node) => {
                            const intervalNode = {
                                ...node,
                                files: node.data?.files,
                                buttons: node.data?.buttons,
                                isInterval: false,
                                messenger: node?.data?.messenger,
                                parentNode: "",
                                uuid: node.id,
                                blueprintID: funnelId,
                            }
                            updateNode(intervalNode, node.type).then((res) =>
                                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
                            )
                        })
                        const changedNodes = intervalNodes.map((node) => {
                            const formatedNode = {
                                uuid: node.id,
                                position: { x: node.position.x + sourceNode.position.x, y: node.position.y + sourceNode.position.y },
                            }
                            return formatedNode
                        })
                        changeNodePosition({ blueprintID: funnelId, nodes: changedNodes })

                        intervalEndEdge.forEach((edge) => {
                            const edgeToDelete = {
                                uuid: edge.id,
                                blueprintID: funnelId,
                            }
                            deleteEdge(edgeToDelete).then((res) =>
                                getFunnel(funnelId).then((res) =>
                                    setEdges(JSON?.parse(JSON?.stringify(res?.data?.edges).replaceAll('"uuid"', '"id"')))
                                )
                            )
                        })
                    }
                })
            })
            setEdges((eds) => applyEdgeChanges(edges, eds))
        },
        [setEdges, nodes, user]
    )

    const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
        if (newConnection.target !== newConnection.source) {
            const edge = {
                blueprintID: funnelId,
                uuid: oldEdge.id,
                ...newConnection,
            }
            setEdges((els) => updateEdge(oldEdge, newConnection, els))
            updatingEdge(edge)
        }
    }, [])

    const onConnect = useCallback(
        (connection, e) => {
            if (user?.role === "admin" || user?.role === "moderator") {
                const sourceNode = nodes?.find((node) => node.id === connection.source)
                const targetNode = nodes?.find((node) => node.id === connection.target)

                if (connection.target !== connection.source) {
                    const edge = {
                        blueprintID: funnelId,
                        uuid: uuid(),
                        source: connection.source,
                        target: connection.target,
                        sourceHandle: connection.sourceHandle,
                        targetHandle: connection.targetHandle,
                    }
                    if (
                        (sourceNode?.type !== "intervalStart" && !sourceNode?.isInterval) ||
                        (sourceNode.type === "intervalEnd" && !targetNode.isInterval)
                    ) {
                        // Edge не в интервале
                        setEdges((eds) => addEdge(connection, eds))
                        createEdge(edge)
                    } else if (
                        ((sourceNode?.type === "intervalStart" || sourceNode?.isInterval) &&
                            (targetNode?.type === "message" ||
                                targetNode?.type === "button" ||
                                targetNode?.type === "file" ||
                                targetNode?.type === "intervalEnd") &&
                            !targetNode.isInterval) ||
                        targetNode.type === "intervalEnd"
                    ) {
                        // Edge в интервале
                        setEdges((eds) => addEdge(connection, eds))
                        createEdge(edge)
                    } else if (sourceNode?.type === "nodesButton" && !targetNode.isInterval) {
                        setEdges((eds) => addEdge(connection, eds))
                        createEdge(edge)
                    }
                }
            }
        },
        [setEdges, nodes, user]
    )

    const reactFlowWrapper = useRef(null)
    const connectingNodeId = useRef(null)
    const [newNode, setNewNode] = useState(null)
    const [sidebar, setSidebar] = useState(false)
    const [newNodeFile, setNewNodeFile] = useState(null)

    // console.log(newNodeFile)

    const onConnectStart = useCallback((_, { nodeId }) => {
        connectingNodeId.current = nodeId
    }, [])

    const onConnectEnd = useCallback(
        (event) => {
            const targetIsPane = event.target.classList.contains("react-flow__pane")
            if (user?.role === "admin" || user?.role === "moderator") {
                if (targetIsPane) {
                    const { top, left } = reactFlowWrapper.current.getBoundingClientRect()
                    const id = uuid()
                    const files = []
                    let textBlock = {
                        blueprintID: funnelId,
                        type: "message",
                        title: "",
                        text: "",
                        files: files,
                        messenger: "Telegram",
                        data: { title: "", text: "", files: files, blueprintID: funnelId },
                    }

                    const addTextBlock = {
                        ...textBlock,
                        files,
                        uuid: id,
                        position: project({ x: event.clientX - left - 75, y: event.clientY - top }),
                    }
                    setPositionNewNode(project({ x: event.clientX - left - 75, y: event.clientY - top }))

                    setNewNode(addTextBlock)

                    const edge = {
                        blueprintID: funnelId,
                        uuid: uuid(),
                        source: connectingNodeId.current,
                        target: id,
                        sourceHandle: "r",
                        targetHandle: "l",
                    }

                    setNewEdge(edge)
                    /*  createNode(addTextBlock, "message")
                         .then((res) => getFunnel(funnelId)
                             .then((res) => dispatch(setInitNodes(res?.data?.nodes))))
                         .then(() => createEdge(edge))
    */
                    getFunnel(funnelId).then((res) => dispatch(setInitNodes([...res?.data?.nodes, addTextBlock])))

                    setEdges((eds) => eds.concat({ id, source: connectingNodeId.current, target: id }))

                    setSidebar(true)
                }
            }
        },
        [project, user]
    )
    const colorTheme = useSelector((state) => state?.colorTheme.theme)

    const snapGrid = [10, 10]

    // Скопировать и вставить ноду

    const copyNode = (node) => {
        setCopiedNode(node)
    }

    // useEffect(() => {
    //     const onKeypress = (e) => {
    //         e.preventDefault()
    //         if (e.ctrlKey === true && e.code === "KeyC") {
    //             const formatNode = { ...copiedNode, position: { x: 1000, y: 350 }, selected: false }
    //             setCopiedNode(formatNode)
    //         }
    //         if (e.ctrlKey === true && e.code === "KeyV") {
    //             if (copiedNode) {
    //                 console.log(copiedNode)
    //                 setNodes((nds) => nds.concat({ ...copiedNode, id: node_id }))
    //                 addNode(copiedNode)
    //             }
    //         }
    //     }
    //     document.addEventListener("keyup", onKeypress)
    //     return () => {
    //         document.removeEventListener("keyup", onKeypress)
    //     }
    // }, [copiedNode])

    // Стили

    const rfStyle = {
        backgroundColor: colorTheme === "dark" ? "dark" : "var(--surface-500)",
    }

    const connectionLineStyle = { stroke: "#007AFF" }

    const onChangeEdgeZIndex = (value) => {
        setEdgeZIndex(value)
        localStorage.setItem("edgeZIndex", value)
        setEdges(
            edges.map((edge) => {
                return { ...edge, zIndex: value ? 99999 : 0 }
            })
        )
    }

    const edgeOptions = {
        style: { strokeWidth: "2px", stroke: `var(--text-color)` },
        markerEnd: {
            type: MarkerType.ArrowClosed,
            // color: `var(--text-color)`,
            color: colorTheme === "dark" ? "#fff" : "#212529",
        },
        zIndex: edgeZIndex ? 999999 : 0,
        interactionWidth: 25,
        // className: "custom-edge",
        // type: "default",
        // selected: true
    }

    // console.log(initNodes)
    // console.log(newNode)

    const hideHandler = () => {
        setSidebar(false)
        dispatch(setInitNodes(initNodes.filter((item) => item.uuid !== newNode.uuid)))
        // console.log({ fileObject: "node", nodeID: newNode?.uuid, filename: newNodeFile?.path })
        /*  deleteMedia({ fileObject: "node", nodeID: newNode?.uuid, filename: newNodeFile?.path }) */
    }

    useEffect(() => {
        if (nodesCount >= nodesLimit) {
            setIsLimitPanelHidden(false)
        }
    }, [nodesCount])

    const isLimitOver = nodesCount >= nodesLimit
    // console.log("Тест", nodesCount > nodesLimit)

    return (
        <div className='funnels-container' ref={reactFlowWrapper}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                defaultEdgeOptions={edgeOptions}
                nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                onNodeDragStop={onNodeDragStop}
                onSelectionDragStop={onSelectionDragStop}
                onNodesDelete={onNodesDelete}
                // onNodeClick={(e, node) => copyNode(node)}
                deleteKeyCode={["Delete", "Backspace"]}
                multiSelectionKeyCode={"Control"}
                onEdgesChange={onEdgesChange}
                onEdgesDelete={onEdgesDelete}
                onEdgeUpdate={onEdgeUpdate}
                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onConnectEnd={onConnectEnd}
                snapToGrid={true}
                snapGrid={snapGrid}
                style={rfStyle}
                defaultZoom={1}
                className='funnel'
                connectionLineStyle={connectionLineStyle}
            >
                {/* <MiniMap /> */}
                <Controls className='funnel-control'>
                    <ControlButton title='Видимость линий' onClick={() => onChangeEdgeZIndex(!edgeZIndex)}>
                        <div>
                            <i className='pi pi-arrow-up-right'></i>
                        </div>
                    </ControlButton>
                </Controls>
                <Background color='#7e7e7e' gap={20} />
                {(user?.role === "admin" || user?.role === "moderator") && (
                    <Toolbar
                        isLimitOver={isLimitOver}
                        setNodes={setNodes}
                        funnelId={funnelId}
                        usedBot={usedBot}
                        setEdges={setEdges}
                        setInitNodes={setInitNodes}
                    />
                )}
                {!isLimitPanelHidden && nodesLimit - nodesCount <= 5 && (
                    <div className='funnel-limit'>
                        <LimitPanel count={nodesCount} limit={nodesLimit} setIsLimitPanelHidden={setIsLimitPanelHidden} />
                    </div>
                )}

                <Sidebar className='toolbar-sidebar' dismissable={false} visible={sidebar} onHide={hideHandler} position='right'>
                    {newNode && (
                        <CreateBlock
                            onHide={hideHandler}
                            funnelId={funnelId}
                            data={newNode}
                            positionNewNode={positionNewNode}
                            sourceNewEdge={connectingNodeId.current}
                            setEdges={setEdges}
                            newNodeFile={newNodeFile}
                            setNewNodeFile={setNewNodeFile}
                        />
                    )}
                </Sidebar>
            </ReactFlow>

            {/* <UsedBot /> */}
        </div>
    )
}
