import { NavLink } from 'react-router-dom'
import './NotFound.css'

export const NotFound = () => {
    return <div className="NotFound flex flex-column align-items-center justify-content-center pt-3">
        <div className='not-found_numbers flex'>
            <div>
                <img className='mr-0 sm:mr-3' src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/405.png`}></img>
            </div>
            <div>
                <img className='ml-0 sm:ml-3' src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/406.png`}></img>
            </div>

        </div>
        <div className='text-center'>
            <span>Страница была изменена или удалена.</span>
            <div>
                <span>Давайте вернемся </span>
                <NavLink to="/main" className="p-link">
                    <span>на главную</span>
                </NavLink>
            </div>

        </div>

    </div>
}