import React, { memo } from "react"
import { Handle } from "react-flow-renderer"
import "./Blocks.scss"

export default memo(() => {
    return (
        <div className='StartBlock'>
            <div>Старт</div>
            <Handle
                id='r'
                type='source'
                className='custom-handle'
                position='right'
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={true}
            />
        </div>
    )
})
