import React from 'react'
import { Button } from 'primereact/button'

import './DemoMode.css'

const DemoMode = ({ setVisibleDemo }) => {
    return (
        <div className="demo-mode">
            DemoMode <Button label="x" onClick={() => setVisibleDemo(false)} />
        </div>
    )
}

export default DemoMode
