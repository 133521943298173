import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import React, { useState } from "react"

import { useEffect } from "react"
import {
    addTelegramToken,
    getAllCompanyBots,
    getFunnel,
    removeTelegramBot,
    setTelegramBot,
    startBlueprint,
    updateBlueprint,
} from "../../../service/funnels"
import SidebarHeader from "./components/SidebarHeader"
import "./Sidebar.scss"

const Publication = ({ data, onHide, funnelId }) => {
    console.log("Data in publication: ", data)
    const [usedBot, setUsedBot] = useState("")

    const [title, setTitle] = useState("")
    const [tagFunnel, setTagFunnel] = useState("")
    const [descriptionFunnel, setDescriptionFunnel] = useState("")

    const [addNewBot, setAddNewBot] = useState(false)

    const [bots, setBots] = useState([])
    const [tokenBot, setTokenBot] = useState("")
    const [isValidToken, setIsValidToken] = useState(true)
    const [selectedBot, setSelectedBot] = useState(usedBot)

    const tags = [
        { label: "Тэг 1", value: "tag1" },
        { label: "Янг Тэг", value: "tag2" },
    ]

    const formatingBots = (arrayBots) => {
        const arr = arrayBots
        let newArr = [{ label: "Бот не назначен", value: "" }]
        arr.map((item) => {
            const edited = {
                label: item.title ? item.title : item.botID,
                value: item.id,
            }
            newArr.push(edited)
        })
        setBots(newArr)
    }

    useEffect(() => {
        getFunnel(funnelId).then((res) => {
            setUsedBot(res?.data?.botID)
            setTitle(res?.data?.title)
        })
        getAllCompanyBots().then((res) => {
            formatingBots(res.data)
        })
    }, [])

    useEffect(() => {
        setSelectedBot(usedBot)
    }, [usedBot])

    useEffect(() => {
        let regExp = /[0-9]{10}:[a-zA-Z0-9_-]{34}/
        const isValid = regExp.test(tokenBot)
        setIsValidToken(isValid)
    }, [tokenBot])

    // Добавление нового блока

    const addBotHandler = () => {
        if (isValidToken) {
            addTelegramToken(tokenBot).then((res) =>
                getAllCompanyBots(1).then((res) => {
                    formatingBots(res.data)
                })
            )
            setAddNewBot(false)
        }
    }

    const saveHandler = () => {
        const saved = {
            blueprintID: funnelId,
            title: title,
            // tag: tagFunnel,
            // description: descriptionFunnel,
        }
        if (selectedBot !== "") {
            setTelegramBot(funnelId, selectedBot).then((res) =>
                getFunnel(funnelId).then((res) => {
                    setUsedBot(res?.data?.botID)
                    onHide()
                })
            )
            // setTelegramBot(funnelId, selectedBot).then((res) => getFunnel(funnelId).then((res) => setUsedBot(res?.data?.botID)))
        } else {
            removeTelegramBot(funnelId, usedBot).then((res) =>
                getFunnel(funnelId).then((res) => {
                    setUsedBot(res?.data?.botID)
                    onHide()
                })
            )
        }

        updateBlueprint(saved)
        // onHide()
    }
    const publicateHandler = () => {
        startBlueprint(funnelId)
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Публикация воронки' icon={"publication"} onHide={() => onHide()} isBorder={true} />

            <div
                className='block publication mt-2'
                style={{
                    background: `url('${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/publication-fon.svg') no-repeat bottom`,
                    backgroundSize: "contain",
                }}
            >
                <h2 className='text-left'>Проверьте воронку перед публикацией</h2>
                <p>Наведите порядок, введите названия для блоков, добавьте разъясняющие комментарии.</p>
            </div>

            <Accordion multiple activeIndex={[0, 1]}>
                <AccordionTab header='Информация' headerClassName='accordion-header'>
                    <div className='mt-3 mb-3'>
                        <div className='field'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <label htmlFor='title'>Название воронки</label>
                            </span>
                        </div>
                        {/* <div className='field'>
                            <span className='p-float-label'>
                                <Dropdown
                                    id='tag'
                                    className='w-full select'
                                    value={tagFunnel}
                                    options={tags}
                                    onChange={(e) => setTagFunnel(e.value)}
                                />
                                <label htmlFor='tag'>Тэг для воронки</label>
                            </span>
                        </div>

                        <div className='field'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={descriptionFunnel}
                                    onChange={(e) => setDescriptionFunnel(e.target.value)}
                                />
                                <label htmlFor='title'>Описание (не обязательно)</label>
                            </span>
                        </div> */}
                    </div>
                </AccordionTab>
                <AccordionTab header='Подключение к боту' headerClassName='accordion-header'>
                    <div className='mt-3 mb-3'>
                        {!addNewBot && (
                            <Dropdown
                                className='w-full select mb-3'
                                value={selectedBot}
                                options={bots}
                                onChange={(e) => setSelectedBot(e.value)}
                                placeholder='Бот не назначен'
                            />
                        )}
                        {!addNewBot ? (
                            <Button className='btn w-full' label='Добавить нового бота' onClick={() => setAddNewBot(true)} />
                        ) : (
                            <>
                                <p>
                                    Введите токен полученный в <span style={{ color: "#007AFF" }}>@BotFather</span>, иначе воронка просто не
                                    будет работать
                                </p>
                                <div className='field mb-3'>
                                    <InputTextarea
                                        autoResize
                                        aria-describedby='token-help'
                                        className={`text-area w-full ${tokenBot.length !== 0 && !isValidToken && "p-invalid"}`}
                                        placeholder='Токен'
                                        value={tokenBot}
                                        onChange={(e) => setTokenBot(e.target.value)}
                                    />
                                    {tokenBot?.length !== 0 && !isValidToken && (
                                        <small id='token-help' className='p-error'>
                                            Неверный токен
                                        </small>
                                    )}
                                </div>
                                <div className='flex justify-content-between'>
                                    <Button className='btn-40 w-full mr-2' label='Добавить к списку' onClick={() => addBotHandler()} />{" "}
                                    <Button className='btn-40 w-full btn-cancel' label='Отменить' onClick={() => setAddNewBot(false)} />
                                </div>
                            </>
                        )}
                    </div>
                </AccordionTab>
            </Accordion>

            <div className='flex flex-column justify-content-between sidebar-buttons'>
                <Button label='Опубликовать и выйти' className='btn btn-publicate py-3 px-4' onClick={() => publicateHandler()} />
                <Button label='Сохранить изменения' className='btn btn py-3 px-4' onClick={() => saveHandler()} />
                <Button label='Отмена' className='p-button-text btn-cancel py-3 px-4' onClick={() => onHide()} />
            </div>
        </div>
    )
}

export default Publication
