import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { Password } from 'primereact/password';
import { useSelector } from "react-redux";

export const AddNewCard = ({ visible, onHide }) => {

    const [number, setNumber] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [username, setUsername] = useState('');
    const [selectUsing, setSelectUsing] = useState(false);

    const [cvc, setCvc] = useState('')

    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const options = ['Мужской', 'Женский'];


    const rights = [
        { name: 'Администратор', code: 'Администратор' },
    ];

    const maskCard = (number) => {
        return number.replace(/\s/g, '').replace(/(.{4})/g, "$1 ")
    }

    const renderFooter = () => {
        return (
            <>
                <div className="flex justify-content-between">
                    <Button label="Отменить" onClick={() => onHide(false)} className='btn-main p-button-text py-3 px-4 mr-3' />
                    <Button label="Привязать" onClick={() => onHide(false)} autoFocus className="btn-save py-3 px-4" />
                </div>
            </>

        );
    }


    const using = [
        { name: 'Только для оплаты', code: 'Только для оплаты' },
    ];


    return (
        <>
            <Dialog className={`personal-setting w-12 sm:w-11 md:w-8 lg:w-6 xl:w-4 ${colorTheme}`} header="Привязка новой карты" visible={visible} footer={renderFooter()} onHide={() => onHide(false)}>

                <div className="grid">
                    <div className="col-6 mb-4">
                        <span className="p-float-label">
                            <InputText className="w-full" id="username" value={number} onChange={(e) => setNumber(e.target.value)} />
                            <label htmlFor="username">Номер</label>
                        </span>
                    </div>
                    <div className="col-6 flex mb-4">
                        <span className="p-float-label w-6">
                            <InputText className={`w-full valid-month ${colorTheme}`} id="username" value={month} onChange={(e) => setMonth(e.target.value)} />
                            <label htmlFor="username">MM</label>
                        </span>
                        <span className="p-float-label w-6">
                            <InputText className="w-full valid-year" id="username" value={year} onChange={(e) => setYear(e.target.value)} />
                            <label htmlFor="username">ГГ</label>
                        </span>
                    </div>
                    <div className="col-12 mb-4">
                        <span className="p-float-label">
                            <InputText className="w-full" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                            <label htmlFor="username">Имя(как на карте)</label>
                        </span>
                    </div>
                    <div className={selectUsing ? "col-6 mt-1" : "col-6"}>
                        {selectUsing && <label className="label" htmlFor="username">Использовать как</label>}
                        <Dropdown className="mt-0 w-full select-rights flex align-items-center" value={selectUsing} options={using} onChange={(e) => setSelectUsing(e.value)} optionLabel="name" placeholder="Использовать как" />
                    </div>
                    <div className={selectUsing ? "col-6 mt-4" : "col-6"}>
                        <div className="flex justify-content-end w-7 ml-auto">
                            <span className="p-float-label">
                                <Password value={cvc} onChange={(e) => setCvc(e.target.value)} feedback={false} />
                                <label htmlFor="username">cvc/cvv</label>
                            </span>
                        </div>

                    </div>
                </div>

            </Dialog>
        </>
    )
}