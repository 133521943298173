import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import apiClient from '../api'

export const getUser = createAsyncThunk(
    'user/getUser',
    async () => {
        const response = await apiClient.get(`gt/user/profile`)
        console.log(response);
        return response.data
    }
)

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async function (updatedData, { rejectWithValue }) {
        try {
            const response = await apiClient.put(`/gt/user/update-profile`, updatedData)
            if (response.status !== 200) {
                throw new Error('Ошибка при изменении пользователя');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updatePositionUser = createAsyncThunk(
    'user/updatePositionUser',
    async function (updatedData, { rejectWithValue }) {
        try {
            const response = await apiClient.put(`/gt/user/update-position`, updatedData)
            if (response.status !== 200) {
                throw new Error('Ошибка при изменении должности');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null,
        error: null
    },
    reducers: {
        setSelectedCompany: (state, action) => {
            state.rows = action.payload
        },
    },
    extraReducers(builder) {
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.error = null
            state.user = action.payload
            localStorage.setItem('roleUser', action.payload.role)
        })
            .addCase(getUser.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.error = null
                state.user = action.payload
            })
            .addCase(updateUser.pending, state => {
                state.error = null
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(updatePositionUser.fulfilled, (state, action) => {
                state.error = null
                state.user = action.payload
            })
            .addCase(updatePositionUser.pending, state => {
                state.error = null
            })
            .addCase(updatePositionUser.rejected, (state, action) => {
                state.error = action.payload
            })
    }
})

export default userSlice.reducer
