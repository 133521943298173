import axios from 'axios'
import apiClient from './api'

export const createBlueprint = async (currentDate) => {
    return await apiClient.post(`bp/blueprint/create`, {title: `Новая воронка | ${currentDate}` })
}

export const updateBlueprint = async (blueprint) => {
    return await apiClient.put(`bp/blueprint/update`, blueprint)
}

export const deleteBlueprint = async (funnelId) => {
    await apiClient.delete(`bp/blueprint/delete`, {data: {blueprintID: funnelId} })
}

export const getFunnel = async (funnelId) => {
    return await apiClient.get(`bp/blueprint/one/?blueprintID=${funnelId}`).then((res) => res)
}

export const getAllBlueprints = async () => {
    return await apiClient.get(`bp/company/list-blueprints`).then((res) => res)
}

export const startBlueprint = async (funnelId) => {
    await apiClient.put(`bp/blueprint/start/`, {blueprintID: funnelId})
}
export const stopBlueprint = async (funnelId) => {
    await apiClient.put(`bp/blueprint/stop/`, {blueprintID: funnelId})
}

// ==== Боты/токены
export const getAllCompanyBots = async () => {
    return await apiClient.get(`bp/company/list-bots`).then((res) => res)
}
export const addTelegramToken = async (token) => {
    return await apiClient.put(`bp/company/add-telegram-token`, {token: token })
}

export const setTelegramBot = async (funnelId, usedBot) => {
    await apiClient.put(`bp/blueprint/include-telegram-bot`,  {blueprintID: funnelId, botID: usedBot} )
}
export const removeTelegramBot = async (funnelId) => {
    await apiClient.put(`bp/blueprint/remove-telegram-bot`, {blueprintID: funnelId})
    // await apiClient.delete(`bp/blueprint/remove-telegram-bot`, {data: {blueprintID: funnelId, botID: usedBot}})
}
//  ==== Ноды

export const addNode = async (node) => {
    return await apiClient.post(`bp/node/add`, node)
}

export const createNode = async (node, type) => {
    return await apiClient.post(`bp/node/create-${type}`, node)
}
export const changeNodePosition = async (nodes) => {
    return await apiClient.put(`bp/node/change-position`, nodes)
}
export const updateNode = async (node, type) => {
    return await apiClient.put(`bp/node/update-${type}`, node)
}

export const deleteNode = async (node) => {
    await apiClient.delete(`bp/node/delete`, {data: node})
}

export const deleteMedia = async (media) => {
    await axios.delete(`https://storage.sdew.ru/fh/file/delete`, {data: media})
}

// ==== Еджи

export const createEdge = async (edge) => {
    await apiClient.post(`bp/edge/create`, edge)
}
export const updatingEdge = async (edge) => {
    await apiClient.put(`bp/edge/update`, edge)
}
export const deleteEdge = async (edge) => {
    await apiClient.delete(`bp/edge/delete`, {data: edge})
}

// ==== Переменные

export const getVariables = async (funnelId) => {
    return await apiClient.get(`bp/blueprint/list-variable?blueprintID=${funnelId}`).then(res => res.data)
}
export const createVariable = async (variable) => {
    await apiClient.post(`bp/blueprint/create-variable`, variable)
}
export const updateVariable = async (variable) => {
    await apiClient.put(`bp/blueprint/update-variable`, variable)
}
export const deleteVariable = async (variable) => {
    await apiClient.delete(`bp/blueprint/delete-variable`, {data: variable})
}