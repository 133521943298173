import { Dropdown } from "primereact/dropdown"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SkeletonDiagram } from "../../../components/SkeletonDiagram/SkeletonDiagram";
import { formatDate } from "../../../service/formatDate"
import { Diagram } from "../../SmartAnswers/Diagram";
import axios from "axios";
import { Chart } from "primereact/chart";
import { SvgIconsFilters } from "../../../components/SvgIconsFilters/SvgIconsFilters"
import { SkeletonDiagramMain } from "./SkeletonDiagramMain";
import './CardDiagramMain.css'

export const CardDiagramMain = ({ dataMessangers, title }) => {
    const selectDate = useSelector((state) => state.selectDate.selectDate)
    const answers = useSelector((state) => state.smartAnswers.answers)
    const loading = useSelector((state) => state.smartAnswers.loading)
    //  const messangers = useSelector((state) => state.smartAnswers.messangers)
    const start = useSelector((state) => state.selectDate.start)
    const end = useSelector((state) => state.selectDate.end)
    const [formatStart, setFormatStart] = useState(new Date(start))
    const [formatEnd, setFormatEnd] = useState(new Date(end))
    const [messangers, setMessangers] = useState(dataMessangers ? dataMessangers : [
        {
            name: "@andreyMarketing_bot",
            messanger: "telegram",
            coverage: 59
        },
        {
            name: "@bot_number4",
            messanger: "telegram",
            coverage: 33
        },
        {
            name: "другие",
            coverage: 8
        },

    ])

    console.log(dataMessangers)
    console.log(messangers)


    /*     useEffect(() => {
            axios.get('data.json')
                .then((response) => {
                    setMessangers(response.data.audience.messangers)
                })
                .catch((error) => console.error(error))
        }, []) */

    useEffect(() => {
        setFormatStart(new Date(start))
        setFormatEnd(new Date(end))
    }, [start, end])

    const select = [
        { name: 'По мессенджерам' }
    ];


    const data = messangers?.map((item) => {
        return item.coverage
    })

    console.log(data)

    const labels = messangers?.map((item) => {
        return item.name
    })
    console.log(labels)

    const backgroundMessanger = (name) => {
        switch (name) {
            case 'Telegram':
                return '#007AFF';
            case 'ВКонтакте':
                return '#78909C';
            case 'WhatsApp':
                return '#28CD41';
            case 'Другие':
                return '#FFFFFF'

            default:
                break;
        }
    }

    const backgroundColor = [
        "#007AFF",
        "#FFE66D",
        "#566476",
        "#EF3E36"
    ]
    const sumCalls = messangers?.reduce((acc, current) => acc + current.coverage, 0)

    console.log(sumCalls)
    const chartData = {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: backgroundColor,
                hoverBackgroundColor: backgroundColor,
                cutout: '89%',
                borderRadius: 15,
                rotation: 150,
                borderWidth: 0,
            },
        ]
    }
        ;

    const lightOptions = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (item) {
                        return item.label + ': ' + item.parsed + ' (' + ((item.parsed * 100) / sumCalls).toFixed(1) + '%)';
                    }
                }
            },
            legend: false
        }
    };


    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: 2,
            plugins: {
                legend: false
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#2D3645'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#566476'
                    }
                }
            }
        };

        return {
            basicOptions

        }
    }

    const { basicOptions } = getLightTheme();

    const nameMessanger = (name) => {
        switch (name) {
            case 'Telegram':
                return 'telegram';
            case 'ВКонтакте':
                return 'vk';
            case 'WhatsApp':
                return 'whatsapp';
            default:
                break;
        }
    }

    const SvgIconsMessangers = ({ name }) => {
        switch (name) {
            case 'telegram':
                return (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.3584 7.3824C9.38613 7.78707 7.4422 8.6244 4.5266 9.8944C4.05326 10.0827 3.80546 10.2667 3.7832 10.4464C3.74447 10.7506 4.12544 10.8704 4.64426 11.0336C4.71492 11.0558 4.78813 11.0788 4.8632 11.1032C5.3738 11.2692 6.0606 11.4634 6.4178 11.4712C6.74153 11.4785 7.103 11.3452 7.5022 11.0712C10.2259 9.23254 11.6319 8.3032 11.7202 8.28321C11.7826 8.269 11.8686 8.2512 11.9272 8.3032C11.9858 8.3552 11.98 8.4536 11.9738 8.48C11.9362 8.64107 10.4418 10.0302 9.66726 10.7503C9.42521 10.9753 9.25344 11.135 9.2184 11.1714C9.13997 11.2529 9.06004 11.3299 8.98322 11.404C8.50894 11.8612 8.15324 12.2041 9.003 12.764C9.41139 13.0331 9.73817 13.2557 10.0642 13.4777C10.4202 13.7201 10.7752 13.9619 11.2346 14.263C11.3515 14.3397 11.4633 14.4193 11.5721 14.4968C11.9868 14.7924 12.3594 15.0579 12.8188 15.0156C13.086 14.9904 13.3622 14.7396 13.5022 13.9904C13.8336 12.22 14.4846 8.382 14.6352 6.8008C14.6444 6.66939 14.6387 6.53736 14.6184 6.4072C14.6052 6.3294 14.5772 6.2184 14.4762 6.1364C14.3564 6.04 14.1714 6.0186 14.0886 6.02C13.7126 6.0266 13.1354 6.2274 10.3584 7.3824Z" fill="white" />
                        <path d="M4.5266 9.8944C7.4422 8.6244 9.38613 7.78707 10.3584 7.3824C13.1354 6.2274 13.7126 6.0266 14.0886 6.02C14.1714 6.0186 14.3564 6.04 14.4762 6.1364C14.5772 6.2184 14.6052 6.3294 14.6184 6.4072C14.6387 6.53736 14.6444 6.66939 14.6352 6.8008C14.4846 8.382 13.8336 12.22 13.5022 13.9904C13.3622 14.7396 13.086 14.9904 12.8188 15.0156C12.3594 15.0579 11.9868 14.7924 11.5721 14.4968C11.4633 14.4193 11.3515 14.3397 11.2346 14.263C10.7752 13.9619 10.4202 13.7201 10.0642 13.4777C9.73817 13.2557 9.41139 13.0331 9.003 12.764C8.15324 12.2041 8.50894 11.8612 8.98322 11.404C9.06004 11.3299 9.13997 11.2529 9.2184 11.1714C9.25344 11.135 9.42521 10.9753 9.66726 10.7503C10.4418 10.0302 11.9362 8.64107 11.9738 8.48C11.98 8.4536 11.9858 8.3552 11.9272 8.3032C11.8686 8.2512 11.7826 8.269 11.7202 8.28321C11.6319 8.3032 10.2259 9.23254 7.5022 11.0712C7.103 11.3452 6.74153 11.4785 6.4178 11.4712C6.0606 11.4634 5.3738 11.2692 4.8632 11.1032C4.78813 11.0788 4.71492 11.0558 4.64426 11.0336C4.12544 10.8704 3.74447 10.7506 3.7832 10.4464C3.80546 10.2667 4.05326 10.0827 4.5266 9.8944Z" fill="#007AFF" />
                    </svg>


                );

            default:
                return <svg></svg>;

        }
    }


    return (
        <>
            {loading
                ? <SkeletonDiagramMain />
                : <div className="CardDiagramMain">
                    <h5 className="title-card_statistics">
                        {title} {selectDate === 'today' ? 'сегодня' : selectDate === 'yesterday' ? 'вчера' : `${formatDate(formatStart)} - ${formatDate(formatEnd)}`}

                    </h5>
                    <div className="diagram">

                        <div className="relative flex align-items-center justify-content-center container-chart">
                            <div className="absolute mb-0 text-center">
                                <p className="percent">{sumCalls}</p>
                            </div>
                            <Chart className="chart-doughnut" type="doughnut" data={chartData} options={lightOptions} />
                        </div>

                        <div className="messangers-block">
                            {messangers?.map((item, idx) => {
                                return <div key={idx} className="flex">
                                    <div className="legend mr-2 mt-1" style={{ backgroundColor: backgroundColor[idx] }}></div>
                                    <div>
                                        <div className="name-messangers" style={{ color: backgroundColor[idx] }}>{item.name}</div>

                                        <div className="coverage-messangers">{item.coverage}%</div>
                                    </div>
                                    {item.messanger &&
                                        <SvgIconsMessangers name={item.messanger} />}

                                    {/* <div className={`messanger-wrapper ${item.name === 'Telegram' || item.name === 'ВКонтакте' ? 'white-font' : 'black-font'}`} style={{ backgroundColor: backgroundMessanger(item.name) }}>
                                           <p>{nameMessanger(item.name)} - {(item.coverage * 100 / sumCalls).toFixed(1)}%</p>
                                        <>
                                            {item.name !== 'Другие'
                                                ? <SvgIconsFilters name={nameMessanger(item.name)} width='20px' height='20px' />
                                                : <p className="other-messangers">Другие</p>
                                            }
                                        </>
                                        <div>
                                            {Math.round(item.coverage * 100 / sumCalls)}%
                                        </div>
                                    </div> */}
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            }
        </>


    )
}