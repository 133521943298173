import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"
import { createNode, getFunnel, updateNode } from "../../../service/funnels"
import { setInitNodes } from "../../../service/slices/initNodesSlice"
import { useReactFlow } from "react-flow-renderer"
import SidebarFooter from "./components/SidebarFooter"
import SidebarHeader from "./components/SidebarHeader"

import "./Sidebar.scss"

const CreateInterval = ({ onHide, data, funnelId, centerCoordinates }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)
    // console.log("Dataaa: ", data)

    const reactFlowInstance = useReactFlow()

    const [blockTitle, setBlockTitle] = useState(data?.title || "")

    const [dateEnd, setDateEnd] = useState(null)
    const [minDate, setMinDate] = useState(null)

    const [intervalEndId, setIntervalEndId] = useState("")

    const todayDate = new Date()

    useEffect(() => {
        if (data) {
            setDateEnd(new Date(data?.waitUntil))
            setMinDate(new Date(data?.minimumDate))

            const getNodesFrom = reactFlowInstance.getNodes()
            const intervalEnd = getNodesFrom?.filter((node) => node.parentNode === data.id && node.type === "intervalEnd")
            console.log("Интервал енд: ", intervalEnd[0].id)
            setIntervalEndId(intervalEnd[0].id)
        }
    }, [data])

    // console.log(dateEnd?.toString())

    // Добавление нового блока

    const saveHandler = () => {
        const startInterval = {
            blueprintID: funnelId,
            title: blockTitle,
            waitUntil: dateEnd.toString(),
            minimumDate: minDate.toString(),
            data: { title: blockTitle, text: "Start" },
        }
        const endInterval = {
            blueprintID: funnelId,
            title: blockTitle,
            data: { title: blockTitle, text: "End" },
        }

        if (data) {
            const updatedStartInterval = { ...startInterval, uuid: data?.id }
            const updatedEndInterval = { ...endInterval, uuid: intervalEndId }
            updateNode(updatedStartInterval, "send-by-interval-start").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
            updateNode(updatedEndInterval, "send-by-interval-end").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
        } else {
            const createStartInterval = { ...startInterval, uuid: uuid(), position: centerCoordinates, isInterval: true }
            const createEndInterval = {
                ...endInterval,
                uuid: uuid(),
                position: { x: 0, y: 250 },
                parentNode: createStartInterval.uuid,
                isInterval: true,
            }
            createNode(createStartInterval, "send-by-interval-start")
                .then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes))))
                .then((res) =>
                    createNode(createEndInterval, "send-by-interval-end").then((res) =>
                        getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
                    )
                )
        }

        onHide()
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Распределение' icon={"interval"} onHide={() => onHide()} />

            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={[0, 1]}>
                    <AccordionTab header='Информация' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === "employer"}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Контент' headerClassName='accordion-header'>
                        <div className='mb-3 mt-3 w-full'>
                            <h3>Распределить сообщения до даты</h3>
                            <p>Распределяет последующие блоки для равномерной подачи контента пользователю до определенной даты.</p>
                        </div>
                        <div className='pb-3'>
                            <h5>Дата конца действия</h5>
                            <Calendar
                                className={`delay-calendar ${user?.role === "employer" ? "disabled-btn" : ""} w-full`}
                                showTime
                                dateFormat='dd/mm/yy'
                                minDate={todayDate}
                                hourFormat='24'
                                showIcon
                                value={dateEnd}
                                onChange={(e) => setDateEnd(e.value)}
                                showOnFocus={user?.role !== "employer"}
                            />

                            <h5 className='mt-3'>Минимальная дата срабатывания</h5>
                            <Calendar
                                className={`delay-calendar ${user?.role === "employer" ? "disabled-btn" : ""} w-full`}
                                showTime
                                dateFormat='dd/mm/yy'
                                minDate={todayDate}
                                maxDate={dateEnd}
                                hourFormat='24'
                                showIcon
                                value={minDate}
                                onChange={(e) => setMinDate(e.value)}
                                showOnFocus={user?.role !== "employer"}
                            />
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>

            <SidebarFooter saveHandler={() => saveHandler()} disabled={!dateEnd || !minDate} onHide={() => onHide()} />
        </div>
    )
}

export default CreateInterval
