import { Button } from "primereact/button"
import { useDispatch } from "react-redux"
import { setVisited } from "../../service/slices/authSlice"

export const Video = () => {
    const dispatch = useDispatch()
    const closeHandler = () => {
        dispatch(setVisited(true))
    }

    function openFullscreen() {
        const elem = document.getElementById("myvideo");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    return <div className="w-full xl:w-9">
        <div className="video-container">
            <div className="buttons">
                <Button
                    label="Закрыть"
                    className="btn-skip"
                    onClick={closeHandler}
                />
            </div>
            <div className="text-block">
                <p className="text-onboard">Короткое видео после которого <br /> все станет максимально понятно!</p>
            </div>

            <img id="myvideo" src="assets/layout/images/video-img.png" alt="" />
            <img className="btn-play" src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/play_arrow.svg`} alt="" />
            {/*  <video id="myvideo" className="video-onboard">
                <source src="https://www.w3schools.com/html/movie.mp4" type="video/mp4" />
                <img className="btn-play" src="assets/layout/images/play_arrow.svg" alt="" onClick={openFullscreen} />
            </video> */}
        </div>

        {/*  <div className="video-onboard">
            <img id="myvideo" src="assets/layout/images/video-img.jpg" alt="" />
            <img className="btn-play" src="assets/layout/images/play_arrow.svg" alt="" onClick={openFullscreen} />
        </div> */}

        {/*  <h1 className="title-onboard">
            Сделали для вас запись</h1>
        <p className="text-onboard">
            Короткое видео после которого все станет максимально понятно!
        </p>
        <div className="buttons">
            <Button
                label="Закрыть"
                className="btn-close"
                onClick={closeHandler}
            />
        </div> */}
    </div>
}