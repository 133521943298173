import { createSlice } from '@reduxjs/toolkit'


export const newUsersSlice = createSlice({
    name: 'newUsers',
    initialState: {
        users: 0
    },
    reducers: {
        setNewUsers: (state, action) => {
            state.users = action.payload
        },

    }
})

export const { setNewUsers } = newUsersSlice.actions
export default newUsersSlice.reducer
