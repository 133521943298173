import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Editor } from "primereact/editor"
import { InputText } from "primereact/inputtext"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"
import { createNode, deleteMedia, getFunnel, updateNode } from "../../../service/funnels"

import { setInitNodes } from "../../../service/slices/initNodesSlice"
import SidebarFooter from "./components/SidebarFooter"
import SidebarHeader from "./components/SidebarHeader"
import UploadFiles from "./components/UploadFiles"
import "./Sidebar.scss"

const CreateFiles = ({ data, onHide, funnelId, centerCoordinates }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)
    const [nodeID, setNodeID] = useState("")
    const [blockTitle, setBlockTitle] = useState(data?.title || "")
    const [blockText, setBlockText] = useState(data?.text)

    const [files, setFiles] = useState(data?.files)
    const [deletedFile, setDeletedFile] = useState([])

    useEffect(() => {
        const node_id = uuid()
        setNodeID(node_id)
    }, [])

    // Файлы
    const deleteMediaHandler = (file) => {
        /*  deleteMedia({ fileObject: "node", nodeID: data?.id, filename: file?.path }) */
        const deletedMedia = files?.filter((fileItem) => fileItem.path !== file?.path)
        setFiles(deletedMedia)
    }

    // Добавление нового блока

    const saveHandler = () => {
        const textBlock = {
            blueprintID: funnelId,
            title: blockTitle,
            text: blockText,
            files: files,
            messenger: "Telegram",
            data: { title: blockTitle, text: blockText, files: files, blueprintID: funnelId },
        }
        // setNodes((nds) => nds.concat(textBlock, btn))
        // setNodes((nds) => nds.concat(textBlock))

        if (data) {
            const updatedNode = { ...textBlock, uuid: data?.id, files, messageType: data?.messageType }
            updateNode(updatedNode, "file").then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes))))
        } else {
            const createFileBlock = {
                ...textBlock,
                uuid: nodeID,
                position: centerCoordinates,
            }
            createNode(createFileBlock, "file").then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes))))
        }

        deletedFile.map((item) => deleteMedia({ fileObject: "node", nodeID: data?.id, filename: item?.path }))

        onHide()
        setBlockTitle("")
        setBlockText("")
    }

    const hideHandler = () => {
        onHide()
        const dataId = data?.files.map((item) => item.path)
        console.log(dataId)
        const uploadedFiles = files?.filter((item) => !dataId.includes(item.path))
        console.log(uploadedFiles)
        uploadedFiles?.map((item) => deleteMedia({ fileObject: "node", nodeID: data?.id, filename: item?.path }))

        if (deletedFile?.length !== 0) {
            deletedFile?.map((item) => {
                if (!dataId.includes(item.path)) {
                    deleteMedia({ fileObject: "node", nodeID: data?.id, filename: item?.path })
                }
            })
        }

    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Файлы' icon={"files"} onHide={hideHandler} />
            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={[0, 1]}>
                    <AccordionTab header='Контент' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === 'employer'}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                        {/* <div className='field'>
                            <Editor
                                headerTemplate={header}
                                value={blockText}
                                onTextChange={(e) => setBlockText(e.htmlValue?.replaceAll("style", "_style"))}
                                placeholder='Текст блока'
                            />
                        </div> */}
                    </AccordionTab>
                    <AccordionTab header='Файлы' headerClassName='accordion-header'>
                        <div className='attach mb-3'>
                            <div className='mb-4'>
                                <p>Вы можете добавить документ, изображение, видео или звуковой файл.</p>
                            </div>
                            <div className='w-full mb-4'>
                                <UploadFiles
                                    nodeID={data?.id ? data.id : nodeID}
                                    setFiles={setFiles}
                                    files={files}
                                    data={data}
                                    multiple={true}
                                    uploadText={"Загрузить файл"}
                                    deleteMediaHandler={deleteMediaHandler}
                                    deletedFile={deletedFile}
                                    setDeletedFile={setDeletedFile}
                                />
                            </div>
                            {/* <div>
                                <p>
                                    С текстом будет отправлено только изображение, остальные файлы будут отправленны отдельными сообщениями.
                                </p>
                            </div> */}
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>

            <SidebarFooter saveHandler={() => saveHandler()} onHide={hideHandler} />
        </div>
    )
}

export default CreateFiles
