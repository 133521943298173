import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const Action = ({ id, onChangeActions, variables, data }) => {
    const [type, setType] = useState("")
    const [option2, setOption2] = useState(data?.variableID)
    const [selectedVariable, setSelectedVariable] = useState("")
    const [action, setAction] = useState(data?.action || "")
    const [argument, setArgument] = useState(data?.argument || "")
    const user = useSelector((state) => state.user.user)
    useEffect(() => {
        onChangeActions(id, type, option2, action, argument)
    }, [type, option2, action, argument])

    useEffect(() => {
        const selectedVar = variables?.filter((variable) => variable.variableID === option2)
        if (selectedVar) {
            setSelectedVariable(selectedVar[0])
        }
    }, [variables])

    // console.log(variables)

    useEffect(() => {
        const actionType = getActionType(data)
        setType(actionType)
    }, [data])

    useEffect(() => {
        const selectedVar = variables?.filter((variable) => variable.variableID === option2)
        if (selectedVar) {
            setSelectedVariable(selectedVar[0])
        }
    }, [option2])

    const getActionType = (data) => {
        switch (data?.action) {
            case "varSetNum":
            case "varSum":
            case "varSub":
            case "varMul":
            case "varDiv":
            case "varSetString":
            case "varConcatString":
            case "varSetBool":
                return "variable"

            case "tagAdd":
            case "tagDelete":
                return "tag"

            default:
                return ""
        }
    }

    const options = [
        { label: "Тег", value: "tag" },
        { label: "Переменная", value: "variable" },
    ]
    const mails = [
        { label: "Туда", value: "there" },
        { label: "Сюда", value: "here" },
    ]

    const tagsOptions = [
        { label: "Присвоить тег", value: "tagAdd" },
        { label: "Удалить тег", value: "tagDelete" },
    ]

    const variablesBoolean = [{ label: "Установить значение", value: "varSetBool" }]
    const variablesString = [
        { label: "Установить значение", value: "varSetString" },
        { label: "Объединить со строкой", value: "varConcatString" },
    ]
    const variablesNumber = [
        { label: "Установить значение", value: "varSetNum" },
        { label: "Сложить", value: "varSum" },
        { label: "Вычесть", value: "varSub" },
        { label: "Умножить", value: "varMul" },
        { label: "Разделить", value: "varDiv" },
    ]
    const booleanOptions = [
        { label: "Да", value: "true" },
        { label: "Нет", value: "false" },
    ]

    return (
        <div>
            <Dropdown
                className='select w-full mb-3'
                value={type}
                options={options}
                onChange={(e) => {
                    setType(e.value)
                    setOption2(null)
                    setArgument("")
                }}
                placeholder='Выбрать действие'
                disabled={user?.role === "employer"}
            />
            {type === "tag" && (
                <>
                    <Dropdown
                        className='select w-full mb-3'
                        value={action}
                        options={tagsOptions}
                        onChange={(e) => setAction(e.value)}
                        placeholder='Действие с тегом'
                        disabled={user?.role === "employer"}
                    />
                    {action === "tagAdd" && (
                        <InputText
                            className='text w-full mb-3'
                            value={argument}
                            onChange={(e) => setArgument(e.target.value)}
                            placeholder='Введите тэг'
                            readOnly={user?.role === "employer"}
                        />
                    )}
                    {action === "tagDelete" && (
                        <InputText
                            className='text w-full mb-3'
                            value={argument}
                            onChange={(e) => setArgument(e.target.value)}
                            placeholder='Введите тэг'
                            readOnly={user?.role === "employer"}
                        />
                    )}
                </>
            )}
            {/* {type === "mail" && (
                <Dropdown
                    className='select w-full mb-3'
                    value={option2}
                    options={mails}
                    onChange={(e) => setOption2(e.value)}
                    placeholder='Куда'
                    disabled={user?.role === 'employer'}
                />
            )} */}
            {type === "variable" && (
                <>
                    <Dropdown
                        className='select w-full mb-3'
                        optionLabel='name'
                        value={option2}
                        options={variables}
                        onChange={(e) => {
                            setArgument(null)
                            setOption2(e.value)
                        }}
                        placeholder='Выберите переменную'
                        disabled={user?.role === "employer"}
                    />
                    {selectedVariable?.type === "number" && (
                        <>
                            <Dropdown
                                className='select w-full mb-3'
                                value={action}
                                options={variablesNumber}
                                onChange={(e) => setAction(e.value)}
                                placeholder='Действие'
                                disabled={user?.role === "employer"}
                            />
                            <InputText
                                keyfilter='num'
                                className='text w-full mb-3'
                                value={argument}
                                onChange={(e) => setArgument(+e.target.value)}
                                placeholder='Введите значение'
                                readOnly={user?.role === "employer"}
                            />
                        </>
                    )}
                    {selectedVariable?.type === "string" && (
                        <>
                            <Dropdown
                                className='select w-full mb-3'
                                value={action}
                                options={variablesString}
                                onChange={(e) => setAction(e.value)}
                                placeholder='Действие'
                                disabled={user?.role === "employer"}
                            />
                            <InputText
                                className='text w-full mb-3'
                                value={argument}
                                onChange={(e) => setArgument(e.target.value)}
                                placeholder='Введите значение'
                                readOnly={user?.role === "employer"}
                            />
                        </>
                    )}
                    {selectedVariable?.type === "boolean" && (
                        <>
                            <Dropdown
                                className='select w-full mb-3'
                                value={action}
                                options={variablesBoolean}
                                onChange={(e) => setAction(e.value)}
                                placeholder='Действие'
                                disabled={user?.role === "employer"}
                            />
                            <Dropdown
                                className='select w-full mb-3'
                                value={argument}
                                options={booleanOptions}
                                onChange={(e) => setArgument(e.value)}
                                placeholder='Значение'
                                disabled={user?.role === "employer"}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default Action
