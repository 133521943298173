import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import apiClient from '../api'

const server = "https://bots.sdew.ru/cl"


export const getAllAnswers = createAsyncThunk(
    'smartAnswers/getAllAnswers',
    async (data, { rejectWithValue, getState }) => {
        const onlyEnabled = getState().smartAnswers.onlyEnabled

        try {
            const response = await apiClient.get(`cl/smart-answer/list?page=${data.currentPage}&size=${data.basicRows}&onlyEnabled=${onlyEnabled}`)
            if (response.status !== 200) {
                throw new Error('Ошибка получения списка ответов');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getOneAnswer = createAsyncThunk(
    'smartAnswers/getOneAnswer',
    async (answerID, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(`cl/smart-answer/one?answerID=${answerID}`).then((res) => res)

            if (response.status !== 200) {
                throw new Error('Ошибка получения ответа');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const createSmartAnswer = createAsyncThunk(
    'smartAnswers/createSmartAnswer',
    async function (dataAnswer, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.post(`cl/smart-answer/create`, dataAnswer);
            if (response.status !== 201) {
                throw new Error('Ошибка при добавлении нового ответа');
            }
            /*  dispatch(getAllAnswers(0)); */
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateSmartAnswer = createAsyncThunk(
    'smartAnswers/updateSmartAnswer',
    async function (updatedData, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.put(`cl/smart-answer/update`, updatedData)
            if (response.status !== 200) {
                throw new Error('Ошибка при изменении ответа');
            }
            dispatch(updateAnswer(updatedData));
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteSmartAnswer = createAsyncThunk(
    'smartAnswers/deleteSmartAnswer',
    async function (answerID, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.delete(`cl/smart-answer/delete`, { data: { answerID: answerID } })
            if (response.status !== 200) {
                throw new Error('Ошибка при удалении ответа');
            }
            /*   dispatch(deleteAnswer(answerID)); */
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const addTagAnswer = createAsyncThunk(
    'smartAnswers/addTagAnswer',
    async function (dataTag, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.put(`cl/smart-answer/add-tag`, dataTag)
            if (response.status !== 200) {
                throw new Error('Ошибка при добавлении тега');
            }
            dispatch(addTags(dataTag));
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteTagAnswer = createAsyncThunk(
    'smartAnswers/deleteTagAnswer',
    async function (dataTag, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.delete(`cl/smart-answer/delete-tag`, { data: dataTag })
            if (response.status !== 200) {
                throw new Error('Ошибка при удалении тега');
            }
            dispatch(deleteTags(dataTag));
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const enableAnswer = createAsyncThunk(
    'smartAnswers/enableAnswer',
    async function (answerID, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.put(`cl/smart-answer/enable`, { answerID: answerID })
            if (response.status !== 200) {
                throw new Error('Ошибка при удалении тега');
            }
            dispatch(changeEnable(answerID));
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const disableAnswer = createAsyncThunk(
    'smartAnswers/disableAnswer',
    async function (answerID, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.put(`cl/smart-answer/disable`, { answerID: answerID })
            if (response.status !== 200) {
                throw new Error('Ошибка при удалении тега');
            }
            dispatch(changeEnable(answerID));
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const enableAllAnswers = createAsyncThunk(
    'smartAnswers/enableAllAnswers',
    async function (_, { rejectWithValue }) {
        try {
            const response = await apiClient.put(`cl/smart-answer/enable-all`)
            if (response.status !== 200) {
                throw new Error('Ошибка фильтрации ответов');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const disableAllAnswers = createAsyncThunk(
    'smartAnswers/disableAllAnswers',
    async function (_, { rejectWithValue }) {
        try {
            const response = await apiClient.put(`cl/smart-answer/disable-all`)
            if (response.status !== 200) {
                throw new Error('Ошибка фильтрации ответов');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const coverageMessangersAnsvers = createAsyncThunk(
    'smartAnswers/coverageMessangersAnsvers',
    async function (date, { rejectWithValue, dispatch }) {
        try {
            const response = await apiClient.get(`cl/smart-answer/coverage-messenger?start=${date.start}&end=${date.end}`).then((res) => res)
            if (response.status !== 200) {
                throw new Error('Ошибка получения списка вызовов умных ответов по мессенджерам');
            }
            dispatch(setMessangers(response.data))
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const smartAnswersSlice = createSlice({
    name: 'smartAnswers',
    initialState: {
        answers: [],
        totalPages: null,
        answer: {},
        error: null,
        onlyEnabled: false,
        messangers: []
    },
    reducers: {
        setRows: (state, action) => {
            state.rows = action.payload
        },
        addSmartAnswer: (state, action) => {
            state.answers.push(action.payload)
        },
        updateAnswer: (state, action) => {
            const updatedAnswer = state.answers.findIndex(answer => answer.answerID === action.payload.answerID);
            Object.keys(action.payload).forEach((item) => {
                state.answers[updatedAnswer][item] = action.payload[item]
            })
        },
        deleteAnswer: (state, action) => {
            state.answers = state.answers.filter((answer) => answer.answerID !== action.payload);
        },
        addTags: (state, action) => {
            const updatedAnswer = state.answers.findIndex(answer => answer.answerID === action.payload.answerID);
            if (state.answers[updatedAnswer].tags) {
                state.answers[updatedAnswer].tags.push(action.payload.tag)
                state.answer.tags.push(action.payload.tag)
            } else {
                state.answers[updatedAnswer].tags = [action.payload.tag]
                state.answer.tags = [action.payload.tag]
            }

        },
        deleteTags: (state, action) => {
            const updatedAnswer = state.answers.findIndex(answer => answer.answerID === action.payload.answerID);
            state.answers[updatedAnswer].tags = state.answers[updatedAnswer].tags.filter((tag) => tag !== action.payload.tag)
            state.answer.tags = state.answer.tags.filter((tag) => tag !== action.payload.tag)
        },
        changeEnable: (state, action) => {
            const updatedAnswer = state.answers.findIndex(answer => answer.answerID === action.payload);
            state.answers[updatedAnswer].enabled = !state.answers[updatedAnswer].enabled
            state.answer.enabled = !state.answer.enabled
        },
        setOnlyEnabled: (state) => {
            state.onlyEnabled = !state.onlyEnabled
        },
        setMessangers: (state, action) => {
            state.messangers = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getAllAnswers.pending, state => {
                state.error = null
            })
            .addCase(getAllAnswers.fulfilled, (state, action) => {
                state.error = null;
                state.answers = action.payload.content
                state.totalPages = action.payload.totalPages
            })
            .addCase(getAllAnswers.rejected, (state, action) => {
                state.error = action.payload
            })

            .addCase(getOneAnswer.pending, state => {
                state.error = null
            })
            .addCase(getOneAnswer.fulfilled, (state, action) => {
                state.error = null;
                state.answer = action.payload
            })
            .addCase(getOneAnswer.rejected, (state, action) => {
                state.error = action.payload
            })

            .addCase(createSmartAnswer.pending, state => {
                state.error = null
            })
            .addCase(createSmartAnswer.rejected, (state, action) => {
                state.error = action.payload
            })

            .addCase(updateSmartAnswer.pending, state => {
                state.error = null
            })
            .addCase(updateSmartAnswer.rejected, (state, action) => {
                state.error = action.payload
            })

            .addCase(deleteSmartAnswer.pending, state => {
                state.error = null
            })
            .addCase(deleteSmartAnswer.rejected, (state, action) => {
                state.error = action.payload
            })

            .addCase(addTagAnswer.pending, state => {
                state.error = null
            })
            .addCase(addTagAnswer.rejected, (state, action) => {
                state.error = action.payload
            })

            .addCase(deleteTagAnswer.pending, state => {
                state.error = null
            })
            .addCase(deleteTagAnswer.rejected, (state, action) => {
                state.error = action.payload
            })

            .addCase(disableAllAnswers.pending, state => {
                state.error = null
            })
            .addCase(disableAllAnswers.rejected, (state, action) => {
                state.error = action.payload
            })

            .addCase(enableAllAnswers.pending, state => {
                state.error = null
            })
            .addCase(enableAllAnswers.rejected, (state, action) => {
                state.error = action.payload
            })

            .addCase(coverageMessangersAnsvers.pending, state => {
                state.error = null
            })
            .addCase(coverageMessangersAnsvers.rejected, (state, action) => {
                state.error = action.payload
            })
    }
})

export const { addTags, deleteTags, addSmartAnswer, updateAnswer, deleteAnswer, changeEnable, setOnlyEnabled, setMessangers, setRows } = smartAnswersSlice.actions
export default smartAnswersSlice.reducer
