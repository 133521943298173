import Cookies from "js-cookie";
import { useSelector } from "react-redux"

export const CardAuth = ({ children }) => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    let auth = Cookies.get('auth');
    return (
        <div className={`flex ${auth ? 'calc-height' : 'h-screen'} justify-content-center lg:justify-content-end`}>
            <div className={`card-auth ${colorTheme} my-auto mx-3 sm:mx-auto lg:mx-0`}>
                <img
                    className='logo'
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/bots.${colorTheme}.svg`} />
                {children}
            </div>
        </div>

    )
}