import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import apiClient from '../api'

export const getCompany = createAsyncThunk(
    'auth/getCompany',
    async () => {
        const response = await apiClient.get(`gt/company/profile`)
        console.log(response);
        return response.data
    }
)

export const getListCompaniesUser = createAsyncThunk(
    'auth/getListCompaniesUser',
    async () => {
        const response = await apiClient.get(`gt/company/owned-user`)
        console.log(response);
        return response.data
    }
)

export const getEmployees = createAsyncThunk(
    'auth/getEmployees',
    async () => {
        const response = await apiClient.get(`gt/company/users`)
        console.log(response);
        return response.data
    }
)

export const changeActiveCompany = createAsyncThunk(
    'smartAnswers/changeActiveCompany',
    async function (companyId, { rejectWithValue }) {
        try {
            const response = await apiClient.put(`gt/company/set-active`, companyId)
            if (response.status !== 200) {
                throw new Error('Ошибка при переключении компании');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        company: null,
        employees: [],
        error: null,
        listCompanies: [],
        selectedCompany: null
    },
    reducers: {
        setSelectedCompany: (state, action) => {
            state.rows = action.payload
        },
    },
    extraReducers(builder) {
        builder.addCase(getCompany.fulfilled, (state, action) => {
            state.company = action.payload
            state.error = null
        })
            .addCase(getCompany.rejected, (state, action) => {
                state.error = action.payload
            });
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            state.employees = action.payload
            state.error = null
        })
            .addCase(getEmployees.rejected, (state, action) => {
                state.error = action.payload
            });
        builder.addCase(getListCompaniesUser.fulfilled, (state, action) => {
            state.listCompanies = action.payload
            state.selectedCompany = action.payload.find((item) => item.selected)
            state.error = null
        })
            .addCase(getListCompaniesUser.rejected, (state, action) => {
                state.error = action.payload
            });
    }
})

export const { setSelectedCompany } = companySlice.actions
export default companySlice.reducer
