import { Sidebar } from "primereact/sidebar"
import React, { memo, useState } from "react"
import { useParams } from "react-router-dom"
import CreateComment from "../Sidebars/CreateComment"
import "./Blocks.scss"

export default memo((props) => {
    const params = useParams()
    const funnelId = +params?.id

    const data = props?.data
    data.id = props?.id
    data.type = props?.type
    data.position = { x: props.xPos, y: props.yPos }

    const [visibleCommentSidebar, setVisibleCommentSidebar] = useState(false)

    return (
        <>
            <div className='CommentBlock' onDoubleClick={() => setVisibleCommentSidebar(true)}>
                <p>{data?.text}</p>
            </div>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleCommentSidebar}
                onHide={() => setVisibleCommentSidebar(false)}
                position='right'
            >
                <CreateComment onHide={() => setVisibleCommentSidebar(false)} data={data} funnelId={funnelId} />
            </Sidebar>
        </>
    )
})
