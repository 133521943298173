import { Badge } from 'primereact/badge';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getData } from '../../../service/getData';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'
import { ScrollPanel } from 'primereact/scrollpanel';

export const Events = () => {
    const [events, setEvents] = useState([
        { title: 'Отказ от оплаты', amount: 4 },
        { title: 'Отказ от оплаты', amount: 4 },
        { title: 'Отказ от оплаты', amount: 4 },
        { title: 'Отказ от оплаты', amount: 4 },
        { title: 'Отказ от оплаты', amount: 4 },
        { title: 'Отказ от оплаты', amount: 4 },
        { title: 'Отказ от оплаты', amount: 4 },
        { title: 'Отказ от оплаты', amount: 4 }])
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const tagsTemlate = (rowData) => {
        return (
            <div className='tag-event'></div>
        )
    }

    return (
        <div className="card p-0">
            <div className='events-title_block'>
                <h5 className='title-card_statistics'>События</h5>
            </div>

            <DataTable className='table-events' /* scrollable scrollHeight="370px"  */ value={events} responsiveLayout="scroll">
                <Column field="title" header="Название события"></Column>
                <Column body={tagsTemlate} header="Теги"></Column>
                <Column field="amount" header="Количество"></Column>
            </DataTable>


        </div>
    )
}