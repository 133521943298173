import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { log } from "../../../service/log";
import { getEmployees } from "../../../service/slices/companySlice";
import { updatePositionUser } from "../../../service/slices/userSlice";

export const EditEmployee = ({ visible, onHide, value, toast }) => {
    const rights = [
        { name: 'Администратор', code: 'admin' },
        { name: 'Модератор', code: 'moderator' },
        { name: 'Пользователь', code: 'employer' },
    ];
    const [name, setName] = useState(value.name)
    const [surname, setSurname] = useState(value.surname)
    const [position, setPosition] = useState(value.position)
    const [gender, setGender] = useState(null)
    const [mail, setMail] = useState(value.email)
    const [selectedRights, setSelectedRights] = useState(rights.find((item) => item.code === value?.role));
    const [widthScreen, setWidthScreen] = useState(window.innerWidth)
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const [options, setOptions] = useState(null)

    const dispatch = useDispatch()
    useEffect(() => {
        window.addEventListener("resize", () => setWidthScreen(window.innerWidth));

        return () => {
            window.removeEventListener("resize", () => setWidthScreen(window.innerWidth));
        };
    }, [])

    useEffect(() => {

        if (widthScreen > 500) {
            setOptions(['Мужской', 'Женский'])
            setGender('Мужской')
        } else {
            setOptions(['Муж', 'Жен'])
            setGender('Муж')
        }


    }, [widthScreen])



    const renderFooter = () => {
        return (
            <>
                <div className="flex justify-content-between">
                    <Button label="Отменить" onClick={() => onHide(false)} className='btn-main py-3 px-4 mr-3' />
                    <Button label="Сохранить" onClick={() => updateHandler(value.id)} autoFocus className="btn-save py-3 px-4" />
                </div>
            </>

        );
    }


    const updateHandler = (id) => {
        onHide(false)

        const updatedData = {
            position,
            email: mail,
            role: selectedRights.code
        }
        log('updatedData', updatedData)
        dispatch(updatePositionUser(updatedData))
            .then(() => dispatch(getEmployees()))
        toast.current.show({ severity: 'success', summary: 'Изменено', detail: 'Персональная информация сотрудника измененa', life: 3000 });
    }

    return (
        <>
            <Dialog className={`personal-setting w-12 sm:w-11 md:w-8 lg:w-6 xl:w-4 ${colorTheme}`} header="Персональная информация сотрудника" visible={visible} footer={renderFooter()} onHide={() => onHide(false)}>
                <div className="grid justify-content-start mt-3">
                    {/*           <div className="col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" id="username" value={name} onChange={(e) => setName(e.target.value)} />
                            <label htmlFor="username">Имя</label>
                        </span>
                    </div>
                    <div className="col-6">
                        <span className="p-float-label">
                            <InputText className="w-full" id="username" value={surname} onChange={(e) => setSurname(e.target.value)} />
                            <label htmlFor="username">Фамилия</label>
                        </span>
                    </div> */}

                    <div className="col-6">
                        <span className="p-float-label">
                            <InputText className="w-full input-position" id="username" value={position} onChange={(e) => setPosition(e.target.value)} />
                            <label htmlFor="username">Должность</label>
                        </span>
                    </div>

                    {/*  <div className="col-6">
                        <label className="label" htmlFor="username">Пол</label>
                        <SelectButton className={`gender ${colorTheme}`} value={gender} options={options} onChange={(e) => setGender(e.value)} />
                    </div>

                    <div className="col-6 mt-4">
                        <span className="p-float-label">
                            <InputText className="w-full input-mail" id="username" value={mail} onChange={(e) => setMail(e.target.value)} />
                            <label htmlFor="username">Почта</label>
                        </span>
                    </div> */}

                    <div className="col-12">
                        {selectedRights && <label className="label" htmlFor="username">Права</label>}
                        <Dropdown className="w-full select-rights flex align-items-center" value={selectedRights} options={rights} onChange={(e) => setSelectedRights(e.value)} optionLabel="name" placeholder="Права" />
                    </div>

                </div>
            </Dialog>
        </>

    )
}