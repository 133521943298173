import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { AddPhone } from "./AddPhone";
import { useDispatch, useSelector } from "react-redux";
import { log } from "../../../service/log";
import axios from "axios";
import { getCompany } from "../../../service/slices/companySlice";
import PhoneInput from "react-phone-number-input";

export const EditBasicInformation = ({ visible, onHide, value, toast }) => {
    const company = useSelector((state) => state.company.company)
    const [titleCompany, setTitleCompany] = useState(company?.title)
    const [website, setWebSite] = useState(value?.website)
    const [proxyServer, setProxyServer] = useState(value?.access?.proxy_server)
    const [email, setEmail] = useState(value?.email)
    const [location, setLocation] = useState(value?.location)
    const [city, setCity] = useState(value?.location?.city)
    const [street, setStreet] = useState(value?.location?.street)
    const [selectTimeZone, setSelectTimeZone] = useState({ name: value?.location?.timeZone, code: value?.location?.timeZone })
    const [phone, setPhone] = useState(value?.phone)

    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const dispatch = useDispatch()

    const renderFooter = () => {
        return (
            <>
                <div className="flex justify-content-between">
                    <Button label="Отменить" onClick={() => onHide(false)} className='btn-main p-button-text py-3 px-4 mr-3' />
                    <Button label="Сохранить" onClick={updateHandler} autoFocus className="btn-save py-3 px-4" />
                </div>
            </>

        );
    }

    console.log(company)

    const timeZone = [
        { name: 'UTC-12.00', code: 'UTC-12.00' },
        { name: 'UTC-11.00', code: 'UTC-11.00' },
        { name: 'UTC-10.00', code: 'UTC-10.00' },
        { name: 'UTC-09.00', code: 'UTC-09.00' },
        { name: 'UTC-08.00', code: 'UTC-08.00' },
        { name: 'UTC-07.00', code: 'UTC-07.00' },
        { name: 'UTC-06.00', code: 'UTC-06.00' },
        { name: 'UTC-05.00', code: 'UTC-05.00' },
        { name: 'UTC-04.00', code: 'UTC-04.00' },
        { name: 'UTC-03.00', code: 'UTC-03.00' },
        { name: 'UTC-02.00', code: 'UTC-02.00' },
        { name: 'UTC-01.00', code: 'UTC-01.00' },
        { name: 'UTC+00.00', code: 'UTC+00.00' },
        { name: 'UTC+01.00', code: 'UTC+01.00' },
        { name: 'UTC+02.00', code: 'UTC+02.00' },
        { name: 'UTC+03.00', code: 'UTC+03.00' },
        { name: 'UTC+04.00', code: 'UTC+04.00' },
        { name: 'UTC+05.00', code: 'UTC+05.00' },
        { name: 'UTC+06.00', code: 'UTC+06.00' },
        { name: 'UTC+07.00', code: 'UTC+07.00' },
        { name: 'UTC+08.00', code: 'UTC+08.00' },
        { name: 'UTC+09.00', code: 'UTC+09.00' },
        { name: 'UTC+10.00', code: 'UTC+10.00' },
        { name: 'UTC+11.00', code: 'UTC+11.00' },
        { name: 'UTC+12.00', code: 'UTC+12.00' },
        { name: 'UTC+13.00', code: 'UTC+13.00' },
        { name: 'UTC+14.00', code: 'UTC+14.00' }
    ];

    const addPhoneHandler = () => {
        setPhone([...phone, ''])
    }

    const updateHandler = (id) => {
        onHide(false)

        const updatedData = {
            website,
            email,
            location,
            timeZone: selectTimeZone.name,
            phone
        }
        log('updatedData', updatedData)
        axios
            .put(`https://bots.sdew.ru/gt/company/update-profile`, updatedData)
            .then((response) => {
                console.log(response);
                dispatch(getCompany())
            })
            .catch((error) => console.error(error))
        toast.current.show({ severity: 'success', summary: 'Изменено', detail: 'Основная информация изменена', life: 3000 });
    }

    console.log(selectTimeZone)

    return (
        <Dialog className={`personal-setting w-12 sm:w-11 md:w-8 lg:w-6 xl:w-4 ${colorTheme}`} header="Изменение основной информации" visible={visible} footer={renderFooter()} onHide={() => onHide(false)}>
            <div className="col-12 p-0">
                <span className="p-float-label">
                    <InputText className="w-full" id="username" value={titleCompany} onChange={(e) => setTitleCompany(e.target.value)} />
                    <label htmlFor="username">Название компании*</label>
                </span>
            </div>
            <h6>Доступ</h6>
            <div className="grid justify-content-start mt-5">
                <div className="col-12">
                    <span className="p-float-label pr-2">
                        <InputText className="w-6" id="username" value={website} onChange={(e) => setWebSite(e.target.value)} />
                        <label htmlFor="username">Веб-сайт*</label>
                    </span>
                </div>
                {/*  <div className="col-6">
                    <span className="p-float-label">
                        <InputText className="w-full" id="username" value={proxyServer} onChange={(e) => setProxyServer(e.target.value)} />
                        <label htmlFor="username">Прокси сервер</label>
                    </span>
                </div> */}
                <div className="col-6 mt-3">
                    <span className="p-float-label">
                        <InputText className="w-full" id="username" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <label htmlFor="username">Email*</label>
                    </span>
                </div>
            </div>
            <h6>Местонахождение</h6>
            <div className="grid justify-content-start mt-5">
                <div className="col-12">
                    <span className="p-float-label">
                        <InputText className="w-full" id="username" value={location} onChange={(e) => setLocation(e.target.value)} />
                        <label htmlFor="username">Местонахождение*</label>
                    </span>
                </div>
                {/*          <div className="col-6">
                    <span className="p-float-label">
                        <InputText className="w-full" id="username" value={city} onChange={(e) => setCity(e.target.value)} />
                        <label htmlFor="username">Город</label>
                    </span>
                </div>
                <div className="col-6 mt-4">
                    <span className="p-float-label">
                        <InputText className="w-full" id="username" value={street} onChange={(e) => setStreet(e.target.value)} />
                        <label htmlFor="username">Улица, дом</label>
                    </span>
                </div> */}
                <div className={selectTimeZone ? "col-6 mt-1" : "col-6 mt-4"}>
                    {selectTimeZone.name && <label className="label" htmlFor="username">Часовой пояс</label>}
                    <Dropdown className={`${selectTimeZone.name ? 'mt-1' : 'mt-0'} w-full select-rights flex align-items-center`} value={selectTimeZone} options={timeZone} onChange={(e) => setSelectTimeZone(e.value)} optionLabel="name" placeholder="Часовой пояс" />
                </div>
            </div>
            <h6>Телефон</h6>
            <div className="grid justify-content-start align-items-end">
                <div className="col-6">
                    <div className="mt-4">
                        <span className="p-float-label">
                            <PhoneInput className="add_phone w-full" value={phone} onChange={setPhone} />
                            {/* <label htmlFor="username">Телефон</label> */}

                        </span>
                    </div>

                </div>
                {/*   <div className="col-6">
                    <Button label="Добавить" onClick={addPhoneHandler} className={`btn-add_phone ${colorTheme} w-full p-button-text py-3 px-4 mr-3`} />
                </div> */}
            </div>
        </Dialog>
    )
}