import React, { memo } from "react"
import { Handle } from "react-flow-renderer"
import "./Blocks.scss"

export default memo(({ data }) => {
    return (
        <div className='ButtonBlock'>
            {!data?.url && (
                <Handle
                    id={data?.id}
                    type='source'
                    className='custom-handle'
                    position='right'
                    isConnectable={true}
                    onConnect={(params) => console.log("handle onConnect", params)}
                />
            )}
            <p
                dangerouslySetInnerHTML={{
                    __html: data?.label,
                }}
            ></p>
        </div>
    )
})
