import { Skeleton } from "primereact/skeleton"

export const SkeletonDate = () => {
    return (

        <div className='w-full h-full card-celect_date justify-content-start'>
            <Skeleton className="mb-5" width="60%" height="2rem" />
            <Skeleton className="mb-5" width="100%" height="2rem" />
            <Skeleton width="10rem" height="2rem" />

        </div>

    )
}