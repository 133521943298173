import { Button } from "primereact/button"
import { Sidebar } from "primereact/sidebar"
import React, { memo, useEffect, useState } from "react"
import { Handle } from "react-flow-renderer"
import { useParams } from "react-router-dom"
import { getVariables } from "../../../service/funnels"
import CreateAction from "../Sidebars/CreateAction/CreateAction"
import { slicedText } from "../utils"
import "./Blocks.scss"
import { useSelector } from "react-redux"

export default memo((props) => {
    const params = useParams()
    const funnelId = +params?.id

    const data = props.data
    data.id = props.id
    data.type = props.type
    data.position = { x: props.xPos, y: props.yPos }

    const [variable, setVariable] = useState(null)
    const user = useSelector((state) => state.user.user)
    useEffect(() => {
        getVariables(funnelId).then((res) => {
            // console.log("RES: ", res)
            const variablesFormated = []
            res?.map((variable) => {
                const variableFormated = {
                    variableID: variable.variableID,
                    type: variable.type,
                    name: variable.name,
                    value: variable.variableID,
                    // value: { type: variable.type, id: variable.id },
                    initialValue: variable.initialValue,
                }
                variablesFormated.push(variableFormated)
            })
            setVariable(variablesFormated.find((variable) => variable.variableID === data?.variableID))
        })
    }, [data])
    // console.log("variable: ", variable)

    const getActionType = (data) => {
        switch (data?.action) {
            case "varSetString":
                return "Установить значение"
            case "varConcatString":
                return "Объединить со строкой"

            case "tagAdd":
                return "Присвоить тег"
            case "tagDelete":
                return "Удалить тег"

            case "varSetNum":
                return "Установить значение"
            case "varSum":
                return "Сложить"
            case "varSub":
                return "Вычесть"
            case "varMul":
                return "Умножить"
            case "varDiv":
                return "Разделить"

            case "varSetBool":
                return "Установить значение"

            default:
                return ""
        }
    }

    const [visibleSidebar, setVisibleSidebar] = useState(false)
    return (
        <>
            <div className='ActionBlock' onDoubleClick={() => setVisibleSidebar(true)}>
                <Handle id='l' type='target' className='custom-handle' position='left' isConnectable={true} />
                <div className='node-block'>
                    <div className='header'>
                        <h3>{slicedText(data?.title, 13) || "Действие"}</h3>
                        <div className='button-container'>
                            <Button className={`p-button-text  `} onClick={() => setVisibleSidebar(true)}>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.svg`}></img>
                            </Button>
                        </div>
                    </div>
                    <div className='body'>
                        {variable && (
                            <div style={{ color: "#9747FF", fontWeight: "700" }} className='mb-2'>
                                {variable?.name}
                            </div>
                        )}
                        <div className='mb-2'>{getActionType(data)}:</div>
                        <div className='body-text-wrap'>{data?.argument}</div>
                    </div>
                </div>
                <Handle id='r' type='source' className='custom-handle' position='right' isConnectable={true} />
            </div>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleSidebar}
                onHide={() => setVisibleSidebar(false)}
                position='right'
            >
                <CreateAction onHide={() => setVisibleSidebar(false)} funnelId={funnelId} data={data} />
            </Sidebar>
        </>
    )
})
