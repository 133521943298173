import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { SvgIconsMenu } from "../../../components/SvgIconsMenu/SvgIconsMenu"
import { FormEmail } from "./FormEmail"
import { MailLetter } from "./MailLetter"
import { NewPassword } from "./NewPassword"

export const PasswordRecovery = () => {
    const [showNextStep, setShowNextStep] = useState(false)
    const [newPassword, setNewPassword] = useState(false)

    return (
        <>
            {(!showNextStep && !newPassword)
                && <FormEmail
                    setShowNextStep={setShowNextStep} />}
            {showNextStep
                && <MailLetter
                    setShowNextStep={setShowNextStep}
                    setNewPassword={setNewPassword} />}
            {/*  {newPassword
                && <NewPassword />} */}
        </>
    )
}