import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { SvgIconsMenu } from "../../../components/SvgIconsMenu/SvgIconsMenu"
import { restorePassword } from "../../../service/slices/authSlice"

export const FormEmail = ({ setShowNextStep }) => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const [email, setEmail] = useState('');

    const dispatch = useDispatch()

    const sendHandler = () => {
        let data = {
            email: email,
        }

        dispatch(restorePassword(data)).then(res => {
            console.log(res);
            if (res.payload) {
                setShowNextStep(true)
            } else {
                setShowNextStep(false)
            }
        })

    }

    return (
        <>
            <div className="flex align-items-center">
                <Link
                    to={'/login'}
                    className="btn-back mr-3" >
                    <SvgIconsMenu
                        name="arrow-back"
                        className={colorTheme} />
                </Link>
                <p className="text-left my-5 title">
                    Восстановление пароля
                </p>
            </div>

            <div >
                <InputText
                    id="email"
                    type="text"
                    value={email}
                    className="w-full mb-6"
                    placeholder='Введите Email'
                    onChange={(e) => setEmail(e.target.value)} />
                <Button
                    label='Отправить'
                    className="w-full sign_in py-3"
                    onClick={sendHandler} />
            </div>
        </>
    )
}