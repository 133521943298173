import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import apiClient from '../api'

export const getReaders = createAsyncThunk(
    'readers/getReaders',
    async (data, { rejectWithValue }) => {

        try {
            const response = await apiClient.get(`gt/reader/list?page=${data.currentPage}&size=${data.basicRows}`)
            if (response.status !== 200) {
                throw new Error('Ошибка получения списка читателей');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getRegisteredReaders = createAsyncThunk(
    'readers/getRegisteredReaders',
    async (data, { rejectWithValue }) => {

        try {
            const response = await apiClient.get(`gt/reader/registered?start=${data.start}&end=${data.end}`)
            if (response.status !== 200) {
                throw new Error('Ошибка получения списка читателей');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getCoverage = createAsyncThunk(
    'readers/getCoverage',
    async (data, { rejectWithValue }) => {

        try {
            const response = await apiClient.get(`gt/reader/coverage-messenger`)
            if (response.status !== 200) {
                throw new Error('Ошибка');
            }
            return response.data
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const readersSlice = createSlice({
    name: 'readers',
    initialState: {
        readers: [],
        totalPages: null,
        error: null,
        newUsers: 0
    },
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(getReaders.pending, state => {
                state.error = null
            })
            .addCase(getReaders.fulfilled, (state, action) => {
                state.error = null;
                state.readers = action.payload.content
                state.totalPages = action.payload.totalPages
            })
            .addCase(getReaders.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(getRegisteredReaders.pending, state => {
                state.error = null
            })
            .addCase(getRegisteredReaders.fulfilled, (state, action) => {
                state.error = null;
                state.newUsers = action.payload
            })
            .addCase(getRegisteredReaders.rejected, (state, action) => {
                state.error = action.payload
            })
    }
})


export default readersSlice.reducer
