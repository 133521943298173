import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputSwitch } from "primereact/inputswitch"
import { useEffect, useRef, useState } from "react"
import { NavLink } from "react-router-dom"
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import './Account.css'
import { EditAccount } from "./EditAccount"
import { useDispatch, useSelector } from "react-redux"
import Cookies from "js-cookie"
import { DARK_THEME, getPreferredColorScheme, LIGHT_THEME, selected, setCookieColorMode, setCookieSelectedTheme } from "../../../service/theme"
import { setDarkTheme, setLightTheme, setSelectedTheme } from "../../../service/slices/colorThemeSlice"
import axios from 'axios'
import Gravatar from "react-gravatar"
import { Toast } from "primereact/toast"
import { Skeleton } from "primereact/skeleton"
import { log } from "../../../service/log"

export const Account = () => {
    const [subscription, setSubscription] = useState(null)
    const [subscriptionVersion, setSubscriptionVersion] = useState('basic')
    const [surcharge, setSurcharge] = useState(false)
    const [extension, setExtension] = useState(false)
    const [data, setData] = useState(false)
    const [personalSetting, setPersonalSetting] = useState(false)
    /*   const [selectedTheme, setSelectedTheme] = useState(Cookies.get('theme')) */
    /*  const [user, setUser] = useState(null) */
    /*     const [selectedEmployee, setSelectedEmployee] = useState('') */
    const toast = useRef(null);

    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const selectedTheme = useSelector((state) => state.colorTheme.selectedTheme)
    const user = useSelector((state) => state.user.user)
    /*  const user = { "avatar": "Rectangle (1).png", "name": "Константин", "surname": "Константинопольский", "post": "Project Manager", "mail": "admin@bots.bot" } */

    const dispatch = useDispatch()

    useEffect(() => {
        document.title = 'Bot Magic | Аккаунт'
    }, [])

    useEffect(() => {
        getPreferredColorScheme()
    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_URL}/data.json`)
            .then((response) => {
                log(response.data)
                setData(response.data)
                /*  setUser(response.data.account.user) */
                setSubscription(response.data.account.subscription)
            })
            .catch((error) => console.error(error))

    }, [])

    const changeTheme = (setTheme, theme, value) => {
        dispatch(setTheme())
        setCookieColorMode(theme)
        dispatch(setSelectedTheme(value))
        setCookieSelectedTheme(value)
    }

    const themeSistem = () => {
        if (getPreferredColorScheme() === 'light') {
            return changeTheme(setLightTheme, LIGHT_THEME, 'sistem')
        } else {
            changeTheme(setDarkTheme, DARK_THEME, 'sistem')
        }
    }

    return (
        <>
            <Toast ref={toast} />
            {user ?
                <div className="card setting">
                    <div className="grid">
                        <div className="col-12 sm:col-4 lg:col-3 flex flex-column align-items-center">
                            <div className="avatar-user">
                                {user?.avatar ?
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${user?.avatar}`}></img> :
                                    <Gravatar
                                        email={user?.email}
                                        size={195}
                                        rating="pg"
                                        default="retro"
                                        className="CustomAvatar-image"
                                    />
                                }

                            </div>
                            <a className="underline" href="#">Изменить фото</a>
                        </div>
                        <div className="col-12 sm:col-8 lg:col-5">
                            <h2 className="settings-title">{user?.firstName} {user?.lastName}</h2>
                            <p className="post">{user?.position}</p>
                            <p className="mail">{user?.email}</p>
                            <Button className='btn-main py-3 px-4' onClick={() => setPersonalSetting(true)}>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.${colorTheme}.svg`}></img>
                                <span className="ml-3">Настроить</span>
                            </Button>
                        </div>
                        <div className="col-12 lg:col-4 flex flex-column sm:flex-row lg:flex-column">
                            <div className='w-full sm:w-6 lg:w-12 settings-card mb-3 mr-0 pr-0 sm:mr-3 lg:mr-0 flex'>
                                <div className="wrapper-green mr-4">
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/creditcard.svg`}></img>
                                </div>

                                <div>
                                    <p className="mb-1">Ближайший платеж</p>
                                    <p className="mb-0">{subscription?.next_payment.sum} ₽ - {subscription?.next_payment.date}</p>
                                </div>

                            </div>
                            <div className='w-full sm:w-6 lg:w-12 settings-card mb-3 mr-0 pr-0 sm:mr-3 lg:mr-0 flex'>
                                <div className="wrapper-red mr-4">
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/creditcard.red.svg`}></img>
                                </div>

                                <div>
                                    <p className="mb-0">Обновите <br /> платежные данные</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="card setting">
                    <div className="grid">
                        <div className="col-12 sm:col-4 lg:col-3 flex flex-column align-items-center">
                            <div className="mb-2">
                                <Skeleton shape="circle" size="195px"></Skeleton>
                            </div>
                            <Skeleton width="8rem" />
                        </div>
                        <div className="col-12 sm:col-8 lg:col-5">
                            <Skeleton width="13rem" height="2rem" className="mb-2" />
                            <Skeleton width="18rem" height="2rem" className="mb-4" />
                            <Skeleton width="15rem" height="2rem" className="mb-3" />
                            <Skeleton width="10rem" height="1rem" className="mb-3" />
                            <Skeleton width="11rem" height="3rem" />
                        </div>
                        <div className="col-12 lg:col-4 flex flex-column sm:flex-row lg:flex-column">

                            <Skeleton width="100%" height="130px" borderRadius="20px" className="mb-3 mr-3 lg:mr-0" />

                            <Skeleton width="100%" height="130px" borderRadius="20px" />

                        </div>
                    </div>
                </div>
            }

            <div className="card setting subscription">
                {data ? <>
                    <h2 className="settings-title">Подписка</h2>
                    {!subscription ?
                        <div className="grid">
                            <div className="col-12 md:col-4 flex flex-wrap flex-column sm:flex-row md:flex-column">

                                {/*   <div className={`settings-card_${colorTheme} mb-4`} onClick={() => setSubscription('test')}>
                            <p className="mb-3">Пробный период</p>
                            <p className="mb-0 gray">7 дней полного доступа</p>
                        </div> */}

                                <div className={subscriptionVersion === 'basic' ? `subscription-card ${colorTheme} basic w-12 sm:w-6 md:w-12 mb-4 sm:mb-8 md:mb-4 active` : `subscription-card ${colorTheme} basic w-12 sm:w-6 md:w-12 mb-4 sm:mb-8 md:mb-4`} onClick={() => setSubscriptionVersion('basic')}>
                                    <p className="mb-3">Базовая подписка</p>
                                    <p className="mb-0 green">Полный функционал, до 15 человек команды компании</p>
                                </div>

                                <div className={subscriptionVersion === 'premium' ? `subscription-card ${colorTheme} premium w-12 sm:w-6 md:w-12 mb-8 active` : `subscription-card ${colorTheme} premium w-12 sm:w-6 md:w-12 mb-8`} onClick={() => setSubscriptionVersion('premium')}>
                                    <p className="mb-3">Премиум подписка</p>
                                    <p className="mb-0 yellow">Полный функционал и неограниченное количество пользователей и сообщений</p>
                                </div>
                                <a className="underline hidden md:block" href="#">Публичная оферта</a>
                            </div>
                            <div className="col-12 md:col-8">
                                {subscriptionVersion === 'basic' &&
                                    <div className="grid h-full pb-6">
                                        <div className="col-12 md:col-6 flex flex-wrap flex-row md:flex-column justify-content-between md:justify-content-end pl-0 md:pl-5">
                                            <div>
                                                <div className="grid mb-2">
                                                    <div className="col-2">
                                                        <div className="wrapper-green">
                                                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/message.svg`}></img>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 flex align-items-center">
                                                        10000 сообщений в сутки
                                                    </div>
                                                </div>

                                                <div className="grid mb-2">
                                                    <div className="col-2">
                                                        <div className="wrapper-green">
                                                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/envelope.badge.svg`}></img>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 flex align-items-center">
                                                        Любые мессенджеры
                                                    </div>
                                                </div>

                                                <div className="grid mb-4">
                                                    <div className="col-2">
                                                        <div className="wrapper-green">
                                                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/person.2.square.stack.svg`}></img>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 flex align-items-center">
                                                        15 сотрудников в компании
                                                    </div>
                                                </div>
                                                <a className="underline block md:hidden mb-3" href="#">Публичная оферта</a>
                                            </div>
                                            <div>
                                                <div className="grid">
                                                    <div className="col-2">
                                                        <InputSwitch className='settings-switch' checked={surcharge} onChange={(e) => setSurcharge(e.value)} />
                                                    </div>
                                                    <div className="col-10">
                                                        <span>Автодоплата за сообщения </span>
                                                    </div>

                                                </div>

                                                <div className="grid mb-4">
                                                    <div className="col-2">
                                                        <InputSwitch className='settings-switch' checked={extension} onChange={(e) => setExtension(e.value)} />
                                                    </div>
                                                    <div className="col-10">
                                                        <span>Автопродление {extension ? 'включено' : 'выключено'} </span>
                                                    </div>

                                                </div>
                                                <div className="block md:hidden price pr-5">
                                                    <span>К оплате</span>
                                                    <p>1 129 ₽</p>
                                                    <Button className="w-full px-4 py-3 btn-pay" label="Оплатить"></Button>
                                                </div>
                                            </div>

                                            {/* <a className="underline" href="#">Публичная оферта</a> */}
                                        </div>
                                        <div className="col-6 flex flex-column justify-content-between pt-4">
                                            <div className="img-block hidden md:block">
                                                <img className="w-full" src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/Union.${colorTheme}.svg`}></img>
                                            </div>

                                            <div className="hidden md:block price pr-5">
                                                <span>К оплате</span>
                                                <p>1 129 ₽</p>
                                                <Button className="w-full px-4 py-3 btn-pay" label="Оплатить"></Button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {subscriptionVersion === 'premium' &&
                                    <div className="grid h-full pb-6">
                                        <div className="col-12 md:col-6 flex flex-wrap flex-row md:flex-column justify-content-between md:justify-content-end pl-0 md:pl-5">
                                            <div>
                                                <div className="grid mb-2">
                                                    <div className="col-2">
                                                        <div className="wrapper-green">
                                                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/message.svg`}></img>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 flex align-items-center">
                                                        Неограниченное количество сообщений в сутки
                                                    </div>
                                                </div>

                                                <div className="grid mb-2">
                                                    <div className="col-2">
                                                        <div className="wrapper-green">
                                                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/envelope.badge.svg`}></img>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 flex align-items-center">
                                                        Любые мессенджеры
                                                    </div>
                                                </div>

                                                <div className="grid mb-4">
                                                    <div className="col-2">
                                                        <div className="wrapper-green">
                                                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/person.2.square.stack.svg`}></img>
                                                        </div>
                                                    </div>
                                                    <div className="col-10 flex align-items-center">
                                                        Неограниченное количество сотрудников в компании
                                                    </div>
                                                </div>
                                                <a className="underline block md:hidden mb-3" href="#">Публичная оферта</a>
                                            </div>
                                            <div>
                                                <div className="grid">
                                                    <div className="col-2">
                                                        <InputSwitch className={`settings-switch ${colorTheme}`} checked={surcharge} onChange={(e) => setSurcharge(e.value)} />
                                                    </div>
                                                    <div className="col-10">
                                                        <span>Автодоплата за сообщения </span>
                                                    </div>

                                                </div>

                                                <div className="grid mb-4">
                                                    <div className="col-2">
                                                        <InputSwitch className={`settings-switch ${colorTheme}`} checked={extension} onChange={(e) => setExtension(e.value)} />
                                                    </div>
                                                    <div className="col-10">
                                                        <span>Автопродление {extension ? 'включено' : 'выключено'} </span>
                                                    </div>

                                                </div>
                                                <div className=" block md:hidden price pr-5">
                                                    <span>К оплате</span>
                                                    <p>5 000 ₽</p>
                                                    <Button className='w-full px-4 py-3 btn-pay' label="Оплатить"></Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 flex flex-column justify-content-between">
                                            <div className="img-block hidden md:block">
                                                <img className="w-full h-auto" src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/premium.${colorTheme}.svg`}></img>
                                            </div>
                                            <div className="hidden md:block price pr-5">
                                                <span>К оплате</span>
                                                <p>5 000 ₽</p>
                                                <Button className='w-full px-4 py-3 btn-pay' label="Оплатить"></Button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div> :
                        <div className="grid justify-content-start">
                            <div className="col-12 sm:col-6 lg:col-4">
                                {subscription?.name === 'basic' &&
                                    <div className={`subscription-card ${colorTheme} basic mb-4 active`} >
                                        <p className="mb-3">Базовая подписка</p>
                                        <p className="mb-0 green">Полный функционал, до 15 человек команды компании</p>
                                    </div>}
                                {subscription?.name === 'premium' &&
                                    <div className={`subscription-card ${colorTheme} premium mb-8 active`}>
                                        <p className="mb-3">Премиум подписка</p>
                                        <p className="mb-0 yellow">Полный функционал и неограниченное количество пользователей и сообщений</p>
                                    </div>}
                            </div>


                            <div className="col-12 sm:col-6 lg:col-4">
                                <div className='surcharge_message' >
                                    <p className="mb-3">Доплата за сообщения</p>
                                    <p className="mb-0 blue">+10 000 сообщений</p>
                                </div>
                            </div>
                            <div className="col-12 sm:col-6 md:col-12">
                                <div className="flex align-items-center mb-3">
                                    <InputSwitch className='settings-switch mr-3' checked={surcharge} onChange={(e) => setSurcharge(e.value)} />
                                    <span>Автодоплата за сообщения </span>
                                </div>
                                <div className="flex align-items-center">
                                    <InputSwitch className='settings-switch mr-3' checked={extension} onChange={(e) => setExtension(e.value)} />
                                    <span>Автопродление {extension ? 'включено' : 'выключено'} </span>

                                </div>
                            </div>
                        </div>}
                </>
                    :
                    <>
                        <Skeleton width="12rem" height="2rem" className="mb-4"></Skeleton>
                        <div className="grid">
                            <div className="col-12 md:col-4 flex flex-wrap flex-column sm:flex-row md:flex-column">

                                <Skeleton width="100%" height="120px" borderRadius="20px" className="mb-4"></Skeleton>

                                <Skeleton width="100%" height="120px" borderRadius="20px"></Skeleton>
                                <Skeleton width="70%" className="mt-8" />
                            </div>
                            <div className="col-12 md:col-8">

                                <div className="grid h-full pb-6">
                                    <div className="col-12 w-full md:col-6 flex flex-wrap flex-row md:flex-column justify-content-between md:justify-content-end pl-0 md:pl-5 mb-5">
                                        <div className="w-full">
                                            <div className="grid mb-2">
                                                <div className="col-2">
                                                    <Skeleton shape="circle" size="40px"></Skeleton>
                                                </div>
                                                <div className="col-10 flex align-items-center">
                                                    <Skeleton width="70%" />
                                                </div>
                                            </div>

                                            <div className="grid mb-2">
                                                <div className="col-2">
                                                    <Skeleton shape="circle" size="40px"></Skeleton>
                                                </div>
                                                <div className="col-10 flex align-items-center">
                                                    <Skeleton width="70%" />
                                                </div>
                                            </div>

                                            <div className="grid mb-4">
                                                <div className="col-2">
                                                    <Skeleton shape="circle" size="40px"></Skeleton>
                                                </div>
                                                <div className="col-10 flex align-items-center">
                                                    <Skeleton width="70%" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Skeleton width="100%" className="mb-3" />

                                            <Skeleton width="100%" />
                                        </div>

                                    </div>
                                    <div className="col-12 md:col-6 flex flex-column justify-content-between pt-4">
                                        <Skeleton width="100%" height="220px" className="mb-8" />

                                        <div className="pr-5">
                                            <Skeleton width="5rem" className="mb-2" />
                                            <Skeleton width="8rem" height="2rem" className="mb-2" />
                                            <Skeleton width="100%" height="3rem" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>}

            </div>

            {data ?
                <div className="card setting">
                    <h3 className='settings-subtitle mb-6'>Тема сайта</h3>
                    <div className="grid justify-content-start">
                        <div className="col-12 lg:col-5">
                            <div className="grid">
                                <div className="col-4 flex flex-column align-items-center">
                                    <Button className={selectedTheme === 'sistem' ? "theme active p-button-outlined" : "theme p-button-outlined"} onClick={themeSistem}>
                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/theme_${getPreferredColorScheme()}.svg`}></img>
                                    </Button>
                                    <p className='mt-3'>Как в системе</p>
                                </div>
                                <div className="col-4 flex flex-column align-items-center">
                                    <Button className={selectedTheme === 'light' ? "theme active p-button-outlined w-full" : "theme p-button-outlined"} onClick={() => changeTheme(setLightTheme, LIGHT_THEME, 'light')}>
                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/theme_light.svg`}></img>
                                    </Button>
                                    <p className='mt-3'>Светлая</p>
                                </div>
                                <div className="col-4 flex flex-column align-items-center" onClick={() => changeTheme(setDarkTheme, DARK_THEME, 'dark')}>
                                    <Button className={selectedTheme === 'dark' ? "theme active p-button-outlined" : "theme p-button-outlined"}>
                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/theme_dark.svg`}></img>
                                    </Button>
                                    <p className='mt-3'>Темная</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="card setting">
                    <Skeleton width="10rem" height="2rem" className="mb-5" />
                    <div className="grid justify-content-start">
                        <div className="col-12 lg:col-5">
                            <div className="grid">
                                <div className="col-4 flex flex-column align-items-center">
                                    <Skeleton width="100px" height="100px" borderRadius="20px" className="mb-2" />
                                    <Skeleton width="5rem" />
                                </div>
                                <div className="col-4 flex flex-column align-items-center">
                                    <Skeleton width="100px" height="100px" borderRadius="20px" className="mb-2" />
                                    <Skeleton width="5rem" />
                                </div>
                                <div className="col-4 flex flex-column align-items-center" onClick={() => changeTheme(setDarkTheme, DARK_THEME, 'dark')}>
                                    <Skeleton width="100px" height="100px" borderRadius="20px" className="mb-2" />
                                    <Skeleton width="5rem" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {personalSetting && <EditAccount visible={personalSetting} onHide={setPersonalSetting} value={user} toast={toast} />}

        </>
    )
}