import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { useSelector } from "react-redux";

export const EditAboutCompany = ({ visible, onHide, value }) => {

    const [title, setTitle] = useState(value?.title)
    const [description, setDescription] = useState(value?.description)

    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const renderFooter = () => {
        return (
            <>
                <div className="flex justify-content-between">
                    <Button label="Отменить" onClick={() => onHide(false)} className='btn-main p-button-text py-3 px-4 mr-3' />
                    <Button label="Сохранить" onClick={() => onHide(false)} autoFocus className="btn-save py-3 px-4" />
                </div>
            </>

        );
    }

    return (
        <Dialog className={`personal-setting w-12 sm:w-11 md:w-8 lg:w-6 xl:w-4 ${colorTheme}`} header="О компании" visible={visible} footer={renderFooter()} onHide={() => onHide(false)}>
            <div className="grid justify-content-start mt-3">
                <div className="col-12">
                    <span className="p-float-label">
                        <InputText className="w-full" id="username" value={title} onChange={(e) => setTitle(e.target.value)} />
                        <label htmlFor="username">Название</label>
                    </span>
                </div>
                <div className="col-12 mt-5">
                    <span className="p-float-label">
                        <InputTextarea className="w-full" value={description} onChange={(e) => setDescription(e.target.value)} rows={5} cols={58} />
                        <label htmlFor="username">Описание (не обязательно)</label>
                    </span>
                </div>
            </div>
        </Dialog>
    )
}