import { useSelector } from "react-redux"
import { Route } from "react-router-dom"
import RegistrationPage from "./Registration/RegistrationPage"
import '../../layout/layout.scss'
import { updateCss } from "../../service/theme"
import { useEffect } from "react"
import LoginPage from "./Login/LoginPage"
import { Confirm } from "./ConfirmSuccess"
import { NewPassword } from "./PasswordRecovery/NewPassword"

export const Authorization = () => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    useEffect(() => {
        updateCss(colorTheme)
    }, [colorTheme])

    return (
        <div className={`Authorization ${colorTheme} h-full`}>
            <Route path="/login" exact component={LoginPage} />
            <Route path="/login/:params" exact component={LoginPage}></Route>
            <Route path="/registration" exact component={RegistrationPage} />
            {/*   <Route path="/registration/confirm" exact component={Confirm} /> */}
            {/*   <Route path="/update-password" exact component={NewPassword} /> */}
        </div>

    )
}