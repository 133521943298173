import { useState } from 'react'
import { useSelector } from 'react-redux'
import { formatDate } from '../../service/formatDate'
import './CardNewUsers.css'
import axios from 'axios';
import { useEffect } from 'react'
import { SkeletonCard } from '../SkeletonCard/SkeletonCard';

export const CardNewUsers = ({ page }) => {
    //const [newUsers, setNewUsers] = useState(null)

    const newUsers = useSelector((state) => state.newUsers.users)

    /*     useEffect(() => {
            axios.get('data.json')
                .then((response) => {
                    console.log(response.data)
                    setNewUsers(response.data.new_users)
                })
                .catch((error) => console.error(error))
    
        }, []) */

    const date = useSelector((state) => state.selectDate.date)
    const selectDate = useSelector((state) => state.selectDate.selectDate)
    return (
        <>
            {newUsers !== null ?
                <>
                    {page === 'main'
                        ? <div className={`card statistics card-new_users h-full mb-0`}>
                            <div className="flex justify-content-between">
                                <span>Новых пользователей за {selectDate === 'today' ? 'сегодня' : selectDate === 'yesterday' ? 'вчера' : 'период'}
                                </span>
                                <i className="pi pi-arrow-up-right"></i>
                            </div>
                            <h1 className="mb-0">{newUsers} чел.</h1>
                        </div>
                        :
                        <div className="card h-full card-new_users audience">
                            <div className="wrapper-text">

                                {newUsers === 0
                                    ? <span>Новых пользователей за сегодня нет.</span>
                                    : <div className='flex flex-column'>
                                        <span>Новых пользователей за {selectDate === 'today' ? 'сегодня' : selectDate === 'yesterday' ? 'вчера' : 'период'}
                                        </span>
                                        <h1 className="mb-0">{newUsers} чел.</h1>
                                    </div>
                                }


                            </div>
                        </div>
                    }

                </>
                :
                <SkeletonCard />}
        </>

    )
}