import { Button } from "primereact/button"
import React from "react"
import "./LimitPanel.scss"

const LimitPanel = ({ count, limit, type, setIsLimitPanelHidden }) => {
    return (
        <>
            {type === "funnels" && (
                <div className='limit-panel limit-panel-warning'>
                    <div>
                        <h4 className='limit-panel-title'>Обратите внимание.</h4>
                        <p className='limit-panel-text'>
                            В период бета-тестирования для всех компаний действует ограничение в {limit} воронки.
                        </p>
                    </div>
                </div>
            )}
            {count < limit && (
                <div className='limit-panel limit-panel-warning'>
                    <div className='limit-panel-info'>
                        <h4 className='limit-panel-title'>Обратите внимание.</h4>
                        <p className='limit-panel-text'>
                            Вы использовали {count} из {limit} блоков для создания воронки!
                        </p>
                    </div>
                    <div>
                        <Button label='Продолжить' className='limit-panel-button' onClick={() => setIsLimitPanelHidden(true)} />
                    </div>
                </div>
            )}
            {count >= limit && (
                <div className='limit-panel limit-panel-over'>
                    <div className='limit-panel-over-content'>
                        {/* иконку на ! */}
                        <svg
                            className='limit-panel-pic'
                            width='34'
                            height='35'
                            viewBox='0 0 34 35'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M9.92305 34.4999L0 24.5859V10.4231L9.914 0.5H24.0769L33.9999 10.414V24.5769L24.0859 34.4999H9.92305ZM10.8654 32.2307H23.1346L31.7307 23.6346V11.3654L23.1346 2.7692H10.8654L2.2692 11.3654V23.6346L10.8654 32.2307ZM10.9654 25.1153L17 19.0999L23.0346 25.1153L24.6345 23.5346L18.5999 17.5L24.6345 11.4654L23.0346 9.8654L17 15.9L10.9654 9.8654L9.3846 11.4654L15.4 17.5L9.3846 23.5346L10.9654 25.1153Z'
                                fill='#FF3B30'
                            />
                        </svg>

                        <div>
                            <h4 className='limit-panel-title'>Упс!</h4>
                            <p className='limit-panel-text'>
                                Вы использовали {count} из {limit} блоков для создания воронки!
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default LimitPanel
