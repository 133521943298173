import { Paginator } from 'primereact/paginator'
import { useSelector } from 'react-redux';
import './PaginatorTable.css'

export const PaginatorTable = ({ basicFirst, basicRows, setBasicFirst, setCurrentPage, totalPages }) => {

    const onBasicPageChange = (event) => {
        setBasicFirst(event.first);
        setCurrentPage(event.page);
    };

    return (
        <Paginator
            first={basicFirst}
            rows={basicRows}
            totalRecords={totalPages * basicRows}
            onPageChange={onBasicPageChange}>
        </Paginator>
    )
}