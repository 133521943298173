import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import { AppTopbar } from './AppTopbar'

import PrimeReact from 'primereact/api'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'prismjs/themes/prism-coy.css'
import './layout/layout.scss'
import './App.scss'

import { Statistics } from './pages/Statistics/Statistics'
import { Chats } from './pages/Chats/Chats'
import { SmartAnswers } from './pages/SmartAnswers/SmartAnswers'
import { AddNewAnswer } from './pages/SmartAnswers/AddNewAnswer'
import { Audience } from './pages/Audience/Audience'
import { Settings } from './pages/Settings/Settings'
import { updateCss } from './service/theme'
import { useDispatch, useSelector } from 'react-redux'
import { NotFound } from './pages/NotFound/NotFound'
import { Funnels } from './pages/Funnels/Funnels'
import { CreateFunnel } from './pages/CreateFunnel/CreateFunnel'
import { AppMenu } from './AppMenu'

import Cookies from 'js-cookie'
import { Success } from './pages/Settings/Payments/Success'
import { Mailing } from './pages/Mailing/Mailing'
import apiClient from './service/api'
import { setUser } from './service/slices/authSlice'
import { Onboarding } from './pages/Onboarding/Onboarding'
import axios from 'axios'
import { log } from './service/log'
import { ReactFlowProvider } from 'react-flow-renderer'
import { getListCompaniesUser } from './service/slices/companySlice'
import { updateUser } from './service/slices/userSlice'
import { InviteSuccess } from './pages/Settings/Company/InviteSuccess'
import { InviteFailure } from './pages/Settings/Company/InviteFailure'
import { getAllBots } from './service/slices/botsSlice'

const App = (props) => {
    const { params } = useParams()
    const visited = useSelector((state) => state.auth.visited)
    const [layoutMode, setLayoutMode] = useState('static')
    /*    const [layoutColorMode, setLayoutColorMode] = useState('dark') // light */
    const [inputStyle, setInputStyle] = useState('outlined')
    const [ripple, setRipple] = useState(true)
    const [staticMenuInactive, setStaticMenuInactive] = useState(false)
    const [overlayMenuActive, setOverlayMenuActive] = useState(false)
    const [mobileMenuActive, setMobileMenuActive] = useState(false)
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false)
    const auth = Cookies.get('auth');
    const history = useHistory()
    const dispatch = useDispatch()

    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const bots = useSelector((state) => state.bots.bots)
    console.log('bots', bots)

    console.log(colorTheme);

    const user = useSelector((state) => state.user.user)
    const listCompanies = useSelector((state) => state.company.listCompanies)
    PrimeReact.ripple = true

    let menuClick = false
    let mobileTopbarMenuClick = false

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden')
        } else {
            removeClass(document.body, 'body-overflow-hidden')
        }
    }, [mobileMenuActive])


    useEffect(() => {
        updateCss(colorTheme)
    }, [colorTheme])

    useEffect(() => {
        dispatch(getAllBots())
    }, [])

    /*     useEffect(() => {
            const userEmail = Cookies.get('user-email')
            axios.get('https://bots.sdew.ru/users/profile').then((res) =>
                dispatch(setUser(res.data.find((item) => item.email === userEmail))))
        }, []) */


    // useEffect(() => {
    //     if (!auth) {

    //         history.push("/login")
    //     }
    // }, [])


    useEffect(() => {
        Cookies.set('visited', true)
    }, [])

    const onColorModeChange = (mode) => {
        log(mode)
    }

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle)
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false)
            setMobileMenuActive(false)
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false)
        }

        mobileTopbarMenuClick = false
        menuClick = false
    }

    const onToggleMenuClick = (event) => {
        menuClick = true

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true)
                }

                setOverlayMenuActive((prevState) => !prevState)
                setMobileMenuActive(false)
            } else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState)
            }
        } else {
            setMobileMenuActive((prevState) => !prevState)
        }

        event.preventDefault()
    }

    const onSidebarClick = () => {
        menuClick = true
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true

        setMobileTopbarMenuActive((prevState) => !prevState)
        event.preventDefault()
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true

        event.preventDefault()
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false)
            setMobileMenuActive(false)
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 1550
    }

    const logout = () => {
        props.keycloak.logout()
        localStorage.removeItem('react-refresh-token')
        localStorage.removeItem('react-refresh-token')
    }

    const menu = [
        {
            label: '',
            items: [
                { label: 'Аккаунт', icon: 'account', to: '/settings/account' },
                { label: 'Компания', icon: 'company', to: '/settings/company' },
                { label: 'Платежи и карты', icon: 'payments', to: '/settings/payments' },
            ]
        },
        {
            label: '',
            items: [
                { label: 'Справка', icon: 'reference', to: '/settings/reference' },
                { label: 'Выйти из аккаунта', icon: 'sign_out', to: '/' },
            ]
        }
    ]


    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className)
        else element.className += ' ' + className
    }

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className)
        else
            element.className = element.className.replace(
                new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
                ' '
            )
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': colorTheme === 'light',
    })

    // const ParamsPage = [undefined, 'main', 'chats', 'smart_answers', 'audience', 'funnels', 'mailing', 'create', 'account', 'company', 'payments']
    const exceptions = ['/registration', '/update-password', '/confirm-success', '/bad-token', '/confirm-failure']
    return (
        <>
            {/* {ParamsPage.indexOf(params) !== -1 ? */}
            {auth ?
                <>
                    <div className={wrapperClass} onClick={onWrapperClick}>
                        <AppTopbar
                            onToggleMenuClick={onToggleMenuClick}
                            layoutColorMode={colorTheme}
                            mobileTopbarMenuActive={mobileTopbarMenuActive}
                            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                            onColorModeChange={onColorModeChange}
                            onLogout={logout}
                        />

                        {/* <div className="layout-sidebar" onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div> */}

                        <div className={`layout-main-container ${!visited ? 'overflow-hidden max-h-screen' : ''}`}>
                            <div className="layout-main">
                                {!visited && <Onboarding />}
                                <Switch>
                                    <Route path="/" exact component={Statistics} />
                                    <Route path="/main" exact component={Statistics} />
                                    <Route path="/chats" exact component={Chats} />
                                    <Route path="/smart_answers" exact render={() => <SmartAnswers />} />
                                    <Route path="/smart_answers/create" exact component={AddNewAnswer} />
                                    <Route path="/audience" exact component={Audience} />
                                    <Route path="/funnels" exact component={Funnels} />
                                    <Route path="/mailing" exact component={Mailing} />
                                    <Route path="/funnels/:id" exact render={() => <ReactFlowProvider><CreateFunnel /></ReactFlowProvider>} />
                                    <Route path="/settings" exact render={() => <Settings onMenuItemClick={onMenuItemClick} onSidebarClick={onSidebarClick} />} />
                                    <Route path="/settings/:params" exact render={() => <Settings onMenuItemClick={onMenuItemClick} onSidebarClick={onSidebarClick} />}></Route>
                                    <Route path="/success" exact render={() => <Success onMenuItemClick={onMenuItemClick} onSidebarClick={onSidebarClick} />}></Route>
                                    <Route path="/invite-success" exact component={InviteSuccess} />
                                    <Route path="/invite-failure" exact component={InviteFailure} />
                                    <Route path="*" component={NotFound} />
                                    {/*  <Route render={NotFound} /> */}
                                </Switch>
                            </div>


                        </div>

                        <CSSTransition
                            classNames="layout-mask"
                            timeout={{ enter: 200, exit: 200 }}
                            in={mobileMenuActive}
                            unmountOnExit
                        >
                            <div className="layout-mask p-component-overlay"></div>
                        </CSSTransition>
                    </div>

                </>

                :
                <Redirect to={params} />}
        </>
    )
}

export default App
