import Cookies from "js-cookie"
import { Button } from "primereact/button"
import { NavLink } from "react-router-dom"
import { AppMenu } from "../../../AppMenu"

export const Success = ({ onSidebarClick, onMenuItemClick }) => {

    const menu = [
        {
            label: '',
            items: [
                { label: 'На страницу компании', icon: 'arrow-back', to: '/settings/account' }
            ]
        },
        {
            label: '',
            items: [
                { label: 'Справка', icon: 'reference', to: '/settings/reference' },
                {
                    label: 'Выйти из аккаунта', icon: 'sign_out', command: () => {
                        Cookies.remove('auth_token')
                        window.location.hash = "/login"
                    }
                },
            ]
        }
    ]
    return (
        <>
            <div className="grid justify-content-start">
                <div className="col-2 mr-5">
                    <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                    </div>
                </div>

                <div className="success-content">
                    <h1 className="success-content_title">Спасибо, за покупку!</h1>
                    <p className="success-content_text">Продлили вашу подписку на сервис. Приятного использования.</p>
                    <NavLink to="/main">
                        <Button className='btn-back_main px-4 py-3' label="Вернуться на главную"></Button>
                    </NavLink>

                </div>
            </div>
        </>
    )
}