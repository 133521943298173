import { createSlice } from '@reduxjs/toolkit'


export const initNodesSlice = createSlice({
    name: 'initNodes',
    initialState: {
        nodes: []
    },
    reducers: {
        setInitNodes: (state, action) => {
            state.nodes = action.payload
        },

    },
})

export const { setInitNodes } = initNodesSlice.actions
export default initNodesSlice.reducer
