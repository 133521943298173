import { Button } from "primereact/button"
import './ViewMailing.css'
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { InputSwitch } from "primereact/inputswitch"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"
import { AddTagsMailing } from "../AddMailing/AddTagsMailing"
import { UploadFileMailing } from "../components/UploadFileMailing/UploadFileMailing"
import { ScrollPanel } from "primereact/scrollpanel"
import { deleteDistribution, getAllMailings, getOneMailing, updateMailing } from "../../../service/slices/mailingsSlice"
import { useEffect } from "react"
import { confirmPopup } from "primereact/confirmpopup"

export const ViewMailing = ({ currentPage, basicRows, selectedMailing, setSelectedMailing, setEdit, toast }) => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const mailings = useSelector((state) => state.mailings.mailings)
    const mailing = useSelector((state) => state.mailings.mailing)
    const [filterDate, setFilterDate] = useState(false)
    const [filterFunnel, setFilterFunnel] = useState(false)
    const [filterTag, setFilterTag] = useState(false)
    const [dateStart, setDateStart] = useState(null)
    const [dateFinish, setDateFinish] = useState(null)
    const [selectedFunnel, setSelectedFunnel] = useState(null);
    const [tags, setTags] = useState(null)
    const user = useSelector((state) => state.user.user)
    const dispatch = useDispatch()

    console.log('mailing', mailing)

    useEffect(() => {
        if (selectedMailing) {
            dispatch(getOneMailing(selectedMailing?.distributionID))
                .then((res) => {
                    setFilterDate((res.payload?.from || res.payload?.to) ? true : false)
                    setFilterFunnel(res.payload?.blueprintIDs ? true : false)
                    setFilterTag(res.payload?.tags ? true : false)
                    setDateStart(new Date(res.payload.from))
                    setDateFinish(new Date(res.payload.to))
                    setTags(res.payload?.tags)
                })
        }

    }, [selectedMailing, mailings])

    const funnels = [
        { name: 'funnels1', code: 'f1' },
        { name: 'funnels2', code: 'f2' },
        { name: 'funnels3', code: 'f3' },
        { name: 'funnels4', code: 'f4' },
        { name: 'funnels5', code: 'f5' }
    ];
    const acceptFunc = (value) => {
        const data = {
            currentPage, currentPage,
            basicRows: basicRows
        }
        dispatch(deleteDistribution(value.distributionID))
            .then(() => {
                dispatch(getAllMailings(data))
                    .then(() => {
                        setSelectedMailing(null)
                    })
            })
        toast.current.show({ severity: 'error', summary: 'Удалено', detail: `Рассылка "${value.title}" удалена`, life: 3000 });
    };

    const reject = () => {

    };

    const removeHandler = (mailing, e) => {
        confirmPopup({
            target: e.currentTarget,
            message: 'Вы действительно хотите удалить рассылку',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Да',
            rejectLabel: 'Нет',
            accept: () => acceptFunc(mailing),
            reject
        });
    };

    const sendHandler = () => {
        const data = {
            from: dateStart ? new Date(dateStart).toString() : null,
            to: dateFinish ? new Date(dateFinish).toString() : null,
            blueprintIDs: selectedFunnel?.map((item) => item.id),
            start: true,
        }
        dispatch(updateMailing(data))
        setSelectedMailing(null)
    }

    return (
        <div className="view-mailing">
            <div>
                <div className="mb-3">
                    <>
                        <div className="header-view_answer flex align-items-center justify-content-between py-2">
                            <p className="title-header">Рассылка</p>
                        </div>

                        <div className='flex justify-content-between mt-5'>
                            <div className="flex align-items-center">
                                <h5 className="view-mailing title-mailing">{mailing?.title}</h5>
                            </div>
                            <div className="flex">
                                <Button className="btn-trash_answer" onClick={(e) => removeHandler(mailing, e)}>
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/trash.svg`}></img>
                                </Button>
                            </div>
                        </div>
                    </>
                </div>

                <div className="flex flex-column justify-content-between">
                    <div className="view-mailing_scroll">
                        <ScrollPanel style={{ width: '100%', height: '100%' }} className="custom">
                            {mailing?.text &&
                                <>
                                    <h6 className="subtitle-mailing">Текст сообщения</h6>
                                    <span className="text-message_mailing">{mailing?.text}</span>
                                </>
                            }
                            {mailing?.files &&
                                <UploadFileMailing files={mailing?.files} view={true} />}

                            <div className='button-mailing'>
                                {mailing?.buttonCaption &&
                                    <>
                                        <h6 className="subtitle-mailing">Текст на кнопке</h6>
                                        <p className="text-message_mailing">{mailing?.buttonCaption}</p>
                                    </>
                                }
                                {mailing?.buttonUrl &&
                                    <>
                                        <h6 className="subtitle-mailing">URL кнопки</h6>
                                        <p className="text-message_mailing mt-3">{mailing?.buttonUrl}</p>
                                    </>
                                }
                            </div>

                            <div className="filters">
                                <h6 className="subtitle-mailing">Фильтры отправки</h6>
                                <div className="filter-item">
                                    <div className="filter-item_closed">
                                        <h6 className="subtitle-mailing">По дате регистрации</h6>
                                        <InputSwitch
                                            className={`settings-switch ${colorTheme}`}
                                            checked={filterDate}
                                            onChange={(e) => setFilterDate(e.value)}
                                            disabled={(user?.role === 'admin' || user?.role === 'moderator')}
                                        />
                                    </div>

                                    {filterDate &&
                                        <>
                                            <div className='choice-date'>
                                                <Calendar
                                                    className={`choice-date_calendar ${user?.role === "employer" ? "disabled-btn" : ""}`}
                                                    showIcon
                                                    value={dateStart}
                                                    onChange={(e) => setDateStart(e.value)}
                                                    placeholder='Дата начала'
                                                    showOnFocus={user?.role !== "employer"}
                                                />
                                                <Calendar
                                                    className={`choice-date_calendar ${user?.role === "employer" ? "disabled-btn" : ""}`}
                                                    showIcon
                                                    value={dateFinish}
                                                    onChange={(e) => setDateFinish(e.value)}
                                                    placeholder='Дата окончания'
                                                    showOnFocus={user?.role !== "employer"}
                                                />
                                            </div>
                                            <p className='mailing-warning_text'>Обратите внимание при выборе дат. Если выбрать только дату начала, то рассылка будет отправлена пользователям зарегистрировавшимся после выбранной даты. А если только дату окончания, то все пользователи добавленные до этой даты. Больше в справке</p>
                                        </>
                                    }
                                </div>
                                <div className="filter-item">
                                    <div className="filter-item_closed">
                                        <h6 className="subtitle-mailing">По участникам воронки</h6>
                                        <InputSwitch
                                            className={`settings-switch ${colorTheme}`}
                                            checked={filterFunnel}
                                            onChange={(e) => setFilterFunnel(e.value)}
                                            disabled={(user?.role === 'admin' || user?.role === 'moderator')}
                                        />
                                    </div>

                                    {filterFunnel &&
                                        <div className='choice-funnel'>
                                            <Dropdown
                                                className='droppdown-mailings'
                                                value={selectedFunnel}
                                                onChange={(e) => setSelectedFunnel(e.value)}
                                                options={funnels}
                                                optionLabel="name"
                                                placeholder="Название воронки"
                                                disabled={user?.role === "employer"}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="filter-item">
                                    <div className="filter-item_closed">
                                        <h6 className="subtitle-mailing">По присвоенному тегу</h6>
                                        <InputSwitch
                                            className={`settings-switch ${colorTheme}`}
                                            checked={filterTag}
                                            onChange={(e) => setFilterTag(e.value)}
                                            disabled={(user?.role === 'admin' || user?.role === 'moderator')}
                                        />
                                    </div>

                                    {filterTag &&
                                        <div className='choice-tags'>
                                            <AddTagsMailing tags={tags} setTags={setTags} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </ScrollPanel>
                    </div>
                </div>
                {(user?.role === 'admin' || user?.role === 'moderator') &&
                    <div className="footer-btns">
                        <Button
                            className="mailing-btn_edit"
                            label="Редактировать"
                            onClick={() => setEdit(true)}
                        ></Button>
                        <Button onClick={sendHandler} className="mailing-btn_sent" label="Отправить"></Button>
                    </div>}
            </div>
        </div>
    )
}