import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import apiClient from '../api'

export const login = createAsyncThunk(
    'auth/login',
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`gt/auth/login`, data)
            console.log(response);
            if (response.status !== 201) {
                throw new Error('Error!')
            }

            return response.data
        } catch (error) {
            console.log(error);
            return rejectWithValue(error.response.data.message)
        }


    }
)

export const registration = createAsyncThunk(
    'auth/registration',
    async (data, { rejectWithValue }) => {
        const response = await apiClient.post(`gt/auth/register`, data)
        console.log(response);
        if (response.data.status !== 200) {
            return rejectWithValue(response.data.message)
        }
        return response.data
    }
)

export const validateEmail = createAsyncThunk(
    'auth/validateEmail',
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`gt/auth/validate`, data)
            console.log(response);
            if (response.status !== 201) {
                throw new Error('Error!')
            }
            return response.data
        } catch (error) {
            console.log(error);
            return rejectWithValue(error.response.data.message)
        }

    }
)

export const resendEmail = createAsyncThunk(
    'auth/resendEmail',
    async (data, { rejectWithValue }) => {
        const response = await apiClient.post(`gt/auth/resend-email`, data)
        console.log(response);
        if (response.data.status !== 200) {
            return rejectWithValue(response.data.message)
        }
        return response.data

    }
)


export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        const response = await apiClient.get(`gt/auth/logout`)
        console.log(response);
        return response.data
    }
)

export const restorePassword = createAsyncThunk(
    'auth/restorePassword',
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`gt/auth/restore`, data)
            console.log(response);
            if (response.status !== 201) {
                throw new Error('Error!')
            }
            return response.data
        } catch (error) {
            console.log(error);
            return rejectWithValue(error.response.data.message)
        }

    }
)

export const updatePassword = createAsyncThunk(
    'auth/updatePassword',
    async (data, { rejectWithValue }) => {

        const response = await apiClient.post(`gt/auth/update-pass`, data)
        console.log(response);
        if (response.data.status !== 200) {
            return rejectWithValue(response.data.message)
        }
        return response.data


    }
)

/* export const getUser = createAsyncThunk(
    'auth/getUser',
    async () => {
        const response = await apiClient.get(`gt/user/profile`)
        console.log(response);
        return response.data
    }
) */


export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuth: false,
        error: null,
        errorResend: null,
        /* user: null, */
        visited: Cookies.get('visited')
    },
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setVisited: (state, action) => {
            state.visited = action.payload
        },

    },
    extraReducers(builder) {
        builder.addCase(login.fulfilled, (state, action) => {
            Cookies.set('auth', true)
            window.location.replace("/");
            state.isAuth = true
            state.error = null
        })
            .addCase(login.rejected, (state, action) => {
                state.error = action.payload
            });
        builder.addCase(registration.fulfilled, (state, action) => {
            /*   Cookies.set('auth_token', action.payload.access_token)
              window.location.replace("/"); */
            state.isAuth = true
            state.error = null
        })
            .addCase(registration.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.error = null
            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(resendEmail.fulfilled, (state, action) => {
                state.error = null
            })
            .addCase(resendEmail.rejected, (state, action) => {
                state.errorResend = action.payload
            })
        /*            .addCase(getUser.fulfilled, (state, action) => {
                       state.error = null
                       state.user = action.payload
                   })
                   .addCase(getUser.rejected, (state, action) => {
                       state.error = action.payload
                   }) */
        builder.addCase(logout.fulfilled, (state, action) => {
            Cookies.remove('auth')
            window.location.replace("/login")
            state.isAuth = false
        })
    }
})

export const { setIsAuth, setUser, setVisited, setError } = authSlice.actions
export default authSlice.reducer
