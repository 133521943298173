import { Chart } from "primereact/chart"
import { useEffect, useMemo, useState } from "react";
import { Dropdown } from 'primereact/dropdown';

import './Audience.css'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from 'primereact/divider';
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { Portrait } from "../../components/Portrait/Portrait";
import { SelectDate } from "../../components/SelectDate/SelectDate";
import { useDispatch, useSelector } from "react-redux";
import { CardNewUsers } from "../../components/CardNewUsers/CardNewUsers";
import { Filters } from "../../components/Filters/Filters";
import { Sidebar } from "primereact/sidebar";
import { formatDate } from "../../service/formatDate";
import axios from 'axios'
import { Diagram } from "../SmartAnswers/Diagram";
import { DiagramAudience } from "./DiagramAudience";
import { SkeletonDate } from "../../components/SkeletonDate/SkeletonDate";
import { SkeletonDiagram } from "../../components/SkeletonDiagram/SkeletonDiagram";
import { Skeleton } from "primereact/skeleton";
import { log } from "../../service/log";
import { StatisticsAudience } from "./StatisticsAudience";
import { PaginatorTable } from "../../components/PaginatorTable/PaginatorTable";
import { getCoverage, getReaders } from "../../service/slices/readersSlice";

export const Audience = () => {

    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const start = useSelector((state) => state.selectDate.start)
    const end = useSelector((state) => state.selectDate.end)
    const selectDate = useSelector((state) => state.selectDate.selectDate)
    const users = useSelector((state) => state.readers.readers)

    const [selected, setSelected] = useState({ name: 'По мессенджерам' });
    const [selectedUser, setSelectedUser] = useState(null)
    const [maximize, setMaximize] = useState(localStorage.getItem('maximize-audience') ? JSON.parse(localStorage.getItem('maximize-audience')) : false)
    const [displayBasic, setDisplayBasic] = useState(false);
    const [tagName, setTagName] = useState('')
    const [usedTunnels, setUsedTunnels] = useState('')
    const [showFilters, setShowFilters] = useState(false);
    /*  const [users, setUsers] = useState([]) */
    const [messangers, setMessangers] = useState(null)
    const [devices, setDevices] = useState(null)
    const [dataUsers, setDataUsers] = useState(null)

    const [formatStart, setFormatStart] = useState(new Date(start))
    const [formatEnd, setFormatEnd] = useState(new Date(end))

    const [totalPages, setTotalPages] = useState(null)
    const [currentPage, setCurrentPage] = useState(0)
    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(null);

    const dispatch = useDispatch()

    useEffect(() => {
        document.title = 'Bot Magic | Аудитория'
    }, [])

    useEffect(() => {
        setFormatStart(new Date(start))
        setFormatEnd(new Date(end))
    }, [start, end])

    useEffect(() => {
        localStorage.setItem('maximize-audience', maximize)
        if (maximize) {
            setBasicRows(13)
        } else {
            setBasicRows(8)
        }
    }, [maximize])

    useEffect(() => {

        axios.get(`${process.env.REACT_APP_PUBLIC_URL}/data.json`)
            .then((response) => {
                log(response.data)
                setDataUsers(response?.data?.users)
                //   setMessangers(response.data.audience.messangers)
                /* setDevices(response.data.audience.devices) */
            })
            .catch((error) => console.error(error))

        const data = {
            currentPage, currentPage,
            basicRows: basicRows
        }
        dispatch(getReaders(data))


    }, [currentPage, basicRows])

    console.log(messangers)

    useEffect(() => {
        dispatch(getCoverage())

    }, [])


    const select = [
        { name: 'По мессенджерам' },
        { name: 'По устройствам', disabled: true }
    ];

    const dataMessangers = messangers?.map((item) => {
        return item.coverage
    })

    const dataDevices = devices?.map((item) => {
        return item.coverage
    })

    const backgroundColor = [
        "#007AFF",
        "#FFE66D",
        "#566476",
        "#EF3E36"
    ]

    const registrationTemplate = (rowData) => {
        const date = new Date(rowData.created_at);
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()
        const hours = date.getHours()
        const minutes = date.getMinutes()

        const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']


        return (
            <div>{day} {months[month]} {year} в {hours}:{minutes}</div>
        )
    }

    const contactsTemplate = (rowData) => {
        return (
            <>
                {/*  <p className="mb-0">{rowData.contacts.phone ? rowData.contacts.phone : '-'}</p>
                {rowData.contacts.email ? <a className="email" href="#">{rowData.contacts.email}</a> : '-'} */}
            </>
        )
    }

    const tagsTemplate = (rowData) => {
        return (
            <>
                {rowData.tags ?
                    <>
                        {rowData.tags.map((item, index) => {
                            return <span key={index} className="mb-0">{item.tag}; </span>
                        })}
                    </>
                    :
                    <p className="mb-0">none</p>
                }

            </>
        )
    }

    const renderFooter = () => {
        return (
            <>
                <h6 className="mr-auto">Фильтры</h6>
                <div className="flex justify-content-between">
                    <div className="filters">
                        <Filters />
                    </div>

                    <div>
                        <Button label="Отменить" onClick={() => setDisplayBasic(false)} className="btn-main p-button-text py-3 px-4 mr-3" />
                        <Button label="Сохранить" onClick={() => setDisplayBasic(false)} autoFocus className="btn-save py-3 px-4" />
                    </div>

                </div>
            </>

        );
    }

    const isDesktop = () => {
        return window.innerWidth >= 1200
    }

    const skeleton = [15, 10, 7, 12, 15, 10]

    const skeletonOneTemplate = (rowData) => {
        return <Skeleton width={`${rowData}rem`} className='mb-3'></Skeleton>
    }
    const skeletonTemplate = (number) => {
        return <Skeleton width={`${number}rem`} className='mb-3'></Skeleton>
    }

    return (
        <div className="grid">
            <div className="left-column col-12 xl:col-8">
                <StatisticsAudience messangers={messangers} users={users} maximize={maximize} formatStart={formatStart} formatEnd={formatEnd} selectDate={selectDate} />
                {users ?
                    <div className="card">
                        <div className="flex justify-content-between w-full">
                            <div className="flex flex-column">
                                <div className="flex align-items-center w-full">
                                    <div>
                                        <div className="hidden md:block lg:hidden xl:block">
                                            <Filters />
                                        </div>
                                        <Button label="Фильтры" className="block md:hidden lg:block xl:hidden btn-tag p-button-outlined py-3 block md:hidden lg:block xl:hidden mr-2 sm:mr-5" onClick={() => setShowFilters(!showFilters)} />
                                    </div>



                                </div>
                                <div className={showFilters ? 'block md:hidden lg:block xl:hidden py-3' : 'hidden'}>
                                    <Filters />
                                </div>
                            </div>

                            <div>
                                <Button icon={maximize ? "pi pi-window-minimize" : "pi pi-window-maximize"} className="p-button-text maximize hidden lg:block" onClick={() => setMaximize(!maximize)} />
                            </div>
                        </div>

                        <Divider />
                        <DataTable selectionMode="single" selection={selectedUser} onSelectionChange={e => setSelectedUser(e.value)} value={users} responsiveLayout="scroll">
                            <Column field="first_name" header="Имя"></Column>
                            <Column body={tagsTemplate} header="Теги"></Column>
                            <Column body={registrationTemplate} header="Дата, время регистрации"></Column>
                            {/*    <Column field="sales" header="Продажи"></Column> */}
                            <Column body={contactsTemplate} header="Контакты"></Column>
                        </DataTable>
                        {users.length !== 0 &&
                            <PaginatorTable
                                totalPages={totalPages}
                                basicFirst={basicFirst}
                                basicRows={basicRows}
                                setBasicFirst={setBasicFirst}
                                setCurrentPage={setCurrentPage}
                            />}
                    </div>
                    :
                    <div className="card">
                        <div className="flex justify-content-between w-full">
                            <div className="flex flex-column">
                                <div className="flex align-items-center w-full">
                                    <div>
                                        <div className="hidden md:block lg:hidden xl:block">
                                            <div className="grid">
                                                <div className="flex flex-wrap justify-content-start">
                                                    <div className="flex flex-column align-items-start mr-5 mt-3">
                                                        <Skeleton width="4rem" className="mb-2"></Skeleton>
                                                        <div className="flex">
                                                            <Skeleton size="1.5rem" className="mr-2" />
                                                            <Skeleton size="1.5rem" />
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-column align-items-start justify-content-between mb-1 mr-5 mt-3">
                                                        <Skeleton width="4rem" className="mb-2"></Skeleton>
                                                        <Skeleton width="3rem" height="1.5rem" />
                                                    </div>

                                                    <div className="flex flex-column align-items-start justify-content-between mb-1 ml-2 mr-5 mt-3">
                                                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                        <Skeleton width="3rem" height="1.5rem" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <Skeleton width="7rem" className="mb-2"></Skeleton>
                                                        <div className="flex">
                                                            <Skeleton size="1.5rem" className="mr-2" />
                                                            <Skeleton size="1.5rem" className="mr-2" />
                                                            <Skeleton size="1.5rem" className="mr-2" />
                                                            <Skeleton size="1.5rem" />
                                                        </div>
                                                    </div>


                                                </div>


                                            </div>

                                        </div>

                                    </div>

                                    <div className="ml-0 md:ml-5 lg:ml-0 xl:ml-5 flex justify-content-start w-max lg:w-full xl:w-max">
                                        <Skeleton width="8rem" height="3rem" />

                                    </div>

                                </div>
                            </div>

                            <div className="hidden lg:block">
                                <Skeleton size="1.5rem" />
                            </div>
                        </div>

                        <Divider className={`${colorTheme}`} />
                        <DataTable selectionMode="single" value={skeleton} responsiveLayout="scroll">
                            <Column field="name" body={skeletonOneTemplate}></Column>
                            <Column field="tags" body={() => skeletonTemplate(5)}></Column>
                            <Column field="create_date" body={() => skeletonTemplate(9)}></Column>
                            <Column field="used" body={() => skeletonTemplate(3)}></Column>
                            <Column className="w-2" body={() => skeletonTemplate(10)}></Column>
                        </DataTable>
                    </div>}
            </div>

            <Dialog className="dialog-tag w-12 sm:w-11 md:w-8 lg:w-6" header="Присвоить тег" visible={displayBasic} footer={renderFooter()} onHide={() => setDisplayBasic(false)}>
                <div className="grid">
                    <div className="col-6 pr-3">
                        <InputText className="w-full" value={tagName} onChange={(e) => setTagName(e.target.value)} placeholder="Имя тега" />
                        <p className="mt-3">Выберите название с которым вам будет удобно работать в дальнейшем.</p>
                    </div>
                    <div className="col-6 pl-3">
                        <InputText className="w-full" value={usedTunnels} onChange={(e) => setUsedTunnels(e.target.value)} placeholder="Используемые туннели" />
                        <p className="mt-3">Вы можете выбрать туннели в которых учавствовал или участвует пользователь - система автоматически присвоит им тег.</p>
                    </div>
                </div>
            </Dialog>

            <div className="right-column  hidden">
                <div className="right-column fixed pr-5 pt-0">
                    {users ? <>
                        {!selectedUser ? <div className="card preview">
                            <div className="h-full  px-8 flex flex-column justify-content-center align-items-center">
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/person.fill1.${colorTheme}.svg`}></img>
                                <p className="text-no_selected text-center mt-3">Выберите пользователя и посмотрите более подробную информацию о нем</p>
                            </div>

                        </div>
                            :
                            <div className="card preview lg:col-5 xl:col-4 w-full pl-2 pr-3">
                                <Portrait activeChat={selectedUser} dataUsers={dataUsers[0]} /* setUsers={setUsers} */ display='hidden' className='message-list' />

                            </div>
                        }
                    </>
                        :
                        <div className="card w-full preview flex flex-column justify-content-center align-items-center lg:col-5 xl:col-4 pr-3">
                            <div className="flex flex-column align-items-center">
                                <Skeleton size="8rem" className="mb-3"></Skeleton>
                                <Skeleton width="20rem" height="1.5rem" className="mb-2"></Skeleton>
                                <Skeleton width="15rem" height="1.5rem" className="mb-2"></Skeleton>
                            </div>

                        </div>}

                </div>

            </div>
            {!isDesktop() &&
                <Sidebar visible={selectedUser} position="right" className="popover" onHide={() => setSelectedUser(false)}>
                    <Portrait activeChat={selectedUser} display='hidden' className='sidebar' />
                </Sidebar>}
        </div>
    )
}