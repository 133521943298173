import { Button } from "primereact/button"
import { Sidebar } from "primereact/sidebar"
import { ScrollPanel } from "primereact/scrollpanel"
import React, { memo, useState } from "react"
import { Handle } from "react-flow-renderer"
import { useParams } from "react-router-dom"
import CreateForm from "../Sidebars/CreateFormBlock/CreateForm"
import { slicedText } from "../utils"
import "./Blocks.scss"
import { useSelector } from "react-redux"

export default memo((props) => {
    const params = useParams()
    const funnelId = +params?.id

    const data = props.data
    data.id = props.id
    data.position = { x: props.xPos, y: props.yPos }

    const [visibleFormSidebar, setVisibleFormSidebar] = useState(false)
    const user = useSelector((state) => state.user.user)
    const openWebform = () => {
        window.open(`https://static.bmagic.ru/?formId=${data?.formUniqueName}`, "_blank")
    }

    return (
        <div className='FormBlock' onDoubleClick={() => setVisibleFormSidebar(true)}>
            <Handle
                id='l'
                type='target'
                className='custom-handle'
                position='left'
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={true}
            />
            <div className='node-block'>
                <div className='header'>
                    <h3>{slicedText(data?.title, 25) || "Форма"}</h3>
                    <div className='button-container'>
                        <Button className={`p-button-text  `} onClick={() => setVisibleFormSidebar(true)}>
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.svg`}></img>
                        </Button>
                    </div>
                </div>
                <div className='body'>
                    <ScrollPanel style={{ width: "100%", height: "50px" }}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: data?.text,
                            }}
                        ></p>
                    </ScrollPanel>
                </div>
                <div className='footer'>
                    <Button className={`p-button-text `} label='Посмотреть' onClick={() => openWebform()} />
                </div>
            </div>
            <Handle
                id='r'
                type='source'
                className='custom-handle'
                position='right'
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={true}
            />
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleFormSidebar}
                onHide={() => setVisibleFormSidebar(false)}
                position='right'
            >
                <CreateForm onHide={() => setVisibleFormSidebar(false)} funnelId={funnelId} data={data} />
            </Sidebar>
        </div>
    )
})
