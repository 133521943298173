import { Button } from "primereact/button"
import { Link } from "react-router-dom"

export const MailLetter = ({ setShowNextStep, setNewPassword }) => {

    const saveHandler = () => {
        setShowNextStep(false)
        /*  setNewPassword(true) */
    }

    return (
        <>
            <p className='registration-text mt-5 mb-6'>
                На ваш Email отправлено письмо для подтверждения. Откройте его и перейдите по предложенной ссылке.
            </p>
            <Link to={'/login'}>
                <Button
                    label='Продолжить'
                    className="w-full sign_in py-3"
                    onClick={saveHandler} />
            </Link>

        </>
    )
}