import React, { useState, useEffect } from "react"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { getAllCompanyBots } from "../../../../service/funnels"
import { useSelector } from "react-redux"

const ChannelButton = ({ bot, setBot }) => {
    // const [bot, setBot] = useState("")
    const [bots, setBots] = useState("")
    const [messenger, setMessenger] = useState("")
    const user = useSelector((state) => state.user.user)

    useEffect(() => {
        getAllCompanyBots().then((res) => {
            formatingBots(res.data)
        })
    }, [])

    const formatingBots = (arrayBots) => {
        const arr = arrayBots
        let newArr = [{ label: "Бот не назначен", value: "" }]
        arr.map((item) => {
            const edited = {
                label: item.title ? item.title : item.botID,
                value: item.id,
            }
            newArr.push(edited)
        })
        setBots(newArr)
    }

    const channelOptions = [
        { label: "Канал 1", value: "channel1" },
        { label: "Канал 2", value: "channel2" },
        { label: "Канал 3", value: "channel3" },
    ]
    const messengers = [
        { label: "Telegram", value: "telegram" },
        { label: "WhatsApp", value: "whatsapp" },
        { label: "Viber", value: "viber" },
    ]

    const messengersItem = (option) => {
        return (
            <div className='country-item'>
                <img
                    alt={option.name}
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/telegram.svg`}
                    onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                    className={`flag}`}
                />
                <div>{option.label}</div>
            </div>
        )
    }
    const selectedMessenger = (option, props) => {
        if (option) {
            return (
                <div className='country-item'>
                    <img
                        alt={option.name}
                        src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/telegram.svg`}
                        onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
                        className={`flag}`}
                    />
                    {/* <div>{option.label}</div> */}
                </div>
            )
        }

        return <span>{props.placeholder}</span>
    }

    return (
        <div>
            <div className='create-channel-header'>
                <h5 className='mb-2'>Переход в бот</h5>
                {/* <Button className='p-button-rounded button-delete' icon='pi pi-times' onClick={() => deleteBotButton(id)} /> */}
            </div>
            {/* <div className='mb-3'>
                <Dropdown
                    className='select w-full'
                    value={messenger}
                    options={messengers}
                    onChange={(e) => setMessenger(e.value)}
                    itemTemplate={messengersItem}
                    valueTemplate={selectedMessenger}
                    placeholder='Выберите мессенджер'
                />
            </div> */}
            <div>
                <Dropdown
                    className='select w-full'
                    value={bot}
                    options={bots}
                    onChange={(e) => setBot(e.value)}
                    placeholder='Выберите бота'
                    disabled={user?.role === 'employer'}
                />
            </div>
        </div>
    )
}

export default ChannelButton
