import { Button } from "primereact/button"
import { confirmPopup } from "primereact/confirmpopup"
import { Toast } from "primereact/toast"
import React, { useEffect, useRef, useState } from "react"

import { deleteVariable, getVariables } from "../../../../service/funnels"
import SidebarHeader from "../components/SidebarHeader"
import "../Sidebar.scss"
import AddVariable from "./AddVariable"

const CreateVariables = ({ onHide, funnelId }) => {
    const toast = useRef(null)
    const [variables, setVariables] = useState(null)

    const [selectedVariable, setSelectedVariable] = useState({})
    const [addVariable, setAddVariable] = useState(false)

    useEffect(() => {
        getVariables(funnelId).then((res) => setVariables(res))
    }, [])

    const createHandler = () => {
        setSelectedVariable({})
        setAddVariable(true)
    }

    const editHandler = (variable) => {
        setSelectedVariable(variable)
        setAddVariable(true)
    }

    const deleteHandler = (variableID) => {
        const variableToDelete = {
            blueprintID: funnelId,
            variableID: variableID,
        }
        deleteVariable(variableToDelete).then((res) => getVariables(funnelId).then((res) => setVariables(res)))
        toast.current.show({ severity: "success", summary: "Успешно", detail: "Переменная удалена", life: 3000 })
    }

    const deleteConfirm = (event, variable) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Вы уверены, что хотите удалить переменную?",
            icon: "pi pi-exclamation-triangle",
            accept: () => deleteHandler(event.variableID),
        })
    }

    return (
        <div className='new-node-container variables flex flex-column justify-content-start'>
            <Toast ref={toast} />
            <SidebarHeader title='Переменные' icon={"variables"} onHide={() => onHide()} isBorder={true} />

            <div
                className='block variables-info mt-2'
                style={{
                    background: `url('${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/funnels/variables-fon.svg') no-repeat bottom`,
                    backgroundSize: "contain",
                }}
            >
                <h2 className='text-left'>Здесь отображаются ваши переменные</h2>
                <p>Их можно изменить, добавить, удалить удобно на этой странице</p>
            </div>

            <div className='mt-3 mb-3'>
                {!addVariable && (
                    <div className='block flex flex-column justify-content-between'>
                        <Button label='Создать переменную' className='btn py-3 px-4' onClick={() => createHandler()} />
                    </div>
                )}
                {addVariable && (
                    <AddVariable
                        funnelId={funnelId}
                        selectedVariable={selectedVariable}
                        setAddVariable={setAddVariable}
                        setVariables={setVariables}
                    />
                )}
                {!addVariable && (
                    <>
                        <div className='variables-list-header'>
                            <div>Название/тип</div>
                            <div>Начальное значение</div>
                        </div>
                        {variables?.map((variable) => (
                            <div
                                key={variable.variableID}
                                className='variable-item'
                                style={!variable?.canDelete ? { opacity: "1" } : { opacity: "0.5" }}
                            >
                                <div className='variable-container'>
                                    <div className='variable-info'>
                                        <div
                                            className='variable-name'
                                            style={!variable?.canDelete ? { color: "#28cd41" } : { color: "gray" }}
                                        >
                                            {variable.name}
                                        </div>
                                        <div className='variable-type'>{variable.type}</div>
                                    </div>
                                    <div className='variable-values'>
                                        <div>{variable.initialValue}</div>
                                    </div>
                                </div>
                                <div className='variable-buttons'>
                                    <Button className='var-btn edit' icon='pi pi-pencil' onClick={() => editHandler(variable)} />
                                    <Button
                                        disabled={!variable?.canDelete}
                                        className='var-btn delete'
                                        icon='pi pi-trash'
                                        onClick={() => deleteConfirm(variable)}
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
            <div className='flex flex-column justify-content-between sidebar-buttons'>
                <Button label='Закрыть' className='btn-cancel p-button-text py-3 px-4' onClick={() => onHide()} />
            </div>
        </div>
    )
}

export default CreateVariables
