import { Button } from "primereact/button"
import { InputTextarea } from "primereact/inputtextarea"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { v4 as uuid } from "uuid"
import { createNode, getFunnel, updateNode } from "../../../service/funnels"
import { setInitNodes } from "../../../service/slices/initNodesSlice"
import SidebarFooter from "./components/SidebarFooter"
import SidebarHeader from "./components/SidebarHeader"
import "./Sidebar.scss"

const CreateComment = ({ data, onHide, funnelId, centerCoordinates }) => {
    const dispatch = useDispatch()

    const [blockText, setBlockText] = useState(data?.text || "")

    // Добавление нового блока

    const saveHandler = () => {
        const comment = {
            blueprintID: funnelId,
            text: blockText,
            data: { text: blockText },
        }
        if (data) {
            const updatedComment = { ...comment, uuid: data?.id }
            updateNode(updatedComment, "comment").then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes))))
        } else {
            const addComment = {
                ...comment,
                uuid: uuid(),
                isInterval: false,
                position: centerCoordinates,
            }
            createNode(addComment, "comment").then((res) => getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes))))
        }

        onHide()
        setBlockText("")
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Комментарий' icon={"comment"} onHide={() => onHide()} isBorder={true} />
            <div className='block mt-1'>
                <div className='field w-full'>
                    <span className='p-float-label'>
                        <InputTextarea
                            id='comment'
                            autoResize={true}
                            className='text-area w-full'
                            value={blockText}
                            onChange={(e) => setBlockText(e.target.value)}
                        />
                        <label htmlFor='comment'>Текст комментария</label>
                    </span>
                </div>
            </div>

            <SidebarFooter saveHandler={() => saveHandler()} disabled={!blockText} onHide={() => onHide()} />
        </div>
    )
}

export default CreateComment
