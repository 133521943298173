import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup"
import { InputSwitch } from "primereact/inputswitch"
import { Toast } from "primereact/toast"
import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { log } from "../../../service/log"
import { disableAllAnswers } from "../../../service/slices/smartAnswersSlice"
import { enableAllAnswers } from "../../../service/smartAnswers"

export const AdditionalSettings = () => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const [allSmartAnswers, setAllSmartAnswers] = useState(false)
    const toast = useRef(null);

    const dispatch = useDispatch()
    const accept = (e) => {
        if (!allSmartAnswers) {
            dispatch(disableAllAnswers())
                .then((res) => log(res))
        } else {
            dispatch(enableAllAnswers())
                .then((res) => log(res))
        }
        setAllSmartAnswers(!allSmartAnswers)
        toast.current.show({ severity: 'info', summary: 'Выключено', detail: `Все умные ответы ${allSmartAnswers ? 'включены' : 'выключены'} `, life: 3000 });

    };

    const reject = () => {

    };

    const removeHandler = (e) => {

        confirmPopup({
            target: e.currentTarget,
            message: `Вы действительно хотите ${allSmartAnswers ? 'включить' : 'выключить'} все умные ответы`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Да',
            rejectLabel: 'Нет',
            accept: () => accept(e),
            reject
        });
    };
    return (
        <div className="card setting">
            <ConfirmPopup />
            <Toast ref={toast} />
            <h2 className="settings-title mb-5">Дополнительные настройки</h2>

            <div className="grid justify-content-start mt-3">
                <div className="col-12 lg:col-5">
                    <div className="flex align-items-center">
                        <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={allSmartAnswers} /* onChange={removeHandler}  */ onClick={removeHandler} />
                        <span className='switch-label'>{!allSmartAnswers ? 'Выключить' : 'Включить'} все умные ответы</span>

                    </div>
                    <p className='mt-3 setting-description w-11'>Возможность выключить все настроенные умные ответы. </p>
                </div>
            </div>
        </div>
    )
}