import { Dropdown } from 'primereact/dropdown'
import './AddMailing.css'
import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import { InputSwitch } from 'primereact/inputswitch';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { AddTagsMailing } from './AddTagsMailing';
import { UploadFileMailing } from '../components/UploadFileMailing/UploadFileMailing';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { MultiSelect } from 'primereact/multiselect';
import { createMailing, getAllMailings } from '../../../service/slices/mailingsSlice';
import { useEffect } from 'react';
import { getAllBlueprints, getAllCompanyBots } from '../../../service/funnels';


export const AddMailing = ({ setCreate, setSelectedMailing, setCurrentPage, setBasicFirst, basicRows }) => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const bots = useSelector((state) => state.bots.bots)
    const [selectedBot, setSelectedBot] = useState(null);
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [filterDate, setFilterDate] = useState(false)
    const [filterFunnel, setFilterFunnel] = useState(false)
    const [filterTag, setFilterTag] = useState(false)
    const [dateStart, setDateStart] = useState(null)
    const [dateFinish, setDateFinish] = useState(null)
    const [textBtn, setTextBtn] = useState('')
    const [urlBtn, setUrlBtn] = useState('')
    const [tags, setTags] = useState([])
    const [funnels, setFunnels] = useState([])
    /* const [bots, setBots] = useState([]) */
    /* const [selectedBot, setSelectedBot] = useState(null) */
    const dispatch = useDispatch()

    const [selectedFunnel, setSelectedFunnel] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(null)
    const [dateUntil, setDateUntil] = useState(null)

    const [sendNow, setSendNow] = useState(false)
    const [postponeSending, setPostponeSending] = useState(false)

    const [noSelectedBots, setNoSelectedBots] = useState(false)
    const totalPages = useSelector((state) => state.mailings.totalPages)
    /*     const bots = [
            { name: 'bot1', code: 'b1' },
            { name: 'bot2', code: 'b2' },
            { name: 'bot3', code: 'b3' },
            { name: 'bot4', code: 'b4' },
            { name: 'bot5', code: 'b5' }
        ]; */

    /*     const funnels = [
            { name: 'funnels1', code: 'f1' },
            { name: 'funnels2', code: 'f2' },
            { name: 'funnels3', code: 'f3' },
            { name: 'funnels4', code: 'f4' },
            { name: 'funnels5', code: 'f5' }
        ]; */

    useEffect(() => {
        getAllBlueprints().then((res) => {
            setFunnels(res.data.blueprints)
        })
        /* 
                getAllCompanyBots()
                    .then((res) => setBots(res.data)) */
    }, [])

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="btn-editor ql-bold" aria-label="Bold"></button>
                <button className="btn-editor ql-italic" aria-label="Italic"></button>
                <button className="btn-editor ql-underline" aria-label="Underline"></button>
                <button className='btn-editor'>
                    <img src='assets/layout/images/add_reaction.svg' />
                </button>
            </span>
        );
    };

    const header = renderHeader();


    const botTemplate = (option) => {
        return (
            <div className="bot-item">
                <div>{option.title}</div>
            </div>
        );
    }

    const selectedBotsTemplate = (option) => {
        if (option) {
            return (
                <div className="bot-item bot-item-value">
                    <div>{option.title}</div>
                </div>
            );
        }

        return "Выберите бота";
    }

    const selectedFunnelTemplate = (option) => {
        if (option) {
            return (
                <div className="bot-item bot-item-value">
                    <div>{option.title}</div>
                </div>
            );
        }

        return "Название воронки";
    }

    const panelFooterTemplate = (name) => {
        const selectedItems = name === 'bot' ? selectedBot : selectedFunnel;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    }

    const saveHandler = (e) => {
        /*         if (!selectedBot) {
                    e.preventDefault()
                    setNoSelectedBots(true)
                } else { */
        const data = {
            title: title,
            text: text,
            buttonUrl: urlBtn,
            buttonCaption: textBtn,
            tags: tags,
            from: dateStart ? new Date(dateStart).toString() : null,
            to: dateFinish ? new Date(dateFinish).toString() : null,
            botIDs: selectedBot?.map((item) => item.id),
            blueprintIDs: selectedFunnel?.map((item) => item.id),
            files: fileUploaded?.map((item) => {
                return {
                    type: item.type.split("/")[0],
                    path: item.name,
                    media: "string"
                }
            }),
            start: sendNow,
            when: dateUntil ? new Date(dateUntil).toString() : null
        }
        dispatch(createMailing(data))
            .then(() => {
                dispatch(getAllMailings())
                setCreate(false)
                setCurrentPage(totalPages)
                setBasicFirst((totalPages - 1) * basicRows)
            })
            .then(() => {
                const data = {
                    currentPage: totalPages === 0 ? 0 : totalPages - 1,
                    basicRows: basicRows
                }
                dispatch(getAllMailings(data))
                    .then((res) => {
                        console.log(res.payload.content)
                        setSelectedMailing(res.payload.content[res.payload.content.length - 1])
                    })
            })
        /*   } */
    }


    const selectedBotHandler = (e) => {
        setSelectedBot(e.value)
        setNoSelectedBots(false)
    }

    return (
        <>
            <h5 className="title-mailing">Создание новой рассылки</h5>
            <div className="flex flex-column justify-content-between">
                <div className="add-mailing_scroll">
                    <ScrollPanel style={{ width: '100%', height: '100%' }} className="custom">
                        <div className='choice-bot'>
                            <h6 className="subtitle-mailing">Бот для отправки</h6>
                            <MultiSelect
                                value={selectedBot}
                                options={bots}
                                onChange={(e) => selectedBotHandler(e)}
                                optionLabel="title"
                                placeholder="Выберите бота"
                                filter
                                className={noSelectedBots ? "p-invalid droppdown-mailings" : 'droppdown-mailings'}
                                itemTemplate={botTemplate}
                                selectedItemTemplate={selectedBotsTemplate}
                                panelFooterTemplate={() => panelFooterTemplate('bot')}
                            />
                            {noSelectedBots && <small id="title-help" className="p-error block">Выберите бота</small>}
                        </div>


                        <div className='title-mailing'>
                            <h6 className="subtitle-mailing">Название</h6>
                            <InputText
                                className='input-mailings'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder='Название рассылки'
                            />
                        </div>
                        <div className='text-mailing'>
                            <h6 className="subtitle-mailing">Текст сообщения</h6>
                            <Editor
                                value={text}
                                onTextChange={(e) => setText(e.htmlValue)}
                                headerTemplate={header}
                                placeholder='Текст сообщения'
                            />
                        </div>
                        <div>
                            <UploadFileMailing fileUploaded={fileUploaded} setFileUploaded={setFileUploaded} />
                        </div>
                        <div className='button-mailing'>
                            <h6 className="subtitle-mailing">Кнопка</h6>
                            <InputText
                                className='input-mailings'
                                value={textBtn}
                                onChange={(e) => setTextBtn(e.target.value)}
                                placeholder='Текст на кнопке'
                            />
                            <InputText
                                className='input-mailings mt-3'
                                value={urlBtn}
                                onChange={(e) => setUrlBtn(e.target.value)}
                                placeholder='Введите URL кнопки'
                            />
                        </div>
                        <div className="filters">
                            <h6 className="subtitle-mailing">Фильтры отправки</h6>
                            <div className="filter-item">
                                <div className="filter-item_closed">
                                    <h6 className="subtitle-mailing">По дате регистрации</h6>
                                    <InputSwitch
                                        className={`settings-switch ${colorTheme}`}
                                        checked={filterDate}
                                        onChange={(e) => setFilterDate(e.value)}
                                    />
                                </div>

                                {filterDate &&
                                    <>
                                        <div className='choice-date'>
                                            <Calendar
                                                className={`choice-date_calendar`}
                                                showIcon
                                                value={dateStart}
                                                onChange={(e) => setDateStart(e.value)}
                                                placeholder='Дата начала'
                                            />
                                            <Calendar
                                                className={`choice-date_calendar`}
                                                showIcon
                                                value={dateFinish}
                                                onChange={(e) => setDateFinish(e.value)}
                                                placeholder='Дата окончания'
                                            />
                                        </div>
                                        <p className='mailing-warning_text'>Обратите внимание при выборе дат. Если выбрать только дату начала, то рассылка будет отправлена пользователям зарегистрировавшимся после выбранной даты. А если только дату окончания, то все пользователи добавленные до этой даты. Больше в справке</p>
                                    </>

                                }
                            </div>

                            <div className="filter-item">
                                <div className="filter-item_closed">
                                    <h6 className="subtitle-mailing">По участникам воронки</h6>
                                    <InputSwitch
                                        className={`settings-switch ${colorTheme}`}
                                        checked={filterFunnel}
                                        onChange={(e) => setFilterFunnel(e.value)}
                                    />
                                </div>

                                {filterFunnel &&
                                    <div className='choice-funnel'>
                                        <MultiSelect
                                            value={selectedFunnel}
                                            onChange={(e) => setSelectedFunnel(e.value)}
                                            options={funnels}
                                            optionLabel="title"
                                            placeholder="Название воронки"
                                            filter
                                            className='droppdown-mailings'
                                            itemTemplate={botTemplate}
                                            selectedItemTemplate={selectedFunnelTemplate}
                                            panelFooterTemplate={() => panelFooterTemplate('funnel')}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="filter-item">
                                <div className="filter-item_closed">
                                    <h6 className="subtitle-mailing">По присвоенному тегу</h6>
                                    <InputSwitch
                                        className={`settings-switch ${colorTheme}`}
                                        checked={filterTag}
                                        onChange={(e) => setFilterTag(e.value)}
                                    />
                                </div>

                                {filterTag &&
                                    <div className='choice-tags'>
                                        <AddTagsMailing tags={tags} setTags={setTags} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='sending'>
                            <h6 className="subtitle-mailing">Время отправки</h6>
                            <div className="filter-item">
                                <div className="filter-item_closed">
                                    <h6 className="subtitle-mailing">Отправить сейчас</h6>
                                    <InputSwitch
                                        disabled={postponeSending}
                                        className={`settings-switch ${colorTheme}`}
                                        checked={sendNow}
                                        onChange={(e) => setSendNow(e.value)}
                                    />
                                </div>
                            </div>

                            <div className="filter-item until-date_block">
                                <div className="filter-item_closed">
                                    <h6 className="subtitle-mailing">Отложить до даты</h6>
                                    <InputSwitch
                                        disabled={sendNow}
                                        className={`settings-switch ${colorTheme}`}
                                        checked={postponeSending}
                                        onChange={(e) => setPostponeSending(e.value)}
                                    />
                                </div>
                                {postponeSending &&
                                    <div className='until-date'>
                                        <Calendar
                                            className={`until-date_calendar`}
                                            showIcon
                                            value={dateUntil}
                                            onChange={(e) => setDateUntil(e.value)}
                                            placeholder='Выберите дату'
                                            showTime
                                            hourFormat="24"
                                        />
                                    </div>
                                }
                            </div>

                        </div>
                    </ScrollPanel>
                </div>

                <div className="footer-btns">
                    <Button
                        className="mailing-btn_cancel"
                        label="Отменить"
                        onClick={() => setCreate(false)}
                    ></Button>
                    <Button
                        className="mailing-btn_sent"
                        label="Сохранить"
                        onClick={saveHandler}
                    ></Button>
                </div>
            </div>
        </>
    )
}