import { Skeleton } from "primereact/skeleton"

export const SkeletonCard = () => {
    return (
        <div className='card h-11rem mb-0 flex flex-column justify-content-between'>
            <div className="flex justify-content-between">
                <Skeleton width="14rem" height="1.5rem" className="mb-2"></Skeleton>
                <Skeleton size="1.5rem"></Skeleton>
            </div>
            <Skeleton width="10rem" height="4rem"></Skeleton>
        </div>
    )
}