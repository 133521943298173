import { Button } from "primereact/button"
import { Sidebar } from "primereact/sidebar"
import React, { memo, useState } from "react"
import { Handle } from "react-flow-renderer"
import { useParams } from "react-router-dom"
import CreateRedirect from "../Sidebars/CreateRedirect"
import { slicedText } from "../utils"
import "./Blocks.scss"
import { useSelector } from "react-redux"

export default memo((props) => {
    const params = useParams()
    const funnelId = +params?.id

    const data = props.data
    data.id = props.id
    // data.position = { x: props.xPos, y: props.yPos }

    const [visibleRedirectSidebar, setVisibleRedirectSidebar] = useState(false)
    const user = useSelector((state) => state.user.user)
    return (
        <div className='TransitionBlock' onDoubleClick={() => setVisibleRedirectSidebar(true)}>
            <Handle
                id='l'
                type='target'
                className='custom-handle'
                position='left'
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={true}
            />
            <div className='node-block'>
                <div className='header'>
                    <h3>{slicedText(data?.title, 15) || "Переход"}</h3>
                    <div className='button-container'>
                        <Button className={`p-button-text`} onClick={() => setVisibleRedirectSidebar(true)}>
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.svg`}></img>
                        </Button>
                    </div>
                </div>
                <div className='body'>
                    <div>Редирект на воронку: </div>
                    <div>{data?.blueprintName}</div>
                </div>
            </div>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleRedirectSidebar}
                onHide={() => setVisibleRedirectSidebar(false)}
                position='right'
            >
                <CreateRedirect onHide={() => setVisibleRedirectSidebar(false)} funnelId={funnelId} data={data} />
            </Sidebar>
        </div>
    )
})
