import React, { useState, useEffect } from "react"
import { Checkbox } from "primereact/checkbox"
import { Dropdown } from "primereact/dropdown"
import { Editor } from "primereact/editor"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useSelector } from "react-redux"

const TextButton = ({ deleteButton, onChangeButton, button, isKeyboardButtons, buttonIndex }) => {
    // console.log("Кнопка в редакторе: ", button)
    const [buttonText, setButtonText] = useState(button?.text)
    const [buttonType, setButtonType] = useState(button?.type || "callback")
    const [isValidUrl, setIsValidUrl] = useState(true)
    const [buttonUrl, setButtonUrl] = useState(button?.url || "")
    const user = useSelector((state) => state.user.user)
    // const [buttonCallback, setButtonCallback] = useState(button?.callbackData)
    // const [waitingForPayment, setWaitingForPayment] = useState(false)

    // const [isButtons, setIsButtons] = useState(true)
    const options = [
        { label: "Перейти по ссылке", value: "url" },
        { label: "Перейти по воронке", value: "callback" },
    ]
    const keyboardOptions = [{ label: "Перейти по воронке", value: "callback" }]

    useEffect(() => {
        onChangeButton(button?.id, buttonText, buttonUrl, buttonType, buttonIndex)
    }, [buttonText, buttonUrl, buttonType])

    useEffect(() => {
        let regExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        const isValid = regExp.test(buttonUrl)
        setIsValidUrl(isValid)
    }, [buttonUrl])

    const renderHeader = () => {
        return (
            <span className='ql-formats'>
                <button className='ql-bold' aria-label='Bold'></button>
                <button className='ql-italic' aria-label='Italic'></button>
                <button className='ql-underline' aria-label='Underline'></button>
                <button className='ql-strike' aria-label='Strike'></button>
                <button className='ql-code' aria-label='Code'></button>
            </span>
        )
    }
    const header = renderHeader()

    return (
        <div className='create-button-container mb-3'>
            {(user?.role === "admin" || user?.role === "moderator") && (
                <div className='create-button-header'>
                    <h5>Редактирование кнопки {buttonIndex + 1}</h5>
                    <Button className='p-button-rounded button-delete' icon='pi pi-trash' onClick={() => deleteButton(button?.id)} />
                </div>
            )}
            <Editor
                className='mb-3'
                headerTemplate={header}
                style={{ height: "40px" }}
                value={buttonText}
                onTextChange={(e) => setButtonText(e.htmlValue?.replaceAll("style", "_style").replaceAll("&nbsp;", ""))}
                placeholder='Текст на кнопке'
                readOnly={user?.role === "employer"}
            />
            <Dropdown
                className='select w-full mb-3'
                value={buttonType}
                options={isKeyboardButtons ? keyboardOptions : options}
                onChange={(e) => setButtonType(e.value)}
                placeholder='Тип кнопки'
                disabled={user?.role === "employer"}
            />
            {!isKeyboardButtons && buttonType === "url" && (
                <div className='field mb-3'>
                    <InputText
                        className={`text w-full ${buttonUrl?.length !== 0 && !isValidUrl && "p-invalid"}`}
                        placeholder='Введите URL'
                        value={buttonUrl}
                        onChange={(e) => setButtonUrl(e.target.value)}
                        readOnly={user?.role === "employer"}
                    />
                    {buttonUrl?.length !== 0 && !isValidUrl && (
                        <small id='token-help' className='p-error'>
                            Неверный URL
                        </small>
                    )}
                    {/* <div className="field-checkbox">
                        <Checkbox inputId="binary" checked={waitingForPayment} onChange={(e) => setWaitingForPayment(e.checked)} />
                        <label htmlFor="binary">Ожидание оплаты</label>
                    </div> */}
                </div>
            )}
        </div>
    )
}

export default TextButton
