import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { CardAuth } from '../CardAuth';
import { Confirm, ConfirmSuccess } from '../ConfirmSuccess';
import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';
import { SuccessRegistration } from './SuccessRegistration';

const RegistrationPage = () => {
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)
    const employeeEmail = searchParams.get('email')

    const [showNextStep, setShowNextStep] = useState(false)
    const [success, setSuccess] = useState('')

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState(employeeEmail || '');
    const [password, setPassword] = useState('');

    const { params } = useParams()


    console.log(employeeEmail)
    return (

        <CardAuth>
            {!params
                && <>
                    {(!showNextStep && !success)
                        && <FirstStep
                            employeeEmail={employeeEmail}
                            setShowNextStep={setShowNextStep}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                            setSuccess={setSuccess}
                        />
                    }
                    {showNextStep
                        && <SecondStep
                            setShowNextStep={setShowNextStep}
                            setSuccess={setSuccess}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                        />
                    }
                    {success
                        && <SuccessRegistration />
                    }
                    {/* <p className='text-center mt-5 footer mb-2'>© Bots 2022</p> */}
                </>
            }
            {params === 'confirm'
                && <ConfirmSuccess />
            }

        </CardAuth>

    )
}

export default RegistrationPage