import axios from "axios"
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { registration, setError, validateEmail } from "../../../service/slices/authSlice"

export const FirstStep = ({ setShowNextStep, setFirstName, setLastName, setEmail, setPassword, firstName, lastName, email, password, employeeEmail, setSuccess }) => {
    const dispatch = useDispatch()
    const error = useSelector(state => state.auth.error)

    /* const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(''); */
    const [repeatPassword, setRepeatPassword] = useState('');

    const [firstNameDirty, setFirstNameDirty] = useState(false)
    const [emailDirty, setEmailDirty] = useState(false)
    const [passwordDirty, setPasswordDirty] = useState(false)
    const [passwordRepeatDirty, setPasswordRepeatDirty] = useState(false)

    const [firstNameError, setFirstNameError] = useState('Введите имя')
    const [emailError, setEmailError] = useState('Введите Email')
    const [passwordError, setPasswordError] = useState('Введите пароль')
    const [passwordRepeatError, setPasswordRepeatError] = useState('Повторите пароль')

    const [noValid, setNoValid] = useState(false)
    const [formValid, setFormValid] = useState(false)

    useEffect(() => {
        dispatch(setError(null))
    }, [error])

    useEffect(() => {
        if (employeeEmail) {
            setEmailDirty(true)
            setEmailError('')
        }
    }, [])

    useEffect(() => {
        if (firstNameError || emailError || passwordError || passwordRepeatError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [firstNameError, emailError, passwordError, passwordRepeatError])

    /*     const nextStepHandler = () => {
            let data = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
            }
    
            dispatch(registration(data)).then(res => {
                if (res.payload.access_token) {
                    setShowNextStep(true)
                } else {
                    setShowNextStep(false)
                    setNoValid(true)
                }
            })
        } */

    const firstNameHandler = (e) => {
        setFirstName(e.target.value)

        if (!e.target.value) {
            setFirstNameError('Введите имя')
        } else {
            setFirstNameError('')
        }
    }

    const lastNameHandler = (e) => {
        setLastName(e.target.value)
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
        dispatch(setError(null))

        const re = /^[_a-z0-9-\+-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i
        if (!e.target.value) {
            setEmailError('Введите Email')
        } else if (!re.test((e.target.value).toLowerCase())) {
            setEmailError('Некорректный Email')
        } else {
            setEmailError('')
        }
    }

    const passwordHandler = (e) => {
        setPassword(e.target.value)
        const re = /^(?=[^А-Яа-я\s]{9,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=[^А-Яа-я\s]+\d|\d)((?=[^А-Яа-я\s]+[a-z]|[a-z]){1})((?=[^А-Яа-я\s]+[A-Z]|[A-Z]){1})[^А-Яа-я\s]+$/
        if (!e.target.value) {
            setPasswordError('Введите пароль')
        } else if (!re.test((e.target.value))) {
            setPasswordError('Слабый пароль')
        } else {
            setPasswordError('')
        }
    }

    const passwordRepeatHandler = (e) => {
        setRepeatPassword(e.target.value)

        if (!e.target.value) {
            setPasswordRepeatError('Повторите пароль')
        } else if (password !== e.target.value) {
            setPasswordRepeatError('Пароли не совпадают')
        } else {
            setPasswordRepeatError('')
        }
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'firstName':
                setFirstNameDirty(true)
                break;
            /*  case 'email':
                 setEmailDirty(true)
                 break; */
            case 'password':
                setPasswordDirty(true)
                break;
            case 'repeatPassword':
                setPasswordRepeatDirty(true)
                break;
        }
    }

    const footer = (
        <>
            <Divider />
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>Хотя бы одна строчная латинская буква</li>
                <li>Хотя бы одна заглавная латинская буква</li>
                <li>Хотя бы одно число</li>
                <li>Хотя бы один специальный символ</li>
                <li>Длина не менее 6 символов</li>
            </ul>
        </>
    )

    const nextHandler = async () => {
        setShowNextStep(true)
    }

    const cancelHandler = () => {
        dispatch(setError(null))
    }
    console.log(emailError);

    const emailBlurHandler = () => {
        let data = {
            email: email,
        }

        setEmailDirty(true)

        if (email) {
            dispatch(validateEmail(data)).then(res => {
                console.log(res);
                if (res.payload) {
                    setEmailError('')
                } else {
                    setEmailError('Пользователь с таким Email уже существует')

                }
            })

        }

    }

    const registrationHandler = () => {
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            isInvited: true
        }

        dispatch(registration(data))
            .then((res) => {
                console.log(res);
                if (!res.payload) {
                    setSuccess(true)
                }

            })
        // axios.post(`https://bots.sdew.ru/auth/register`, data).then((res) => console.log(res))
        /*   setShowNextStep(false)
          setSuccess(true) */
    }
    console.log(employeeEmail)

    return (
        <>
            <div className='my-5 flex justify-content-between'>
                <p className="title">Регистрация</p>
                <p className="title">1 из 2</p>
            </div>

            <div>
                <InputText
                    value={firstName}
                    id="firstName"
                    name='firstName'
                    type="text"
                    className={`w-full ${(firstNameDirty && firstNameError) ? 'error-margin' : 'default-margin'}`}
                    placeholder='Введите Имя'
                    onChange={firstNameHandler}
                    onBlur={(e) => blurHandler(e)} />

                {(firstNameDirty && firstNameError)
                    && <small
                        className="p-error error-margin block">
                        {firstNameError}
                    </small>}

                <InputText
                    value={lastName}
                    id="lastName"
                    name='lastName'
                    type="text"
                    className={`w-full mb-4`}
                    placeholder='Введите Фамилию'
                    onChange={lastNameHandler} />

                <InputText
                    disabled={employeeEmail}
                    value={email}
                    id="email"
                    name='email'
                    type="text"
                    className={`w-full ${(emailDirty && emailError) ? 'error-margin' : 'default-margin'}`}
                    placeholder='Введите Email'
                    onChange={emailHandler}
                    onBlur={emailBlurHandler} />

                {(emailDirty && emailError)
                    && <small
                        className="p-error block error-margin">
                        {emailError}
                    </small>}

                <Password
                    value={password}
                    name='password'
                    className={`w-full ${(passwordDirty && passwordError) ? 'error-margin' : 'default-margin'}`}
                    placeholder='Введите пароль'
                    toggleMask
                    footer={footer}
                    onChange={passwordHandler}
                    onBlur={(e) => blurHandler(e)}
                    promptLabel={'Пожалуйста, введите пароль'}
                    weakLabel={'Слабый'}
                    mediumLabel={'Средний'}
                    strongLabel={'Сильный'}
                    strongRegex={'^(?=.*[0-9])(?=.*[!@#$%^&*.<>])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}'}
                />

                {(passwordDirty && passwordError)
                    && <small
                        className="p-error block error-margin">
                        {passwordError}
                    </small>}

                <Password
                    value={repeatPassword}
                    name='repeatPassword'
                    className={`w-full ${(passwordRepeatDirty && passwordRepeatError) || error ? 'error-margin' : 'default-margin'}`}
                    placeholder='Повторите пароль'
                    toggleMask
                    feedback={false}
                    onChange={passwordRepeatHandler}
                    onBlur={(e) => blurHandler(e)}
                />
                {(passwordRepeatDirty && passwordRepeatError)
                    && <small
                        className="p-error block error-margin">
                        {passwordRepeatError}
                    </small>}

                {error
                    && <small
                        className="p-error block error-margin">
                        {error}
                    </small>}
            </div>
            <div className='btns-block flex justify-content-between mt-5'>
                <Link to={'/login'}>
                    <Button
                        onClick={cancelHandler}
                        className="cancel-registration mr-3">
                        Отмена
                    </Button>
                </Link>
                <Button
                    className="next-step"
                    disabled={!formValid}
                    onClick={employeeEmail ? registrationHandler : nextHandler}>
                    {employeeEmail ? 'Регистрация' : 'Далее'}
                </Button>
            </div>
        </>
    )
}