import { Accordion, AccordionTab } from "primereact/accordion"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"
import { createNode, getAllBlueprints, getFunnel, updateNode } from "../../../service/funnels"

import { setInitNodes } from "../../../service/slices/initNodesSlice"
import SidebarFooter from "./components/SidebarFooter"
import SidebarHeader from "./components/SidebarHeader"
import "./Sidebar.scss"

const CreateRedirect = ({ onHide, data, funnelId, centerCoordinates }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)
    const [funnels, setFunnels] = useState(null)
    const [blockTitle, setBlockTitle] = useState(data?.title || "")
    const [funnel, setFunnel] = useState(data?.toBlueprintID?.toString())

    useEffect(() => {
        getAllBlueprints().then((res) => {
            const newArr = res?.data?.blueprints
                ?.map(({ title, id, ...n }) => ((n.label = title), (n.value = id.toString()), n))
                .filter((fnl) => +fnl.value !== funnelId)
            setFunnels(newArr)
        })
    }, [])

    // Добавление нового блока

    const saveHandler = () => {
        const redirectNode = {
            blueprintID: funnelId,
            toBlueprintID: +funnel,
            title: blockTitle,
            position: centerCoordinates,
            data: { title: blockTitle, toBlueprintID: +funnel },
        }
        if (data) {
            const updateRedirectNode = { ...redirectNode, uuid: data?.id }
            updateNode(updateRedirectNode, "redirect").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
        } else {
            const newRedirectNode = { ...redirectNode, uuid: uuid() }
            createNode(newRedirectNode, "redirect").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
        }

        onHide()
        setBlockTitle("")
        setFunnel("")
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Переход' icon={"funnel"} onHide={() => onHide()} />
            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={[0, 1]}>
                    <AccordionTab header='Информация' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === 'employer'}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Переход в воронку' headerClassName='accordion-header'>
                        <p>Возможность отправить клиента в другую воронку. Это экономит место и оптимизирует вашу работу.</p>
                        <Dropdown
                            className='select w-full mb-3'
                            value={funnel}
                            options={funnels}
                            filter
                            filterBy='label'
                            onChange={(e) => setFunnel(e.value)}
                            placeholder='Название воронки для перехода'
                            disabled={user?.role === 'employer'}
                        />
                    </AccordionTab>
                </Accordion>
            </div>

            <SidebarFooter saveHandler={() => saveHandler()} disabled={!funnel} onHide={() => onHide()} />
        </div>
    )
}

export default CreateRedirect
