import { Button } from "primereact/button"
import { Sidebar } from "primereact/sidebar"
import React, { memo, useState } from "react"
import { Handle } from "react-flow-renderer"
import { useParams } from "react-router-dom"
import CreateWebhook from "../Sidebars/CreateWebhook/CreateWebhook"
import { slicedText } from "../utils"
import "./Blocks.scss"
import { useSelector } from "react-redux"

export default memo((props) => {
    const params = useParams()
    const funnelId = +params?.id

    const data = props.data
    data.id = props.id
    data.type = props.type
    data.position = { x: props.xPos, y: props.yPos }

    const [visibleSidebar, setVisibleSidebar] = useState(false)
    const user = useSelector((state) => state.user.user)
    return (
        <>
            <div className='ActionBlock' onDoubleClick={() => setVisibleSidebar(true)}>
                <Handle id='l' type='target' className='custom-handle' position='left' isConnectable={true} />
                <div className='node-block'>
                    <div className='header'>
                        <h3>{slicedText(data?.title, 13) || "Webhook"}</h3>
                        <div className='button-container'>
                            <Button className={`p-button-text  `} onClick={() => setVisibleSidebar(true)}>
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.svg`}></img>
                            </Button>
                        </div>
                    </div>
                    <div className='body'>
                        <div>Метод: {data?.webhookType} </div>
                        <div className='body-text-wrap'>{data?.webhookUrl}</div>
                    </div>
                </div>
                <Handle id='r' type='source' className='custom-handle' position='right' isConnectable={true} />
            </div>
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visibleSidebar}
                onHide={() => setVisibleSidebar(false)}
                position='right'
            >
                <CreateWebhook onHide={() => setVisibleSidebar(false)} funnelId={funnelId} data={data} />
            </Sidebar>
        </>
    )
})
