import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { ScrollPanel } from "primereact/scrollpanel"
import { useEffect, useState } from "react"
import { useRef } from "react"
import { useSelector } from "react-redux"

export const History = ({ setShowProfile, setShowChats, setShowHistory, active }) => {
    const role = localStorage.getItem('roleUser')
    const user = useSelector((state) => state.user.user)
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const [message, setMessage] = useState('')

    const profileHandler = () => {
        setShowProfile(true)
    }

    const backHandler = () => {
        setShowChats(true)
        setShowHistory(false)
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [active?.history]);
    const messagesEndRef = useRef(null)
    const sistemMessage = (title, name, number, message) => {
        return (
            <div className="flex flex-column align-items-end mt-3">
                <div className="card sistem-message flex flex-column ml-auto w-9 mb-0">
                    <div className="flex justify-content-between w-full">
                        <h6 className="mb-0 mr-2">{title} {number} “{name}”</h6>
                        <i className="pi pi-arrow-up-right"></i>
                    </div>
                    <span className="mt-2 text-sm gray">{message}</span>
                </div>
                <span className="mt-2 text-sm gray mb-3">Системное сообщение, 16:34</span>
            </div>

        )
    }



    return (
        <div className="card chats pt-0 flex flex-column justify-content-between px-2">
            <div className="pt-3 header-chat w-full px-3">
                <h5 className='hidden xl:block title-card'>История переписки</h5>
                <div className="flex align-items-center">
                    <Button icon="pi pi-arrow-left" className="block md:hidden p-button-text" onClick={backHandler} />
                    <Button className="block xl:hidden p-button-text w-full" onClick={profileHandler}>{active?.name}</Button>
                </div>

                <Divider className={`${colorTheme}`} />
            </div>

            <div className="message-list">
                <ScrollPanel style={{ width: '100%', height: '100%' }} className="custom">
                    <div className="flex flex-column justify-content-end mt-auto pt-5 w-full">
                        <div className="px-3">
                            <div>
                                {active?.history?.map((item, idx) => {
                                    return <div key={idx}>
                                        {active.history[idx - 1] && item.date === active.history[idx - 1].date ? '' :
                                            <div className="mx-auto wrapper-date w-max px-4 flex align-items-center justify-content-center">
                                                {idx === 0 ?
                                                    <p className="date-messange text-center">Чат начат: {item.date} 14:44:32</p> :
                                                    <p className="date-messange text-center">{item.date}</p>

                                                }

                                            </div>
                                        }
                                        {item.from ? <div className="message-from w-min mb-5 mt-5">{item.message}</div>
                                            :

                                            <div>
                                                {item.tunnel && sistemMessage('Туннель', item.tunnel.name, item.tunnel.number, item.message)}
                                                {item.smart_answer && sistemMessage('Умный ответ', item.smart_answer, null, item.message)}
                                                {!item.tunnel && !item.smart_answer && <div className="flex flex-column align-items-end mb-5">
                                                    <div className="message-to">{item.message}</div>
                                                    <span className="mt-2 text-sm gray">Вероника Донская, 16:35</span>
                                                </div>}
                                            </div>

                                        }
                                    </div>

                                })}
                                <div ref={messagesEndRef} />
                            </div>

                        </div>
                    </div>

                </ScrollPanel>
            </div>
            {active?.blocked ? <div className="flex justify-content-center px-3">
                <div className="block-chat w-full">
                    <p className="text-xl py-2">Чат-бот заблокирован</p>
                </div>
            </div> :
                <>
                    {(user?.role === 'admin' || user?.role === 'moderator') &&
                        <div className="px-3 flex">
                            <div className={`write_message ${colorTheme} w-full flex`}>
                                <input type="text" className="w-full p-4" value={message} placeholder="Написать сообщение..." onChange={(e) => setMessage(e.target.value)} />
                                <Button className="p-button-outlined btn-paperclip mr-2">
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/☺.svg`} />
                                </Button>
                                <Button className="p-button-outlined btn-paperclip mr-3">
                                    <i className="pi pi-paperclip"></i>
                                </Button>
                            </div>
                            <Button className="btn-send_message p-4 ml-2">
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/paperplane.fill.svg`} alt="" />
                            </Button>
                        </div>
                    }
                </>
            }

        </div>
    )
}