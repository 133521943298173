import { InputText } from "primereact/inputtext"
import { useState } from "react"
import { useSelector } from "react-redux"

export const AddTagsMailing = ({ tags, setTags }) => {
    const [tagValue, setTagValue] = useState('')
    const user = useSelector((state) => state.user.user)

    const enterHandler = (e) => {
        if (e.keyCode === 13) {
            if (!e.target.value) {
                e.preventDefault()
            } else {
                setTags([...tags, e.target.value])
                e.target.value = null
            }

        }
    }


    const deleteTagHandler = (item) => {
        setTags(tags.filter((tag) => tag !== item))
    }
    return (
        <div className='w-full'>
            <ul className='tags-container'>
                {tags?.map((item) => <li>
                    <span>{item}</span>
                    {user?.role !== "employer" &&
                        <span onClick={() => deleteTagHandler(item)}>
                            <i className="pi pi-times" style={{ fontSize: '10px' }}></i>
                        </span>}

                </li>)}
                <li className='input_tags_container'>
                    <InputText
                        readOnly={user?.role === "employer"}
                        className='input-add_tags'
                        placeholder='Введите тег...'
                        value={tagValue}
                        onChange={(e) => setTagValue(e.value)}
                        onKeyUp={(e) => enterHandler(e)}
                    />
                </li>
            </ul>
        </div>
    )
}