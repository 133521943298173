import { Accordion, AccordionTab } from "primereact/accordion"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"

import { createNode, getFunnel, getVariables, updateNode } from "../../../service/funnels"
import { setInitNodes } from "../../../service/slices/initNodesSlice"
import SidebarFooter from "./components/SidebarFooter"
import SidebarHeader from "./components/SidebarHeader"
import "./Sidebar.scss"

const CreateCondition = ({ data, onHide, funnelId, centerCoordinates }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.user)

    const [nodeID, setNodeID] = useState("")
    const [blockTitle, setBlockTitle] = useState(data?.title || "")

    const [variables, setVariables] = useState(null)
    const [variable, setVariable] = useState(data?.variableID)
    const [selectedVariable, setSelectedVariable] = useState(data?.variableID)
    const [conditionType, setConditionType] = useState("")
    const [condition, setCondition] = useState(data?.condition)
    const [argument, setArgument] = useState(data?.argument)

    // const [contactOption, setContactOption] = useState("email")

    const [date1, setDate1] = useState(null)
    const [date2, setDate2] = useState(null)
    const [betweenDates, setBetweenDates] = useState(null)

    useEffect(() => {
        getVariables(funnelId).then((res) => {
            const variablesFormated = []
            res?.map((variable) => {
                const variableFormated = {
                    variableID: variable.variableID,
                    type: variable.type,
                    name: variable.name,
                    value: variable.variableID,
                    // value: { type: variable.type, id: variable.id },
                    initialValue: variable.initialValue,
                }
                variablesFormated.push(variableFormated)
            })
            setVariables(variablesFormated)
        })

        const node_id = uuid()
        setNodeID(node_id)
    }, [])

    useEffect(() => {
        const type = getConditionType(data)
        setConditionType(type)

        if (data) {
            const formatDate1 = new Date(data.date1)
            const formatDate2 = new Date(data.date2)
            setDate1(formatDate1)
            setDate2(formatDate2)
            if (data.date2) {
                setBetweenDates([formatDate1, formatDate2])
            }
        }
    }, [data])

    useEffect(() => {
        const selectedVar = variables?.filter((variableItem) => variableItem.variableID === variable)
        if (selectedVar) {
            setSelectedVariable(selectedVar[0])
        }
    }, [variables])

    useEffect(() => {
        const selectedVar = variables?.filter((variableItem) => variableItem.variableID === variable)
        if (selectedVar) {
            setSelectedVariable(selectedVar[0])
        }
    }, [variable])

    const getConditionType = (data) => {
        switch (data?.condition) {
            case "eqVariable":
            case "leVariable":
            case "leEqVariable":
            case "grVariable":
            case "grEqVariable":
                return "variable"

            case "beforeDate":
            case "afterDate":
            case "betweenDates":
                return "date"

            case "hasContact":
                return "hasContact"

            case "hasTag":
                return "hasTag"

            default:
                return ""
        }
    }

    const conditions = [
        { label: "Наличие контакта", value: "hasContact" },
        { label: "Наличие тега", value: "hasTag" },
        { label: "Дата", value: "date" },
        { label: "Переменная", value: "variable" },
    ]

    const dateOptions = [
        { label: "До даты", value: "beforeDate" },
        { label: "После даты", value: "afterDate" },
        { label: "Диапазон дат", value: "betweenDates" },
    ]

    const contactOptions = [
        { label: "Email", value: "email" },
        { label: "Телефон", value: "phone" },
        { label: "Email и Телефон", value: "emailAndPhone" },
    ]
    const variableNumber = [
        { label: "Равно", value: "eqVariable" },
        { label: "Меньше", value: "leVariable" },
        { label: "Меньше или равно", value: "leEqVariable" },
        { label: "Больше", value: "grVariable" },
        { label: "Больше или равно", value: "grEqVariable" },
    ]
    const variableString = [{ label: "Равно", value: "eqVariable" }]
    const variableBoolean = [{ label: "Равно", value: "eqVariable" }]

    const booleanOptions = [
        { label: "Да", value: "true" },
        { label: "Нет", value: "false" },
    ]

    const selectBetweenDays = (days) => {
        setBetweenDates(days)
    }
    useEffect(() => {
        if (betweenDates) {
            setDate1(betweenDates[0])
            setDate2(betweenDates[1])
        }
    }, [betweenDates])

    const saveHandler = () => {
        const conditionBlock = {
            blueprintID: funnelId,
            title: blockTitle,
            type: "condition",
            variableID: variable,
            condition: condition,
            argument: argument,
            date1: date1,
            date2: date2,
            data: { title: blockTitle },
        }

        if (data) {
            const updatedConditionBlock = { ...conditionBlock, uuid: data?.id, argument }
            updateNode(updatedConditionBlock, "condition").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
        } else {
            const newConditionBlock = {
                ...conditionBlock,
                uuid: nodeID,
                position: centerCoordinates,
                uuidTrue: uuid(),
                uuidFalse: uuid(),
            }
            createNode(newConditionBlock, "condition").then((res) =>
                getFunnel(funnelId).then((res) => dispatch(setInitNodes(res?.data?.nodes)))
            )
        }

        onHide()
        setBlockTitle("")
    }

    return (
        <div className='new-node-container flex flex-column justify-content-start'>
            <SidebarHeader title='Условие' icon={"condition"} onHide={() => onHide()} />
            <div className='new-node-container_content'>
                <Accordion multiple activeIndex={[0, 1]}>
                    <AccordionTab header='Информация' headerClassName='accordion-header'>
                        <div className='field w-full mt-2'>
                            <span className='p-float-label'>
                                <InputText
                                    id='title'
                                    className='block text w-full'
                                    value={blockTitle}
                                    onChange={(e) => setBlockTitle(e.target.value)}
                                    readOnly={user?.role === "employer"}
                                />
                                <label htmlFor='title'>Название блока</label>
                            </span>
                        </div>
                    </AccordionTab>
                    <AccordionTab header='Условия срабатывания' headerClassName='accordion-header'>
                        <div>
                            <p>Определяет значение пользователя и отправляет его по указанной ветви воронки</p>
                        </div>
                        <div className='mt-3 mb-3'>
                            <Dropdown
                                className='select w-full mb-3'
                                value={conditionType}
                                options={conditions}
                                onChange={(e) => {
                                    if (e.value === "hasContact") {
                                        setCondition("hasContact")
                                        setConditionType("hasContact")
                                        setDate1(null)
                                        setDate2(null)
                                    } else if (e.value === "hasTag") {
                                        setCondition("hasTag")
                                        setConditionType("hasTag")
                                        setDate1(null)
                                        setDate2(null)
                                    } else {
                                        setConditionType(e.value)
                                        setDate1(null)
                                        setDate2(null)
                                    }
                                }}
                                placeholder='Событие для срабатывания'
                                disabled={user?.role === "employer"}
                            />
                            {conditionType === "hasContact" && (
                                <Dropdown
                                    className='select w-full mb-3'
                                    value={argument}
                                    options={contactOptions}
                                    onChange={(e) => setArgument(e.value)}
                                    placeholder='Выбрать контакт'
                                    disabled={user?.role === "employer"}
                                />
                            )}
                            {conditionType === "hasTag" && (
                                <InputText
                                    className='text w-full mb-3'
                                    value={argument}
                                    onChange={(e) => setArgument(e.target.value)}
                                    placeholder='Введите тег'
                                    readOnly={user?.role === "employer"}
                                />
                            )}
                            {conditionType === "date" && (
                                <>
                                    <Dropdown
                                        className='select w-full mb-3'
                                        value={condition}
                                        options={dateOptions}
                                        onChange={(e) => {
                                            setCondition(e.value)
                                            setDate1(null)
                                            setDate2(null)
                                        }}
                                        placeholder='Выбрать дату'
                                        disabled={user?.role === "employer"}
                                    />
                                    {condition === "beforeDate" && (
                                        <Calendar
                                            className={`delay-calendar ${user?.role === "employer" ? "disabled-btn" : ""} w-full`}
                                            value={date1}
                                            onChange={(e) => {
                                                setDate1(e.value)
                                            }}
                                            showIcon
                                            showOnFocus={user?.role !== "employer"}
                                        />
                                    )}
                                    {condition === "afterDate" && (
                                        <Calendar
                                            className={`delay-calendar ${user?.role === "employer" ? "disabled-btn" : ""} w-full`}
                                            value={date1}
                                            onChange={(e) => setDate1(e.value)}
                                            showIcon
                                            showOnFocus={user?.role !== "employer"}
                                        />
                                    )}
                                    {condition === "betweenDates" && (
                                        <Calendar
                                            className={`delay-calendar ${user?.role === "employer" ? "disabled-btn" : ""} w-full`}
                                            value={betweenDates}
                                            onChange={(e) => selectBetweenDays(e.value)}
                                            showIcon
                                            selectionMode='range'
                                            readOnlyInput
                                            showOnFocus={user?.role !== "employer"}
                                        />
                                    )}
                                </>
                            )}

                            {conditionType === "variable" && (
                                <>
                                    <Dropdown
                                        optionLabel='name'
                                        className='select w-full mb-3'
                                        value={variable}
                                        options={variables}
                                        onChange={(e) => {
                                            setVariable(e.value)
                                            setArgument(null)
                                        }}
                                        placeholder='Выберите переменную'
                                        disabled={user?.role === "employer"}
                                    />
                                    {selectedVariable?.type === "number" && (
                                        <>
                                            <Dropdown
                                                className='select w-full mb-3'
                                                value={condition}
                                                options={variableNumber}
                                                onChange={(e) => setCondition(e.value)}
                                                placeholder='Выберите условие'
                                                disabled={user?.role === "employer"}
                                            />
                                            <InputText
                                                keyfilter='num'
                                                className='text w-full mb-3'
                                                value={argument}
                                                onChange={(e) => setArgument(+e.target.value)}
                                                placeholder='Введите значение'
                                                readOnly={user?.role === "employer"}
                                            />
                                        </>
                                    )}
                                    {selectedVariable?.type === "string" && (
                                        <>
                                            <Dropdown
                                                className='select w-full mb-3'
                                                value={condition}
                                                options={variableString}
                                                onChange={(e) => setCondition(e.value)}
                                                placeholder='Выберите условие'
                                                disabled={user?.role === "employer"}
                                            />
                                            <InputText
                                                className='text w-full mb-3'
                                                value={argument}
                                                onChange={(e) => setArgument(e.target.value)}
                                                placeholder='Введите значение'
                                                readOnly={user?.role === "employer"}
                                            />
                                        </>
                                    )}
                                    {selectedVariable?.type === "boolean" && (
                                        <>
                                            <Dropdown
                                                className='select w-full mb-3'
                                                value={condition}
                                                options={variableBoolean}
                                                onChange={(e) => setCondition(e.value)}
                                                placeholder='Выберите условие'
                                                disabled={user?.role === "employer"}
                                            />
                                            <Dropdown
                                                className='select w-full mb-3'
                                                value={argument}
                                                options={booleanOptions}
                                                onChange={(e) => setArgument(e.value)}
                                                placeholder='Значение'
                                                disabled={user?.role === "employer"}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>

            <SidebarFooter saveHandler={() => saveHandler()} disabled={!argument && !date1 && !date2} onHide={() => onHide()} />
        </div>
    )
}

export default CreateCondition
