
import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import { useEffect, useRef, useState } from 'react'
import './Company.css'
import { EditAboutCompany } from './EditAboutCompany'
import { EditBasicInformation } from './EditBasicInformation'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import PhoneInput from 'react-phone-number-input'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import axios from 'axios'
import Gravatar from 'react-gravatar'
import { EditEmployee } from './EditEmployee'
import { Toast } from 'primereact/toast'
import { AddEmployee } from './AddEmployee'
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup'
import { Skeleton } from 'primereact/skeleton'
import { AdditionalSettings } from './AdditionalSettings'
import { ConnectedBots } from './ConnectedBots'
import { log } from '../../../service/log'
import { getCompany, getEmployees } from '../../../service/slices/companySlice'
import { InviteUser } from './InviteUser'

export const Company = () => {

    const [allSmartAnswers, setAllSmartAnswers] = useState(false)
    const [editAboutCompany, setEditAboutCompany] = useState(false)
    const [editBasic, setEditBasic] = useState(false)
    const [selectedTheme, setSelectedTheme] = useState(Cookies.get('theme'))
    /*     const [company, setCompany] = useState(null) */
    /* const company = { "avatar": "Rectangle (2).png", "title": "Bots.bot", "description": "Команда разработки и поддержки сервиса, также занимаемся тестом своего же продукта и прочими процедурами. Всегда рады выслушать и помочь." } */
    const [selectedEmployee, setSelectedEmployee] = useState('')
    const [employeeSetting, setEmployeeSetting] = useState(false)
    const [basicInformation, setBasicInformation] = useState(null)
    const [addEmployee, setAddEmployee] = useState(false)
    /*     const [employees, setEmployees] = useState(null) */
    /*  const basicInformation = { "access": { "web_site": "Bots.bot", "proxy_server": "Bots.net", "email": "main@bots.bot" }, "location": { "country": "Израиль", "city": "Тель-Авив", "street": "Хаяркон, 120", "time_zone": "UTC +2" }, "phones": ['+7(987)117 90 88', '+12345678901'] } */

    const toast = useRef(null);

    const dispatch = useDispatch()
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const company = useSelector((state) => state.company.company)
    const employees = useSelector((state) => state.company.employees)
    const user = useSelector((state) => state.user.user)

    const role = localStorage.getItem('roleUser')

    console.log(employees)
    const avatarTemplate = (rowData) => {
        return (
            <Gravatar
                email={rowData.email}
                size={50}
                rating="pg"
                default="retro"
                className="CustomAvatar-image"
            />
        )
    }

    useEffect(() => {
        document.title = 'Bot Magic | Компания'
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_URL}/data.json`)
            .then((response) => {
                log(response.data)
                /*       setCompany(response.data.company) */
                setBasicInformation(response.data.company.basicInformation)
                /* setEmployees(response.data.company.employees) */
            })
            .catch((error) => console.error(error))
        /*  axios.get(`https://bots.sdew.ru/gt/company/profile`)
             .then((response) => {
                 console.log(response.data)
             })
             .catch((error) => console.error(error)) */
        dispatch(getEmployees())
    }, [])

    /* const employees = [
        { "id": 1, "avatar": "photo_1.png", "name": "Виктор", "surname": "Калюжный", "position": "FrontEnd Developer", "mail": "frontend@bots.bot" },
        { "id": 2, "avatar": "photo_2.png", "name": "Андрей", "surname": "Судничников", "position": "Lead Designer", "mail": "leadesigner@bots.bot" },
        { "id": 3, "avatar": "photo_3.png", "name": "Степан", "surname": "Солнечный", "position": "Junior designer", "mail": "juniordesigner@bots.bot" },
        { "id": 4, "avatar": "photo_4.png", "name": "Вероника", "surname": "Донская", "position": "Marketing specialist", "mail": "manager@bots.bot" },
        { "id": 5, "avatar": "photo_5.png", "name": "Антон", "surname": "Касперский", "position": "Marketing specialist", "mail": "managerone@bots.bot" },
        { "id": 6, "avatar": "photo_6.png", "name": "Арина", "surname": "Мамонтова", "position": "Marketing specialist", "mail": "managertwo@bots.bot" }
    ] */
    const actionsTemplate = (rowData) => {

        const editHandler = () => {
            setSelectedEmployee(rowData)
            setEmployeeSetting(true)
        }
        return (
            <>
                {user.role === 'admin' && (rowData?.email !== user?.email) &&
                    <div className="flex justify-content-end">
                        <Button className={`btn-edit mr-4 ${colorTheme}`} onClick={editHandler}>
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.${colorTheme}.svg`}></img>
                        </Button>
                        <Button className="btn-trash" onClick={(e) => removeHandler(rowData, e)}>
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/trash.svg`}></img>
                        </Button>
                    </div>}
            </>

        )
    }

    const mailTemplate = (rowData) => {
        return (
            <span className="employees_mail">{rowData?.email}</span>
        )
    }

    const nameTemplate = (rowData) => {
        return (
            <span>{rowData.firstName} {rowData?.lastName}</span>
        )
    }

    const acceptFunc = (value) => {
        /*  axios
              .delete(`.../${id}`)
              .then((response) => { })
              .catch((error) => console.error(error)) */

        /*  setEmployees(employees.filter((employee) => employee.id !== value.id)) */

        toast.current.show({ severity: 'error', summary: 'Удалено', detail: `Сотрудник ${value.name} ${value.surname} удален`, life: 3000 });
    };

    const reject = () => {

    };

    const removeHandler = (employee, e) => {

        confirmPopup({
            target: e.currentTarget,
            message: `Вы действительно хотите удалить ${employee.name} ${employee.surname}`,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Да',
            rejectLabel: 'Нет',
            accept: () => acceptFunc(employee),
            reject
        });
    };

    const skeleton = [1, 2, 3, 4, 5]

    const avatarSkeletonTemplate = () => {
        return <Skeleton shape="circle" size="40px"></Skeleton>
    }

    const skeletonTemplate = () => {
        return <Skeleton width='13rem'></Skeleton>
    }

    const actionsSkeletonTemplate = () => {
        return <div className="flex justify-content-end">
            <Skeleton shape="circle" size="40px" className='mr-4'></Skeleton>
            <Skeleton shape="circle" size="40px"></Skeleton>
        </div>
    }

    return (
        <>
            <ConfirmPopup />
            <Toast ref={toast} />
            {company ?
                <div className="card setting">
                    <div className="flex justify-content-between">
                        <h2 className="settings-title">Основная информация</h2>
                        <div>
                            {user.role === 'admin' &&
                                <Button className={`btn-edit ${colorTheme}`} onClick={() => setEditBasic(true)}>
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.${colorTheme}.svg`}></img>
                                </Button>}
                        </div>

                    </div>
                    <div className="grid mt-4 justify-content-start">
                        <div className="col-12 md:col-6 lg:col-4">
                            <h3 className='settings-subtitle mb-5'>Доступ</h3>
                            <span className='basic-information_label'>Веб-сайт</span>
                            {company?.website
                                ? <p className='mt-2'><a href={company?.website} className='mt-2'>{company?.website}</a></p>
                                : <p className='mt-2'>Не указан</p>
                            }
                            {/*   <span className='basic-information_label'>Прокси сервер</span>
                            <p className='mt-2'>{basicInformation?.access.proxy_server}</p> */}
                            <span className='basic-information_label'>Email</span>
                            <p className='mt-2'>{company?.email ? company?.email : 'Не указан'}</p>
                        </div>
                        <div className="col-12 md:col-6 lg:col-4 mt-4 md:mt-0">
                            <h3 className='settings-subtitle mb-5'>Местонахождение</h3>
                            <p className='w-6'>{company?.location ? company?.location : 'Не указано'}</p>
                            <span className='basic-information_label'>Временная зона</span>
                            <p className='mt-2'>{company?.timeZone ? company?.timeZone : 'Не указан'}</p>
                        </div>
                        <div className="col-12 md:col-6 lg:col-4 mt-4 lg:mt-0">
                            <h3 className='settings-subtitle mb-5'>Телефон</h3>
                            {/*    {basicInformation?.phones.map((item, idx) => {
                                return <PhoneInput key={idx} className="phone w-full mb-3" value={item} disabled />
                            })} */}
                            {company?.phone ?
                                <PhoneInput className="phone w-full mb-3" value={company?.phone} disabled />
                                : <p className='mt-2'>Не указан</p>
                            }

                        </div>
                    </div>
                </div>
                :
                <div className="card setting">
                    <div className="flex justify-content-between">
                        <Skeleton width='22rem' height='2rem' />
                        <div>
                            <Skeleton shape="circle" size="40px"></Skeleton>
                        </div>

                    </div>
                    <div className="grid mt-4 justify-content-start">
                        <div className="col-12 md:col-6 lg:col-4">
                            <Skeleton width='7rem' height='1.8rem' className='mb-5' />
                            <Skeleton width='5rem' className='mb-2' />
                            <Skeleton width='5rem' className='mb-4' />
                            <Skeleton width='8rem' className='mb-2' />
                            <Skeleton width='5rem' className='mb-4' />
                            <Skeleton width='4rem' className='mb-2' />
                            <Skeleton width='9rem' />
                        </div>
                        <div className="col-12 md:col-6 lg:col-4 mt-4 sm:mt-0">
                            <Skeleton width='15rem' height='1.8rem' className='mb-5' />
                            <Skeleton width='8rem' className='mb-3' />
                            <Skeleton width='8rem' className='mb-3' />
                            <Skeleton width='11rem' className='mb-5' />
                            <Skeleton width='10rem' className='mb-2' />
                            <Skeleton width='4rem' />
                        </div>
                        <div className="col-12 md:col-6 lg:col-4 mt-4 md:mt-0">
                            <Skeleton width='7rem' height='1.8rem' className='mb-5' />
                            <Skeleton width='12rem' height='1.5rem' className='mb-3' />
                            <Skeleton width='12rem' height='1.5rem' />
                        </div>
                    </div>
                </div>}
            {employees ?
                <div className="card setting">
                    <div className="flex justify-content-between align-items-center">
                        <h2 className="settings-title">Сотрудники</h2>
                        {user?.role === 'admin' &&
                            <Button className='btn-main px-4' label="Добавить" onClick={() => setAddEmployee(!addEmployee)}></Button>
                        }
                    </div>

                    <DataTable value={employees} emptyMessage='Список сотрудников пуст' responsiveLayout="scroll">
                        <Column body={avatarTemplate}></Column>
                        <Column body={nameTemplate}></Column>
                        <Column field="position"></Column>
                        <Column body={mailTemplate}></Column>
                        <Column body={actionsTemplate}></Column>
                    </DataTable>
                </div>
                :
                <div className="card setting">

                    <div className="flex justify-content-between">
                        <Skeleton width='13rem' height='2rem' className='mr-3' />
                        <Skeleton width='8rem' height='3rem'></Skeleton>
                    </div>

                    <DataTable value={skeleton} responsiveLayout="scroll">
                        <Column body={avatarSkeletonTemplate}></Column>
                        <Column body={skeletonTemplate}></Column>
                        <Column field="position" body={skeletonTemplate}></Column>
                        <Column body={skeletonTemplate}></Column>
                        <Column body={actionsSkeletonTemplate}></Column>
                    </DataTable>
                </div>
            }
            {company
                ? <ConnectedBots />
                : <div className="card setting">

                    <div className="flex justify-content-between">
                        <Skeleton width='13rem' height='2rem' className='mr-3' />
                    </div>

                    <DataTable value={skeleton} responsiveLayout="scroll">
                        <Column body={skeletonTemplate}></Column>
                        <Column field="position" body={skeletonTemplate}></Column>
                        <Column body={skeletonTemplate}></Column>
                        <Column body={actionsSkeletonTemplate}></Column>
                    </DataTable>
                </div>
            }

            {employees ?
                <>
                    {user?.role === 'admin' &&
                        <AdditionalSettings />}
                </>

                :
                <div className="card setting">
                    <Skeleton width='17rem' height='2rem' />

                    <div className="grid justify-content-start mt-5">
                        <div className="col-12 lg:col-5">
                            <Skeleton width='16rem' height='1.5rem' className='mb-3' />
                            <div>
                                <Skeleton width='16rem' className='mb-2' />
                                <Skeleton width='6rem' />
                            </div>

                        </div>
                    </div>
                </div>
            }
            <EditAboutCompany visible={editAboutCompany} onHide={setEditAboutCompany} value={company} />
            {editBasic && <EditBasicInformation toast={toast} visible={editBasic} onHide={setEditBasic} value={company} />}

            {employeeSetting && <EditEmployee className='editData' visible={employeeSetting} onHide={setEmployeeSetting} value={selectedEmployee} toast={toast} />}
            {addEmployee && <InviteUser visible={addEmployee} onHide={setAddEmployee} toast={toast} />}
        </>
    )
}