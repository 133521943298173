import { Button } from "primereact/button"
import { Sidebar } from "primereact/sidebar"
import React, { memo, useEffect, useState } from "react"
import { Handle } from "react-flow-renderer"
import { useParams } from "react-router-dom"

import CreateDelay from "../Sidebars/CreateDelay"
import { formatedSeconds, slicedText } from "../utils"

import "./Blocks.scss"
import { useSelector } from "react-redux"

export default memo((props) => {
    const params = useParams()
    const funnelId = +params?.id

    const [visiblePauseSidebar, setVisiblePauseSidebar] = useState(false)
    const user = useSelector((state) => state.user.user)
    const data = props.data
    data.id = props.id

    const waitUntil = new Date(data?.waitUntil).toLocaleDateString()
    const delay = formatedSeconds(data?.delay)
    const formatedDelay = (delay[0] !== 0 && delay[0] + "дн. ") + delay[1] + "ч. " + delay[2] + "мин."

    return (
        <div className='PauseBlock' onDoubleClick={() => setVisiblePauseSidebar(true)}>
            <Handle
                id='l'
                type='target'
                className='custom-handle'
                position='left'
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={true}
            />
            <div className='node-block'>
                <div className='header'>
                    <h3>{slicedText(data?.title, 12) || "Приостановка"}</h3>
                    <div className='button-container'>
                        <Button className={`p-button-text`} onClick={() => setVisiblePauseSidebar(true)}>
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/gearshape.circle.svg`}></img>
                        </Button>
                    </div>
                </div>
                <div className='body'>
                    {data?.delay && (
                        <>
                            <div>Приостановка на:</div>
                            <div>{formatedDelay}</div>
                        </>
                    )}
                    {data?.waitUntil && (
                        <>
                            <div>Ждать до:</div>
                            <div>{waitUntil}</div>
                        </>
                    )}
                </div>
            </div>
            <Handle
                id='r'
                type='source'
                className='custom-handle'
                position='right'
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={true}
            />
            <Sidebar
                className='toolbar-sidebar'
                dismissable={false}
                visible={visiblePauseSidebar}
                onHide={() => setVisiblePauseSidebar(false)}
                position='right'
            >
                <CreateDelay onHide={() => setVisiblePauseSidebar(false)} funnelId={funnelId} data={data} />
            </Sidebar>
        </div>
    )
})
