import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useSelector } from "react-redux";

export const PaymentToAccount = ({ visible, onHide }) => {
    const [phone, setPhone] = useState('+7 (987) 117-90-80');
    const [email, setEmail] = useState('example@mail.ru');
    const [username, setUsername] = useState('Мой господин');
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const renderFooter = () => {
        return (
            <>
                <div className="flex justify-content-center">
                    <Button label="Отправить" onClick={() => onHide(false)} className='btn-main p-button-text py-3 px-4' />
                </div>
            </>

        );
    }

    return <>
        <Dialog className={`personal-setting w-12 sm:w-11 md:w-8 lg:w-6 xl:w-4 ${colorTheme}`} header="Олата для юридических лиц" visible={visible} footer={renderFooter()} onHide={() => onHide(false)}>

            <div className="grid">
                <div className="col-6 mb-4">
                    <span className="p-float-label">
                        <InputText className="w-full" id="username" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <label htmlFor="username">Номер телефона</label>
                    </span>
                </div>
                <div className="col-6 mb-4">
                    <span className="p-float-label">
                        <InputText className="w-full" id="username" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <label htmlFor="username">Электронная почта</label>
                    </span>
                </div>
                <div className="col-12 mb-4">
                    <span className="p-float-label">
                        <InputText className="w-full" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        <label htmlFor="username">Как к Вам обратиться</label>
                    </span>
                </div>
                <p className="p-2">Для оплаты нашего продукта как юридическое лицо введите номер телефона и (или) адрес Вашей электронной почты. Менеджер свяжется с вами в ближайшее время.</p>
            </div>

        </Dialog>
    </>
}