import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react"

export const Devices = ({ values }) => {
    const [widthScreen, setWidthScreen] = useState(window.innerWidth)

    const count = () => {
        if (widthScreen > 1855) {
            return 5
        } else if (widthScreen < 1855 && widthScreen > 1645) {
            return 4
        } else if (widthScreen < 1645 && widthScreen > 1350) {
            return 3
        } else if (widthScreen < 1350) {
            return 2
        }
    }

    useEffect(() => {
        window.addEventListener("resize", () => setWidthScreen(window.innerWidth));

        return () => {
            window.removeEventListener("resize", () => setWidthScreen(window.innerWidth));
        };
    }, []);

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

    return (
        <>
            <DataTable className="table-tags devices" value={values} paginator={values.length > count()} responsiveLayout="scroll"
                paginatorTemplate="PageLinks"
                rows={count()}
                paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
                <Column field="device" header="device" ></Column>
                <Column field="browser" header="browser"></Column>
            </DataTable>
        </>
    )

}