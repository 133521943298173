import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { useDispatch, useSelector } from "react-redux";
import { setDate, setEnd, setSelectDate, setStart } from "../../service/slices/selectDateSlice";
import { formatDate } from "../../service/formatDate";

import './SelectDate.css'
import axios from "axios";
import { setNewUsers } from "../../service/slices/newUsersSlice";
import { detNewUsers, getNewUsers } from "../../service/getData";
import { SelectButton } from 'primereact/selectbutton';
import { log } from "../../service/log";
import { getRegisteredReaders } from "../../service/slices/readersSlice";

export const SelectDate = () => {
    const [calendar, setCalendar] = useState(false);
    const dispatch = useDispatch()


    const date = useSelector((state) => state.selectDate.date)
    const start = useSelector((state) => state.selectDate.start)
    const end = useSelector((state) => state.selectDate.end)
    const newUsers = useSelector((state) => state.newUsers.users)
    const selectDate = useSelector((state) => state.selectDate.selectDate)
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const [formatStart, setFormatStart] = useState(new Date(start))
    const [formatEnd, setFormatEnd] = useState(new Date(end))

    const [calendarData, setCalendarData] = useState(null)

    const [value1, setValue1] = useState('Сегодня');
    const options = ['Сегодня', 'Вчера', 'Неделя', 'Месяц'];

    useEffect(() => {
        if (calendarData) {
            if (calendarData[1]) {
                setCalendar(false)
            }
        }
    }, [calendarData])

    useEffect(() => {
        if (formatStart && formatEnd) {
            /*  getNewUsers(formatStart, formatEnd).then((response) => {
                 log(response);
 
             })
                 .catch((error) => console.error(error)) */
            const data = {
                start: formatStart,
                end: formatEnd
            }
            dispatch(getRegisteredReaders(data))
        }


    }, [formatStart, formatEnd])


    useEffect(() => {
        setFormatStart(new Date(start))
        setFormatEnd(new Date(end))
    }, [start, end])

    useEffect(() => {
        if (value1 === 'Сегодня') {
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            dispatch(setSelectDate('today'))

            dispatch(setStart(today.toISOString()))
            dispatch(setEnd(new Date().toISOString()))
        } else if (value1 === 'Вчера') {
            const yesterdayStart = new Date([addDays(new Date(), 1, 0, 0)])
            yesterdayStart.setHours(0, 0, 0, 0)

            const yesterdayEnd = new Date([addDays(new Date(), 1, 0, 0)])
            yesterdayEnd.setHours(23, 59, 59, 0)

            dispatch(setSelectDate('yesterday'))

            dispatch(setStart(yesterdayStart.toISOString()))
            dispatch(setEnd(yesterdayEnd.toISOString()))
        } else if (value1 === 'Неделя') {
            dispatch(setSelectDate('week'))

            const weekStart = addDays(new Date(), 6, 0, 0)

            dispatch(setStart(weekStart))
            dispatch(setEnd(new Date().toISOString()))
        } else {
            dispatch(setSelectDate('month'))

            const monthStart = addDays(new Date(), 0, 1, 0)

            dispatch(setStart(monthStart))
            dispatch(setEnd(new Date().toISOString()))
        }
    }, [value1])

    /*    useEffect(() => {
           localStorage.setItem("date1", date[0]);
           localStorage.setItem("date2", date[1]);
           localStorage.setItem("selectDate", selectDate);
   
       }, [date, selectDate]) */

    function addDays(date, days, month, year) {
        return new Date(
            date.getFullYear() - year,
            date.getMonth() - month,
            date.getDate() - days,
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        ).toISOString();
    }

    const weekHandler = () => {
        dispatch(setSelectDate('week'))

        const weekStart = addDays(new Date(), 6, 0, 0)

        dispatch(setStart(weekStart))
        dispatch(setEnd(new Date().toISOString()))
    }

    const monthHandler = () => {
        dispatch(setSelectDate('month'))

        const monthStart = addDays(new Date(), 0, 1, 0)

        dispatch(setStart(monthStart))
        dispatch(setEnd(new Date().toISOString()))
    }

    const todayHandler = () => {
        const today = new Date()
        today.setHours(0, 0, 0, 0)

        dispatch(setSelectDate('today'))

        dispatch(setStart(today.toISOString()))
        dispatch(setEnd(new Date().toISOString()))
    }

    const yesterdayHandler = () => {
        const yesterdayStart = new Date([addDays(new Date(), 1, 0, 0)])
        yesterdayStart.setHours(0, 0, 0, 0)

        const yesterdayEnd = new Date([addDays(new Date(), 1, 0, 0)])
        yesterdayEnd.setHours(23, 59, 59, 0)

        dispatch(setSelectDate('yesterday'))

        dispatch(setStart(yesterdayStart.toISOString()))
        dispatch(setEnd(yesterdayEnd.toISOString()))
    }

    const calendarHandler = (e) => {
        setValue1(null)
        dispatch(setSelectDate('calendar'))


        setCalendarData(e.value)
        if (e.value[0] && e.value[1]) {
            dispatch(setStart(e.value[0].toISOString()))
            dispatch(setEnd(e.value[1].toISOString()))
        }


    }

    return (
        <>
            <div className='w-full h-full card-celect_date justify-content-start'>
                <p className=''>Выберите даты для просмотра</p>
                {/* <div className='col-5 py-0 select-date'>
                    <Button className={selectDate === 'calendar' ? 'card calendar-block active w-full h-11rem mb-2' : 'card calendar-block w-full h-11rem mb-2'} onClick={() => setCalendar(!calendar)}>
                        <div className='flex flex-column h-full justify-content-between w-full'>
                            <div className='flex justify-content-start pl-2'>
                                <img src='assets/layout/images/calendar.light.svg'></img>
                            </div>
                            {formatStart && formatEnd ?
                                <div>
                                    {selectDate === 'today' || selectDate === 'yesterday' ? <p className='w-full text-left selected-date'>{formatDate(formatStart)}</p>
                                        :
                                        <p className='w-full text-left selected-date'>{formatDate(formatStart)} - {formatDate(formatEnd)}</p>
                                    }
                                </div>

                                :
                                <p className='text-left'>Выберите даты для просмотра</p>}

                        </div>
                    </Button>
                </div>
                <div className="col-7 py-0 select-date">
                    <div className="grid h-full">
                        <div className="col-6 pb-0 mb-2 date-col">
                            <Button className={selectDate === 'today' ? 'date active w-full h-full' : 'date w-full h-full'} onClick={todayHandler}>
                                Сегодня
                            </Button>
                        </div>
                        <div className="col-6 pb-0 mb-2 date-col">
                            <Button className={selectDate === 'yesterday' ? 'date active w-full h-full' : 'date w-full h-full'} onClick={yesterdayHandler}>
                                Вчера
                            </Button>
                        </div>
                        <div className="col-6 pb-0 date-col">
                            <Button className={selectDate === 'week' ? 'date active w-full h-full' : 'date w-full h-full'} onClick={weekHandler}>
                                Неделя
                            </Button>
                        </div>
                        <div className="col-6 pb-0 date-col">
                            <Button className={selectDate === 'month' ? 'date active w-full h-full' : 'date w-full h-full'} onClick={monthHandler}>
                                Месяц
                            </Button>
                        </div> 
                    </div>


                </div>*/}

                {/* <div className={`${colorTheme} col-4 flex flex-column justify-content-between h-full`}>
                    
                    

                </div> */}
                <SelectButton className="select-date" value={value1} options={options} onChange={(e) => setValue1(e.value)} />
                <Button className={selectDate === 'calendar' ? 'btn-calendar active mb-2' : 'btn-calendar mb-2'} onClick={() => setCalendar(!calendar)}>
                    <div className='flex h-full justify-content-between w-full'>

                        {formatStart && formatEnd ?
                            <div>
                                {value1 === 'Сегодня' || value1 === 'Вчера' ? <p className='w-full text-left selected-date'>{formatDate(formatStart)}</p>
                                    :
                                    <p className='w-full text-left selected-date'>{formatDate(formatStart)} - {formatDate(formatEnd)}</p>
                                }
                            </div>

                            :
                            <p className='text-left'>Выберите даты</p>}
                        <div className='flex justify-content-start pl-2'>
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/edit_calendar.svg`}></img>
                        </div>
                    </div>
                </Button>
            </div>
            {calendar && <Calendar className={`calendar`} selectionMode="range" id="range" value={calendarData} onChange={calendarHandler} inline />}
        </>

    )
}