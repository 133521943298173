import { configureStore } from '@reduxjs/toolkit'
import companySlice from './slices/companySlice'
import authSlice from './slices/authSlice'
import colorThemeSlice from './slices/colorThemeSlice'
import initNodesSlice from './slices/initNodesSlice'
import newUsersSlice from './slices/newUsersSlice'
import selectDateSlice from './slices/selectDateSlice'
import smartAnswersSlice from './slices/smartAnswersSlice'
import userSlice from './slices/userSlice'
import readersSlice from './slices/readersSlice'
import mailingsSlice from './slices/mailingsSlice'
import botsSlice from './slices/botsSlice'


export default configureStore({
    reducer: {
        colorTheme: colorThemeSlice,
        selectDate: selectDateSlice,
        initNodes: initNodesSlice,
        newUsers: newUsersSlice,
        auth: authSlice,
        smartAnswers: smartAnswersSlice,
        company: companySlice,
        user: userSlice,
        readers: readersSlice,
        mailings: mailingsSlice,
        bots: botsSlice
    },
})
