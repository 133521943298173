import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useRef, useState } from "react"
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch, useSelector } from "react-redux";
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import axios from "axios";
import { Toast } from "primereact/toast";
import { getOneAnswers } from "../../service/smartAnswers";
import { getAllBlueprints } from "../../service/funnels";
import { useEffect } from "react";
import { addTagAnswer, deleteTagAnswer, getAllAnswers, setSelectedTag, updateSmartAnswer } from "../../service/slices/smartAnswersSlice";
import { AddTag } from "./AddTag";
import { AddTags } from "../../components/AddTags/AddTags";
import { MultiSelect } from "primereact/multiselect";

export const EditSmartAnswer = ({ setEdit, value, toast, className, setAnswers, sidebar }) => {
    const answer = useSelector((state) => state.smartAnswers.answer)
    const [title, setTitle] = useState(answer.title)

    function addZero(val) {
        if (val < 10) {
            return '0' + val;
        }
        return val;
    };

    const [noResponse, setNoResponse] = useState(answer.noAnswerDays || answer.noAnswerHours || answer.noAnswerMinutes || answer.noAnswerSeconds ? true : false)
    const [message, setMessage] = useState(answer.reactions ? true : false)
    const [messageText, setMessageText] = useState(null)
    const [selectDay, setSelectDay] = useState(addZero(answer.noAnswerDays) || '00')
    const [selectHour, setSelectHour] = useState(addZero(answer.noAnswerHours) || '00')
    const [selectMinutes, setSelectMinutes] = useState(addZero(answer.noAnswerMinutes) || '00')
    const [selectSeconds, setSelectSeconds] = useState(addZero(answer.noAnswerSeconds) || '00')

    const [sendMessage, setSendMessage] = useState(answer.text ? true : false)
    const [sendMessageText, setSendMessageText] = useState(answer.text)
    const [messagesFromUser, setMessagesFromUser] = useState(answer.reactions)
    const [showThen, setShowThen] = useState(false)
    const [funnels, setFunnels] = useState(null)
    const [selectedFunnel, setSelectedFunnel] = useState(null)
    const [filteredFunnels, setFilteredFunnels] = useState(null)
    const [startFunnel, setStartFunnel] = useState(answer.blueprintID ? true : false)
    const [noTitle, setNoTitle] = useState(false)

    const [showNewTag, setShowNewTag] = useState(false)
    const selectedTag = useSelector((state) => state.smartAnswers.selectedTag)

    const dispatch = useDispatch()

    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const [tagValue, setTagValue] = useState('')

    const [emptyMessage, setEmptyMessage] = useState(false)
    const [emptySendMessageText, setEmptySendMessageText] = useState(false)
    const [emptyStartFunnel, setEmptyStartFunnel] = useState(false)
    const [emptyAttachBot, setEmptyAttachBot] = useState(false)

    const [attachBot, setAttachBot] = useState(false)
    const [selectedBot, setSelectedBot] = useState(null)
    const [filteredBots, setFilteredBots] = useState(null)

    useEffect(() => {
        getAllBlueprints(1).then((response) => {
            const allFunnels = response.data.map((item) => ({ id: item.id, title: item.title }))

            setFunnels(allFunnels)
            setSelectedFunnel(allFunnels.find((item) => item.id === answer.blueprintID))
        })

    }, [])


    const addTagHandler = (e) => {
        if (!tagValue) {
            e.preventDefault()
        } else {
            const dataTag = {
                answerID: answer.answerID,
                tag: tagValue
            }
            dispatch(addTagAnswer(dataTag))
        }
    }


    const deleteHandler = (item) => {
        const dataTag = {
            answerID: answer.answerID,
            tag: item
        };
        dispatch(deleteTagAnswer(dataTag))
    }

    const deleteMessagesHandler = (item) => {
        let messages = [...messagesFromUser];
        messages.splice(messages.indexOf(item), 1);
        setMessagesFromUser(messages)
    }



    const searchFunnel = (event) => {
        setTimeout(() => {
            let _filteredFunnels;
            if (!event.query.trim().length) {
                _filteredFunnels = [...funnels];
            }
            else {
                _filteredFunnels = funnels.filter((funnel) => {
                    return funnel.title.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredFunnels(_filteredFunnels);
        }, 250);
    }

    const addMessageHandler = () => {
        const messages = messagesFromUser ? messagesFromUser : []
        setMessagesFromUser([...messages, messageText])
        setMessageText('')
    }


    const selectTimeHandler = (selectValue, value, number, min) => {
        value = value.replace(/[^0-9]/g, '')
        if (+value > number) {
            value = number
        } else if (+value < min) {
            value = min
        }
        selectValue(addZero(Number(value)))
    }

    const updateHandler = (e) => {

        if (!title || (sendMessage && !sendMessageText) || (startFunnel && !selectedFunnel) || (attachBot && !selectedBot)) {
            e.preventDefault()

            if (!title) {
                setNoTitle(true)
            }
            if (sendMessage && !sendMessageText) {
                setEmptySendMessageText(true)
            }
            if (startFunnel && !selectedFunnel) {
                setEmptyStartFunnel(true)
            }
            if (attachBot && !selectedBot) {
                setEmptyAttachBot(true)
            }

        } else {
            const updatedData = {
                answerID: answer.answerID,
                title: title,
                text: sendMessageText,
                noAnswerDays: selectDay,
                noAnswerHours: selectHour,
                noAnswerMinutes: selectMinutes,
                noAnswerSeconds: selectSeconds,
                blueprintID: selectedFunnel?.id,
                reactions: messagesFromUser
            }
            dispatch(updateSmartAnswer(updatedData))
            toast.current.show({ severity: 'success', summary: 'Изменено', detail: `Умный ответ "${title}" изменен`, life: 3000 });
            setEdit(false)
        }

    }

    const bots = [
        { name: 'Bot1', type: 'yandex' },
        { name: 'Bot2', type: 'sms' },
        { name: 'Bot3', type: 'whatsapp' },
        { name: 'Bot4', type: 'telegram' },
        { name: 'Bot5', type: 'vk' },
    ];

    const botTemplate = (option) => {
        return (
            <div className="bot-item">
                <img alt={option.name} className='mr-2' src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${option.type}-bot.png`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                <div>{option.name}</div>
            </div>
        );
    }

    const selectedBotsTemplate = (option) => {
        if (option) {
            return (
                <div className="bot-item bot-item-value">
                    <img alt={option.name} className='mr-2' src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${option.type}-bot.png`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return "Введите имя бота";
    }

    const panelFooterTemplate = () => {
        const selectedItems = selectedBot;
        const length = selectedItems ? selectedItems.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> item{length > 1 ? 's' : ''} selected.
            </div>
        );
    }

    const changeTitleHandler = (e) => {
        setTitle(e.target.value)
        setNoTitle(false)
    }

    const minutesMinusHandler = () => {
        setSelectMinutes(prev => {
            if (prev <= 5) {
                return addZero(5)
            }
            return addZero((+prev) - 1)
        })
    }
    const minutesPlusHandler = () => {
        setSelectMinutes(prev => addZero((+prev) + 1))
    }

    console.log(emptyMessage)

    const thenHandler = (e) => {
        if (noResponse) {
            if (message) {

                if (!messageText) {
                    if (!messagesFromUser) {
                        setEmptyMessage(true)
                    } else {
                        setEmptyMessage(false)
                        setShowThen(true)
                    }
                } else {
                    const messages = messagesFromUser ? messagesFromUser : []
                    setMessagesFromUser([...messages, messageText])
                    setMessageText('')
                    setEmptyMessage(false)
                    setShowThen(true)
                }

            } else {
                setShowThen(true)
            }

        } else {
            if (message) {

                if (!messageText) {
                    if (!messagesFromUser) {
                        setEmptyMessage(true)
                    } else {
                        setEmptyMessage(false)
                        setShowThen(true)
                    }
                } else {
                    const messages = messagesFromUser ? messagesFromUser : []
                    setMessagesFromUser([...messages, messageText])
                    setMessageText('')
                    setEmptyMessage(false)
                    setShowThen(true)
                }

            } else {
                setShowThen(false)
            }
        }
    }


    const noResponseHandler = (e) => {
        setNoResponse(e.value)

        if (e.value) {
            setSelectMinutes(addZero(5))
        } else {
            setSelectMinutes(null)
        }
    }

    const messageHandler = (e) => {
        setMessage(e.value)

        if (!e.value) {
            setEmptyMessage(false)
        }
    }

    const messageTextHandler = (e) => {
        setMessageText(e.target.value)
        setEmptyMessage(false)
    }

    const sendMessageTextHandler = (e) => {
        setSendMessageText(e.target.value)
        setEmptySendMessageText(false)
    }

    const selectedFunnelHandler = (e) => {
        setSelectedFunnel(e.value)
        setEmptyStartFunnel(false)
    }

    const selectedBotsHandler = (e) => {
        setSelectedBot(e.value)
        setEmptyAttachBot(false)
    }
    console.log(filteredFunnels)

    return (
        <>

            <div className="mb-4">
                <InputText id="title" type="text" className={noTitle ? "p-invalid title-answer_input w-full sm:w-10 " : title ? "w-full sm:w-10 title-answer_input" : "w-full sm:w-10 title-answer_input"} value={title} placeholder='Название ответа'
                    onChange={changeTitleHandler} />
                {noTitle && <small id="title-help" className="p-error block">Введите название умного ответа</small>}
            </div>


            {!showThen ?
                <div className="flex flex-column justify-content-between">

                    <div className="">

                        <div className={sidebar ? 'smart-answer_scroll_2 sidebar' : 'smart-answer_scroll_2'}>
                            <ScrollPanel style={{ width: '100%', height: '100%' }} className="custom">
                                <AddTags edit={true} />
                                <span>Если: </span>

                                <div className="w-full">

                                    <div className="flex align-items-center mt-4">
                                        <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={noResponse} onChange={noResponseHandler} />
                                        <label htmlFor="noResponse">На сообщение пользователя нет ответа</label>
                                    </div>
                                    {noResponse &&
                                        <div className="card-time w-full sm:w-10 flex justify-content-center py-4 mt-4 mb-8">
                                            <div className="flex flex-column align-items-center">
                                                <h6>Дни</h6>
                                                <div className="flex align-items-center">
                                                    <Button className="btn-arrow px-1 xl:px-2" disabled={selectDay === '00'} onClick={() => setSelectDay(prev => addZero((+prev) - 1))}>
                                                        <i className="pi pi-angle-left"></i>
                                                    </Button>
                                                    <InputText className="select-time_input" inputId="integeronly" value={selectDay} onChange={(e) => selectTimeHandler(setSelectDay, e.target.value)} />
                                                    <Button className="btn-arrow px-1 xl:px-2" onClick={() => setSelectDay(prev => addZero((+prev) + 1))}>
                                                        <i className="pi pi-angle-right"></i>
                                                    </Button>
                                                </div>

                                            </div>
                                            <div className="flex flex-column align-items-center">
                                                <h6>Часы</h6>
                                                <div className="flex align-items-center">
                                                    <Button className="btn-arrow px-1 xl:px-2" disabled={selectHour === '00'} onClick={() => setSelectHour(prev => addZero((+prev) - 1))}>
                                                        <i className="pi pi-angle-left"></i>
                                                    </Button>
                                                    <InputText className="select-time_input" inputId="integeronly" value={selectHour} onChange={(e) => selectTimeHandler(setSelectHour, e.target.value, 23)} />
                                                    <Button className="btn-arrow px-1 xl:px-2" disabled={selectHour === 23} onClick={() => setSelectHour(prev => addZero((+prev) + 1))}>
                                                        <i className="pi pi-angle-right"></i>
                                                    </Button>
                                                </div>

                                            </div>
                                            <div className="flex flex-column align-items-center">
                                                <h6>Минуты</h6>
                                                <div className="flex align-items-center">
                                                    <Button className="btn-arrow px-1 xl:px-2" disabled={selectMinutes === '00'} onClick={minutesMinusHandler}>
                                                        <i className="pi pi-angle-left"></i>
                                                    </Button>
                                                    <InputText className="select-time_input" inputId="integeronly" value={selectMinutes} onChange={(e) => selectTimeHandler(setSelectMinutes, e.target.value, 59, 5)} />
                                                    <Button className="btn-arrow px-1 xl:px-2" disabled={selectMinutes === 59} onClick={minutesPlusHandler}>
                                                        <i className="pi pi-angle-right"></i>
                                                    </Button>
                                                </div>

                                            </div>
                                            {/* <div className="flex flex-column align-items-center">
                                                <h6>Секунды</h6>
                                                <div className="flex align-items-center">
                                                    <Button className="btn-arrow px-1 xl:px-2" disabled={selectSeconds === '00'} onClick={() => setSelectSeconds(prev => addZero((+prev) - 1))}>
                                                        <i className="pi pi-angle-left"></i>
                                                    </Button>
                                                    <InputText className="select-time_input" inputId="integeronly" value={selectSeconds} onChange={(e) => selectTimeHandler(setSelectSeconds, e.target.value, 59)} />
                                                    <Button className="btn-arrow px-1 xl:px-2" disabled={selectSeconds === 59} onClick={() => setSelectSeconds(prev => addZero((+prev) + 1))}>
                                                        <i className="pi pi-angle-right"></i>
                                                    </Button>
                                                </div>

                                            </div> */}
                                        </div>
                                    }
                                    <div>
                                        <div className="flex align-items-center mt-4">
                                            <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={message} onChange={(e) => messageHandler(e)} />
                                            <label htmlFor="noResponse">Сообщение от пользователя содержит</label>
                                        </div>
                                        {message && <>
                                            <div className="messages_user-block w-full sm:w-10">
                                                <div className="flex align-items-center">
                                                    <InputText id="title" type="text" value={messageText} className={emptyMessage ? 'p-invalid new-answer_input w-full' : "new-answer_input w-full"} placeholder='Сообщение'
                                                        onChange={(e) => messageTextHandler(e)} />

                                                    <Button className="add-message" onClick={(e) => addMessageHandler(e)}>
                                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/plus_green.svg`}></img>
                                                    </Button>
                                                </div>
                                                {emptyMessage && <small id="title-help" className="p-error block">Введите сообщение</small>}
                                                <div className="list-messages flex align-items-center flex-wrap">
                                                    {messagesFromUser?.map((item) => {
                                                        return <div className="messages_from-user flex relative" >
                                                            <span className="text-messages_user">{item}</span>
                                                            <Button className="btn-delete_tag" onClick={() => deleteMessagesHandler(item)}>
                                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/trash.svg`}></img>
                                                            </Button>

                                                        </div>

                                                    })}
                                                </div>
                                            </div>
                                            <p className="mt-3 warning-message">При чтении сообщения система игнорирует ошибки и неточности. Например если у вас добавлено слово “Вторник”, то система засчитает также слова: “Фторник”, “Вторнек” и тд.</p>
                                        </>}

                                    </div>


                                </div>

                            </ScrollPanel>
                        </div>
                    </div>


                    <div className="flex justify-content-between mt-5">
                        <Button label="Отменить" className='btn-cancel p-button-text py-3 px-4 mr-3' onClick={() => setEdit(false)} />
                        <Button label="Продолжить" className="btn-save py-3 px-4" disabled={!noResponse && !message} onClick={(e) => thenHandler(e)} />
                    </div>
                </div>
                :
                <div className="flex flex-column justify-content-between">

                    <div className="w-full">

                        <div className={sidebar ? 'smart-answer_scroll_2 sidebar' : 'smart-answer_scroll_2'}>
                            <ScrollPanel style={{ width: '100%', height: '100%' }} className="custom">
                                <span>То: </span>
                                <div className="w-full">
                                    <div className="">
                                        <div className="flex align-items-center mt-4">
                                            <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={sendMessage} onChange={(e) => setSendMessage(e.value)} />
                                            <label htmlFor="noResponse">Отправить сообщение</label>
                                        </div>
                                        {sendMessage &&
                                            <div className={emptySendMessageText ? "p-invalid new-answer_textarea w-full sm:w-10" : "new-answer_textarea w-full sm:w-10"}>
                                                <InputTextarea className="w-full" value={sendMessageText} onChange={sendMessageTextHandler} rows={2} cols={56} autoResize placeholder="Сообщение" />
                                                <div className="w-full flex justify-content-end">
                                                    <Button className="p-button-outlined btn-paperclip mr-2">
                                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/smile.svg`} />
                                                    </Button>
                                                </div>

                                            </div>}
                                        {emptySendMessageText && <small id="title-help" className="p-error block">Введите сообщение</small>}
                                    </div>
                                    <div>
                                        <div className="flex align-items-center mt-4">
                                            <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={startFunnel} onChange={(e) => setStartFunnel(e.value)} />
                                            <label htmlFor="noResponse">Запустить воронку</label>
                                        </div>
                                        {startFunnel && <>
                                            <div className="mt-3">e
                                                <AutoComplete className={emptyStartFunnel ? "p-invalid funnel_selection w-full sm:w-10" : "funnel_selction w-full sm:w-10"} value={selectedFunnel} suggestions={filteredFunnels} completeMethod={searchFunnel} field="title" dropdown forceSelection onChange={selectedFunnelHandler} aria-label="Funnels" placeholder="Выберите воронку" />
                                            </div>
                                            {emptyStartFunnel ? <small id="title-help" className="p-error block">Выберите воронку</small> :
                                                <span className="warning-message">Введите точное название воронки.</span>}
                                        </>}
                                    </div>
                                    <div className="attach-bot w-full sm:w-10">
                                        <div className="flex align-items-center switch-block">
                                            <InputSwitch className={`settings-switch ${colorTheme} mr-3`} checked={attachBot} onChange={(e) => setAttachBot(e.value)} />
                                            <label htmlFor="noResponse">Выбрать ботов</label>
                                        </div>
                                        {attachBot
                                            ? <>
                                                <div className="flex align-items-center">
                                                    <MultiSelect value={selectedBot} options={bots} onChange={(e) => selectedBotsHandler(e)} optionLabel="name" placeholder="Введите имя бота" filter className={emptyAttachBot ? "p-invalid attach-bot_select" : "attach-bot_select"}
                                                        itemTemplate={botTemplate} selectedItemTemplate={selectedBotsTemplate} panelFooterTemplate={panelFooterTemplate} />
                                                </div>
                                                {emptyAttachBot && <small id="title-help" className="p-error block">Выберите бот</small>}
                                            </>
                                            : <span className="warning-message">Если умный ответ не прикрепляется к боту, то он используется во всех ботах привязанных к аккаунту.</span>
                                        }

                                    </div>
                                </div>

                            </ScrollPanel>
                        </div>


                    </div>


                    <div className="btns-block flex justify-content-between mt-5">
                        <Button label="Отменить" className='btn-cancel p-button-text py-3 px-4 mr-3' onClick={() => setEdit(false)} />
                        <div>
                            <Button label="Назад" className='btn-main p-button-text py-3 px-6 mr-3' onClick={() => setShowThen(false)} />
                            <Button label="Сохранить" className="btn-save green py-3 px-4" onClick={(e) => updateHandler(e, value.id)} />
                        </div>

                    </div>
                </div>}

        </>
    )
}