import { createSlice } from '@reduxjs/toolkit'
import { DARK_THEME, LIGHT_THEME, getCookieColorMode, getCookieSelectedTheme } from '../theme'


export const colorThemeSlice = createSlice({
    name: 'colorTheme',
    initialState: {
        theme: getCookieColorMode(),
        selectedTheme: getCookieSelectedTheme()
    },
    reducers: {
        setLightTheme(state) {
            state.theme = LIGHT_THEME
        },
        setDarkTheme(state) {
            state.theme = DARK_THEME
        },
        setSelectedTheme: (state, action) => {
            state.selectedTheme = action.payload
        }
    },
})

export const { setLightTheme, setDarkTheme, setSelectedTheme } = colorThemeSlice.actions
export default colorThemeSlice.reducer
