import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { log } from "../../../service/log";
import { addTelegramToken } from "../../../service/funnels";

export const AddBot = ({ visible, onHide, toast }) => {

    const [email, setEmail] = useState(null)
    const [widthScreen, setWidthScreen] = useState(window.innerWidth)
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const [token, setToken] = useState('')
    const [isValidToken, setIsValidToken] = useState(true)

    useEffect(() => {
        let regExp = /[0-9]{10}:[a-zA-Z0-9_-]{34}/
        const isValid = regExp.test(token)
        setIsValidToken(isValid)
    }, [token])

    useEffect(() => {
        window.addEventListener("resize", () => setWidthScreen(window.innerWidth));

        return () => {
            window.removeEventListener("resize", () => setWidthScreen(window.innerWidth));
        };
    }, [])

    const renderFooter = () => {
        return (
            <>
                <div className="flex justify-content-between">
                    <Button label="Отменить" onClick={() => onHide(false)} className='btn-main py-3 px-4 mr-3' />
                    <Button label="Добавить" onClick={sendHandler} autoFocus className="btn-save py-3 px-4" />
                </div>
            </>

        );
    }


    const sendHandler = () => {
        if (isValidToken) {
            onHide(false)

            addTelegramToken(token)
            toast.current.show({ severity: 'success', summary: 'Сохранено', detail: `Бот добавлен`, life: 3000 });
        }
    }

    return (
        <>
            <Dialog className={`personal-setting w-12 sm:w-11 md:w-8 lg:w-6 xl:w-4 ${colorTheme}`} header="Добавление бота" visible={visible} footer={renderFooter()} onHide={() => onHide(false)}>
                <div className="grid justify-content-start">
                    <div className="col-12">
                        <p>Введите токен полученный в <span style={{ color: "#007AFF" }}>@BotFather</span></p>
                        <span className="">
                            <InputText
                                className={`w-full input-mail ${token.length !== 0 && !isValidToken && "p-invalid"}`}
                                id="username"
                                value={token}
                                onChange={(e) => setToken(e.target.value)}
                                placeholder="Введите токен"
                            />
                            {token?.length !== 0 && !isValidToken && (
                                <small id='token-help' className='p-error'>
                                    Неверный токен
                                </small>
                            )}
                        </span>
                    </div>
                </div>
            </Dialog>
        </>

    )
}