import axios from 'axios'

const server = "https://bots.sdew.ru/clv"

export const getAllAnswers = async (onlyEnabled) => {
    return await axios.get(`${server}/smart-answer/list?page=0&size=10&onlyEnabled=${onlyEnabled}`).then((res) => res)
}

export const getOneAnswers = async (answerID) => {
    return await axios.get(`${server}/smart-answer/one?answerID=${answerID}`).then((res) => res)
}


export const createSmartAnswer = async (dataAnswer) => {
    return await axios.post(`${server}/smart-answer/create`, dataAnswer)
}

export const deleteSmartAnswer = async (answerID) => {
    return await axios.delete(`${server}/smart-answer/delete`, { data: { answerID: answerID } })
}

export const addTagAnswer = async (dataTag) => {
    return await axios.put(`${server}/smart-answer/add-tag`, dataTag)
}

export const deleteTagAnswer = async (dataTag) => {
    return await axios.delete(`${server}/smart-answer/delete-tag`, { data: dataTag })
}

export const enableAnswer = async (answerID) => {
    return await axios.put(`${server}/smart-answer/enable`, { answerID: answerID })
}

export const disableAnswer = async (answerID) => {
    return await axios.put(`${server}/smart-answer/disable`, { answerID: answerID })
}

export const enableAllAnswers = async (answerID) => {
    return await axios.put(`${server}/smart-answer/enable-all`, { answerID: answerID })
}

export const disableAllAnswers = async (answerID) => {
    return await axios.put(`${server}/smart-answer/disable-all`, { answerID: answerID })
}

export const coverageMessangersAnsvers = async (start, end) => {
    return await axios.get(`${server}/smart-answer/coverage-messenger?start=${start}&end=${end}`).then((res) => res)
}