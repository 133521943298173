import axios from "axios"
import { Button } from "primereact/button"
import { Toast } from "primereact/toast"
import { confirmPopup } from "primereact/confirmpopup"
import React, { useEffect, useRef, useState } from "react"
import { getFileIcon, slicedText, slicedTextWithExtension } from "../../utils"
import { useSelector } from "react-redux"
import { Tooltip } from "primereact/tooltip"

const UploadFiles = ({
    nodeID,
    setFiles,
    files,
    deleteMediaHandler,
    multiple,
    uploadText,
    filesType,
    filesSize,
    deletedFile,
    setDeletedFile,
    newNodeFile,
    setNewNodeFile,
}) => {
    const toast = useRef(null)

    const [drag, setDrag] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState(null)
    const [filesList, setFilesList] = useState(null)
    const [uploading, setUploading] = useState(false)
    const upload = useRef(null)
    const user = useSelector((state) => state.user.user)
    useEffect(() => {
        uploadFile()
    }, [selectedFiles])

    const formatFiles = (filesToFormat) => {
        setSelectedFiles(filesToFormat)
        setUploading(true)

        const filesToNode = files ? [...files] : []

        for (let i = 0; i < filesToFormat?.length; i++) {
            const obj = {
                type: getFileType(filesToFormat[i].type.split("/")[0]),
                path: filesToFormat[i].name,
                // filename: filesToFormat[i].name,
                caption: "file",
            }
            filesToNode.push(obj)
        }
        // uploadFile()
        setFilesList(filesToNode)

        if (newNodeFile) {
            setNewNodeFile(filesToNode[0])
        }
    }

    const handleChange = (event) => {
        formatFiles(event.target.files)
    }

    const getFileType = (file) => {
        switch (file) {
            case "application":
            case "text":
                return "document"

            // case "i":
            //     return "image"

            default:
                return file
        }
    }

    const uploadFile = async () => {
        for (let i = 0; i < selectedFiles?.length; i++) {
            const formData = new FormData()
            formData.append("file", selectedFiles[i])
            formData.append("fileObject", "node")
            // formData.append("typeInfo", "node file")
            // formData.append("typeFile", selectedFiles[i].type.split("/")[0])
            formData.append("typeFile", getFileType(selectedFiles[i].type.split("/")[0]))
            formData.append("nodeID", nodeID)

            const res = await axios
                .put("https://storage.sdew.ru/fh/file/save-from-form", formData, {
                    headers: {
                        "Content-type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.current.show({ severity: "success", summary: "Успешно", detail: "Файл загружен", life: 3000 })
                        setFiles(filesList)
                    } else {
                        toast.current.show({ severity: "error", summary: "Ошибка", detail: "Файл не загружен", life: 3000 })
                    }
                    setUploading(false)
                })
                .catch((error) => {
                    toast.current.show({ severity: "error", summary: "Ошибка", detail: "Файл не загружен", life: 3000 })
                    setUploading(false)
                    setFiles(filesList?.filter((file) => file.media))
                })
        }
    }

    const dragStartHandler = (e) => {
        e.preventDefault()
        setDrag(true)
    }
    const dragLeaveHandler = (e) => {
        e.preventDefault()
        setDrag(false)
    }
    const onDropHandler = (e) => {
        e.preventDefault()
        let dragFiles = [...e.dataTransfer.files]
        formatFiles(dragFiles)
        setDrag(false)
    }

    // const handleUpload = async () => {
    //     if (!selectedFiles) {
    //         return
    //     }
    //     uploadFile()
    // }

    const getIconType = (type) => {
        return type.toString().split("/").pop()
    }

    const uploadFileByClick = () => {
        const btn = document.querySelector(".custom-choose-btn-form").click()
    }

    const deleteConfirm = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Вы уверены, что хотите удалить файл?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                setDeletedFile([...deletedFile, event])
                deleteMediaHandler(event)
            },
        })
    }

    const isUpload = () => {
        if (multiple) {
        } else {
            if (files?.length > 0) {
                return true
            }
        }
    }

    return (
        <div className='upload-container'>
            <Toast ref={toast} />
            {/* {drag ? <h2>drag</h2> : <h2>no drag</h2>} */}
            {(user?.role === "admin" || user?.role === "moderator") && (
                <>
                    <input
                        ref={upload}
                        type='file'
                        className='hidden'
                        multiple={multiple}
                        accept={!multiple ? "image/png, image/jpeg" : ""}
                        onChange={handleChange}
                    />

                    <div
                        onClick={() => (uploading || isUpload() ? null : uploadFileByClick())}
                        className={`flex align-items-center flex-column form-draganddropfile ${drag ? "drag" : ""}`}
                        style={uploading || isUpload() ? { opacity: "0.3", cursor: "default" } : { opacity: "1" }}
                        onDragStart={(e) => dragStartHandler(e)}
                        onDragLeave={(e) => dragLeaveHandler(e)}
                        onDragOver={(e) => dragStartHandler(e)}
                        onDrop={(e) => onDropHandler(e)}
                    >
                        {/* {drag && (
                    <>
                        <i className='pi pi-image'></i>
                        <span>Перетащите файлы сюда</span>
                    </>
                )} */}
                        {uploading && (
                            <>
                                <div>
                                    <i className='pi pi-spin pi-spinner' style={{ fontSize: "2em" }}></i>
                                </div>

                                <span>Загрузка...</span>
                            </>
                        )}
                        {!uploading && (
                            <>
                                <div>
                                    <i className='pi pi-image'></i>
                                </div>

                                <span>
                                    {uploadText || "Добавить изображение или видео"} {filesType && `(${filesType})`}
                                </span>
                                {filesSize && <span>до {filesSize} мб.</span>}
                            </>
                        )}
                    </div>
                </>
            )}

            <Button onClick={() => upload.current.click()} icon='pi pi-fw pi-paperclip' className='custom-choose-btn-form hidden' />

            {files && files?.length !== 0 && <div className='media-items'>Прикрепленные файлы</div>}
            {files?.map((file) => (
                <div className='media-item' key={file.media}>
                    <Tooltip target='.media-item-name' position='top' />
                    <div>
                        {getFileIcon(file.type.toString().split("/").pop(), "var(--text-color)")}
                        <span className='media-item-name' data-pr-tooltip={file.path}>
                            {slicedText(file.path, 30)}
                        </span>
                    </div>
                    {(user?.role === "admin" || user?.role === "moderator") && (
                        <Button className='p-button-rounded button-delete' icon='pi pi-trash' onClick={() => deleteConfirm(file)} />
                    )}
                </div>
            ))}
        </div>
    )
}

export default UploadFiles
