export const formatDate = (value) => {
    const date = [
        addZero(value?.getDate()),
        addZero(value?.getMonth() + 1),
        value?.getFullYear()
    ].join('.')

    function addZero(val) {
        if (val < 10) {
            return '0' + val;
        }
        return val;
    };

    return date
}

export const initialDateStart = () => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    return today.toISOString()
}

export const initialDateEnd = () => {
    return new Date().toISOString()
}

export const initialSelect = () => {
    if (localStorage.getItem("selectDate") !== null) {

        return localStorage.getItem("selectDate")

    } else {
        return 'today'
    }
}