import { Skeleton } from 'primereact/skeleton';
import './Events.css'

export const SkeletonEvents = () => {
    const skeletonsEvent = [1, 2, 3, 4, 5, 6]

    return <div className="card h-full">
        <Skeleton width='7rem' height='1.5rem' />

        <div className='w-full mt-5'>
            {skeletonsEvent.map((item, idx) => {
                return <div key={idx}>
                    <span className='grid mb-3'>
                        <div className='col-4'>
                            <div className="flex justify-content-start">
                                <Skeleton width='7rem' />
                            </div>

                        </div>
                        <div className="col-4 text-center">
                            <div className="flex justify-content-center">
                                <Skeleton width='7rem' />
                            </div>
                        </div>
                        <div className="col-4 text-right">
                            <div className="flex justify-content-end">
                                <Skeleton width='7rem' />
                            </div>
                        </div>
                    </span>

                </div>

            })}
        </div>
    </div>

}