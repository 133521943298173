import Cookies from 'js-cookie'

export const LIGHT_THEME = 'light'
export const DARK_THEME = 'dark'

export const setCookieColorMode = (colorMode) => {
    Cookies.set('colorMode', colorMode)
}

export const getPreferredColorScheme = () => {
    if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return DARK_THEME
        } else {
            return LIGHT_THEME
        }
    }

    return LIGHT_THEME
}

export const getCookieColorMode = () => {
    return Cookies.get('colorMode') || getPreferredColorScheme()
}



export const setCookieSelectedTheme = (selectedTheme) => {
    Cookies.set('theme', selectedTheme)
}

export const getCookieSelectedTheme = () => {
    return Cookies.get('theme') || 'sistem'
}

export const getThemeCss = (theme) => `bootstrap4-${theme}-blue`



export const updateCss = (theme) => {
    let themeElement = document.getElementById('theme-link')
    const themeHref = `${process.env.REACT_APP_PUBLIC_URL}/assets/themes/${getThemeCss(theme)}/theme.css`
    replaceLink(themeElement, themeHref)
}


export const replaceLink = (linkElement, href, callback) => {
    if (isIE()) {
        linkElement.setAttribute('href', href)

        if (callback) {
            callback()
        }
    } else {
        const id = linkElement.getAttribute('id')
        const cloneLinkElement = linkElement.cloneNode(true)

        cloneLinkElement.setAttribute('href', href)
        cloneLinkElement.setAttribute('id', id + '-clone')

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling)

        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove()
            cloneLinkElement.setAttribute('id', id)

            if (callback) {
                callback()
            }
        })
    }
}

export const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
}