import { Button } from "primereact/button"
import { useState } from "react"
import { useSelector } from "react-redux"
import { SvgIconsFilters } from "../SvgIconsFilters/SvgIconsFilters"


export const MessengersFilter = () => {
    const [activeMessanger, setActiveMessanger] = useState(null)
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    return (
        <div className="flex flex-column align-items-start">
            <span className="ml-2">Мессенджер</span>
            <div>
                <Button className={activeMessanger === 'telegram' ? `p-button-text active p-2` : `p-button-text ${colorTheme} p-2`} onClick={() => setActiveMessanger('telegram')}>
                    <SvgIconsFilters name='telegram' width={20} height={20} />
                </Button>
                <Button className={activeMessanger === 'whatsapp' ? `p-button-text active p-2` : `p-button-text ${colorTheme} p-2`} onClick={() => setActiveMessanger('whatsapp')}>
                    <SvgIconsFilters name='whatsapp' width={20} height={20} />
                </Button>
                <Button className={activeMessanger === 'vk' ? `p-button-text active p-2` : `p-button-text ${colorTheme} p-2`} onClick={() => setActiveMessanger('vk')}>
                    <SvgIconsFilters name='vk' width={20} height={20} />
                </Button>
                <Button className={activeMessanger === 'yandex' ? `p-button-text active p-2` : `p-button-text ${colorTheme} p-2`} onClick={() => setActiveMessanger('yandex')}>
                    <SvgIconsFilters name='yandex' width={13} height={20} />
                </Button>
                <Button className={activeMessanger === 'sms' ? `p-button-text active p-2` : `p-button-text ${colorTheme} p-2`} onClick={() => setActiveMessanger('sms')}>
                    <SvgIconsFilters name='sms' width={20} height={20} />
                </Button>
            </div>


        </div>
    )
}