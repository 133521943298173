import { Button } from "primereact/button"
import { useState } from "react"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { updateCss } from "../../service/theme"
import { CardAuth } from "./CardAuth"

export const ConfirmSuccess = () => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const [seconds, setSeconds] = useState(5)

    useEffect(() => {
        updateCss(colorTheme)
    }, [colorTheme])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (seconds > 0) {
                setSeconds(prevstate => prevstate - 1)
            } else {
                window.location.replace('/login');
            }

        }, 1000)

        return () => clearInterval(timer)
    }, [seconds])


    return <div className={`Authorization ${colorTheme} h-full`}>
        <CardAuth>
            <>
                <div>
                    <p className='registration-text mt-5 mb-3'>
                        Ваша почта подтверждена, теперь можно войти в систему и пользоваться сервисом
                    </p>
                </div>
                <p className="timer-redirect">Вернем вас на страницу входа через {seconds} секунд</p>
            </>
        </CardAuth>
    </div>
}