import { useSelector } from "react-redux"
import { ChartMini } from "./ChartMini"
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export const CarouselFunnel = ({ item, currentFunnel, setCurrentFunnel, id, updateHandler }) => {

    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const update = (item) => {
        setCurrentFunnel(item.id)
        updateHandler(item)
    }

    return (
        <div className={item.сonversion < 1 ? 'problem' : 'green' && (item.conversion < 50 && item.conversion > 20 ? 'yellow' : 'green')}>
            <div className={currentFunnel === item.id ? `card-funnel ${colorTheme} mr-2 pb-1 active` : `card-funnel ${colorTheme} mr-2 pb-1`} id={item.id} onClick={() => update(item)}>
                <h6 className='card-funnel_title'>{item.title}</h6>
                <p className={item.сonversion < 1 ? 'card-funnel_views problem' : 'card-funnel_views green' && (item.conversion < 50 && item.conversion > 20 ? 'card-funnel_views yellow' : 'card-funnel_views green')}>{Intl.NumberFormat('ru-RU').format(item.sum)} просмотра</p>
                <ChartMini id={`${id}-${item.id}`} item={item} />
            </div>

        </div>

    )

}