import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useParams } from "react-router-dom"
import { updatePassword } from "../../../service/slices/authSlice"
import { updateCss } from "../../../service/theme"
import { CardAuth } from "../CardAuth"
/* import { useSearchParams } from "react-router-dom" */

export const NewPassword = () => {
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [showSuccessUpdate, setShowSuccessUpdate] = useState(false)
    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const [passwordDirty, setPasswordDirty] = useState(false)
    const [passwordRepeatDirty, setPasswordRepeatDirty] = useState(false)
    const [passwordError, setPasswordError] = useState('Введите пароль')
    const [passwordRepeatError, setPasswordRepeatError] = useState('Повторите пароль')
    const { search } = useLocation()

    const searchParams = new URLSearchParams(search)
    const token = searchParams.get('token')
    const error = useSelector((state) => state.auth.error)
    console.log(error);

    useEffect(() => {
        updateCss(colorTheme)
    }, [colorTheme])

    const dispatch = useDispatch()
    const restoreHandler = () => {
        let data = {
            token: token,
            password: password,
        }

        dispatch(updatePassword(data))
            .then(() => {
                if (error) {
                    setShowSuccessUpdate(false)
                } else {
                    setShowSuccessUpdate(true)
                }
            })
    }

    const footer = (
        <>
            <Divider />
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>Хотя бы одна строчная латинская буква</li>
                <li>Хотя бы одна заглавная латинская буква</li>
                <li>Хотя бы одно число</li>
                <li>Хотя бы один специальный символ</li>
                <li>Длина не менее 6 символов</li>
            </ul>
        </>
    )

    const passwordHandler = (e) => {
        setPassword(e.target.value)
        const re = /^(?=[^А-Яа-я\s]{9,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=[^А-Яа-я\s]+\d|\d)((?=[^А-Яа-я\s]+[a-z]|[a-z]){1})((?=[^А-Яа-я\s]+[A-Z]|[A-Z]){1})[^А-Яа-я\s]+$/
        if (!e.target.value) {
            setPasswordError('Введите пароль')
        } else if (!re.test((e.target.value))) {
            setPasswordError('Слабый пароль')
        } else {
            setPasswordError('')
        }
    }

    const passwordRepeatHandler = (e) => {
        setRepeatPassword(e.target.value)

        if (!e.target.value) {
            setPasswordRepeatError('Повторите пароль')
        } else if (password !== e.target.value) {
            setPasswordRepeatError('Пароли не совпадают')
        } else {
            setPasswordRepeatError('')
        }
    }

    const blurHandler = (e) => {
        switch (e.target.name) {

            case 'password':
                setPasswordDirty(true)
                break;
            case 'repeatPassword':
                setPasswordRepeatDirty(true)
                break;
        }
    }

    return (
        <div className={`Authorization ${colorTheme} h-full`}>
            <CardAuth>
                {showSuccessUpdate ?
                    <>
                        <p className="my-5 title">Поздравляем!</p>

                        <div>
                            <p className='registration-text mt-5 mb-3'>
                                Пароль успешно изменен
                            </p>
                        </div>
                        <Link to={'/login'}>
                            <Button
                                label='Продолжить'
                                className="w-full sign_in py-3 my-5" />
                        </Link>
                    </>
                    :
                    <>
                        <p className="text-left my-5 title">
                            Восстановление пароля
                        </p>
                        <div>
                            {/* <InputText
                                id="email"
                                type="text"
                                className="w-full mb-3"
                                placeholder='Новый пароль'
                                onChange={(e) => setPassword(e.target.value)} /> */}
                            <Password
                                value={password}
                                name='password'
                                className={`w-full ${(passwordDirty && passwordError) ? 'error-margin' : 'default-margin'}`}
                                placeholder='Новый пароль'
                                toggleMask
                                footer={footer}
                                onChange={passwordHandler}
                                onBlur={(e) => blurHandler(e)}
                                promptLabel={'Пожалуйста, введите пароль'}
                                weakLabel={'Слабый'}
                                mediumLabel={'Средний'}
                                strongLabel={'Сильный'}
                                strongRegex={'^(?=.*[0-9])(?=.*[!@#$%^&*.<>])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}'}
                            />
                            {(passwordDirty && passwordError)
                                && <small
                                    className="p-error block error-margin">
                                    {passwordError}
                                </small>}
                            {/*  <InputText
                                id="email"
                                type="text"
                                className="w-full mb-6"
                                placeholder='Повторите пароль'
                                onChange={(e) => setRepeatPassword(e.target.value)} /> */}
                            <Password
                                value={repeatPassword}
                                name='repeatPassword'
                                toggleMask
                                feedback={false}
                                className={`w-full ${(passwordRepeatDirty && passwordRepeatError) || error ? 'error-margin' : 'default-margin'}`}
                                placeholder='Повторите пароль'
                                onBlur={(e) => blurHandler(e)}
                                onChange={passwordRepeatHandler}
                            />
                            {(passwordRepeatDirty && passwordRepeatError)
                                && <small
                                    className="p-error block error-margin">
                                    {passwordRepeatError}
                                </small>}
                            {error
                                && <small
                                    className="p-error block error-margin">
                                    {error}
                                </small>}
                            {/* <Link to={'/login'}> */}
                            <Button
                                label='Продолжить'
                                disabled={passwordError || passwordRepeatError}
                                onClick={restoreHandler}
                                className="w-full sign_in py-3" />
                            {/*  </Link> */}

                        </div>
                    </>
                }
            </CardAuth>

        </div>
    )
}