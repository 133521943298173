import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AddNewCard } from './AddNewCard'
import { EditPayment } from './EditPayment'
import './Payments.css'
import { PaymentToAccount } from './PaymentToAccount'
import axios from 'axios'
import { Skeleton } from 'primereact/skeleton'
import { Cards } from './Cards'
import { log } from '../../../service/log'

export const Payments = () => {
    const colorTheme = useSelector((state) => state.colorTheme.theme)

    const [historyPayments, setHistoryPayments] = useState(null)
    const [paymentToAccount, setPaymentToAccount] = useState(false)

    useEffect(() => {
        document.title = 'Bot Magic | Платежи и карты'
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_URL}/data.json`)
            .then((response) => {
                log(response.data)
                setHistoryPayments(response.data.payments.history_payments)
            })
            .catch((error) => console.error(error))

    }, [])


    const cardTemplate = (rowData) => {
        return (
            <div className={`card-mini ${colorTheme} flex flex-column justify-content-between`}>
                <p>*{rowData.number}</p>
                <div className='flex justify-content-end'>
                    <div className={`wrapper-payment_sistem ${colorTheme}`}>
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/${rowData.payment_system}.svg`}></img>
                    </div>
                </div>

            </div>
        )
    }

    const sumTemplate = (rowData) => {
        return (
            <div className='flex justify-content-end'>
                {rowData.type === 'payment' ?
                    <p className='sum'>-{Intl.NumberFormat('ru-RU').format(rowData.sum)}₽</p>
                    :
                    <p className='sum receiving'>+{Intl.NumberFormat('ru-RU').format(rowData.sum)}₽</p>
                }
            </div>
        )
    }

    const dateTemplate = (rowData) => {
        return (
            <div className='flex flex-column align-items-end'>
                <p className='date-payment mb-0'> {rowData.date}</p>
                <p className='date-payment'>{rowData.time}</p>
            </div>
        )
    }

    const actionTemplate = (rowData) => {
        return (
            <div className='flex flex-column align-items-start'>
                <p className='payment-action mb-0'> {rowData.action}</p>
                <p className='date-payment'>{rowData.description}</p>
            </div>
        )
    }

    const skeleton = [1, 2, 3]

    const actionSkeletonTemplate = () => {
        return (
            <div className='flex flex-column align-items-start'>
                <Skeleton width='20rem' height='1.5rem' className='mb-2' />
                <Skeleton width='15rem' />
            </div>
        )
    }

    const sumSkeletonTemplate = () => {
        return (
            <div className='flex justify-content-end'>
                <Skeleton width='7rem' height='2rem' />
            </div>
        )
    }
    const cardSkeletonTemplate = () => {
        return <Skeleton width='80px' height='100px' borderRadius='6px' />
    }

    const dateSkeletonTemplate = () => {
        return (
            <div className='flex flex-column align-items-end'>
                <Skeleton width='7rem' className='mb-2' />
                <Skeleton width='3rem' />
            </div>
        )
    }
    return (
        <>
            {historyPayments
                ?
                <div className='pt-5'>
                    <h2 className="settings-title">Способы оплаты подписки на сервис</h2>
                    <div className='flex'>
                        <Button className={`payments-btn ${colorTheme} mr-3 sm:mr-5`} >
                            <div className="flex w-full h-full">
                                <div className="p-0 flex flex-column justify-content-between align-items-start">
                                    <div>
                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/badge.svg`}></img>
                                    </div>

                                    <p className='payments-btn_text'>Оплатить подписку <br /> как физ. лицо</p>
                                </div>
                            </div>
                        </Button>

                        <Button className={`payments-btn ${colorTheme}`} onClick={() => setPaymentToAccount(true)}>
                            <div className="flex w-full h-full">
                                <div className="p-0 flex flex-column justify-content-between align-items-start">
                                    <div>
                                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/layout/images/apartment.svg`}></img>
                                    </div>

                                    <p className='payments-btn_text'>Оплатить по счету <br /> (как юр. лицо)</p>
                                </div>
                            </div>
                        </Button>
                    </div>

                </div>
                :

                <div className='flex mt-5'>
                    <Skeleton width="255px" height="154px" borderRadius="20px" className='mr-3' />

                    <Skeleton width="255px" height="154px" borderRadius="20px" className='' />
                </div>
            }

            {historyPayments ?
                <div className="card setting mt-6">
                    <h2 className="settings-title">История платежей</h2>
                    <DataTable value={historyPayments} responsiveLayout="scroll">
                        <Column className='w-1' body={cardTemplate}></Column>
                        <Column body={actionTemplate}></Column>
                        <Column body={sumTemplate}></Column>
                        <Column body={dateTemplate}></Column>

                    </DataTable>
                </div>
                :
                <div className="card setting mt-6">
                    <Skeleton width='17rem' height='2rem' />
                    <DataTable value={skeleton} responsiveLayout="scroll">
                        <Column className='w-1' body={cardSkeletonTemplate}></Column>
                        <Column body={actionSkeletonTemplate}></Column>
                        <Column body={sumSkeletonTemplate}></Column>
                        <Column body={dateSkeletonTemplate}></Column>

                    </DataTable>
                </div>
            }
            <PaymentToAccount visible={paymentToAccount} onHide={setPaymentToAccount} />
        </>

    )
}