import React, { useState, useEffect } from "react"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { useSelector } from "react-redux"

const WebhookField = ({ id, data, deleteHandler, onChangeHandler }) => {
    const [title, setTitle] = useState(data?.title)
    const [value, setValue] = useState(data?.value)
    const user = useSelector((state) => state.user.user)
    useEffect(() => {
        onChangeHandler(id, title, value)
    }, [title, value])

    return (
        <div className='flex media-item mb-3'>
            <div className='w-6'>
                <span className='p-float-label'>
                    <InputText id='title' className='block text w-full' value={title} onChange={(e) => setTitle(e.target.value)} readOnly={user?.role === 'employer'} />
                    <label htmlFor='title'>Название</label>
                </span>
            </div>
            <div className='w-4'>
                <span className='p-float-label'>
                    <InputText id='value' className='block text w-full' value={value} onChange={(e) => setValue(e.target.value)} readOnly={user?.role === 'employer'} />
                    <label htmlFor='value'>Значение</label>
                </span>
            </div>
            {/* <InputText className='text w-6' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Название' /> */}
            {/* <InputText className='text w-4' value={value} onChange={(e) => setValue(e.target.value)} placeholder='Значение' /> */}
            {(user?.role === 'admin' || user?.role === 'moderator') &&
                <Button className='p-button-rounded button-delete' icon='pi pi-trash' onClick={() => deleteHandler(id)} />}
        </div>
    )
}

export default WebhookField
