import axios from "axios"
import Cookies from "js-cookie"
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { Password } from "primereact/password"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { login, resendEmail, setError } from "../../../service/slices/authSlice"
import './Login.css'

export const LoginForm = () => {
    const dispatch = useDispatch()

    const colorTheme = useSelector((state) => state.colorTheme.theme)
    const isAuth = useSelector(state => state.auth.isAuth)
    const error = useSelector(state => state.auth.error)
    const errorResend = useSelector(state => state.auth.errorResend)

    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailDirty, setEmailDirty] = useState(false)
    const [passwordDirty, setPasswordDirty] = useState(false)

    const [emailError, setEmailError] = useState('Email не может быть пустым')
    const [passwordError, setPasswordError] = useState('Пароль не может быть пустым')

    const [formValid, setFormValid] = useState(false)

    const [noValid, setNoValid] = useState(false)

    const [successResend, setSuccessResend] = useState(false)

    useEffect(() => {
        if (emailError || passwordError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [emailError, passwordError])

    const baseURL = `https://bots.sdew.ru`

    const signIn = (e) => {
        e.preventDefault()
        let data = {
            username: email,
            password: password
        };
        dispatch(login(data))
            .then((res) => {
                Cookies.set('user-email', email)
            })
            .catch((error) => {
                setNoValid(true)
            })

        /* {
        if (!res.payload.access_token) {
            setNoValid(true)
        } else {
            Cookies.set('user-email', email)
        }
    }) */
        /* app.post(`/auth/login`, data).then((res) => {
            Cookies.set('auth', true);
            window.location.replace("/")

        }) */

    }

    const emailHandler = (e) => {
        setNoValid(false)
        setEmail(e.target.value)
        dispatch(setError(null))
        const re = /^[_a-z0-9-\+-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i

        if (!e.target.value) {
            setEmailError('Введите Email')
        } else if (!re.test((e.target.value).toLowerCase())) {
            setEmailError('Некорректный Email')
        } else {
            setEmailError('')
        }
    }

    const passwordHandler = (e) => {
        setNoValid(false)
        dispatch(setError(null))
        setPassword(e.target.value)

        if (!e.target.value) {
            setPasswordError('Пароль не может быть пустым')
        } else {
            setPasswordError('')
        }
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'email':
                setEmailDirty(true)
                break;
            case 'password':
                setPasswordDirty(true)
                break;
        }
    }

    const resendHandler = () => {
        let data = {
            email: email,
        }
        dispatch(resendEmail(data)).then((res) => {
            if (res.payload === 'Ссылка устарела') {
                setSuccessResend(false)
            } else {
                setSuccessResend(true)
            }
        })
    }

    return (
        <>
            <p className="text-left my-5 title">Рады снова видеть вас!</p>
            <form
                onSubmit={signIn}>
                <InputText
                    id="email"
                    type="text"
                    name='email'
                    className={`w-full ${(emailDirty && emailError)
                        ? 'error-margin'
                        : 'default-margin'}`}
                    placeholder='Введите Email'
                    onChange={emailHandler}
                    onBlur={(e) => blurHandler(e)}
                />
                {(emailDirty && emailError)
                    && <small
                        className="p-error block error-margin">
                        {emailError}
                    </small>}

                <Password
                    value={password}
                    name='password'
                    className={`w-full ${(passwordDirty && passwordError) || error ? 'error-margin' : 'default-margin'}`}
                    onChange={passwordHandler}
                    onBlur={(e) => blurHandler(e)}
                    toggleMask
                    feedback={false}
                    placeholder='Введите пароль' />

                {(passwordDirty && passwordError)
                    && <small
                        className="p-error block error-margin">
                        {passwordError}
                    </small>}

                {error === 'Почта пользователя не подтверждена'
                    ? <>
                        {successResend
                            ? <div className="success-resend">
                                На ваш Email отправлено письмо для подтверждения. Откройте его и перейдите по предложеной ссылке.
                            </div>
                            : <div className="error-confirm">
                                <div className="error-confirm_header">
                                    <h6 className="title-error">{error}</h6>
                                    <i className="pi pi-times" onClick={() => dispatch(setError(null))} style={{ fontSize: '14px', marginBottom: '14px', cursor: 'pointer' }}></i>
                                </div>
                                <span className="error-confirm_description">
                                    Похоже, ваша почта не подтверждена, если потеряли письмо, то отправьте его еще раз.
                                </span>
                                <Button className="resend-email_btn" label="Отправить письмо" onClick={resendHandler}></Button>
                                {errorResend && <small
                                    className="p-error block error-margin mt-2">
                                    {errorResend}
                                </small>}
                            </div>
                        }
                    </>
                    : <small
                        className="p-error block error-margin">
                        {error}
                    </small>
                }

                <div className="flex align-items-center justify-content-between mb-6">
                    <div className="flex align-items-center">
                        <InputSwitch
                            className='settings-switch mr-2'
                            checked={checked}
                            onChange={(e) => setChecked(e.value)} />
                        <label
                            className='remember-label'
                            htmlFor="rememberme">
                            Запомнить</label>
                    </div>
                    <Link
                        className="text-primary problems ml-2 text-right cursor-pointer"
                        to={'login/password_recovery'}
                        exact>
                        Проблемы с входом?
                    </Link>
                </div>
                <Button
                    label='Войти'
                    type="submit"
                    className="w-full sign_in py-3 mb-5"
                    disabled={!formValid} />
                <Divider
                    className='m-0' />
            </form>
            {/*  <div className='flex justify-content-between mt-5'>
                <Button className="p-button-outlined login_yandex mr-3">
                    <span>Войти через Яндекс</span>
                </Button>
                <Button className="p-button-outlined login_vk">
                    <span>Войти через VK</span>
                </Button>
            </div> */}
            <div className="text-center mt-5">
                <span
                    className="line-height-3 no_account">
                    Еще нет аккаунта?
                </span>
                <Link
                    className="text-primary ml-2 text-right cursor-pointer no_account"
                    to={'/registration'}
                    exact>
                    Зарегистрируйтесь
                </Link>
            </div>
            {/* <p className='text-center mt-5 footer mb-2'>© Bots 2022</p> */}

        </>


    )
}